import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { FileContext, ILegalSystemNavigatorFields } from './FileContext';
import DatePicker from 'react-datepicker';
import { UsersContext } from '../../contexts/UsersContext';
import { IUser } from '../Users/UsersTable';
import CreatableSelect from 'react-select/creatable';
import {
  DEPARTMENT_JUSTICE,
  DomainContext,
  IIndigenousGroup,
  ILegalSystemNavigatorTypeOfService,
  INDIGENOUS_GROUP_FIRST_NATION,
  INDIGENOUS_GROUP_INUIT,
  INDIGENOUS_GROUP_METIS,
  INDIGENOUS_GROUP_OTHER,
  IStage,
} from '../../contexts/DomainContext';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { MyUserContext } from '../../contexts/MyUserContext';

export const LegalSystemNavigatorFileDetails: React.FC<{
  isEditingAllowed: boolean;
  trySave: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateReminder?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenUncheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const fileContext = useContext(FileContext);
  const usersContext = useContext(UsersContext);
  const domainContext = useContext(DomainContext);
  const myUser = useContext(MyUserContext);
  const stages = domainContext.stages;
  const yukonFns = domainContext.yukonFns;
  const indigenousGroups = domainContext.indigenousGroups;
  const legalSystemNavigatorTypesOfService = domainContext.legalSystemNavigatorTypesOfService;

  const staff = usersContext.users;
  const [selectedStaff, setSelectedStaff] = useState<IUser[]>([]);
  const [changedCourtDatesListLength, setChangedCourtDatesListLength] = useState(0);

  const firstNationOptions = yukonFns.map((fn) => ({ value: fn.yukonFirstNationId, label: fn.name }));

  useEffect(() => {
    setSelectedStaff(staff.filter((s) => fileContext.staffIds.includes(s.userId)));
  }, [staff, fileContext.staffIds]);

  useEffect(() => {
    if (fileContext.courtDatesList.length === 0) {
      fileContext.setCourtDatesList((cdl) =>
        cdl.concat([{ courtDateId: undefined, date: undefined, details: undefined, addReminder: true }])
      );
    }
  }, [fileContext.courtDatesList]);

  useEffect(() => {
    if (changedCourtDatesListLength > 0) {
      var elmt2 = document.getElementById('select-court-date-date');
      if (elmt2) {
        elmt2.focus();
        elmt2.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [changedCourtDatesListLength]);

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
        borderRadius: '1.25rem',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
    menu: (provided: any) => {
      return {
        ...provided,
        zIndex: 9999,
      };
    },
  };

  const contactTypeOptions = [
    { value: 'Phone Number', label: 'Phone Number' },
    { value: 'Email', label: 'Email' },
    { value: 'Address', label: 'Address' },
  ];

  return (
    <>
      <Row className={'mb-4'}>
        <Col>
          <Card className={'mb-3'}>
            <CardHeader>Court Details</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'participated'}>Offender</Label>
                    <Input
                      value={fileContext.legalSystemNavigatorFields?.offender}
                      type={'text'}
                      onChange={(value: any) => {
                        fileContext.setLegalSystemNavigatorFields(
                          (s) => ({ ...s, offender: value?.target.value } as ILegalSystemNavigatorFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'participated'}>Complainant</Label>
                    <Input
                      value={fileContext.legalSystemNavigatorFields?.complainant}
                      type={'text'}
                      onChange={(value: any) => {
                        fileContext.setLegalSystemNavigatorFields(
                          (s) => ({ ...s, complainant: value?.target.value } as ILegalSystemNavigatorFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>Charges</Label>
                    <Input
                      type='textarea'
                      placeholder=''
                      style={{ minHeight: '226px' }}
                      name='name'
                      value={fileContext.legalSystemNavigatorFields?.charges}
                      disabled={!props.isEditingAllowed}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const charges = e.target.value;
                        fileContext.setLegalSystemNavigatorFields(
                          (s) => ({ ...s, charges } as ILegalSystemNavigatorFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className={'mb-3'}>
            <CardHeader>Court Dates</CardHeader>
            <CardBody>
              <ListGroup flush={true}>
                {fileContext.courtDatesList &&
                  fileContext.courtDatesList.map((c, cIndex) => {
                    const dateTime = fileContext.courtDatesList[cIndex]?.date;
                    const date = dateTime ? new Date(dateTime) : undefined;
                    return (
                      <ListGroupItem key={cIndex} className={'py-0 border-0'}>
                        <Row className={'px-0'}>
                          <Col className={'pl-0'} md={3}>
                            <FormGroup className={'w-100 mb-2'}>
                              <Label for={'court-date-date'}>Court Date</Label>
                              <DatePicker
                                id={cIndex === fileContext.courtDatesList.length - 1 ? 'select-court-date-date' : ''}
                                selected={date}
                                name={'court-date-date' + '_' + cIndex.toString()}
                                onChange={(date) => {
                                  fileContext.setCourtDatesList((oldCDL) => [
                                    ...oldCDL.slice(0, cIndex),
                                    {
                                      ...oldCDL[cIndex],
                                      date: date ? date.getTime() : undefined,
                                    },
                                    ...oldCDL.slice(cIndex + 1),
                                  ]);
                                  if (props.setUpdateReminder && fileContext.courtDatesList[cIndex].addReminder) {
                                    props.setUpdateReminder(true);
                                  }
                                  props.setIsDirty(true);
                                }}
                                peekNextMonth
                                showMonthDropdown
                                isClearable={true}
                                disabled={!props.isEditingAllowed}
                                showYearDropdown
                                dateFormat='MMM dd, yyyy'
                                dropdownMode='select'
                                className={
                                  props.isEditingAllowed
                                    ? 'date-picker rounded mb-0'
                                    : 'date-picker-disabled rounded mb-0'
                                }
                                placeholderText={'MMM dd, yyyy'}
                              />
                            </FormGroup>
                          </Col>
                          <Col className={'pl-0'} md={1}>
                            <FormGroup className={'mb-2'}>
                              <Label for={'court-date-add-reminder'}>Reminder</Label>
                              <CustomInput
                                className={'mt-2'}
                                type='checkbox'
                                id={'addReminder' + cIndex.toString()}
                                name={'addReminder'}
                                label={''}
                                checked={fileContext.courtDatesList[cIndex].addReminder}
                                onChange={() => {
                                  fileContext.setCourtDatesList((oldCDL) => [
                                    ...oldCDL.slice(0, cIndex),
                                    {
                                      ...oldCDL[cIndex],
                                      addReminder: !oldCDL[cIndex].addReminder,
                                    },
                                    ...oldCDL.slice(cIndex + 1),
                                  ]);
                                  if (props.setUpdateReminder && fileContext.courtDatesList[cIndex].date) {
                                    props.setUpdateReminder(true);
                                  }
                                  props.setIsDirty(true);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col className={'pl-4'} md={7}>
                            <FormGroup className={'mb-2'}>
                              <Label for={'court-date-details'}>Details</Label>
                              <Input
                                type='text'
                                value={fileContext.courtDatesList[cIndex].details}
                                onChange={(e) => {
                                  const details = e.target.value;
                                  fileContext.setCourtDatesList((oldCDL) => [
                                    ...oldCDL.slice(0, cIndex),
                                    {
                                      ...oldCDL[cIndex],
                                      details: details,
                                    },
                                    ...oldCDL.slice(cIndex + 1),
                                  ]);
                                  props.setIsDirty(true);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Button
                            color={'link'}
                            className={'text-danger mt-3'}
                            onClick={() => {
                              if (
                                fileContext.courtDatesList[cIndex].date &&
                                fileContext.courtDatesList[cIndex].addReminder &&
                                props.setUpdateReminder
                              ) {
                                props.setUpdateReminder(true);
                              }
                              fileContext.setCourtDatesList((oldCDL) => [
                                ...oldCDL.slice(0, cIndex),
                                ...oldCDL.slice(cIndex + 1),
                              ]);
                              props.setIsDirty(true);
                            }}
                          >
                            <FaTimes />
                          </Button>
                        </Row>
                      </ListGroupItem>
                    );
                  })}
                {fileContext.courtDatesList.length > 0 && (
                  <ListGroupItem
                    id={'add-new-court-date'}
                    onClick={() => {
                      fileContext.setCourtDatesList((cdl) =>
                        cdl.concat([{ date: undefined, details: undefined, addReminder: true }])
                      );
                      setChangedCourtDatesListLength((c) => c + 1);
                      props.setIsDirty(true);
                    }}
                    className={'pl-0'}
                    tag={'button'}
                  >
                    <FaPlus /> Add Court Date
                  </ListGroupItem>
                )}
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>File Details</CardHeader>
            <CardBody>
              {myUser.isSuperAdmin && (
                <Row className='square border-bottom mb-2'>
                  <Col>
                    <FormGroup>
                      <Label for={'isConfidential'}>Confidentiality</Label>
                      <CustomInput
                        type='checkbox'
                        id={'isConfidential'}
                        name={'isConfidential'}
                        label={'Highly Confidential File'}
                        checked={fileContext.isConfidential}
                        onChange={() => {
                          if (fileContext.isConfidential) {
                            props.setOpenUncheckConfidentialityModal(true);
                          } else {
                            props.setOpenCheckConfidentialityModal(true);
                          }
                          fileContext.setIsConfidential(!fileContext.isConfidential);
                          props.setIsDirty(true);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'stageId'}>Stage</Label>
                    <Select
                      styles={disabledSelectStyles}
                      name={'stageId'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={stages.filter(
                        (s) =>
                          s.fileTypes &&
                          s.fileTypes.includes(fileContext.fileTypeId ? fileContext.fileTypeId : '') &&
                          (s.fileSubTypes
                            ? s.fileSubTypes.includes(fileContext.fileSubTypeId ? fileContext.fileSubTypeId : '')
                            : true)
                      )}
                      value={stages.find((s: IStage) => s.stageId === fileContext.stageId)}
                      onChange={(value: any) => {
                        fileContext.setStageId(value?.stageId || '');
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: IStage) => option.stageId}
                      getOptionLabel={(option: IStage) => option.name}
                      isDisabled={!props.isEditingAllowed}
                    />
                    <FormFeedback>This field is mandatory</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'application-date'}>Created Date</Label>
                    <DatePicker
                      selected={fileContext.createdDate}
                      name={'application-date'}
                      onChange={(date) => {
                        fileContext.setCreatedDate(date ? date : undefined);
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      disabled={!props.isEditingAllowed}
                      showYearDropdown
                      isClearable={true}
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                    {props.trySave && fileContext.createdDate === undefined && (
                      <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'staffIds'}>Staff</Label>
                    <Select
                      styles={disabledSelectStyles}
                      name={'staffIds'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={
                        fileContext.isConfidential
                          ? staff.filter(
                              (u) =>
                                (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                u.departmentAccessIds.includes(DEPARTMENT_JUSTICE) &&
                                u.role === 'SuperAdmin'
                            )
                          : staff.filter(
                              (u) =>
                                (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                u.departmentAccessIds.includes(DEPARTMENT_JUSTICE)
                            )
                      }
                      onChange={(value: any) => {
                        fileContext.setStaffIds(value.map((v: IUser) => v.userId));
                        if (props.setUpdateReminder) {
                          props.setUpdateReminder(true);
                        }
                        props.setIsDirty(true);
                      }}
                      value={selectedStaff}
                      getOptionValue={(option: IUser) => option.userId}
                      getOptionLabel={(option: IUser) =>
                        option.lastName + ', ' + option.firstName + (option.active ? '' : ' (Inactive)')
                      }
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                      isMulti={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'mb-2'}>
                    <Label>Indigenous Group</Label>
                    <Select
                      name={'indigenousGroup'}
                      styles={disabledSelectStyles}
                      options={indigenousGroups}
                      placeholder={<span className='placeholder'>Select...</span>}
                      value={
                        indigenousGroups.find(
                          (d: any) => d.indigenousGroupId === fileContext.legalSystemNavigatorFields?.indigenousGroupId
                        ) ?? undefined
                      }
                      onChange={(value) => {
                        fileContext.setLegalSystemNavigatorFields(
                          (s) =>
                            ({
                              ...s,
                              indigenousGroupId: value ? value.indigenousGroupId : undefined,
                              bandNation: undefined,
                            } as ILegalSystemNavigatorFields)
                        );
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: IIndigenousGroup) => option.indigenousGroupId}
                      getOptionLabel={(option: IIndigenousGroup) => option.name}
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
                {fileContext.legalSystemNavigatorFields?.indigenousGroupId === INDIGENOUS_GROUP_FIRST_NATION && (
                  <Col>
                    <FormGroup className={'mb-2'}>
                      <Label>First Nation</Label>
                      <CreatableSelect
                        formatCreateLabel={(inputValue: string) => inputValue}
                        placeholder={<span className='placeholder'>Select or Type...</span>}
                        styles={disabledSelectStyles}
                        options={firstNationOptions}
                        value={
                          firstNationOptions.find(
                            (firstNation) => firstNation.value === fileContext.legalSystemNavigatorFields?.bandNation
                          ) ?? undefined
                        }
                        onChange={(val) => {
                          fileContext.setLegalSystemNavigatorFields(
                            (s) => ({ ...s, bandNation: val ? val.value : '' } as ILegalSystemNavigatorFields)
                          );
                        }}
                        isMulti={false}
                        isClearable={true}
                      />
                    </FormGroup>
                  </Col>
                )}
                {(fileContext.legalSystemNavigatorFields?.indigenousGroupId === INDIGENOUS_GROUP_INUIT ||
                  fileContext.legalSystemNavigatorFields?.indigenousGroupId === INDIGENOUS_GROUP_METIS ||
                  fileContext.legalSystemNavigatorFields?.indigenousGroupId === INDIGENOUS_GROUP_OTHER) && (
                  <Col>
                    <FormGroup className={'mb-2'}>
                      <Label>Band / Council</Label>
                      <Input
                        type='text'
                        placeholder='Type... '
                        name='band'
                        autoComplete={'off'}
                        disabled={!props.isEditingAllowed}
                        value={fileContext.legalSystemNavigatorFields?.bandNation}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const detail = e.target.value;
                          fileContext.setLegalSystemNavigatorFields(
                            (s) => ({ ...s, bandNation: detail } as ILegalSystemNavigatorFields)
                          );
                          props.setIsDirty(true);
                        }}
                      />
                    </FormGroup>
                  </Col>
                )}
                {fileContext.legalSystemNavigatorFields?.indigenousGroupId === undefined && <Col></Col>}
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'mb-2'}>
                    <Label>Type of Service</Label>
                    <Select
                      name={'typeOfService'}
                      styles={disabledSelectStyles}
                      options={legalSystemNavigatorTypesOfService}
                      placeholder={<span className='placeholder'>Select...</span>}
                      value={
                        legalSystemNavigatorTypesOfService.find(
                          (d: any) =>
                            d.legalSystemNavigatorTypeOfServiceId ===
                            fileContext.legalSystemNavigatorFields?.legalSystemNavigatorTypeOfServiceId
                        ) ?? undefined
                      }
                      onChange={(value) => {
                        fileContext.setLegalSystemNavigatorFields(
                          (s) =>
                            ({
                              ...s,
                              legalSystemNavigatorTypeOfServiceId: value
                                ? value.legalSystemNavigatorTypeOfServiceId
                                : undefined,
                            } as ILegalSystemNavigatorFields)
                        );
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: ILegalSystemNavigatorTypeOfService) =>
                        option.legalSystemNavigatorTypeOfServiceId
                      }
                      getOptionLabel={(option: ILegalSystemNavigatorTypeOfService) => option.name}
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'mb-2'}>
                    <Label>Type of Service Details</Label>
                    <Input
                      type='text'
                      placeholder='Type... '
                      name='typeOfServiceDetails'
                      autoComplete={'off'}
                      disabled={!props.isEditingAllowed}
                      value={fileContext.legalSystemNavigatorFields?.legalSystemNavigatorTypeOfServiceDetails}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const detail = e.target.value;
                        fileContext.setLegalSystemNavigatorFields(
                          (s) =>
                            ({ ...s, legalSystemNavigatorTypeOfServiceDetails: detail } as ILegalSystemNavigatorFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'mb-2'}>
                    <Label>Lawyer</Label>
                    <Input
                      type='text'
                      placeholder='Type... '
                      name='typeOfServiceDetails'
                      autoComplete={'off'}
                      disabled={!props.isEditingAllowed}
                      value={fileContext.legalSystemNavigatorFields?.lawyer}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const lawyerDetail = e.target.value;
                        fileContext.setLegalSystemNavigatorFields(
                          (s) => ({ ...s, lawyer: lawyerDetail } as ILegalSystemNavigatorFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className={'px-0'}>
                  <FormGroup className={'mb-2'}>
                    <Label for={'lawyerContactType'}>Lawyer Contact Type</Label>
                    <CreatableSelect
                      formatCreateLabel={(inputValue: string) => inputValue}
                      placeholder={<span className='placeholder'>Select or Type...</span>}
                      options={[
                        ...contactTypeOptions,
                        ...(!fileContext.legalSystemNavigatorFields?.lawyerContactDetails ||
                        contactTypeOptions.some(
                          (e) => e.value === fileContext.legalSystemNavigatorFields?.lawyerContactDetails?.contactType
                        )
                          ? []
                          : [
                              {
                                value: fileContext.legalSystemNavigatorFields?.lawyerContactDetails.contactType,
                                label: fileContext.legalSystemNavigatorFields?.lawyerContactDetails.contactType,
                              },
                            ]),
                      ]}
                      value={
                        fileContext.legalSystemNavigatorFields &&
                        fileContext.legalSystemNavigatorFields?.lawyerContactDetails &&
                        fileContext.legalSystemNavigatorFields?.lawyerContactDetails.contactType
                          ? {
                              value: fileContext.legalSystemNavigatorFields?.lawyerContactDetails.contactType,
                              label: fileContext.legalSystemNavigatorFields?.lawyerContactDetails.contactType,
                            }
                          : undefined
                      }
                      onChange={(val) => {
                        props.setIsDirty(true);
                        fileContext.setLegalSystemNavigatorFields(
                          (s) =>
                            ({
                              ...s,
                              lawyerContactDetails: {
                                contactType: val ? val.value : '',
                                details:
                                  fileContext.legalSystemNavigatorFields?.lawyerContactDetails &&
                                  fileContext.legalSystemNavigatorFields?.lawyerContactDetails.details
                                    ? fileContext.legalSystemNavigatorFields?.lawyerContactDetails.details
                                    : '',
                              },
                            } as ILegalSystemNavigatorFields)
                        );
                      }}
                      isMulti={false}
                      isClearable={true}
                      isDisabled={!props.isEditingAllowed}
                      styles={disabledSelectStyles}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'mb-2'}>
                    <Label>Lawyer Contact Detail</Label>
                    <Input
                      type='text'
                      placeholder=''
                      name='lawyerContactInfo'
                      value={
                        fileContext.legalSystemNavigatorFields?.lawyerContactDetails &&
                        fileContext.legalSystemNavigatorFields?.lawyerContactDetails.details
                          ? fileContext.legalSystemNavigatorFields?.lawyerContactDetails.details
                          : ''
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const detail = e.target.value;
                        fileContext.setLegalSystemNavigatorFields(
                          (s) =>
                            ({
                              ...s,
                              lawyerContactDetails: {
                                contactType:
                                  fileContext.legalSystemNavigatorFields?.lawyerContactDetails &&
                                  fileContext.legalSystemNavigatorFields?.lawyerContactDetails.contactType
                                    ? fileContext.legalSystemNavigatorFields?.lawyerContactDetails.contactType
                                    : '',
                                details: detail,
                              },
                            } as ILegalSystemNavigatorFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>File Details/Background</Label>
                    <Input
                      type='textarea'
                      placeholder=''
                      style={{ minHeight: '226px' }}
                      name='name'
                      value={fileContext.details}
                      disabled={!props.isEditingAllowed}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setDetails(e.target.value);
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
