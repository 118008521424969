import * as React from 'react';
import { Button, Input, Label } from 'reactstrap';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from 'react-icons/fa';

interface IProps {
  pageCount: number;
  pageOptions: number[];
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
  previousPage: () => void;
  nextPage: () => void;
  setPageSize: (pageSize: number) => void;
  pageIndex: number;
  pageSize: number;
  pageSizes: number[];
}

export const TablePagination: React.FC<IProps> = (props) => {
  return (
    <div className='d-flex flex-wrap align-items-center flex-row ml-4 no-print'>
      <div className={'d-flex mt-2'}>
        <Button
          className={'mr-2'}
          outline
          color='secondary'
          onClick={() => props.gotoPage(0)}
          disabled={!props.canPreviousPage}
        >
          <FaAngleDoubleLeft />
        </Button>
        <Button
          className={'flex align-items-center mr-2'}
          outline
          color='secondary'
          onClick={() => props.previousPage()}
          disabled={!props.canPreviousPage}
        >
          <FaAngleLeft />
        </Button>
        <Button
          className={'mr-2'}
          outline
          color='secondary'
          onClick={() => props.nextPage()}
          disabled={!props.canNextPage}
        >
          <FaAngleRight />
        </Button>
        <Button
          className={'mr-2'}
          outline
          color='secondary'
          onClick={() => props.gotoPage(props.pageCount - 1)}
          disabled={!props.canNextPage}
        >
          <FaAngleDoubleRight />
        </Button>

        <div className={'mr-4 mt-2'}>
          Page&nbsp;
          <strong>
            {props.pageIndex + 1} of {props.pageOptions.length}
          </strong>
        </div>
      </div>
      <div className={'d-flex mt-2'}>
        <div className={'d-flex align-items-center mr-2'}>
          <Label for={'page'} className={'mr-2 mb-0'}>
            Go to page:
          </Label>
          <Input
            type='number'
            defaultValue={props.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              props.gotoPage(page);
            }}
            style={{ width: '60px' }}
          />
        </div>
        <div>
          <Input
            type={'select'}
            className={'custom-select'}
            value={props.pageSize}
            onChange={(e) => {
              props.setPageSize(Number(e.target.value));
            }}
          >
            {props.pageSizes.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Input>
        </div>
      </div>
    </div>
  );
};
