import * as React from 'react';
import { useParams } from 'react-router';
import { useContext, useState } from 'react';
import { FileContext } from '../../components/Files/FileContext';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { ConfirmModal } from '../../components/ConfirmModal/ConfirmModal';
import {
  FILE_SUB_TYPE_COMMUNITY_IMPACT,
  FILE_SUB_TYPE_GLADUE_REPORT,
  FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR,
  FILE_SUB_TYPE_WCC_CONNECTIVE,
  FILE_TYPE_BIRTH_WORKER,
  FILE_TYPE_CULTURAL_CONNECTIONS,
  FILE_TYPE_FAMILY_PRESERVATION,
  FILE_TYPE_JORDANS_PRINCIPLE,
  FILE_TYPE_JUSTICE,
  STAGE_ACTIVE,
  STAGE_DRAFT,
  STAGE_IN_PROGRESS,
  STAGE_INTAKE_IN_PROGRESS,
  STAGE_REJECTED,
  STAGE_REQUESTED,
} from '../../contexts/DomainContext';
import { formatDateFromISONumber } from '../../formatters/DateTimeFormatter';

export const IntakeFileRequestActions = () => {
  const params = useParams<{ fileId: string }>();
  const fileContext = useContext(FileContext);
  const setFile = fileContext.setFile;
  const setStageId = fileContext.setStageId;
  const file = fileContext.file;
  const reason = fileContext.reason;
  const setReason = fileContext.setReason;

  const [trySubmit, setTrySubmit] = useState(false);

  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  const acceptRequest = () => {
    const getAcceptedStage = () => {
      if (fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE) {
        return STAGE_DRAFT;
      } else if (fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER) {
        return STAGE_ACTIVE;
      } else if (fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION) {
        return STAGE_ACTIVE;
      } else if (fileContext.fileTypeId === FILE_TYPE_CULTURAL_CONNECTIONS) {
        return STAGE_ACTIVE;
      } else if (
        fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
        fileContext.fileSubTypeId === FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR
      ) {
        return STAGE_ACTIVE;
      } else if (
        fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
        fileContext.fileSubTypeId === FILE_SUB_TYPE_WCC_CONNECTIVE
      ) {
        return STAGE_ACTIVE;
      } else if (
        fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
        fileContext.fileSubTypeId === FILE_SUB_TYPE_COMMUNITY_IMPACT
      ) {
        return STAGE_IN_PROGRESS;
      } else if (
        fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
        fileContext.fileSubTypeId === FILE_SUB_TYPE_GLADUE_REPORT
      ) {
        return STAGE_REQUESTED;
      } else {
        return '';
      }
    };
    const acceptedStage = getAcceptedStage();
    if (acceptedStage) {
      const data = {
        fileId: params.fileId,
        stageId: acceptedStage,
      };
      authAxios.post('/api/files/admin/accept-file-request', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('Request Accepted.');
          setFile((f) => ({ ...f, stageId: acceptedStage, acceptedDate: response.data.acceptedDate }));
          setStageId(acceptedStage);
        } else {
          notifyError(response.data.errorMessage);
        }
      });
    } else {
      notifyError('Invalid Intake file');
    }
  };

  const rejectRequest = (reason?: string) => {
    if (reason) {
      const data = {
        fileId: params.fileId,
        stageId: STAGE_REJECTED,
        reason: reason,
      };
      authAxios.post('/api/files/admin/reject-file-request', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('Request Rejected.');
          setFile((f) => ({
            ...f,
            stageId: STAGE_REJECTED,
            rejectedDate: response.data.rejectedDate,
            reason,
          }));
          setStageId(STAGE_REJECTED);
        } else {
          notifyError(response.data.errorMessage);
        }
      });
    } else {
      setTrySubmit(true);
    }
  };

  return (
    <>
      <Card className={' mb-3'}>
        <CardHeader>
          {file.stageId === STAGE_INTAKE_IN_PROGRESS
            ? 'Accept or Reject File Request'
            : file.stageId === STAGE_REJECTED
            ? 'File Request Rejected'
            : 'File Request Accepted'}
        </CardHeader>
        {file.stageId !== STAGE_REJECTED && file.stageId !== STAGE_INTAKE_IN_PROGRESS && (
          <CardBody>
            <Row>
              <Col>File Request Accepted on {formatDateFromISONumber(file.acceptedDate)}</Col>
            </Row>
          </CardBody>
        )}
        {file.stageId === STAGE_REJECTED && (
          <CardBody>
            <Row>
              <Col>
                File Rejected on {formatDateFromISONumber(file.rejectedDate)} for reason: {file.reason}
              </Col>
            </Row>
          </CardBody>
        )}
        {file.stageId === STAGE_INTAKE_IN_PROGRESS && (
          <ListGroup flush={true}>
            <ListGroupItem>
              <Button
                color='primary'
                className='ml-auto'
                disabled={fileContext.stageId !== STAGE_INTAKE_IN_PROGRESS}
                onClick={() => setAcceptModal(true)}
              >
                Accept Request
              </Button>
            </ListGroupItem>
            <ListGroupItem>
              <FormGroup>
                <Label for={'reason'}>If rejecting, please provide a reason</Label>
                <Input
                  value={file.reason}
                  type={'text'}
                  disabled={fileContext.stageId !== STAGE_INTAKE_IN_PROGRESS}
                  name={'Reason'}
                  autoComplete={'off'}
                  onChange={(e: any) => setReason(e.target.value)}
                  invalid={trySubmit && !reason}
                />
                <FormFeedback>This field is mandatory</FormFeedback>
              </FormGroup>
              <Button
                color='danger'
                className='ml-auto'
                disabled={fileContext.stageId !== STAGE_INTAKE_IN_PROGRESS}
                onClick={() => {
                  setTrySubmit(true);
                  if (reason) {
                    setRejectModal(true);
                  }
                }}
              >
                Reject Request
              </Button>
            </ListGroupItem>
          </ListGroup>
        )}
      </Card>
      <ConfirmModal
        isOpen={acceptModal}
        title={'Accept'}
        onConfirm={() => {
          setAcceptModal(false);
          acceptRequest();
        }}
        onCancel={() => setAcceptModal(false)}
        confirmButton={'Accept'}
      >
        <div>Are you sure you want to accept the request?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={rejectModal}
        title={'Reject'}
        onConfirm={() => {
          setRejectModal(false);
          rejectRequest(reason);
        }}
        onCancel={() => setRejectModal(false)}
        confirmButton={'Reject'}
      >
        <div>Are you sure you want to reject the request?</div>
      </ConfirmModal>
    </>
  );
};
