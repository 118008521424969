import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Column, TableInstance, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { useMemo } from 'react';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import {
  DEPARTMENT_BIRTH_WORKER,
  DEPARTMENT_CULTURAL_CONNECTIONS,
  DEPARTMENT_FAMILY_PRESERVATION,
  DomainContext,
  IDepartment,
  IStatus,
} from '../../contexts/DomainContext';
import { formatDateFromISONumber } from '../../formatters/DateTimeFormatter';
import { IWorkshop } from './WorkshopsTable';
import { MyUserContext } from '../../contexts/MyUserContext';
import { authAxios } from '../../services/AxiosService';

interface IProps {
  selectRow?: (userId: string) => void;
  clientId: string;
}

export const AssociatedWorkshopsTable: React.FC<IProps> = (props) => {
  const domainContext = useContext(DomainContext);
  const myUserContext = useContext(MyUserContext);
  const history = useHistory();
  const departments = domainContext.departments;
  const statuses = domainContext.statuses;
  const [clientWorkshops, setClientWorkshops] = useState<IWorkshop[]>([]);

  useEffect(() => {
    authAxios.get('/api/workshops/' + props.clientId + '/associated-workshops').then((response) => {
      setClientWorkshops(response.data);
    });
  }, []);

  const columns: Column<IWorkshop>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Department',
        id: 'departmentId',
        accessor: (w: IWorkshop) => {
          return departments.find((d: IDepartment) => w.departmentId === d.departmentId)?.name;
        },
      },
      {
        Header: 'Status',
        id: 'statusId',
        accessor: (w: IWorkshop) => {
          return statuses.find((s: IStatus) => w.status === s.statusId)?.name;
        },
      },
      {
        Header: 'Start Date',
        id: 'startDate',
        accessor: (w: IWorkshop) => {
          const earliestEvent = w.workshopEvents.reduce(
            (a, b) => (a.startDate < b.startDate ? a : b),
            w.workshopEvents[0] || undefined
          );
          if (earliestEvent) {
            return formatDateFromISONumber(earliestEvent.startDate);
          } else {
            return '';
          }
        },
      },
      {
        Header: 'End Date',
        id: 'endDate',
        accessor: (w: IWorkshop) => {
          let latestEvent: any = undefined;
          w.workshopEvents.forEach((e) => {
            if (e.endDate && latestEvent === undefined) {
              latestEvent = e;
            } else if (e.endDate && latestEvent?.endDate) {
              if (latestEvent.endDate < e.endDate) {
                latestEvent = e;
              }
            }
          });
          if (latestEvent) {
            return formatDateFromISONumber(latestEvent?.endDate);
          } else {
            return '';
          }
        },
      },
    ],
    [departments, statuses]
  );
  const data: IWorkshop[] = useMemo(() => clientWorkshops, [clientWorkshops]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    prepareRow,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IWorkshop> = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: 20 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const selectRow = (workshopId: string) => {
    if (props.selectRow) {
      props.selectRow(workshopId);
    } else {
      if (
        myUserContext.departmentAccessIds.some((d) =>
          [DEPARTMENT_BIRTH_WORKER, DEPARTMENT_FAMILY_PRESERVATION, DEPARTMENT_CULTURAL_CONNECTIONS].includes(d)
        )
      ) {
        history.push('/workshops/' + workshopId);
      }
    }
  };

  return (
    <div>
      <div className={'table-responsive'}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`workshops-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`workshops-table-thead-tr-${index}-${column.id}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);

              return (
                <tr key={`workshops-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`workshops-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() => selectRow(row.original.workshopId)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
      />
    </div>
  );
};
