import * as React from 'react';
import { useState } from 'react';

type ContextType = {
  selectedGlobalSearch?: string;
  setSelectedGlobalSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
  filters?: { id: string; value: any }[];
  setFilters: React.Dispatch<React.SetStateAction<{ id: string; value: any }[]>>;
};

export const ClientsTableContext = React.createContext<ContextType>({
  selectedGlobalSearch: undefined,
  setSelectedGlobalSearch: () => {},
  filters: [],
  setFilters: () => {},
});

export const ClientsTableProvider = (props: any) => {
  const [selectedGlobalSearch, setSelectedGlobalSearch] = useState<string | undefined>('');
  const [filters, setFilters] = useState<{ id: string; value: any }[]>([]);

  return (
    <ClientsTableContext.Provider
      value={{
        selectedGlobalSearch,
        setSelectedGlobalSearch,
        filters,
        setFilters,
      }}
    >
      {props.children}
    </ClientsTableContext.Provider>
  );
};

export const PeopleTableConsumer = ClientsTableContext.Consumer;
