export const NavigationBarEnglish = {
  navigationBar: {
    clientsLink: 'Clients',
  },
};

export const NavigationBarSouthernTutchone = {
  navigationBar: {
    clientsLink: 'Dän',
  },
};
