import * as React from 'react';
import { useContext, useState } from 'react';
import { ClientsContext } from '../../contexts/ClientsContext';
import { useHistory } from 'react-router';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { Button, Card, Col, Container, Navbar, NavbarBrand, Row } from 'reactstrap';
import Select from 'react-select';
import { IClient } from './ClientContext';
import { formatDateFromISONumber } from '../../formatters/DateTimeFormatter';
import { ClientOption, ClientSingleValue } from '../SelectComponents/SelectClientComponents';
import { ClientDisplay } from '../Files/ClientDisplay';
import { authAxios } from '../../services/AxiosService';
import * as ToastService from '../../services/ToastService';

export const ClientsMerge: React.FC = () => {
  const clientContext = useContext(ClientsContext);
  const {
    state: { clients },
  } = clientContext;

  const [mergeClientsModal, setMergeClientsModal] = useState(false);
  const [client1, setClient1] = useState<IClient | undefined>();
  const [client2, setClient2] = useState<IClient | undefined>();
  const [client1InputValue, setClient1InputValue] = useState('');
  const [client2InputValue, setClient2InputValue] = useState('');

  const history = useHistory();

  const mergeClients = () => {
    if (client1 && client2) {
      authAxios
        .post('/api/clients/merge', { client1Id: client1.clientId, client2Id: client2.clientId })
        .then((response) => {
          if (response.data.status === 'OK') {
            clientContext.dispatch({
              type: 'CLIENTS_ACTION_UPDATE_CLIENT',
              data: { ...client1, active: false },
            });

            clientContext.dispatch({
              type: 'CLIENTS_ACTION_UPDATE_CLIENT',
              data: { ...client2 },
            });

            ToastService.notifySuccess('Individuals Merged');
            setClient1(undefined);
            setClient2(undefined);
          } else {
            ToastService.notifyError('Error Merging Individuals - ' + response.data.desc);
          }
        });
    }
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand>Merge Clients</NavbarBrand>
        <div className={'d-flex ml-auto navbar-actions'}>
          <Button color={'link'} onClick={() => history.push('/clients')}>
            Cancel
          </Button>
          <Button
            color='warning'
            disabled={!client1 || !client2}
            className={'ml-2'}
            onClick={() => setMergeClientsModal(true)}
          >
            Merge Clients
          </Button>
        </div>
      </Navbar>
      <Container fluid={true} className={'my-3'}>
        <Row>
          <Col>
            <h5>Client 1</h5>
            <span>The client whose files will be moved and will be made inactive.</span>
            <Select<IClient>
              className={'client-select flex-fill'}
              name={'existingPerson'}
              inputValue={client1InputValue}
              onInputChange={(e) => setClient1InputValue(e)}
              options={
                !!client1InputValue && client1InputValue.length >= 3
                  ? clients.filter((c) => c.searchString?.includes(client1InputValue.toUpperCase()) && c.active)
                  : []
              }
              value={client1 ?? null}
              onChange={(value: IClient | null) => {
                setClient1(value ?? undefined);
              }}
              getOptionValue={(option) => option.clientId}
              getOptionLabel={(o) =>
                `${o.lastName.toUpperCase()}, ${o.firstName} ${o.middleName} ${
                  o.birthDate ? formatDateFromISONumber(o.birthDate) : ''
                }`
              }
              placeholder={<span className='placeholder'>Select existing client...</span>}
              isClearable={true}
              components={{ SingleValue: ClientSingleValue, Option: ClientOption }}
              noOptionsMessage={() =>
                !!client1InputValue && client1InputValue.length >= 3
                  ? 'No client match your search. Add a new client or try again.'
                  : 'Enter at least 3 characters to search.'
              }
              styles={{
                singleValue: (base) => ({
                  ...base,
                  position: 'relative',
                  top: 0,
                  transform: 'translateY(0)',
                  height: '100%',

                  padding: '0.25em 0',
                }),
              }}
            />
            {client1 && (
              <div>
                <Card className={'mt-2 p-2'}>
                  <ClientDisplay client={client1} />
                </Card>
              </div>
            )}
          </Col>
          <Col>
            <h5>Client 2</h5>
            <span>The client who will remain active and be associated with all files.</span>
            <Select<IClient>
              className={'client-select flex-fill'}
              name={'existingPerson'}
              inputValue={client2InputValue}
              onInputChange={(e) => setClient2InputValue(e)}
              options={
                !!client2InputValue && client2InputValue.length >= 3
                  ? clients.filter(
                      (c) =>
                        c.searchString?.includes(client2InputValue.toUpperCase()) &&
                        c.active &&
                        c.clientId !== client1?.clientId
                    )
                  : []
              }
              value={client2 ?? null}
              onChange={(value: IClient | null) => {
                setClient2(value ?? undefined);
              }}
              getOptionValue={(option: IClient) => option.clientId}
              getOptionLabel={(o: IClient) =>
                `${o.lastName.toUpperCase()}, ${o.firstName} ${o.middleName} ${
                  o.birthDate ? formatDateFromISONumber(o.birthDate) : ''
                }`
              }
              placeholder={<span className='placeholder'>Select existing client...</span>}
              isClearable={true}
              components={{ SingleValue: ClientSingleValue, Option: ClientOption }}
              noOptionsMessage={() =>
                !!client2InputValue && client2InputValue.length >= 3
                  ? 'No client match your search. Add a new client or try again.'
                  : 'Enter at least 3 characters to search.'
              }
              styles={{
                singleValue: (base) => ({
                  ...base,
                  position: 'relative',
                  top: 0,
                  transform: 'translateY(0)',
                  height: '100%',

                  padding: '0.25em 0',
                }),
              }}
            />
            {client2 && (
              <div>
                <Card className={'mt-2 p-2'}>
                  <ClientDisplay client={client2} />
                </Card>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <ConfirmModal
        isOpen={mergeClientsModal}
        title={'Merge Clients'}
        onConfirm={() => {
          setMergeClientsModal(false);
          mergeClients();
        }}
        onCancel={() => setMergeClientsModal(false)}
        confirmButton={'Merge'}
        confirmButtonColor={'warning'}
      >
        <div>
          Are you sure you want to merge clients?
          <ul className={'mt-3'}>
            <li>All files associated with Client 1 will be merged to Client 2. </li>
            <li>All documents associated with Client 1 will be copied to Client 2.</li>
            <li>All case notes associated with Client 1 will be copied to Client 2.</li>
            <li>Client 1 will be made inactive.</li>
          </ul>
          <b>This action cannot be undone.</b>
        </div>
      </ConfirmModal>
    </div>
  );
};
