import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { DomainContext, IDepartment } from '../../contexts/DomainContext';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { FormEvent } from 'react';
import Select from 'react-select';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';

export const UserDetails: React.FC = () => {
  const domainContext = useContext(DomainContext);
  const departments = domainContext.departments;

  const [trySubmit, setTrySubmit] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [departmentAccessIds, setDepartmentAccessIds] = useState<string[]>([]);
  const [homeDepartmentId, setHomeDepartmentId] = useState('');
  const [active, setActive] = useState<boolean>(true);
  const history = useHistory();
  const params = useParams<{ userId: string }>();
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (params.userId) {
      authAxios
        .get('/api/users/admin/' + params.userId)
        .then((response) => {
          if (response.data && response.data.status && response.data.status.includes('KO')) {
            history.push('/404');
          } else if (response.data) {
            setFirstName(response.data.firstName);
            setLastName(response.data.lastName);
            setEmail(response.data.email);
            setRole(response.data.role);
            setDepartmentAccessIds(response.data.departmentAccessIds);
            setActive(response.data.active);
            setHomeDepartmentId(response.data.homeDepartmentId);
          }
        })
        .catch((response) => {
          history.push('/404');
        });
    }
  }, [params.userId]);

  const roles = [
    { val: 'Staff', display: 'Staff' },
    { val: 'SuperAdmin', display: 'Super Admin' },
  ];

  const formGroupInput = (name: string, label: string, type: InputType, val: any, onChange: any, required: boolean) => (
    <FormGroup>
      <Label for={name}>
        {label}
        {required && <span className='text-required'> (required)</span>}
      </Label>
      <Input label={label} value={val} type={type} name={name} id={name} onChange={onChange} />
      {trySubmit && val === '' && <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>}
    </FormGroup>
  );

  const formGroupInputCheckbox = (name: string, label: string, type: InputType, val: any, onChange: any) => (
    <FormGroup>
      <CustomInput
        type='checkbox'
        id={name}
        name={name}
        label={label}
        checked={val}
        onChange={onChange}
        invalid={trySubmit && val === ''}
      />
      <FormFeedback>This field is mandatory</FormFeedback>
    </FormGroup>
  );

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
        borderRadius: '1.25rem',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
    menu: (provided: any) => {
      return {
        ...provided,
        zIndex: 9999,
      };
    },
  };

  const saveDetails = () => {
    if (!formFullyFilled()) {
      setTrySubmit(true);
    } else {
      if (params.userId) {
        const data = {
          userId: params.userId,
          email,
          firstName,
          lastName,
          role,
          departmentAccessIds: departmentAccessIds,
          active,
          homeDepartmentId,
        };
        authAxios.post('/api/users/admin/update-staff-user', data).then((response) => {
          if (response.data.status === 'OK') {
            notifySuccess('User details updated.');
            setIsDirty(false);
          } else {
            notifyError(response.data.errorMessage);
          }
        });
      } else {
        const data = { email, firstName, lastName, role, departmentAccessIds, active, homeDepartmentId };
        authAxios.post('/api/users/admin/create-staff-user', data).then((response) => {
          if (response.data.status === 'OK') {
            const userId = response.data.userId;
            setIsDirty(false);
            notifySuccess('User created. Account activation email sent.');
            history.push('/users/' + userId);
          } else {
            notifyError(response.data.errorMessage);
          }
        });
      }
    }
  };

  const formFullyFilled = () => {
    return (
      firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      role !== '' &&
      departmentAccessIds.length > 0 &&
      homeDepartmentId !== ''
    );
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>{params.userId ? 'Edit User' : 'Add New User'}</NavbarBrand>
            </Col>
            {params.userId ? (
              <Col className={'d-flex'}>
                <Button color={'primary'} className={'ml-auto'} onClick={() => saveDetails()}>
                  Save Details
                </Button>
              </Col>
            ) : (
              <Col className={'d-flex'}>
                <Button color={'link'} className={'ml-auto'} onClick={() => history.push('/users')}>
                  Cancel
                </Button>
                <Button color={'primary'} className={'ml-2'} onClick={() => saveDetails()}>
                  Save Details
                </Button>
              </Col>
            )}
          </Row>
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          <Row>
            <Col>
              <Row>
                <Col md={6}>
                  {formGroupInput(
                    'firstName',
                    'First Name',
                    'text',
                    firstName,
                    (e: any) => {
                      setFirstName(e.target.value);
                      setIsDirty(true);
                    },
                    true
                  )}
                </Col>
                <Col md={6}>
                  {formGroupInput(
                    'lastName',
                    'Last Name',
                    'text',
                    lastName,
                    (e: any) => {
                      setLastName(e.target.value);
                      setIsDirty(true);
                    },
                    true
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  {formGroupInput(
                    'email',
                    'Email',
                    'text',
                    email,
                    (e: any) => {
                      setEmail(e.target.value);
                      setIsDirty(true);
                    },
                    true
                  )}
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for={'role'}>
                      Role <span className='text-required'> (required)</span>
                    </Label>
                    <Select
                      name={'role'}
                      options={roles}
                      placeholder={<span className='placeholder'>Select...</span>}
                      value={roles.find((r: any) => r.val === role)}
                      onChange={(value: any) => {
                        setRole(value?.val || '');
                        setIsDirty(true);
                      }}
                      getOptionValue={(option: any) => option.val}
                      getOptionLabel={(option: any) => option.display}
                      isClearable={true}
                      styles={disabledSelectStyles}
                    />
                    {trySubmit && role === '' && (
                      <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for={'departmentId'}>
                      File Type Access <span className='text-required'> (required)</span>
                    </Label>
                    <Select
                      name={'departmentId'}
                      options={departments.filter((o: IDepartment) => o.active)}
                      placeholder={<span className='placeholder'>Select...</span>}
                      value={departments.filter((o: IDepartment) => departmentAccessIds.includes(o.departmentId))}
                      onChange={(value: any) => {
                        setDepartmentAccessIds(value.map((v: IDepartment) => v.departmentId));
                        if (value.filter((v: any) => v.departmentId === homeDepartmentId).length <= 0) {
                          setHomeDepartmentId('');
                        }
                        setIsDirty(true);
                      }}
                      getOptionValue={(option: IDepartment) => option.departmentId}
                      getOptionLabel={(option: IDepartment) => option.name}
                      isClearable={true}
                      isMulti={true}
                      styles={disabledSelectStyles}
                    />
                    {trySubmit && departmentAccessIds.length <= 0 && (
                      <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for={'home-department'}>
                      Home Department <span className='text-required'> (required)</span>
                    </Label>
                    <Select
                      name={'home-department'}
                      options={departments.filter(
                        (o: IDepartment) => o.active && departmentAccessIds.includes(o.departmentId)
                      )}
                      placeholder={<span className='placeholder'>Select...</span>}
                      value={
                        departments.find((o: IDepartment) => homeDepartmentId === o.departmentId)
                          ? departments.find((o: IDepartment) => homeDepartmentId === o.departmentId)
                          : null
                      }
                      onChange={(value: any) => {
                        setHomeDepartmentId(value ? value.departmentId : '');
                        setIsDirty(true);
                      }}
                      getOptionValue={(option: any) => option.departmentId}
                      getOptionLabel={(option: any) => option.name}
                      isClearable={true}
                      styles={disabledSelectStyles}
                    />
                    {trySubmit && homeDepartmentId === '' && (
                      <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  {formGroupInputCheckbox('active', 'Active', 'checkbox', active, (e: any) => {
                    setActive(!active);
                    setIsDirty(true);
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};
