import { AxiosUpdateTuple } from '../../AxiosPutHook';
import { usePostAxiosHook } from '../../AxiosPostHook';

const URL = '/api/clients/create';

export const useCreateClient = (): AxiosUpdateTuple => {
  const [callBack, { data, loading, called, error }] = usePostAxiosHook(URL);

  return [callBack, { data, loading, called, error }];
};
