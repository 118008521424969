import { Button, Card, CardHeader, ListGroup, ListGroupItem, Navbar, NavbarBrand, Row } from 'reactstrap';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { FileContext, IFile } from './FileContext';
import Select from 'react-select';
import { formatDateFromISONumber, formatDateTime } from '../../formatters/DateTimeFormatter';
import { ClientProvider, IClient } from '../Clients/ClientContext';
import { ClientDetails } from '../Clients/ClientDetails';
import { ClientsContext } from '../../contexts/ClientsContext';
import { ClientDisplay } from './ClientDisplay';
import {
  FILE_SUB_TYPE_GLADUE_REPORT,
  FILE_SUB_TYPE_GROUP,
  FILE_SUB_TYPE_WCC_CONNECTIVE,
} from '../../contexts/DomainContext';
import { useHistory } from 'react-router-dom';
import { ClientOption, ClientSingleValue } from '../SelectComponents/SelectClientComponents';
import { authAxios } from '../../services/AxiosService';

export const FileClients: React.FC<{
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  disableEditing?: boolean;
}> = (props) => {
  const fileContext = useContext(FileContext);
  const fileId = fileContext.file.fileId;
  const setFile = fileContext.setFile;
  const clientsContext = useContext(ClientsContext);
  const clientIds = fileContext.clientIds;
  const clients = clientsContext.state.clients;
  const history = useHistory();

  const [editingClientId, setEditingClientId] = useState<string | undefined>(undefined);
  const [showNewPersonOptions, setShowNewPersonOptions] = useState<boolean>(false);
  const [addingExistingClientId, setAddingExistingClientId] = useState<string | undefined>(undefined);
  const [showNewPersonDetails, setShowNewPersonDetails] = useState<boolean>(false);
  const [addingNewClient, setAddingNewClient] = useState(0);
  const [clientInputValue, setClientInputValue] = useState('');

  const peopleData = () => {
    if (clientIds) {
      const foundPeople = clientIds.map((pId) => clients.find((i) => i.clientId === pId));
      return foundPeople;
    } else {
      return [];
    }
  };

  useEffect(() => {
    props.setIsEditing &&
      props.setIsEditing(showNewPersonOptions || showNewPersonDetails || !!editingClientId || !!addingExistingClientId);
  }, [showNewPersonOptions, showNewPersonDetails, editingClientId, addingExistingClientId]);

  const createPersonAction = (client: IClient) => {
    const updatedClientIds = [...fileContext.clientIds, client.clientId];

    fileContext.setClientIds(updatedClientIds);
    setShowNewPersonOptions(false);
    setShowNewPersonDetails(false);
    setAddingExistingClientId(undefined);
    authAxios.put('/api/files/set-file-clients', {
      fileId: fileId,
      clientIds: updatedClientIds,
    });
    props.setIsDirty(false);
  };

  const editCompleteAction = (clientId: IClient) => {
    const updatedClientIds =
      fileContext.clientIds === undefined
        ? [clientId.clientId]
        : fileContext.clientIds.includes(clientId.clientId)
        ? fileContext.clientIds
        : fileContext.clientIds.concat([clientId.clientId]);

    fileContext.setClientIds(updatedClientIds);
    setFile((f) => ({ ...f, clientIds: updatedClientIds }));
    setEditingClientId(undefined);
    setShowNewPersonOptions(false);
    setShowNewPersonDetails(false);
    setAddingExistingClientId(undefined);
    authAxios.put('/api/files/set-file-clients', {
      fileId: fileId,
      clientIds: updatedClientIds,
    });
    props.setIsDirty(false);
  };

  useEffect(() => {
    if (addingNewClient > 0) {
      var elmnt = document.getElementById('new-client-add');
      if (elmnt) {
        elmnt.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [addingNewClient]);

  return (
    <Card className={'mb-3'}>
      <CardHeader>{fileContext.file?.fileSubTypeId === FILE_SUB_TYPE_GROUP ? 'Clients' : 'Client'}</CardHeader>
      <ListGroup flush={true}>
        {peopleData().map((p) => {
          if (p !== undefined) {
            if (editingClientId === p.clientId) {
              return (
                <ListGroupItem className={'p-0'} key={p.clientId}>
                  <ClientProvider clientId={p.clientId}>
                    <ClientDetails
                      allowCreateFile={false}
                      editCompleteAction={editCompleteAction}
                      deleteAction={() => {
                        fileContext.setClientIds((pIds) => pIds.filter((e) => e !== p.clientId));
                        fileContext.setFile((f: IFile) => ({
                          ...f,
                          clientIds: f.clientIds.filter((e) => e !== p.clientId),
                        }));
                        authAxios.put('/api/files/set-file-clients', {
                          fileId: fileId,
                          clientIds: fileContext.clientIds.filter((e) => e !== p.clientId),
                        });
                        setEditingClientId(undefined);
                      }}
                      clientId={editingClientId}
                      multiplePeopleFormat={true}
                      cancelAction={() => {
                        setEditingClientId(undefined);
                      }}
                      disableActions={false}
                    />
                  </ClientProvider>
                </ListGroupItem>
              );
            } else {
              return (
                <React.Fragment key={'fragment_' + p.clientId}>
                  <Navbar
                    color={'light'}
                    light={true}
                    expand={'xs'}
                    className={'border-bottom d-flex flex-wrap'}
                    key={'nav_' + p.clientId}
                  >
                    <NavbarBrand className={'w-mobile-100'}>
                      <Button
                        color={'link'}
                        className={'p-0'}
                        onClick={() => {
                          history.push('/clients/' + p.clientId);
                        }}
                      >
                        <span style={{ color: 'black' }}>
                          {p.lastName?.toUpperCase() + ', ' + p.firstName + ' ' + p.middleName}
                        </span>
                      </Button>

                      <small className={'text-muted'}>
                        &nbsp;&nbsp;Last Updated:{' '}
                        {p.lastUpdated !== undefined ? formatDateTime(new Date(p.lastUpdated)) : ''}
                      </small>
                    </NavbarBrand>
                    <div
                      className={'d-flex flex-grow-1 justify-content-end flex-wrap navbar-actions'}
                      style={{ rowGap: '0.5rem', columnGap: '0.5rem' }}
                    >
                      <Button
                        color={'primary'}
                        className={'w-mobile-100'}
                        disabled={showNewPersonOptions || editingClientId !== undefined || props.disableEditing}
                        onClick={() => {
                          setEditingClientId(p.clientId);
                          setAddingNewClient((c) => c + 1);
                        }}
                      >
                        Edit Details
                      </Button>
                    </div>
                  </Navbar>
                  <ListGroupItem
                    key={p.clientId}
                    disabled={showNewPersonOptions || editingClientId !== undefined || props.disableEditing}
                  >
                    <ClientDisplay client={p} />
                  </ListGroupItem>
                </React.Fragment>
              );
            }
          }
        })}
        {!showNewPersonOptions &&
          fileContext.fileSubTypeId !== FILE_SUB_TYPE_WCC_CONNECTIVE &&
          fileContext.fileSubTypeId !== FILE_SUB_TYPE_GLADUE_REPORT && (
            <ListGroupItem
              onClick={() => {
                setShowNewPersonOptions(true);
                setShowNewPersonDetails(false);
                setEditingClientId(undefined);
                setAddingExistingClientId(undefined);
              }}
              disabled={showNewPersonOptions || !!editingClientId || props.disableEditing}
              tag={'button'}
            >
              <FaPlus /> Add Client
            </ListGroupItem>
          )}
      </ListGroup>
      {(showNewPersonDetails || addingExistingClientId) && (
        <ClientProvider clientId={addingExistingClientId}>
          <ClientDetails
            allowCreateFile={false}
            createCompleteAction={createPersonAction}
            editCompleteAction={editCompleteAction}
            clientId={addingExistingClientId}
            multiplePeopleFormat={true}
            autoFocus={true}
            cancelAction={() => {
              setShowNewPersonOptions(false);
              setShowNewPersonDetails(false);
              setAddingExistingClientId(undefined);
            }}
            newClient={true}
          />
        </ClientProvider>
      )}
      {!(showNewPersonDetails || addingExistingClientId) && showNewPersonOptions && (
        <div className={'p-3'}>
          <Select
            className={'client-select flex-fill'}
            name={'existingPerson'}
            inputValue={clientInputValue}
            onInputChange={(e) => setClientInputValue(e)}
            options={
              !!clientInputValue && clientInputValue.length >= 3
                ? clients.filter(
                    (c) =>
                      c.searchString?.includes(clientInputValue.toUpperCase()) &&
                      !clientIds.includes(c.clientId) &&
                      c.active
                  )
                : []
            }
            value={clients.find((i: IClient) => i.clientId === addingExistingClientId) || null}
            onChange={(value: any) => {
              setAddingExistingClientId(value?.clientId || undefined);
              setShowNewPersonDetails(false);
              props.setIsDirty(true);
              setAddingNewClient((c) => c + 1);
            }}
            getOptionValue={(option: IClient) => option.clientId}
            getOptionLabel={(o: IClient) =>
              `${o.lastName.toUpperCase()}, ${o.firstName} ${o.middleName} ${
                o.birthDate ? formatDateFromISONumber(o.birthDate) : ''
              }`
            }
            placeholder={<span className='placeholder'>Select existing client...</span>}
            isClearable={true}
            components={{ SingleValue: ClientSingleValue, Option: ClientOption }}
            noOptionsMessage={() =>
              !!clientInputValue && clientInputValue.length >= 3
                ? 'No client match your search. Add a new client or try again.'
                : 'Enter at least 3 characters to search.'
            }
            styles={{
              singleValue: (base) => ({
                ...base,
                position: 'relative',
                top: 0,
                transform: 'translateY(0)',
                height: '100%',

                padding: '0.25em 0',
              }),
            }}
          />
          <Row>
            <p className={'text-muted mb-3 mt-1 ml-4'}>
              or{' '}
              <Button
                color='link'
                onClick={() => {
                  setShowNewPersonDetails(true);
                  setAddingNewClient((c) => c + 1);
                }}
                aria-label={'Add New Client'}
                disabled={showNewPersonDetails || props.disableEditing}
                className={'p-0 border-0 align-baseline'}
              >
                Add New Client
              </Button>
            </p>
          </Row>
          <Row>
            <Button
              color={'link'}
              className='ml-2'
              onClick={() => {
                setShowNewPersonOptions(false);
              }}
            >
              Cancel
            </Button>
          </Row>
        </div>
      )}
    </Card>
  );
};
