import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { FileContext, IIntakeFields } from './FileContext';
import {
  DomainContext,
  IIncomeOrEmploymentType,
  INCOME_OR_EMPLOYMENT_TYPE_OTHER,
  STAGE_COMPLETED,
} from '../../contexts/DomainContext';
import { useWindowDimensions } from '../../hooks/WindowHooks';

interface IProps {
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  isEditingAllowed: boolean;
}

export const IncomeOrEmployments = (props: IProps) => {
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const incomeOrEmploymentTypes = domainContext.incomeOrEmploymentTypes;

  const stageIsCompleted = fileContext.file.stageId === STAGE_COMPLETED;
  const setIntakeFields = fileContext.setIntakeFields;
  const incomeOrEmployments = fileContext.intakeFields?.incomeOrEmployments
    ? fileContext.intakeFields.incomeOrEmployments
    : [];
  const [changedIncomeOrEmploymentsLength, setChangedIncomeOrEmploymentsLength] = useState(0);
  const { xs, sm } = useWindowDimensions();
  const mobile = xs || sm;
  const formatCurrencyCommas = (amount: string | undefined) => {
    if (amount) {
      return amount
        .toString()
        .replace(/[, ]+/g, '')
        .replace(/-[^0-9.]/g, '')
        .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  const formatCurrencyCommasTwoDigits = (amount: string | undefined) => {
    if (amount) {
      const floatToUse = parseFloat(amount.toString().replace(/[, ]+/g, ''));
      return Number.isNaN(floatToUse)
        ? '0.00'
        : floatToUse
            .toFixed(2)
            .toString()
            .replace(/-[^0-9.]/g, '')
            .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (changedIncomeOrEmploymentsLength > 0) {
      var elmt2 = document.getElementById('add-new-income-or-employment');
      if (elmt2) {
        elmt2.focus();
        elmt2.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [changedIncomeOrEmploymentsLength]);

  const formattedAmountToNumber = (amount: string | undefined) => {
    if (amount) {
      if (amount.length > 0 && amount.charAt(0) === '-' && amount !== '-') {
        return -Math.abs(Number(amount.toString().replace(/[, ]+|[- ]+/g, '')));
      } else {
        return Number(amount.toString().replace(/[,]+|[- ]+/g, ''));
      }
    } else {
      return 0;
    }
  };

  const calculateTotal = (cost: string | undefined) => {
    if (cost) {
      const total = formattedAmountToNumber(cost);
      return formatCurrencyCommasTwoDigits(total.toString());
    } else {
      return '';
    }
  };

  const disabledSelectStyles = {
    control: (styles: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
        borderRadius: '1.25rem',
      };
    },
    singleValue: (styles: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  const allTotals = () => {
    let total = 0;
    incomeOrEmployments.forEach((e) => {
      total = total + formattedAmountToNumber(calculateTotal(e.monthlyAmount));
    });
    return formatCurrencyCommasTwoDigits(total.toString());
  };

  return (
    <>
      <Card>
        <CardHeader className={'d-flex align-items-center'}>Income/Employment</CardHeader>
        <CardBody className={'p-0'}>
          <ListGroup flush={true}>
            {incomeOrEmployments && incomeOrEmployments.length > 0 && (
              <ListGroupItem className={'py-2 d-none d-md-block'}>
                <Row style={{ paddingRight: 42 }} className='row--condensed'>
                  <Col style={{ flexGrow: 0.8 }}>
                    <Label>Type</Label>
                  </Col>
                  <Col style={{ flexGrow: 1.5 }}>
                    <Label>Description</Label>
                  </Col>
                  <Col style={{ flexGrow: 0.6 }}>
                    <Label>Monthly Amount ($)</Label>
                  </Col>
                </Row>
              </ListGroupItem>
            )}
            {incomeOrEmployments.map((e, index) => (
              <ListGroupItem key={index} className='list-group-item--borderless'>
                <div className={'d-flex'}>
                  <div className={'flex-grow-1'}>
                    <Row className='row--condensed'>
                      <Col sm={12} md={'auto'} style={!mobile ? { flexGrow: 0.8, flexShrink: 0.8, flexBasis: 0 } : {}}>
                        <Select
                          key={`income-or-employment-type-${index}`}
                          styles={disabledSelectStyles}
                          name={'income-or-employment-type'}
                          placeholder={<span className='placeholder'>Select...</span>}
                          options={incomeOrEmploymentTypes}
                          value={incomeOrEmploymentTypes.find(
                            (eType) => eType.incomeOrEmploymentTypeId === e.incomeOrEmploymentTypeId
                          )}
                          onChange={(value: any) => {
                            setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  incomeOrEmployments: s?.incomeOrEmployments
                                    ? [
                                        ...s.incomeOrEmployments.slice(0, index),
                                        {
                                          ...s.incomeOrEmployments[index],
                                          incomeOrEmploymentTypeId: value ? value.incomeOrEmploymentTypeId : '',
                                          description: '',
                                        },
                                        ...s.incomeOrEmployments.slice(index + 1),
                                      ]
                                    : [],
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          getOptionValue={(option: IIncomeOrEmploymentType) => option.incomeOrEmploymentTypeId}
                          getOptionLabel={(option: IIncomeOrEmploymentType) => option.name}
                          isDisabled={!props.isEditingAllowed || stageIsCompleted}
                          isClearable={true}
                        />
                      </Col>
                      <Col sm={12} md={7} style={!mobile ? { flexGrow: 1.5, flexShrink: 1, flexBasis: 0 } : {}}>
                        <Input
                          type='text'
                          value={e.description}
                          disabled={e.incomeOrEmploymentTypeId !== INCOME_OR_EMPLOYMENT_TYPE_OTHER || stageIsCompleted}
                          onChange={(e) => {
                            setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  incomeOrEmployments: s?.incomeOrEmployments
                                    ? [
                                        ...s.incomeOrEmployments.slice(0, index),
                                        {
                                          ...s.incomeOrEmployments[index],
                                          description: e.target.value,
                                        },
                                        ...s.incomeOrEmployments.slice(index + 1),
                                      ]
                                    : [],
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                        />
                      </Col>
                      <Col sm={12} md={'auto'} style={!mobile ? { flexGrow: 0.6, flexShrink: 0.6, flexBasis: 0 } : {}}>
                        <Input
                          type='text'
                          style={{ textAlign: 'right' }}
                          value={e.monthlyAmount}
                          disabled={stageIsCompleted}
                          onChange={(e) => {
                            const amount = e.target.value;
                            setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  incomeOrEmployments: s?.incomeOrEmployments
                                    ? [
                                        ...s.incomeOrEmployments.slice(0, index),
                                        {
                                          ...s.incomeOrEmployments[index],
                                          monthlyAmount: formatCurrencyCommas(amount),
                                        },
                                        ...s.incomeOrEmployments.slice(index + 1),
                                      ]
                                    : [],
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          onBlur={(e) => {
                            const amount = e.target.value;
                            setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  incomeOrEmployments: s?.incomeOrEmployments
                                    ? [
                                        ...s.incomeOrEmployments.slice(0, index),
                                        {
                                          ...s.incomeOrEmployments[index],
                                          monthlyAmount: formatCurrencyCommasTwoDigits(amount),
                                        },
                                        ...s.incomeOrEmployments.slice(index + 1),
                                      ]
                                    : [],
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className={'d-none d-md-block'} style={{ width: 42 }}>
                    <Button
                      color={'link'}
                      className={'text-danger'}
                      disabled={stageIsCompleted}
                      onClick={() => {
                        setIntakeFields(
                          (s) =>
                            ({
                              ...s,
                              incomeOrEmployments: s?.incomeOrEmployments
                                ? [...s.incomeOrEmployments.slice(0, index), ...s.incomeOrEmployments.slice(index + 1)]
                                : [],
                            } as IIntakeFields)
                        );
                        props.setIsDirty(true);
                      }}
                    >
                      <FaTimes />
                    </Button>
                  </div>
                </div>
              </ListGroupItem>
            ))}
            {incomeOrEmployments.length > 0 && (
              <ListGroupItem className={'py-2 d-none d-md-block'}>
                <Row style={{ paddingRight: 42 }}>
                  <Col style={{ flexGrow: 2 }} />
                  <Col style={{ textAlign: 'right' }}>
                    <Label className={'mt-2'}>Total ($)</Label>
                  </Col>
                  <Col>
                    <Input
                      style={{ textAlign: 'right' }}
                      type={'text'}
                      disabled={true}
                      name='total'
                      value={allTotals()}
                    />
                  </Col>
                </Row>
              </ListGroupItem>
            )}
            {props.isEditingAllowed && !stageIsCompleted && (
              <ListGroupItem
                id={'add-new-income-or-employment'}
                onClick={() => {
                  setIntakeFields(
                    (s) =>
                      ({
                        ...s,
                        incomeOrEmployments: incomeOrEmployments.concat({
                          incomeOrEmploymentTypeId: undefined,
                          description: '',
                          monthlyAmount: '',
                        }),
                      } as IIntakeFields)
                  );
                  setChangedIncomeOrEmploymentsLength((s) => s + 1);
                  props.setIsDirty(true);
                }}
                tag={'button'}
              >
                <FaPlus /> Add Income/Employment
              </ListGroupItem>
            )}
          </ListGroup>
        </CardBody>
      </Card>
    </>
  );
};
