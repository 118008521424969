import * as React from 'react';
import { useState } from 'react';
import { Button, CustomInput, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import {
  FILE_SUB_TYPE_COMMUNITY_IMPACT,
  FILE_SUB_TYPE_GLADUE_REPORT,
  FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR,
  FILE_SUB_TYPE_WCC_CONNECTIVE,
} from '../../contexts/DomainContext';

interface IProps {
  options: Array<{ subType: string[]; value: string; display: string }>;
  values: [string, string][];
  setValues: (s: [string, string][]) => void;
  keyPrefix: string;
}

interface IOption {
  subType: string;
  stageId: string;
  display: string;
}

export const JusticeStageDropdownMultiSelectCheckboxes: React.FC<IProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((s) => !s);
  };

  const gladueOptions: IOption[] = props.options
    .filter((o) => o.subType.includes(FILE_SUB_TYPE_GLADUE_REPORT))
    .map((f) => ({
      subType: FILE_SUB_TYPE_GLADUE_REPORT,
      stageId: f.value,
      display: f.display,
    }));

  const wcccOptions: IOption[] = props.options
    .filter((o) => o.subType.includes(FILE_SUB_TYPE_WCC_CONNECTIVE))
    .map((f) => ({
      subType: FILE_SUB_TYPE_WCC_CONNECTIVE,
      stageId: f.value,
      display: f.display,
    }));
  const communityImpactOptions: IOption[] = props.options
    .filter((o) => o.subType.includes(FILE_SUB_TYPE_COMMUNITY_IMPACT))
    .map((f) => ({
      subType: FILE_SUB_TYPE_COMMUNITY_IMPACT,
      stageId: f.value,
      display: f.display,
    }));
  const lsnOptions: IOption[] = props.options
    .filter((o) => o.subType.includes(FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR))
    .map((f) => ({
      subType: FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR,
      stageId: f.value,
      display: f.display,
    }));

  return (
    <Dropdown key={props.keyPrefix + '_dropdown'} isOpen={isOpen} toggle={toggleDropdown}>
      <DropdownToggle
        caret={true}
        className={'d-flex align-items-center justify-content-between w-100 text-left'}
        outline={props.values.length === 0}
        color={'primary'}
        size={'sm'}
      >
        {props.values.length === 0 ? 'Show All' : 'Selected: ' + props.values.length}
      </DropdownToggle>
      <DropdownMenu className={'p-3'} style={{ width: 200 }}>
        <div
          style={{
            color: '#999',
            fontSize: '75%',
            marginBottom: '0.25em',
          }}
        >
          Gladue Report
        </div>
        {gladueOptions.map(({ stageId, display, subType }) => (
          <CustomInput
            type={'checkbox'}
            id={props.keyPrefix + +'_' + subType + '_' + stageId}
            key={props.keyPrefix + +'_' + subType + '_' + stageId}
            name={stageId}
            label={display}
            checked={
              props.values.find(
                (stageIdTuple: [string, string]) => stageIdTuple[0] === subType && stageIdTuple[1] === stageId
              ) !== undefined
            }
            onChange={() => {
              if (
                props.values.find(
                  (stageIdTuple: [string, string]) => stageIdTuple[0] === subType && stageIdTuple[1] === stageId
                ) !== undefined
              ) {
                props.setValues(
                  props.values.filter(
                    (stageIdTuple: [string, string]) =>
                      stageIdTuple[0] !== subType || (stageIdTuple[0] === subType && stageIdTuple[1] !== stageId)
                  )
                );
              } else {
                props.setValues([...props.values, [subType, stageId]]);
              }
            }}
          />
        ))}
        <div
          style={{
            color: '#999',
            fontSize: '75%',
            marginBottom: '0.25em',
            marginTop: '1em',
          }}
        >
          WCC/Connective
        </div>
        {wcccOptions.map(({ stageId, display, subType }) => (
          <CustomInput
            type={'checkbox'}
            id={props.keyPrefix + +'_' + subType + '_' + stageId}
            key={props.keyPrefix + +'_' + subType + '_' + stageId}
            name={stageId}
            label={display}
            checked={
              props.values.find(
                (stageIdTuple: [string, string]) => stageIdTuple[0] === subType && stageIdTuple[1] === stageId
              ) !== undefined
            }
            onChange={() => {
              if (
                props.values.find(
                  (stageIdTuple: [string, string]) => stageIdTuple[0] === subType && stageIdTuple[1] === stageId
                ) !== undefined
              ) {
                props.setValues(
                  props.values.filter(
                    (stageIdTuple: [string, string]) =>
                      stageIdTuple[0] !== subType || (stageIdTuple[0] === subType && stageIdTuple[1] !== stageId)
                  )
                );
              } else {
                props.setValues([...props.values, [subType, stageId]]);
              }
            }}
          />
        ))}
        <div
          style={{
            color: '#999',
            fontSize: '75%',
            marginBottom: '0.25em',
            marginTop: '1em',
          }}
        >
          Legal System Navigator
        </div>
        {lsnOptions.map(({ stageId, display, subType }) => (
          <CustomInput
            type={'checkbox'}
            id={props.keyPrefix + +'_' + subType + '_' + stageId}
            key={props.keyPrefix + +'_' + subType + '_' + stageId}
            name={stageId}
            label={display}
            checked={
              props.values.find(
                (stageIdTuple: [string, string]) => stageIdTuple[0] === subType && stageIdTuple[1] === stageId
              ) !== undefined
            }
            onChange={() => {
              if (
                props.values.find(
                  (stageIdTuple: [string, string]) => stageIdTuple[0] === subType && stageIdTuple[1] === stageId
                ) !== undefined
              ) {
                props.setValues(
                  props.values.filter(
                    (stageIdTuple: [string, string]) =>
                      stageIdTuple[0] !== subType || (stageIdTuple[0] === subType && stageIdTuple[1] !== stageId)
                  )
                );
              } else {
                props.setValues([...props.values, [subType, stageId]]);
              }
            }}
          />
        ))}
        <div
          style={{
            color: '#999',
            fontSize: '75%',
            marginBottom: '0.25em',
            marginTop: '1em',
          }}
        >
          Community Impact
        </div>
        {communityImpactOptions.map(({ stageId, display, subType }) => (
          <CustomInput
            type={'checkbox'}
            id={props.keyPrefix + +'_' + subType + '_' + stageId}
            key={props.keyPrefix + +'_' + subType + '_' + stageId}
            name={stageId}
            label={display}
            checked={
              props.values.find(
                (stageIdTuple: [string, string]) => stageIdTuple[0] === subType && stageIdTuple[1] === stageId
              ) !== undefined
            }
            onChange={() => {
              if (
                props.values.find(
                  (stageIdTuple: [string, string]) => stageIdTuple[0] === subType && stageIdTuple[1] === stageId
                ) !== undefined
              ) {
                props.setValues(
                  props.values.filter(
                    (stageIdTuple: [string, string]) =>
                      stageIdTuple[0] !== subType || (stageIdTuple[0] === subType && stageIdTuple[1] !== stageId)
                  )
                );
              } else {
                props.setValues([...props.values, [subType, stageId]]);
              }
            }}
          />
        ))}
        <Button
          onClick={() => props.setValues([])}
          block={true}
          outline={true}
          color={'primary'}
          className={'mt-2'}
          size={'sm'}
        >
          Clear All
        </Button>
      </DropdownMenu>
    </Dropdown>
  );
};
