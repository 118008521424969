import * as React from 'react';
import { components, OptionProps, SingleValueProps } from 'react-select';
import { IClient } from '../Clients/ClientContext';
import { formatDate } from '../../formatters/DateTimeFormatter';

export const clientSelectValue = (client: IClient) => {
  return (
    <>
      <strong>{client.lastName.toUpperCase() + ', ' + client.firstName}</strong>
      <br />
      <div className={'text-muted small'}>{client.birthDate && formatDate(new Date(client.birthDate))}</div>
    </>
  );
};

export const ClientOption = (props: OptionProps<IClient, false>) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div>{clientSelectValue(data)}</div>
    </components.Option>
  );
};

export const ClientSingleValue = (props: SingleValueProps<IClient>) => {
  const { data } = props;
  return <components.SingleValue {...props}>{clientSelectValue(data)}</components.SingleValue>;
};
