import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import { Column, TableInstance, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { useMemo } from 'react';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { FileContext } from './FileContext';
import { UsersContext } from '../../contexts/UsersContext';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { sanitizeHTMLConfig } from '../../contexts/DomainContext';
import { Button, Col, CustomInput, Navbar, NavbarBrand, Row } from 'reactstrap';
import { IReminder } from './FileReminders';
const DOMPurify = require('dompurify');

export interface IAppointment {
  appointmentId: string;
  date: number;
  description: string;
  location: string;
  staff: string[];
  hasReminder: boolean;
  reminder?: IReminder;
  clientAttended?: boolean;
}

export const FileAppointments = () => {
  const params = useParams<{ fileId: string }>();
  const usersContext = useContext(UsersContext);
  const fileContext = useContext(FileContext);
  const users = usersContext.users;
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const history = useHistory();
  const [showPastAppointments, setShowPastAppointments] = useState(false);

  useEffect(() => {
    setAppointments(
      showPastAppointments
        ? fileContext.appointments
        : fileContext.appointments.filter((a) => a.date >= new Date().getTime())
    );
  }, [fileContext.appointments, showPastAppointments]);

  const columns: Column<IAppointment>[] = useMemo(
    () => [
      {
        Header: 'Appointment Date',
        id: 'date',
        accessor: 'date',
        Cell: (c: any) => (c.row.original.date ? formatDateTime(c.row.original.date) : ''),
        width: 100,
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: 'description',
        width: 200,
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.description) {
            return (
              <main
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(cell.row.original.description, sanitizeHTMLConfig),
                }}
              />
            );
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'Staff',
        id: 'staff',
        width: 250,
        accessor: (a: IAppointment) => {
          const userList = users.filter((u) => a.staff.includes(u.userId));
          return userList.map((u) => u.lastName.toUpperCase() + ' ' + u.firstName).join(', ');
        },
      },
      {
        Header: 'Location',
        id: 'location',
        accessor: 'location',
        width: 200,
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.location) {
            return (
              <main
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(cell.row.original.location, sanitizeHTMLConfig),
                }}
              />
            );
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'Has Reminder',
        id: 'hasReminder',
        width: 100,
        accessor: (a: IAppointment) => {
          return a.hasReminder ? 'Yes' : 'No';
        },
      },
      {
        Header: 'Client Attended',
        id: 'clientAttended',
        width: 100,
        accessor: (a: IAppointment) => {
          return a.clientAttended ? 'Yes' : a.clientAttended === false ? 'No' : '-';
        },
      },
    ],
    [fileContext.appointments]
  );

  const data: IAppointment[] = useMemo(() => appointments, [appointments]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    // visibleColumns,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // preGlobalFilteredRows,
    // setGlobalFilter,
    state: { pageIndex, pageSize },
  }: TableInstance<IAppointment> = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: 'date',
            desc: false,
          },
        ],
        // hiddenColumns: ['userCreated', 'text'],
      },
    },
    // useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <Row className={'flex-fill'}>
            <Col md={2} xl={2}>
              <NavbarBrand>Appointments</NavbarBrand>
            </Col>
            <CustomInput
              className={'mt-2'}
              type='checkbox'
              id={'showPastAppointments'}
              name={'showPastAppointments'}
              label={'Show Past Appointments'}
              checked={showPastAppointments}
              onChange={() => {
                setShowPastAppointments(!showPastAppointments);
              }}
            />
            <Col className={'d-flex'}>
              <Button
                className={'ml-auto'}
                color={'primary'}
                onClick={() => history.push('/files/' + params.fileId + '/appointments/add')}
              >
                <FaPlus /> New Appointment
              </Button>
            </Col>
          </Row>
        </Navbar>
        <table className={'table table-bordered'} {...getTableProps()}>
          <thead style={{ overflow: 'visible' }}>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`files-table-thead-tr-${index}-${column.id}`}
                    width={column.width}
                    style={{ maxWidth: column.width }}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr key={`files-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() =>
                          history.push('/files/' + params.fileId + '/appointments/' + cell.row.original.appointmentId)
                        }
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <TablePagination
          pageCount={pageCount}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizes={[20, 50, 100, 500]}
        />
      </form>
    </div>
  );
};
