import * as React from 'react';
import { Col, Navbar, NavbarBrand, Row } from 'reactstrap';
import { Column, useSortBy, useTable } from 'react-table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { authAxios } from '../../services/AxiosService';
import { useMemo } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { formatDateFromISO } from '../../formatters/DateTimeFormatter';

export interface ILogin {
  time: string;
  success: boolean;
  reason?: string;
}

export const UserLoginHistory: React.FC = () => {
  const [loginHistory, setLoginHistory] = useState<ILogin[]>([]);
  const params = useParams<{ userId: string }>();

  useEffect(() => {
    if (params.userId) {
      authAxios.get('/api/users/admin/' + params.userId + '/login-history').then((response) => {
        setLoginHistory(response.data);
      });
    }
  }, [params.userId]);

  const columns: Column<ILogin>[] = useMemo(
    () => [
      {
        Header: 'Login Date',
        id: 'time',
        accessor: (c: ILogin) => c?.time,
        Cell: (c: any) => (c.row.original.time ? formatDateFromISO(c.row.original.time) : ''),
      },
      {
        Header: 'Success',
        accessor: 'success',
        Cell: (c: any) => (c.row.original.success ? 'Yes' : 'No'),
      },
      {
        Header: 'Reason',
        accessor: 'reason',
      },
    ],
    []
  );
  const data: ILogin[] = useMemo(() => loginHistory, [loginHistory]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <Row className='flex-fill'>
          <Col md={3} xl={2}>
            <NavbarBrand>Login History</NavbarBrand>
          </Col>
        </Row>
      </Navbar>
      <div className={'table-responsive'}>
        <table className={'table table-bordered'} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`users-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`users-table-thead-tr-${index}-${column.id}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);

              return (
                <tr key={`users-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td key={`users-table-td-${cell.row.id}-${cell.column.id}`} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
