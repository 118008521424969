import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import { useHistory } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Column, TableInstance, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { IDepartment } from '../../contexts/DomainContext';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { TablePagination } from '../TablePagination/TablePagination';

interface IProps {
  selectRow?: (userId: string) => void;
}

export const DepartmentsTable: React.FC<IProps> = (props) => {
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const history = useHistory();
  useEffect(() => {
    authAxios.get('/api/departments').then((response) => {
      setDepartments(response.data);
    });
  }, []);

  const columns: Column<IDepartment>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
    ],
    []
  );
  const data: IDepartment[] = useMemo(() => departments, [departments]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    prepareRow,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IDepartment> = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: 20 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const selectRow = (departmentId: string) => {
    if (props.selectRow) {
      props.selectRow(departmentId);
    } else {
      history.push('/departments/' + departmentId);
    }
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>Departments</NavbarBrand>
      </Navbar>
      <div className={'table-responsive'}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`departments-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`departments-table-thead-tr-${index}-${column.id}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);

              return (
                <tr key={`departments-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`departments-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() => selectRow(row.original.departmentId)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
      />
    </div>
  );
};
