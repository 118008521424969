import * as React from 'react';
import {
  Button,
  Col,
  Container,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { FormEvent, useEffect } from 'react';
import { authAxios } from '../../services/AxiosService';
import * as ToastService from '../../services/ToastService';
import { IDepartment } from '../../contexts/DomainContext';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import * as FormHelpers from '../../services/FormHelpers';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';

export const DepartmentDetails: React.FC = () => {
  const params = useParams<{ departmentId: string }>();
  const history = useHistory();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [active, setActive] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  const [trySubmit, setTrySubmit] = useState(false);

  useEffect(() => {
    if (params.departmentId) {
      authAxios
        .get('/api/departments/' + params.departmentId)
        .then((response) => {
          if (response.data && response.data.status && response.data.status.includes('KO')) {
            history.push('/404');
          } else if (response.data) {
            setName(response.data.name);
            setEmail(response.data.email);
            setPhone(response.data.phone);
            setActive(response.data.active);
          }
        })
        .catch((response) => {
          history.push('/404');
        });
    }
  }, [params.departmentId]);

  const formFullyFilled = () => {
    return name !== '' && email !== '' && FormHelpers.isValidEmail(email);
  };

  const saveDepartmentDetails = () => {
    if (!formFullyFilled()) {
      setTrySubmit(true);
      return;
    }
    if (params.departmentId) {
      authAxios
        .post('/api/departments/update', {
          departmentId: params.departmentId,
          name,
          email,
          phone,
          active,
        } as IDepartment)
        .then((response) => {
          if (response.data.status === 'OK') {
            ToastService.notifySuccess('Department Updated');
            setIsDirty(false);
          } else {
            ToastService.notifyError(response.data.errorMessage);
          }
        })
        .catch((error) => {
          ToastService.notifyError('Error:' + error);
        });
    } else {
      authAxios.post('/api/departments/create', { name, email, phone, active } as IDepartment).then((response) => {
        if (response.data.status === 'OK') {
          ToastService.notifySuccess('Department Created');
          setIsDirty(false);
          history.push('/departments/' + response.data.departmentId);
        } else {
          ToastService.notifyError('Error Creating Department - ' + response.data.desc);
        }
      });
    }
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>{params.departmentId ? 'Edit Department' : 'Add New Department'}</NavbarBrand>
            </Col>
            {params.departmentId ? (
              <Col className={'d-flex'}>
                <Button color={'primary'} className={'ml-auto'} onClick={() => saveDepartmentDetails()}>
                  Save Details
                </Button>
              </Col>
            ) : (
              <Col className={'d-flex'}>
                <Button color={'link'} className={'ml-auto'} onClick={() => history.push('/departments')}>
                  Cancel
                </Button>
                <Button color={'primary'} className={'ml-2'} onClick={() => saveDepartmentDetails()}>
                  Save Details
                </Button>
              </Col>
            )}
          </Row>
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          <Row>
            <Col>
              <Row>
                <FormGroup className='col-sm-6'>
                  <Label>
                    Name <span className='text-required'>(required)</span>
                  </Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='name'
                    value={name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setName(e.target.value);
                      setIsDirty(true);
                    }}
                    invalid={trySubmit && name === ''}
                  />
                  <FormFeedback>This field is mandatory</FormFeedback>
                </FormGroup>
              </Row>

              <Row>
                <FormGroup className='col-sm-6'>
                  <Label>Phone Number</Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='phone'
                    value={phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPhone(e.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className='col-sm-6'>
                  <Label>
                    Email <span className='text-required'>(required)</span>
                  </Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='email'
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value);
                      setIsDirty(true);
                    }}
                    invalid={trySubmit && !FormHelpers.isValidEmail(email)}
                  />
                  <FormFeedback>
                    {!FormHelpers.isValidEmail(email) ? 'Invalid Email' : 'This field is mandatory'}
                  </FormFeedback>
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className='col-sm-6'>
                  <CustomInput
                    type='checkbox'
                    id={'active'}
                    name={'active'}
                    label={'Active'}
                    checked={active}
                    onChange={() => {
                      setActive((s) => !s);
                      setIsDirty(true);
                    }}
                  />
                  <FormFeedback>This field is mandatory</FormFeedback>
                </FormGroup>
              </Row>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};
