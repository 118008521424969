import * as React from 'react';
import { useEffect } from 'react';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { authAxios } from '../services/AxiosService';

export interface IAxiosState<TData extends any> {
  type: AuthState;
  data?: TData;
  loading: boolean;
  error?: AxiosError;
  response?: AxiosResponse;
}

export const STATE_REQUEST_INITIAL = 'STATE_REQUEST_INITIAL';
export const STATE_REQUEST_START = 'STATE_REQUEST_START';
export const STATE_REQUEST_END = 'STATE_REQUEST_END';
export type AuthState = 'STATE_REQUEST_START' | 'STATE_REQUEST_END' | 'STATE_REQUEST_INITIAL';

export const ACTION_REQUEST_START = 'ACTION_REQUEST_START';
export const ACTION_REQUEST_END = 'ACTION_REQUEST_END';
export type IAxiosAction = {
  type: 'ACTION_REQUEST_START' | 'ACTION_REQUEST_END';
  data: {};
};

const request = <TData extends any>(url: string, dispatch: React.Dispatch<IAxiosAction>, skip: boolean) => {
  if (skip) {
    dispatch({ type: ACTION_REQUEST_END, data: { data: undefined } });
  } else {
    dispatch({ type: ACTION_REQUEST_START } as IAxiosAction);
    authAxios
      .get<TData>(url)
      .then((response) => {
        if (response.data) {
          dispatch({ type: ACTION_REQUEST_END, data: { data: response.data, response } });
        } else {
          dispatch({ type: ACTION_REQUEST_END, data: { response } });
        }
      })
      .catch((error) => {
        dispatch({ type: ACTION_REQUEST_END, data: { error } });
      });
  }
};

const authReducer: React.Reducer<IAxiosState<any>, IAxiosAction> = <TData extends any>(
  state: IAxiosState<TData>,
  action: IAxiosAction
) => {
  switch (action.type) {
    case ACTION_REQUEST_START:
      return {
        type: STATE_REQUEST_START,
        data: undefined,
        loading: true,
        error: undefined,
        response: undefined,
      } as IAxiosState<TData>;
    case ACTION_REQUEST_END:
      return { type: STATE_REQUEST_END, loading: false, ...action.data } as IAxiosState<TData>;
    default:
      return state;
  }
};

export const useGetAxiosHook = <TData extends any>(
  url: string,
  config?: AxiosRequestConfig,
  skip?: boolean
): IAxiosState<TData> => {
  const [state, dispatch] = React.useReducer(authReducer, {
    type: STATE_REQUEST_INITIAL,
    data: undefined,
    loading: true,
  } as IAxiosState<TData>);

  useEffect(() => {
    request<TData>(url, dispatch, !!skip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
};
