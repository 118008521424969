import * as React from 'react';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  Button,
  CardBody,
  Col,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { IAddress } from './ClientContext';
import { useWindowDimensions } from '../../hooks/WindowHooks';
import { FaPlus } from 'react-icons/fa';

interface IProps {
  saveAction: (rp: IAddress) => void;
  deleteAction?: (rp: IAddress) => void;
  clientId?: string;
  cancel: () => void;
  editAddress?: IAddress;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ClientAddress: React.FC<IProps> = (props) => {
  const [newAddressLabel, setNewAddressLabel] = useState<string>(props.editAddress?.label || '');
  const [newAddressLine1, setNewAddressLine1] = useState<string>(props.editAddress?.line1 || '');
  const [newAddressLine2, setNewAddressLine2] = useState<string>(props.editAddress?.line2 || '');
  const [newAddressCity, setNewAddressCity] = useState<string>(props.editAddress?.city || '');
  const [newAddressTerritory, setNewAddressTerritory] = useState<string>(props.editAddress?.territory || '');
  const [newAddressPostalCode, setNewAddressPostalCode] = useState<string>(props.editAddress?.postalCode || '');
  const [newAddressIsCurrent, setNewAddressIsCurrent] = useState<boolean>(props.editAddress?.isCurrent || true);

  const [tryAddAddress, setTryAddAddress] = useState(false);
  const [showLine2, setShowLine2] = useState(false);
  const { lg, xl } = useWindowDimensions();

  const addressFullyFilled = () => {
    return newAddressLabel !== '' && newAddressLine1 !== '';
  };

  const saveAddress = () => {
    if (addressFullyFilled()) {
      setTryAddAddress(false);
      const newAddress: IAddress = {
        addressId: props.editAddress?.addressId || uuid(),
        clientId: props.clientId || '',
        label: newAddressLabel,
        line1: newAddressLine1,
        line2: newAddressLine2,
        city: newAddressCity,
        territory: newAddressTerritory,
        postalCode: newAddressPostalCode,
        isCurrent: newAddressIsCurrent,
      };
      props.saveAction(newAddress);
    } else {
      setTryAddAddress(true);
    }
  };

  return (
    <>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom d-flex'} id={'adding-new-address'}>
        <NavbarBrand>{props.editAddress?.label ? `Edit ${props.editAddress.label}` : 'Add Address'}</NavbarBrand>
        <div className={'d-flex ml-auto navbar-actions'}>
          {props.cancel !== undefined && (
            <Button color={'link'} size={'sm'} onClick={() => props.cancel && props.cancel()}>
              Cancel
            </Button>
          )}
          {props.editAddress && (
            <Button
              color='danger'
              className='ml-3'
              onClick={() => {
                props.deleteAction && props.editAddress && props.deleteAction(props.editAddress);
              }}
            >
              Delete
            </Button>
          )}
          <Button color={'primary'} size={'sm'} className={'ml-2'} onClick={saveAddress} aria-label={'Save Address'}>
            Save
          </Button>
        </div>
      </Navbar>
      <CardBody className={'pb-3'}>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label>
                Address Label <span className='text-required'>(required)</span>
              </Label>
              <Input
                type='text'
                id={'address-label'}
                placeholder=''
                name='label'
                value={newAddressLabel}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNewAddressLabel(e.target.value);
                  props.setIsDirty(true);
                }}
                invalid={tryAddAddress && newAddressLabel === ''}
              />
              <FormFeedback>This field is mandatory</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <div className={'d-flex'}>
              <FormGroup className={'flex-grow-1'}>
                <Label>
                  Address Line 1 <span className='text-required'>(required)</span>
                </Label>
                <Input
                  type='text'
                  placeholder=''
                  name='line1'
                  value={newAddressLine1}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setNewAddressLine1(e.target.value);
                    props.setIsDirty(true);
                  }}
                  invalid={tryAddAddress && newAddressLine1 === ''}
                />
                <FormFeedback>This field is mandatory</FormFeedback>
              </FormGroup>
              {!(showLine2 || newAddressLine2) && (
                <div className={'ml-2'}>
                  <Button size={'sm'} style={{ marginTop: 28 }} color={'link'} onClick={() => setShowLine2(true)}>
                    {lg || xl ? 'Add Line 2' : <FaPlus />}
                  </Button>
                </div>
              )}
            </div>
          </Col>
          {(showLine2 || newAddressLine2) && (
            <Col xs={12}>
              <FormGroup>
                <Label>Address Line 2</Label>
                <Input
                  type='text'
                  placeholder=''
                  name='line2'
                  value={newAddressLine2}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setNewAddressLine2(e.target.value);
                    props.setIsDirty(true);
                  }}
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={4}>
            <FormGroup>
              <Label>City</Label>
              <Input
                type='text'
                placeholder=''
                name='city'
                value={newAddressCity}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNewAddressCity(e.target.value);
                  props.setIsDirty(true);
                }}
              />
            </FormGroup>
          </Col>
          <Col lg={4}>
            <FormGroup>
              <Label>Province/Territory</Label>
              <Input
                type='text'
                placeholder=''
                name='province'
                value={newAddressTerritory}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNewAddressTerritory(e.target.value);
                  props.setIsDirty(true);
                }}
              />
            </FormGroup>
          </Col>
          <Col lg={4}>
            <FormGroup>
              <Label>Postal Code</Label>
              <Input
                type='text'
                placeholder=''
                name='postalCode'
                value={newAddressPostalCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNewAddressPostalCode(e.target.value);
                  props.setIsDirty(true);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <CustomInput
                type='checkbox'
                id={'isCurrent'}
                name={'isCurrent'}
                label={'Is Current Address'}
                checked={newAddressIsCurrent}
                onChange={() => {
                  setNewAddressIsCurrent(!newAddressIsCurrent);
                  props.setIsDirty(true);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </>
  );
};
