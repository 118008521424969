import * as React from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import { useParams } from 'react-router';
import { AssociatedFilesTable } from '../Files/AssociatedFilesTable';

export const ClientFiles: React.FC = () => {
  const params = useParams<{ clientId: string }>();

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom d-flex'}>
        <NavbarBrand>Associated Files</NavbarBrand>
      </Navbar>
      <Container fluid={true} className={'p-0'}>
        <AssociatedFilesTable clientId={params.clientId} />
      </Container>
    </div>
  );
};
