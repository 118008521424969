import { Button, Card, CardBody, CardHeader, Col, Input, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FileContext, IReferral } from './FileContext';
import CreatableSelect from 'react-select/creatable';
import { FaPlus, FaTimes } from 'react-icons/fa';
import {
  DomainContext,
  FILE_TYPE_BIRTH_WORKER,
  FILE_TYPE_FAMILY_PRESERVATION,
  FILE_TYPE_JUSTICE,
} from '../../contexts/DomainContext';

interface IProps {
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  fromCYFN?: boolean;
}

export const Referrals = (props: IProps) => {
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const [changedReferralLength, setChangedReferralLength] = useState(0);
  const [changedReferralFromLength, setChangedReferralFromLength] = useState(0);

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
        borderRadius: '1.25rem',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  const referralTypeOptions = [
    { value: 'Walk In', label: 'Walk In' },
    { value: 'Phone', label: 'Phone' },
    { value: 'Email', label: 'Email' },
  ];

  const nonBirthWorkerOptions = [
    {
      label: 'Non-First Nation',
      options: [
        { value: 'Family / Friend', label: 'Family / Friend' },
        { value: 'Family and Child Services', label: 'Family and Child Services' },
        { value: 'Self-Referred', label: 'Self-Referred' },
      ],
    },
    {
      label: 'First Nation',
      options: domainContext.yukonFns.map((fn) => ({ value: fn.yukonFirstNationId, label: fn.name })),
    },
  ];

  const birthWorkerOptions = [
    {
      label: 'Non-First Nation',
      options: [
        { value: 'Family / Friend', label: 'Family / Friend' },
        { value: 'Family and Child Services', label: 'Family and Child Services' },
        { value: 'First Nations Health & Maternity', label: 'First Nations Health & Maternity' },
        { value: 'Self-Referred', label: 'Self-Referred' },
        { value: 'Solstice', label: 'Solstice' },
      ],
    },
    {
      label: 'First Nation',
      options: domainContext.yukonFns.map((fn) => ({ value: fn.yukonFirstNationId, label: fn.name })),
    },
  ];

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span
        style={{
          backgroundColor: '#EBECF0',
          borderRadius: '2em',
          color: '#172B4D',
          display: 'inline-block',
          fontSize: 12,
          fontWeight: 'normal',
          lineHeight: '1',
          minWidth: 1,
          padding: '0.16666666666667em 0.5em',
          textAlign: 'center',
        }}
      >
        {data.options.length}
      </span>
    </div>
  );

  const contactTypeOptions = [
    { value: 'Phone Number', label: 'Phone Number' },
    { value: 'Email', label: 'Email' },
    { value: 'Address', label: 'Address' },
  ];

  const referralHeaders = () => (
    <ListGroupItem className={'py-2 d-none d-md-block'}>
      <Row style={{ paddingRight: 42 }} className='row--condensed'>
        <Col md={2}>
          <Label>Date of Referral</Label>
        </Col>
        <Col md={2}>
          <Label>Referral Type</Label>
        </Col>
        {!props.fromCYFN && (
          <Col md={2}>
            <Label>Referred By</Label>
          </Col>
        )}
        {fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && props.fromCYFN && (
          <Col md={2}>
            <Label>Referred To</Label>
          </Col>
        )}
        <Col md={2}>
          <Label>Name</Label>
        </Col>
        <Col md={2}>
          <Label>Contact Type</Label>
        </Col>
        <Col md={2}>
          <Label>Contact Details</Label>
        </Col>
      </Row>
    </ListGroupItem>
  );

  const referralFields = (referral: IReferral, index: number) => (
    <ListGroupItem key={referral.referralId} className='list-group-item--borderless'>
      <div className={'d-flex'}>
        <Row className={'flex-grow-1 row--condensed'}>
          <Col md={2}>
            <Label className={'d-md-none'}>Date of Referral</Label>
            <DatePicker
              selected={referral.date ? new Date(referral.date) : null}
              name={'referral-date'}
              id={
                !props.fromCYFN
                  ? fileContext.referrals.length - 1 === index
                    ? 'select-element'
                    : ''
                  : fileContext.referralsFromCyfn.length - 1 === index
                  ? 'select-element-from'
                  : ''
              }
              onChange={(dateToSet) => {
                if (fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && props.fromCYFN) {
                  fileContext.setReferralsFromCyfn((oldR) => [
                    ...oldR.slice(0, index),
                    { ...oldR[index], date: dateToSet ? dateToSet.getTime() : undefined },
                    ...oldR.slice(index + 1),
                  ]);
                } else {
                  fileContext.setReferrals((oldR) => [
                    ...oldR.slice(0, index),
                    { ...oldR[index], date: dateToSet ? dateToSet.getTime() : undefined },
                    ...oldR.slice(index + 1),
                  ]);
                }
                props.setIsDirty(true);
              }}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              isClearable={true}
              dateFormat='MMM dd, yyyy'
              dropdownMode='select'
              className={'date-picker rounded mb-0'}
              placeholderText={'MMM dd, yyyy'}
            />
          </Col>
          <Col md={2}>
            <Label className={'d-md-none'}>Referral Type</Label>
            <CreatableSelect
              styles={disabledSelectStyles}
              formatCreateLabel={(inputValue: string) => inputValue}
              placeholder={<span className='placeholder'>Select or Type...</span>}
              options={[
                ...referralTypeOptions,
                ...(!referral.referralType || referralTypeOptions.some((e) => e.value === referral.referralType)
                  ? []
                  : [{ value: referral.referralType, label: referral.referralType }]),
              ]}
              value={referral.referralType ? { value: referral.referralType, label: referral.referralType } : undefined}
              onChange={(val) => {
                if (fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && props.fromCYFN) {
                  fileContext.setReferralsFromCyfn((oldR) => [
                    ...oldR.slice(0, index),
                    { ...oldR[index], referralType: val ? val.value : '' },
                    ...oldR.slice(index + 1),
                  ]);
                } else {
                  fileContext.setReferrals((oldR) => [
                    ...oldR.slice(0, index),
                    { ...oldR[index], referralType: val ? val.value : '' },
                    ...oldR.slice(index + 1),
                  ]);
                }
                props.setIsDirty(true);
              }}
              isMulti={false}
              isClearable={true}
            />
          </Col>
          {fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION || fileContext.fileTypeId === FILE_TYPE_JUSTICE ? (
            <Col md={2}>
              <Label className={'d-md-none'}>Referred By</Label>
              <CreatableSelect
                styles={disabledSelectStyles}
                formatCreateLabel={(inputValue: string) => inputValue}
                formatGroupLabel={formatGroupLabel}
                placeholder={<span className='placeholder'>Select or Type...</span>}
                options={nonBirthWorkerOptions}
                value={
                  referral.referredBy
                    ? domainContext.yukonFns.map((fn) => fn.yukonFirstNationId).includes(referral.referredBy)
                      ? {
                          value: referral.referredBy,
                          label: domainContext.yukonFns.find((fn) => fn.yukonFirstNationId === referral.referredBy)
                            ?.name,
                        }
                      : { value: referral.referredBy, label: referral.referredBy }
                    : undefined
                }
                onChange={(val) => {
                  props.setIsDirty(true);
                  fileContext.setReferrals((oldR) => [
                    ...oldR.slice(0, index),
                    { ...oldR[index], referredBy: val ? val.value : '' },
                    ...oldR.slice(index + 1),
                  ]);
                }}
                isMulti={false}
                isClearable={true}
              />
            </Col>
          ) : fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && !props.fromCYFN ? (
            <Col md={2}>
              <Label className={'d-md-none'}>Referred By</Label>
              <CreatableSelect
                styles={disabledSelectStyles}
                formatCreateLabel={(inputValue: string) => inputValue}
                formatGroupLabel={formatGroupLabel}
                placeholder={<span className='placeholder'>Select or Type...</span>}
                options={birthWorkerOptions}
                value={
                  referral.referredBy
                    ? domainContext.yukonFns.map((fn) => fn.yukonFirstNationId).includes(referral.referredBy)
                      ? {
                          value: referral.referredBy,
                          label: domainContext.yukonFns.find((fn) => fn.yukonFirstNationId === referral.referredBy)
                            ?.name,
                        }
                      : { value: referral.referredBy, label: referral.referredBy }
                    : undefined
                }
                onChange={(val) => {
                  props.setIsDirty(true);
                  fileContext.setReferrals((oldR) => [
                    ...oldR.slice(0, index),
                    { ...oldR[index], referredBy: val ? val.value : '' },
                    ...oldR.slice(index + 1),
                  ]);
                }}
                isMulti={false}
                isClearable={true}
              />
            </Col>
          ) : (
            <Col md={2}>
              <Label className={'d-md-none'}>Referred To</Label>
              <Input
                type='text'
                placeholder='Type…'
                value={referral.referredBy}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const detail = e.target.value;
                  fileContext.setReferralsFromCyfn((oldR) => [
                    ...oldR.slice(0, index),
                    { ...oldR[index], referredBy: detail },
                    ...oldR.slice(index + 1),
                  ]);
                  props.setIsDirty(true);
                }}
              />
            </Col>
          )}
          <Col md={2}>
            <Label className={'d-md-none'}>Name</Label>
            <Input
              type='text'
              placeholder='Type…'
              value={referral.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const detail = e.target.value;
                if (fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && props.fromCYFN) {
                  fileContext.setReferralsFromCyfn((oldR) => [
                    ...oldR.slice(0, index),
                    { ...oldR[index], name: detail },
                    ...oldR.slice(index + 1),
                  ]);
                } else {
                  fileContext.setReferrals((oldR) => [
                    ...oldR.slice(0, index),
                    { ...oldR[index], name: detail },
                    ...oldR.slice(index + 1),
                  ]);
                }
                props.setIsDirty(true);
              }}
            />
          </Col>
          <Col md={2}>
            <Label className={'d-md-none'}>Contact Type</Label>
            <CreatableSelect
              styles={disabledSelectStyles}
              formatCreateLabel={(inputValue: string) => inputValue}
              placeholder={<span className='placeholder'>Select or Type...</span>}
              options={[
                ...contactTypeOptions,
                ...(!referral.contact.contactType ||
                contactTypeOptions.some((e) => e.value === referral.contact.contactType)
                  ? []
                  : [{ value: referral.contact.contactType, label: referral.contact.contactType }]),
              ]}
              value={
                referral.contact.contactType
                  ? { value: referral.contact.contactType, label: referral.contact.contactType }
                  : undefined
              }
              onChange={(val) => {
                if (fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && props.fromCYFN) {
                  fileContext.setReferralsFromCyfn((oldR) => [
                    ...oldR.slice(0, index),
                    {
                      ...oldR[index],
                      contact: {
                        contactType: val ? val.value : '',
                        details: oldR[index].contact.details,
                      },
                    },
                    ...oldR.slice(index + 1),
                  ]);
                } else {
                  fileContext.setReferrals((oldR) => [
                    ...oldR.slice(0, index),
                    {
                      ...oldR[index],
                      contact: {
                        contactType: val ? val.value : '',
                        details: oldR[index].contact.details,
                      },
                    },
                    ...oldR.slice(index + 1),
                  ]);
                }
                props.setIsDirty(true);
              }}
              isMulti={false}
              isClearable={true}
              // isDisabled={!props.isEditingAllowed}
              // styles={disabledSelectStyles}
            />
          </Col>
          <Col md={2}>
            <Label className={'d-md-none'}>Contact Details</Label>
            <Input
              type='text'
              placeholder='Type…'
              value={referral.contact.details}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const detail = e.target.value;
                if (fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && props.fromCYFN) {
                  fileContext.setReferralsFromCyfn((oldR) => [
                    ...oldR.slice(0, index),
                    {
                      ...oldR[index],
                      contact: { contactType: oldR[index].contact.contactType, details: detail },
                    },
                    ...oldR.slice(index + 1),
                  ]);
                } else {
                  fileContext.setReferrals((oldR) => [
                    ...oldR.slice(0, index),
                    {
                      ...oldR[index],
                      contact: { contactType: oldR[index].contact.contactType, details: detail },
                    },
                    ...oldR.slice(index + 1),
                  ]);
                }
                props.setIsDirty(true);
              }}
            />
          </Col>
          <Col className={'d-md-none'}>
            <Button
              color='danger'
              className='w-mobile-100'
              onClick={() => {
                props.setIsDirty(true);
                if (fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && props.fromCYFN) {
                  fileContext.setReferralsFromCyfn((oldR) => [...oldR.slice(0, index), ...oldR.slice(index + 1)]);
                } else {
                  fileContext.setReferrals((oldR) => [...oldR.slice(0, index), ...oldR.slice(index + 1)]);
                }
              }}
            >
              Remove
            </Button>
          </Col>
        </Row>
        <div className={'d-none d-md-block'} style={{ width: 42 }}>
          <Button
            color={'link'}
            className={'text-danger'}
            onClick={() => {
              props.setIsDirty(true);
              if (fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && props.fromCYFN) {
                fileContext.setReferralsFromCyfn((oldR) => [...oldR.slice(0, index), ...oldR.slice(index + 1)]);
              } else {
                fileContext.setReferrals((oldR) => [...oldR.slice(0, index), ...oldR.slice(index + 1)]);
              }
            }}
          >
            <FaTimes />
          </Button>
        </div>
      </div>
    </ListGroupItem>
  );

  useEffect(() => {
    if (changedReferralLength > 0) {
      var elmnt = document.getElementById('add-new-referral');
      if (elmnt) {
        elmnt.scrollIntoView({ behavior: 'smooth' });
      }
      var elmnt4 = document.getElementById('select-element');
      if (elmnt4) {
        elmnt4.focus();
      }
    }
  }, [fileContext.referrals.length]);

  useEffect(() => {
    if (changedReferralFromLength > 0) {
      var elmnt = document.getElementById('add-new-referral-from');
      if (elmnt) {
        elmnt.scrollIntoView({ behavior: 'smooth' });
      }
      var elmnt4 = document.getElementById('select-element-from');
      if (elmnt4) {
        elmnt4.focus();
      }
    }
  }, [fileContext.referralsFromCyfn.length]);

  return (
    <>
      <Card>
        {fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION && (
          <CardHeader className={'d-flex align-items-center'}>Referrals</CardHeader>
        )}
        {fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && !props.fromCYFN && (
          <CardHeader className={'d-flex align-items-center'}>Referrals To CYFN</CardHeader>
        )}
        {fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && props.fromCYFN && (
          <CardHeader className={'d-flex align-items-center'}>Referrals From CYFN</CardHeader>
        )}
        <CardBody className={'p-0'}>
          <ListGroup flush={true}>
            {fileContext.referrals.length > 0 && !props.fromCYFN && referralHeaders()}
            {fileContext.referralsFromCyfn.length > 0 && props.fromCYFN && referralHeaders()}
            {!props.fromCYFN && fileContext.referrals.map((r, index) => referralFields(r, index))}
            {props.fromCYFN && fileContext.referralsFromCyfn.map((r, index) => referralFields(r, index))}
            {fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && props.fromCYFN ? (
              <ListGroupItem
                tag={'button'}
                id={'add-new-referral-from'}
                className='border-top'
                onClick={() => {
                  if (fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && props.fromCYFN) {
                    fileContext.setReferralsFromCyfn((r) =>
                      r.concat([
                        {
                          referralId: Math.random().toString(),
                          date: new Date().getTime(),
                          referralType: '',
                          referredBy: '',
                          name: '',
                          contact: { contactType: '', details: '' },
                          fromCyfn: props.fromCYFN,
                        },
                      ])
                    );
                    setChangedReferralFromLength((s) => s + 1);
                    props.setIsDirty(true);
                  }
                }}
              >
                <FaPlus /> Add Referral
              </ListGroupItem>
            ) : (
              <ListGroupItem
                tag={'button'}
                id={'add-new-referral'}
                className='border-top'
                onClick={() => {
                  fileContext.setReferrals((r) =>
                    r.concat([
                      {
                        referralId: Math.random().toString(),
                        date: new Date().getTime(),
                        referralType: '',
                        referredBy: '',
                        name: '',
                        contact: { contactType: '', details: '' },
                        fromCyfn: props.fromCYFN,
                      },
                    ])
                  );
                  setChangedReferralLength((s) => s + 1);
                  props.setIsDirty(true);
                }}
              >
                <FaPlus /> Add Referral
              </ListGroupItem>
            )}
          </ListGroup>
        </CardBody>
      </Card>
    </>
  );
};
