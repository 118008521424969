import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import * as H from 'history';
import * as ToastService from '../../services/ToastService';
import { Badge, Col, Container, Nav, NavItem, NavLink, Row, Button } from 'reactstrap';

import { FileDetails } from './FileDetails';
import { authAxios } from '../../services/AxiosService';
import { FileContext, IFile, IFileMeta } from './FileContext';
import { FileActivityLog } from './FileActivityLog';

import { MyUserContext } from '../../contexts/MyUserContext';
import { FileNotes } from './FileNotes';
import { FileNotesDetails } from './FileNotesDetails';
import { Documents } from './Documents/Documents';
import { FileDocumentDetails } from './Documents/FileDocumentDetails';
import { ClientsContext } from '../../contexts/ClientsContext';
import { FilePrintRequest } from './FilePrintRequest';
import { FileReminders } from './FileReminders';
import { FileReminderDetails } from './FileReminderDetails';
import { FileClientsContainer } from './FileClientsContainer';
import { FileFinancialTracking } from './FileFinancialTracking';
import { useWindowDimensions } from '../../hooks/WindowHooks';
import {
  FILE_TYPE_FAMILY_PRESERVATION,
  FILE_TYPE_INTAKE,
  FILE_TYPE_JORDANS_PRINCIPLE,
  FILE_TYPE_JUSTICE,
  JORDANS_PRINCIPLE_REQUEST_TYPE_ISC_SUBMISSION,
  STAGE_INTAKE_IN_PROGRESS,
  STAGE_REJECTED,
} from '../../contexts/DomainContext';
import { ClientDocumentDetails } from './Documents/ClientDocumentDetails';
import { FileAppointments } from './FileAppointments';
import { FileAppointmentsDetails } from './FileAppointmentsDetails';
import { FileRequestFiles } from '../../components/Files/RequestFilesTable';
import { AssociatedIntakeFileDisplay } from '../../components/Files/AssociatedIntakeFileDisplay';

export const File = () => {
  const fileContext = useContext(FileContext);
  const myUserContext = useContext(MyUserContext);
  const clientsContext = useContext(ClientsContext);
  const clients = clientsContext.state.clients;
  const userRole = myUserContext.role;
  const params = useParams<{ fileId: string; tab?: string }>();
  const file = fileContext.file;
  const location = useLocation<{ overrideReload?: boolean }>();
  const history = useHistory();
  const overrideReload = location?.state?.overrideReload;
  const { xs, sm } = useWindowDimensions();
  const verticalMenu = xs || sm;

  const [associatedIntakeFile, setAssociatedIntakeFile] = useState<IFile | undefined>(undefined);

  useEffect(() => {
    if (!overrideReload) {
      authAxios
        .get('/api/files/admin/' + params.fileId)
        .then((response) => {
          if (response.data && response.data.status && response.data.status.includes('KO')) {
            history.push('/404');
          } else {
            const file: IFile = response.data;
            fileContext.setFileAndAttributes(file);
          }
        })
        .catch((response) => {
          history.push('/404');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId]);

  useEffect(() => {
    if (!overrideReload) {
      authAxios
        .get('/api/files/admin/' + params.fileId + '/reminders')
        .then((response) => {
          if (response.data && response.data.status && response.data.status.includes('KO')) {
            history.push('/404');
          } else if (response.data.status === 'OK') {
            fileContext.setReminders(response.data.reminders);
          }
        })
        .catch((response) => {
          history.push('/404');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId]);

  useEffect(() => {
    if (!overrideReload) {
      authAxios
        .get('/api/files/admin/' + params.fileId + '/appointments')
        .then((response) => {
          if (response.data && response.data.status && response.data.status.includes('KO')) {
            history.push('/404');
          } else if (response.data.status === 'OK') {
            fileContext.setAppointments(response.data.appointments);
          }
        })
        .catch((response) => {
          history.push('/404');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId]);

  useEffect(() => {
    if (!overrideReload) {
      authAxios
        .get('/api/files/admin/' + params.fileId + '/meta')
        .then((response) => {
          if (response.data && response.data.status && response.data.status.includes('KO')) {
            history.push('/404');
          } else {
            const file: IFileMeta = response.data;
            fileContext.setFileMeta(file);
          }
        })
        .catch((response) => {
          history.push('/404');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fileId]);

  useEffect(() => {
    if (file.intakeFileId) {
      authAxios
        .get('/api/files/admin/' + file.intakeFileId)
        .then((response) => {
          if (response.data && response.data.status && response.data.status.includes('KO')) {
            ToastService.notifyError('Intake file not loaded');
          } else {
            const file: IFile = response.data;
            setAssociatedIntakeFile(file);
          }
        })
        .catch((response) => {
          history.push('/404');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.intakeFileId]);

  useEffect(() => {
    history.replace(history.location.pathname, { overrideReload: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tab: string = params.tab
    ? params.tab
    : file.stageId === STAGE_INTAKE_IN_PROGRESS || file.stageId === STAGE_REJECTED
    ? 'intake'
    : 'details';

  const NavItemLink: React.FC<{ to: H.LocationDescriptor; active: boolean; name: any; count?: number }> = (props) => {
    return (
      <NavItem>
        <NavLink tag={Link} to={props.to} active={props.active}>
          {props.name}
          {!!props.count && props.count > 0 && (
            <Badge color={'primary'} className={'ml-2'}>
              {props.count}
            </Badge>
          )}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <article className='page'>
      <div className={'page-header-image d-print-none'} />
      <header className='page-header d-print-none'>
        <Container fluid={true}>
          <Row className={'align-items-center'}>
            <Col>
              <div className='d-flex flex-column'>
                {fileContext.fileName && (
                  <Button
                    color={'link'}
                    className={'order-1 px-0 pt-0'}
                    style={{ width: 'fit-content' }}
                    onClick={() => history.push('/files/' + params.fileId + '/client')}
                  >
                    <h3 className='page-header__title mb-0' style={{ textAlign: 'left', color: 'black' }}>
                      {fileContext.fileName}
                    </h3>
                  </Button>
                )}
                {
                  !fileContext.fileName && (
                    <div className={'order-1 pl-3 d-flex flex-row align-items-start'} style={{ flexWrap: 'wrap' }}>
                      {clients
                        .filter((i) => (file.clientIds ? file.clientIds.includes(i.clientId) : false))
                        .map((i, index) => (
                          <Row
                            key={i.clientId}
                            style={{ width: 'fit-content', maxWidth: 'max-content' }}
                            className-={'py-0'}
                          >
                            <Col
                              style={{ width: 'fit-content', maxWidth: 'max-content' }}
                              className={index === 0 ? 'px-0' : 'px-0'}
                            >
                              <Button
                                color={'link'}
                                style={{ width: 'fit-content' }}
                                className={'py-0 px-1'}
                                onClick={() => history.push('/clients/' + i.clientId)}
                              >
                                <h5 className='text-primary m-0'>{i.lastName.toUpperCase() + ', ' + i.firstName}</h5>
                              </Button>
                            </Col>
                            {index !==
                              clients.filter((i) => (file.clientIds ? file.clientIds.includes(i.clientId) : false))
                                .length -
                                1 && (
                              <Col style={{ width: '40px', maxWidth: '40px' }} className={'px-0'}>
                                <div style={{ fontWeight: 900, fontSize: 'large', color: 'primary' }}>{'•'}</div>
                              </Col>
                            )}
                          </Row>
                        ))}
                    </div>
                  )
                  // .join(' • ')
                }
                <div className={'order-2 d-flex align-items-center flex-row'}>
                  <h3 className='page-header__title pt-1' style={{ textAlign: 'left', color: 'black' }}>
                    {file.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE &&
                    file.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_ISC_SUBMISSION &&
                    file.jordansPrincipleFields?.iscNumber ? (
                      <b>
                        {file.fileId &&
                          `${file?.fileNumber} • ${file?.fileTypeDisplay} • ${file?.jordansPrincipleFields.iscNumber}`}
                      </b>
                    ) : (
                      <b>{file.fileId && `${file?.fileNumber} • ${file?.fileTypeDisplay}`}</b>
                    )}
                  </h3>
                  {file.clientIds?.length === 1 && clients.find((e) => e.clientId === file.clientIds[0])?.flagged && (
                    <Badge className={'ml-2 pb-1'} color={'warning'}>
                      Flagged Client
                    </Badge>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <div className={verticalMenu ? 'd-block d-md-none pb-4' : 'd-none d-md-block'}>
            <Nav tabs={!verticalMenu} pills={verticalMenu} vertical={verticalMenu}>
              {(file.stageId === STAGE_INTAKE_IN_PROGRESS ||
                file.stageId === STAGE_REJECTED ||
                associatedIntakeFile) && (
                <NavItemLink to={'/files/' + params.fileId + '/intake'} active={tab === 'intake'} name={'Intake'} />
              )}
              {file.stageId !== STAGE_INTAKE_IN_PROGRESS && file.stageId !== STAGE_REJECTED && (
                <NavItemLink
                  to={'/files/' + params.fileId + '/details'}
                  active={tab === 'details'}
                  name={file.fileTypeId === FILE_TYPE_INTAKE ? 'Intake Details' : 'File Information'}
                />
              )}
              <>
                <NavItemLink to={'/files/' + params.fileId + '/client'} active={tab === 'client'} name={'Client'} />
                {file.fileTypeId === FILE_TYPE_INTAKE && (
                  <NavItemLink
                    to={'/files/' + params.fileId + '/request-files'}
                    active={tab === 'request-files'}
                    name={'File Requests'}
                  />
                )}
                {file.stageId !== STAGE_INTAKE_IN_PROGRESS && file.stageId !== STAGE_REJECTED && (
                  <>
                    {file.fileTypeId !== FILE_TYPE_INTAKE && (
                      <NavItemLink
                        to={'/files/' + params.fileId + '/notes'}
                        active={tab === 'notes'}
                        name={'Case Notes'}
                        count={fileContext.fileMeta.noteCount}
                      />
                    )}
                    <NavItemLink
                      to={'/files/' + params.fileId + '/reminders'}
                      active={tab === 'reminders'}
                      name={'Reminders'}
                    />
                  </>
                )}
                {(file.fileTypeId === FILE_TYPE_JUSTICE || file.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION) &&
                  file.stageId !== STAGE_INTAKE_IN_PROGRESS &&
                  file.stageId !== STAGE_REJECTED && (
                    <NavItemLink
                      to={'/files/' + params.fileId + '/appointments'}
                      active={tab === 'appointments'}
                      name={'Appointments'}
                    />
                  )}
                {file.fileTypeId !== FILE_TYPE_INTAKE && file.stageId !== STAGE_REJECTED && (
                  <NavItemLink
                    to={'/files/' + params.fileId + '/documents'}
                    active={tab === 'documents' || tab === 'client-documents'}
                    name={'Documents'}
                  />
                )}
              </>
              {file.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE &&
                file.stageId !== STAGE_INTAKE_IN_PROGRESS &&
                file.stageId !== STAGE_REJECTED && (
                  <>
                    <NavItemLink
                      to={'/files/' + params.fileId + '/print-request'}
                      active={tab === 'print-request'}
                      name={'Print Request'}
                    />
                    <NavItemLink
                      to={'/files/' + params.fileId + '/financial-tracking'}
                      active={tab === 'financial-tracking'}
                      name={'Financial Tracking'}
                    />
                  </>
                )}
              {(userRole === 'Intake' || userRole === 'SuperAdmin') &&
                file.stageId !== STAGE_INTAKE_IN_PROGRESS &&
                file.stageId !== STAGE_REJECTED && (
                  <NavItemLink
                    to={'/files/' + params.fileId + '/activity-log'}
                    active={tab === 'activity-log'}
                    name={'Activity Log'}
                  />
                )}
            </Nav>
          </div>
        </Container>
      </header>
      <div style={{ backgroundColor: 'white' }}>
        {(file.stageId === STAGE_INTAKE_IN_PROGRESS || file.stageId === STAGE_REJECTED) && associatedIntakeFile ? (
          <Route exact={true} path='/files/:fileId/(intake)?'>
            <AssociatedIntakeFileDisplay associatedIntakeFile={associatedIntakeFile} />
          </Route>
        ) : (
          <Route exact={true} path='/files/:fileId/(details)?' component={FileDetails} />
        )}
        <Route exact={true} path='/files/:fileId/client' component={FileClientsContainer} />
        <Switch>
          <Route exact={true} path='/files/:fileId/documents'>
            <Documents
              clientDetailsPath={`/files/${file.fileId}/client-documents`}
              newDocumentPath={`/api/documents/add-file-document/${file.fileId}`}
              getPaths={[`/api/files/${params.fileId}/documents`, `/api/files/${params.fileId}/client-documents`]}
            />
          </Route>
          <Route exact={true} path='/files/:fileId/documents/:documentId'>
            <FileDocumentDetails />
          </Route>
          <Route exact={true} path='/files/:fileId/client-documents/:clientId/:documentId'>
            <ClientDocumentDetails canDelete={false} />
          </Route>
        </Switch>
        {userRole === 'SuperAdmin' && (
          <Route exact={true} path='/files/:fileId/activity-log' component={FileActivityLog} />
        )}
        {file.stageId !== STAGE_INTAKE_IN_PROGRESS && file.stageId !== STAGE_REJECTED && (
          <>
            {associatedIntakeFile && (
              <Route exact={true} path='/files/:fileId/intake'>
                <AssociatedIntakeFileDisplay associatedIntakeFile={associatedIntakeFile} />
              </Route>
            )}
            <Route exact={true} path='/files/:fileId/print-request' component={FilePrintRequest} />
            <Route exact={true} path='/files/:fileId/request-files' component={FileRequestFiles} />
            <Route exact={true} path='/files/:fileId/reminders' component={FileReminders} />
            <Route exact={true} path='/files/:fileId/reminders/:reminderId' component={FileReminderDetails} />
            <Switch>
              <Route exact={true} path='/files/:fileId/appointments' component={FileAppointments} />
              <Route exact={true} path='/files/:fileId/appointments/add' component={FileAppointmentsDetails} />
              <Route
                exact={true}
                path='/files/:fileId/appointments/:appointmentId'
                component={FileAppointmentsDetails}
              />
            </Switch>
            <Route exact={true} path='/files/:fileId/financial-tracking' component={FileFinancialTracking} />
            <Switch>
              <Route exact={true} path='/files/:fileId/notes' component={FileNotes} />
              <Route exact={true} path='/files/:fileId/notes/add' component={FileNotesDetails} />
              <Route exact={true} path='/files/:fileId/notes/:noteId' component={FileNotesDetails} />
            </Switch>
          </>
        )}
      </div>
    </article>
  );
};
