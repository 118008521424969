import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as React from 'react';
import { Dispatch, SetStateAction, useContext } from 'react';
import { FileContext } from '../../../components/Files/FileContext';
import { IUser } from '../../../components/Users/UsersTable';

interface IProps {
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  openCheckConfidentialityModal: boolean;
  setOpenCheckConfidentialityModal: Dispatch<SetStateAction<boolean>>;
  openUncheckConfidentialityModal: boolean;
  setOpenUncheckConfidentialityModal: Dispatch<SetStateAction<boolean>>;
  staff: IUser[];
}

export const ConfidentialityModals = (props: IProps) => {
  const fileContext = useContext(FileContext);

  return (
    <>
      <Modal isOpen={props.openCheckConfidentialityModal} className='check-confidentiality-modal'>
        <ModalHeader>Set File to Confidential</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            Confidential files are only visible to administrator users. All non-administrator staff users will be
            removed from the file. Are you sure you want to proceed?
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              fileContext.removeNonSuperAdmins(props.staff);
              props.setOpenCheckConfidentialityModal(false);
            }}
          >
            Proceed
          </Button>
          <Button
            color={'light'}
            onClick={() => {
              fileContext.setIsConfidential(false);
              props.setIsDirty(false);
              props.setOpenCheckConfidentialityModal(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={props.openUncheckConfidentialityModal} className='uncheck-confidentiality-modal'>
        <ModalHeader>Set File to Non-Confidential</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            This file will now be visible to all staff users with access to this department. Are you sure you want to
            continue?
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              props.setOpenUncheckConfidentialityModal(false);
            }}
          >
            Proceed
          </Button>
          <Button
            color={'light'}
            onClick={() => {
              fileContext.setIsConfidential(true);
              props.setIsDirty(false);
              props.setOpenUncheckConfidentialityModal(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
