import * as React from 'react';
import { Button, ListGroupItem } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

interface IDocumentListItem {
  name: string;
  onDelete?: () => void;
  onClick?: () => void;
}

export const DocumentListItem: React.FC<IDocumentListItem> = (props) => {
  const onClick = () => {
    props.onDelete === undefined && props.onClick && props.onClick();
  };

  return (
    <ListGroupItem className={'d-flex align-items-center'} onClick={onClick}>
      <span style={{ wordBreak: 'break-all', textAlign: 'left' }}>{props.name}</span>
      {props.onDelete && (
        <Button color={'link'} className={'ml-auto p-0 text-danger'} onClick={props.onDelete}>
          <FaTimes />
        </Button>
      )}
    </ListGroupItem>
  );
};
