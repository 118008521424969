import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { FileContext, IIntakeFields } from './FileContext';
import { DomainContext, IResourceSupportType, STAGE_COMPLETED } from '../../contexts/DomainContext';
import { useWindowDimensions } from '../../hooks/WindowHooks';

interface IProps {
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  isEditingAllowed: boolean;
}

export const ResourceSupports = (props: IProps) => {
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const resourceSupportTypes = domainContext.resourceSupportTypes;

  const stageIsCompleted = fileContext.file.stageId === STAGE_COMPLETED;
  const setIntakeFields = fileContext.setIntakeFields;
  const resourceSupports = fileContext.intakeFields?.resourceSupports ? fileContext.intakeFields.resourceSupports : [];
  const [changedResourceSupportsLength, setChangedResourceSupportsLength] = useState(0);
  const { xs, sm } = useWindowDimensions();
  const mobile = xs || sm;

  useEffect(() => {
    if (changedResourceSupportsLength > 0) {
      var elmt2 = document.getElementById('add-new-resource-support');
      if (elmt2) {
        elmt2.focus();
        elmt2.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [changedResourceSupportsLength]);

  const disabledSelectStyles = {
    control: (styles: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
        borderRadius: '1.25rem',
      };
    },
    singleValue: (styles: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  return (
    <>
      <Card>
        <CardHeader className={'d-flex align-items-center'}>Resource Support</CardHeader>
        <CardBody className={'p-0'}>
          <ListGroup flush={true}>
            {resourceSupports && resourceSupports.length > 0 && (
              <ListGroupItem className={'py-2 d-none d-md-block'}>
                <Row style={{ paddingRight: 42 }} className='row--condensed'>
                  <Col style={{ flexGrow: 0.7 }}>
                    <Label>Type</Label>
                  </Col>
                  <Col style={{ flexGrow: 1 }}>
                    <Label>Description</Label>
                  </Col>
                </Row>
              </ListGroupItem>
            )}
            {resourceSupports.map((r, index) => (
              <ListGroupItem key={index} className='list-group-item--borderless'>
                <div className={'d-flex'}>
                  <div className={'flex-grow-1'}>
                    <Row className='row--condensed'>
                      <Col sm={12} md={5} style={!mobile ? { flexGrow: 1.5, flexShrink: 1, flexBasis: 0 } : {}}>
                        <Select
                          key={`income-or-employment-type-${index}`}
                          styles={disabledSelectStyles}
                          name={'income-or-employment-type'}
                          isDisabled={!props.isEditingAllowed || stageIsCompleted}
                          placeholder={<span className='placeholder'>Select...</span>}
                          options={resourceSupportTypes}
                          value={resourceSupportTypes.find(
                            (rType) => rType.resourceSupportTypeId === r.resourceSupportTypeId
                          )}
                          onChange={(value: any) => {
                            setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  resourceSupports: s?.resourceSupports
                                    ? [
                                        ...s.resourceSupports.slice(0, index),
                                        {
                                          ...s.resourceSupports[index],
                                          resourceSupportTypeId: value ? value.resourceSupportTypeId : '',
                                        },
                                        ...s.resourceSupports.slice(index + 1),
                                      ]
                                    : [],
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          getOptionValue={(option: IResourceSupportType) => option.resourceSupportTypeId}
                          getOptionLabel={(option: IResourceSupportType) => option.name}
                          isClearable={true}
                        />
                      </Col>
                      <Col sm={12} md={7} style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}>
                        <Input
                          type='text'
                          value={r.description}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                          onChange={(e) => {
                            setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  resourceSupports: s?.resourceSupports
                                    ? [
                                        ...s.resourceSupports.slice(0, index),
                                        {
                                          ...s.resourceSupports[index],
                                          description: e.target.value,
                                        },
                                        ...s.resourceSupports.slice(index + 1),
                                      ]
                                    : [],
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className={'d-none d-md-block'} style={{ width: 42 }}>
                    <Button
                      color={'link'}
                      className={'text-danger'}
                      disabled={!props.isEditingAllowed || stageIsCompleted}
                      onClick={() => {
                        setIntakeFields(
                          (s) =>
                            ({
                              ...s,
                              resourceSupports: s?.resourceSupports
                                ? [...s.resourceSupports.slice(0, index), ...s.resourceSupports.slice(index + 1)]
                                : [],
                            } as IIntakeFields)
                        );
                        props.setIsDirty(true);
                      }}
                    >
                      <FaTimes />
                    </Button>
                  </div>
                </div>
              </ListGroupItem>
            ))}
            {props.isEditingAllowed && !stageIsCompleted && (
              <ListGroupItem
                id={'add-new-resource-support'}
                className='border-top'
                disabled={!props.isEditingAllowed || stageIsCompleted}
                onClick={() => {
                  setIntakeFields(
                    (s) =>
                      ({
                        ...s,
                        resourceSupports: resourceSupports.concat({
                          resourceSupportTypeId: undefined,
                          description: '',
                        }),
                      } as IIntakeFields)
                  );
                  setChangedResourceSupportsLength((s) => s + 1);
                  props.setIsDirty(true);
                }}
                tag={'button'}
              >
                <FaPlus /> Add Resource Support
              </ListGroupItem>
            )}
          </ListGroup>
        </CardBody>
      </Card>
    </>
  );
};
