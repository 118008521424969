import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, CustomInput, Navbar, NavbarBrand } from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import { Link, useHistory } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { Column, TableInstance, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { DomainContext, IDepartment } from '../../contexts/DomainContext';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import { TablePagination } from '../TablePagination/TablePagination';

export interface IUser {
  userId: string;
  firstName: string;
  lastName: string;
  role: string;
  departmentAccessIds: string[];
  email: string;
  active: boolean;
  homeDepartmentId: string;
}

interface IProps {
  selectRow?: (userId: string) => void;
}

export const UsersTable: React.FC<IProps> = (props) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [activeOnly, setActiveOnly] = useState<boolean>(true);
  const domainContext = useContext(DomainContext);
  const departments = domainContext.departments;
  const history = useHistory();
  useEffect(() => {
    authAxios.get('/api/users/admin/staff').then((response) => {
      setUsers(response.data);
    });
  }, [departments]);

  const columns: Column<IUser>[] = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: (user: IUser) =>
          user.lastName.toUpperCase() + ', ' + user.firstName + (user.active ? '' : ' (Inactive)'),
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'File Access',
        id: 'departmentAccessIds',
        accessor: (user: IUser) => {
          return departments
            .filter((o: IDepartment) => user.departmentAccessIds.includes(o.departmentId))
            .map((d) => d.name)
            .join(', ');
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='departmentId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={departments.map((s: IDepartment) => ({
                value: s.departmentId,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: any) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue: string = row.original['departmentAccessIds'];
              return filterValue.find((val: any) => rowValue.includes(val));
            }
          });
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Home Department',
        id: 'homeDepartmentIds',
        accessor: (user: IUser) => {
          return departments.filter((o: IDepartment) => user.homeDepartmentId === o.departmentId).map((d) => d.name);
        },
      },
    ],
    [departments]
  );
  const data: IUser[] = useMemo(() => users.filter((e) => e.active || !activeOnly), [users, activeOnly]);

  const selectRow = (userId: string) => {
    if (props.selectRow) {
      props.selectRow(userId);
    } else {
      history.push('/users/' + userId);
    }
  };

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IUser> = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>Users</NavbarBrand>
        <CustomInput
          type='checkbox'
          id={'active'}
          name={'active'}
          label={'Show Active Only'}
          checked={activeOnly}
          onChange={() => setActiveOnly((s) => !s)}
        />
        <Button color={'primary'} className={'ml-2'} tag={Link} to={{ pathname: '/users/add' }}>
          <FaPlus className='mr-2' />
          Add User
        </Button>
      </Navbar>
      <div className={'table-responsive'}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`users-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`users-table-thead-tr-${index}-${column.id}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);

              return (
                <tr key={`users-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`users-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() => selectRow(row.original.userId)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
      />
    </div>
  );
};
