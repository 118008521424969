import { anonAxios, defaultAxios } from './AxiosService';
import * as ToastService from './ToastService';

const localStorageKey = 'Authorization';

export const checkToken = () => {
  const jwt = getJwtFromStorage();
  if (jwt) {
    const config = {
      headers: { Authorization: 'Bearer ' + jwt },
    };
    anonAxios.post('/api/checkToken', {}, config).then(
      (response) => {
        console.log('Check Token', response);
      },
      (error) => {
        const status = error.response.status;
        if (status === 502 || status === 503) {
          window.setTimeout(() => checkToken(), 1000);
        } else {
          console.log('JWT Error handler', error);
        }
      }
    );
  } else {
    console.log('No JWT');
  }
};

export const login = (username: string, password: string): Promise<void> => {
  return anonAxios.post('/api/users/login', { email: username, plainPassword: password }).then((response) => {
    setJwtInStorage(response.data.jwtToken);
  });
};

export const loggedIn = () => {
  return getUserId() !== null;
};

export const getUserId = () => {
  const jwt = getDecodedJwt();
  if (jwt) {
    return jwt.userId;
  } else {
    return null;
  }
};

export const getUserName = () => {
  const jwt = getDecodedJwt();
  if (jwt) {
    return jwt.lastName.toUpperCase() + ', ' + jwt.firstName;
  } else {
    return 'Anonymous';
  }
};

export const getUserEmail = () => {
  const jwt = getDecodedJwt();
  if (jwt) {
    return jwt.email;
  } else {
    return '';
  }
};

export const getUserSuperAdmin = () => {
  const jwt = getDecodedJwt();
  if (jwt) {
    return jwt.role === 'SuperAdmin';
  } else {
    return false;
  }
};

export const getJwtFromStorage = () => {
  return localStorage.getItem(localStorageKey);
};

export const setJwtInStorage = (jwtToken: string) => {
  localStorage.setItem(localStorageKey, jwtToken);
};

export const removeJwtFromStorage = () => {
  localStorage.removeItem(localStorageKey);
};

export const renew = (): Promise<void> => {
  console.log('Renewing token');
  return defaultAxios.get('/api/users/renew-token').then((response) => {
    setJwtInStorage(response.data.jwtToken);
    ToastService.notifySuccess('Session Extended');
  });
};

export const getDecodedJwt = () => {
  const token = getJwtFromStorage();
  if (token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};
