import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { authAxios } from '../../services/AxiosService';
import { useHistory } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Column, TableInstance, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import {
  DomainContext,
  FILE_TYPE_JORDANS_PRINCIPLE,
  IFileTypeDisplay,
  IJordansPrincipleRequestType,
  IStage,
} from '../../contexts/DomainContext';
import { formatDateFromISONumber } from '../../formatters/DateTimeFormatter';
import { IFileForTable } from './FileContext';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { IUser } from '../Users/UsersTable';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import { TablePagination } from '../TablePagination/TablePagination';
import { CLIENTS_STATE_LOADED, ClientsContext } from '../../contexts/ClientsContext';
import { IClient } from '../Clients/ClientContext';
import { UsersContext } from '../../contexts/UsersContext';
import { useWindowDimensions } from '../../hooks/WindowHooks';
import { DateRangeDropdown } from '../DateRangeDropdown/DateRangeDropdown';

interface IProps {
  selectRow?: (userId: string) => void;
  clientId: string;
}

export const AssociatedFilesTable: React.FC<IProps> = (props) => {
  const [files, setFiles] = useState<IFileForTable[]>([]);
  const domainContext = useContext(DomainContext);
  const clientsContext = useContext(ClientsContext);
  const usersContext = useContext(UsersContext);
  const clients = clientsContext.state.clients;
  const stages = domainContext.stages;
  const staff = usersContext.users;
  const history = useHistory();
  const { xs } = useWindowDimensions();
  const jordansPrincipleRequestTypes = domainContext.jordansPrincipleRequestTypes;

  useEffect(() => {
    authAxios.get('/api/files/' + props.clientId + '/associated-files').then((response) => {
      setFiles(response.data.map((e: any) => ({ ...e.file, relationship: e.relationship })));
    });
  }, []);

  const columns: Column<IFileForTable>[] = useMemo(
    () => [
      {
        Header: 'File #',
        accessor: 'fileNumber',
        width: 100,
        xs: true,
      },
      {
        Header: 'Client',
        id: 'clientIds',
        xs: true,
        accessor: (file: IFileForTable) => {
          if (
            file.jordansPrincipleFields &&
            file.jordansPrincipleFields.fileName &&
            file.jordansPrincipleFields.fileName !== ''
          ) {
            return file.jordansPrincipleFields.fileName;
          } else {
            const clientsMapped = file.clientIds.map((pId) => {
              const client = clients.find((i: IClient) => i.clientId === pId);
              if (!client && clientsContext.state.type === CLIENTS_STATE_LOADED) {
                clientsContext.dispatch({ type: 'CLIENTS_ACTION_DO_RELOADED' });
              }
              return client;
            });
            return clientsMapped.length > 0
              ? clientsMapped.map((p) => p?.lastName.toUpperCase() + ', ' + p?.firstName).join(' • ')
              : '';
          }
        },
      },
      {
        Header: 'Relationship',
        accessor: 'relationship',
        width: 150,
        xs: true,
      },
      {
        Header: 'Stage',
        id: 'stageId',
        width: 150,
        xs: true,
        accessor: (file: IFileForTable) => {
          return stages.find((s: IStage) => s.stageId === file.stageId)?.name;
        },
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const rowAStage = stages.find((s: IStage) => s.stageId === rowA.original[id]);
          const rowBStage = stages.find((s: IStage) => s.stageId === rowB.original[id]);
          if (rowAStage?.order && rowBStage?.order) {
            if (rowAStage.order > rowBStage.order) return 1;
            if (rowBStage.order > rowAStage.order) return -1;
            if (rowAStage.order === rowBStage?.order) {
              if (rowAStage.name > rowBStage?.name) {
                return 1;
              } else if (rowAStage.name < rowBStage.name) {
                return -1;
              } else {
                return 0;
              }
            }
          }
          return 0;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = stages.map((s) => s.stageId), preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='stageId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={stages.map((s: IStage) => ({
                value: s.stageId,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['stageId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'File Type',
        accessor: 'fileTypeDisplay',
        width: 150,
        xs: false,
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='departmentId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={domainContext.fileTypeDisplay.map((s: IFileTypeDisplay) => ({
                value: s.name,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['fileTypeDisplay'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
        Cell: (props) => (
          <>
            <p className='item title'>
              {props.row.original['fileTypeId'] === FILE_TYPE_JORDANS_PRINCIPLE
                ? props.row.original['fileTypeDisplay'] +
                  ' (' +
                  jordansPrincipleRequestTypes.find(
                    (t: IJordansPrincipleRequestType) =>
                      t.jordansPrincipleRequestTypeId === props.row.original.jordansPrincipleFields?.['requestTypeId']
                  )?.abbreviation +
                  ')'
                : props.row.original['fileTypeDisplay']}
            </p>
            {/*<p className="item desc">{props.row.original.desc}</p>*/}
          </>
        ),
      },
      {
        Header: 'Request',
        id: 'jpRequest',
        accessor: (file: IFileForTable) => {
          return file.jordansPrincipleFields?.requestName;
        },
        width: 100,
        xs: false,
      },
      {
        Header: 'Staff',
        id: 'staffIds',
        width: 250,
        xs: false,
        accessor: (file: IFileForTable) => {
          return staff
            .sort((a, b) =>
              (a.lastName + a.firstName).toUpperCase().localeCompare((b.lastName + b.firstName).toUpperCase())
            )
            .filter((s: IUser) => file.staffIds.includes(s.userId))
            .map((s) => s.lastName.toUpperCase() + ', ' + s.firstName)
            .join(' • ');
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = staff.map((s) => s.userId), preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='userId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={staff.map((s: IUser) => ({
                value: s.userId,
                display: s.lastName + ', ' + s.firstName,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['staffIds'];
              return filterValue.find((val: any) => rowValue.includes(val));
            }
          });
        },
      },
      {
        Header: 'Associated Clients',
        accessor: 'fileOtherPeople',
        width: undefined,
        xs: false,
      },
      {
        Header: 'Created Date',
        id: 'createdDate',
        width: 175,
        xs: false,
        accessor: (c: IFileForTable) => {
          return c.createdDate;
        },
        Cell: (c: any) => (c.row.original.createdDate ? formatDateFromISONumber(c.row.original.createdDate) : ''),
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['createdDate'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'createdDate'}
          />
        ),
      },
    ],
    [clients, stages]
  );

  const data: IFileForTable[] = useMemo(() => files, [files]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IFileForTable> = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        hiddenColumns: ['reminders'],
        sortBy: [
          {
            id: 'reminders',
            desc: false,
          },
          {
            id: 'createdDate',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const selectRow = (fileId: string) => {
    if (props.selectRow) {
      props.selectRow(fileId);
    } else {
      history.push('/files/' + fileId);
    }
  };

  return (
    <div>
      <div className={'table-responsive'} style={{ minHeight: '500px' }}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead style={{ overflow: 'visible' }}>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  return (
                    (!xs || (xs && column.xs)) && (
                      <th
                        key={`files-table-thead-tr-${index}-${column.id}`}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          width: column.width,
                          ...column.getHeaderProps(column.getSortByToggleProps()).style,
                        }}
                      >
                        {column.render('Header')}
                        <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                        <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </th>
                    )
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  key={`files-table-tr-${row.id}`}
                  {...row.getRowProps()}
                  style={{ cursor: row.original.canEdit ? 'pointer' : 'default', ...row.getRowProps().style }}
                  className={''}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      (!xs || (xs && cell.column.xs)) && (
                        <td
                          key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                          {...cell.getCellProps()}
                          onClick={() => row.original.canEdit && selectRow(row.original.fileId)}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
      />
    </div>
  );
};
