import * as React from 'react';
import { FormEvent, useContext, useState } from 'react';
import { Col, Navbar, NavbarBrand, Row, Button, CustomInput } from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import { Column, TableInstance, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { useMemo } from 'react';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { FileContext } from './FileContext';
import { UsersContext } from '../../contexts/UsersContext';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { FileReminderDetails } from './FileReminderDetails';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { DomainContext } from '../../contexts/DomainContext';
import { sanitizeHTMLConfig } from '../../contexts/DomainContext';
const DOMPurify = require('dompurify');

export interface IReminder {
  reminderId: string;
  reminderTypeId?: string;
  text: string;
  date?: number;
  staffToNotify: string[];
  notificationTypeIds: string[];
  resolved: boolean;
  courtDateId?: string;
  fileId?: string;
}

export const FileReminders = () => {
  const params = useParams<{ fileId: string }>();
  const usersContext = useContext(UsersContext);
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const users = usersContext.users;
  const [showNewReminder, setShowNewReminder] = useState(false);
  const history = useHistory();
  const [showResolved, setShowResolved] = useState(false);
  const [remindersToShow, setRemindersToShow] = useState<IReminder[]>(fileContext.reminders.filter((s) => !s.resolved));

  useEffect(() => {
    if (showResolved) {
      setRemindersToShow(fileContext.reminders);
    } else {
      setRemindersToShow(fileContext.reminders.filter((s) => !s.resolved));
    }
  }, [showResolved, fileContext.reminders]);

  const columns: Column<IReminder>[] = useMemo(
    () => [
      {
        Header: 'Reminder Date',
        id: 'date',
        accessor: (r: IReminder) => (r.date ? formatDateTime(new Date(r.date)) : ''),
        width: 200,
      },
      {
        Header: 'Reminder',
        id: 'text',
        accessor: 'text',
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.text) {
            return (
              <main
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cell.row.original.text, sanitizeHTMLConfig) }}
              />
            );
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'Users to Notify',
        id: 'users',
        accessor: (r: IReminder) => {
          const userList = users.filter((u) => r.staffToNotify.includes(u.userId));
          return userList.map((u) => u.lastName.toUpperCase() + ' ' + u.firstName).join(', ');
        },
      },
      {
        Header: 'Notification Type',
        id: 'notificationTypeIds',
        accessor: (r: IReminder) => {
          return domainContext.notificationTypes
            .filter((n) => r.notificationTypeIds.includes(n.notificationTypeId))
            .map((nt) => nt.name)
            .join(', ');
        },
      },
    ],
    [fileContext.reminders]
  );

  const data: IReminder[] = useMemo(() => remindersToShow, [remindersToShow]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    // visibleColumns,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // preGlobalFilteredRows,
    // setGlobalFilter,
    state: { pageIndex, pageSize },
  }: TableInstance<IReminder> = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: 'date',
            desc: false,
          },
        ],
        // hiddenColumns: ['userCreated', 'text'],
      },
    },
    // useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        {!showNewReminder && (
          <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
            <Row className={'flex-fill'}>
              <Col md={2} xl={2}>
                <NavbarBrand>Reminders</NavbarBrand>
              </Col>
              <CustomInput
                className={'mt-2'}
                type='checkbox'
                id={'showResolved'}
                name={'showResolved'}
                label={'Show Resolved Reminders'}
                checked={showResolved}
                onChange={() => {
                  setShowResolved(!showResolved);
                }}
              />
              <Col className={'d-flex'}>
                <Button className={'ml-auto'} color={'primary'} onClick={() => setShowNewReminder(true)}>
                  <FaPlus /> New Reminder
                </Button>
              </Col>
            </Row>
          </Navbar>
        )}
        {showNewReminder && (
          <FileReminderDetails onSave={() => setShowNewReminder(false)} onCancel={() => setShowNewReminder(false)} />
        )}
        <table className={'table table-bordered'} {...getTableProps()}>
          <thead style={{ overflow: 'visible' }}>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`files-table-thead-tr-${index}-${column.id}`}
                    width={column.width}
                    style={{ maxWidth: column.width }}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  key={`files-table-tr-${row.id}`}
                  {...row.getRowProps()}
                  className={row.original.date <= new Date().getTime() && !row.original.resolved ? 'alert-row' : ''}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() =>
                          history.push('/files/' + params.fileId + '/reminders/' + cell.row.original.reminderId)
                        }
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <TablePagination
          pageCount={pageCount}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizes={[20, 50, 100, 500]}
        />
      </form>
    </div>
  );
};
