import * as React from 'react';
import { useEffect } from 'react';
import { authAxios } from '../services/AxiosService';
import { IClient } from '../components/Clients/ClientContext';

interface IClientsState {
  type: ClientsState;
  clients: IClient[];
}

interface ClientsStateWithDispatch {
  state: IClientsState;
  dispatch: React.Dispatch<ClientsAction>;
}

export const CLIENTS_STATE_LOADING = 'CLIENTS_STATE_LOADING';
export const CLIENTS_STATE_LOADED = 'CLIENTS_STATE_LOADED';
export const CLIENTS_STATE_RELOADING = 'CLIENTS_STATE_RELOADING';
export type ClientsState = 'CLIENTS_STATE_LOADING' | 'CLIENTS_STATE_LOADED' | 'CLIENTS_STATE_RELOADING';

export const CLIENTS_ACTION_LOADING = { type: 'CLIENTS_ACTION_LOADING' };
export const CLIENTS_ACTION_DO_RELOADED = { type: 'CLIENTS_ACTION_DO_RELOADED' };
export const CLIENTS_ACTION_SET_DATA = { type: 'CLIENTS_ACTION_SET_DATA', data: [] as IClient[] };
export const CLIENTS_ACTION_CREATE_CLIENT = {
  type: 'CLIENTS_ACTION_CREATE_CLIENT',
  data: {} as IClient,
};
export const CLIENTS_ACTION_UPDATE_CLIENT = {
  type: 'CLIENTS_ACTION_UPDATE_CLIENT',
  data: {} as IClient,
};

export type ClientsAction = {
  type:
    | 'CLIENTS_ACTION_LOADING'
    | 'CLIENTS_ACTION_DO_RELOADED'
    | 'CLIENTS_ACTION_SET_DATA'
    | 'CLIENTS_ACTION_UPDATE_CLIENT'
    | 'CLIENTS_ACTION_CREATE_CLIENT';
  data?: any;
};

export const ClientsContext = React.createContext<ClientsStateWithDispatch>({
  state: { type: 'CLIENTS_STATE_LOADING', clients: [] as IClient[] },
  dispatch: () => ({}),
});

const makeClientForContext = (e: IClient) => {
  return {
    ...e,
    searchString: `${e.lastName}, ${e.firstName}`.toUpperCase(),
  };
};

const clientsReducer: React.Reducer<IClientsState, ClientsAction> = (state: IClientsState, action: ClientsAction) => {
  switch (action.type) {
    case CLIENTS_ACTION_LOADING.type:
      return { ...state, type: CLIENTS_STATE_LOADING };
    case CLIENTS_ACTION_DO_RELOADED.type:
      return { ...state, type: CLIENTS_STATE_RELOADING };
    case CLIENTS_ACTION_SET_DATA.type:
      return { ...state, clients: action.data, type: CLIENTS_STATE_LOADED };
    case CLIENTS_ACTION_UPDATE_CLIENT.type:
      return {
        ...state,
        clients: [
          ...state.clients.filter((e) => e.clientId !== action.data.clientId),
          makeClientForContext(action.data),
        ],
      };
    case CLIENTS_ACTION_CREATE_CLIENT.type:
      return { ...state, clients: [...state.clients, makeClientForContext(action.data)] };
    default:
      return state;
  }
};

export const ClientsProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(clientsReducer, { type: CLIENTS_STATE_LOADING, clients: [] });

  useEffect(() => {
    if (state.type === CLIENTS_STATE_LOADING || state.type === CLIENTS_STATE_RELOADING) {
      authAxios.get('/api/clients').then((response) => {
        const clients: IClient[] = response.data.map((e: any) => makeClientForContext(e));
        dispatch({ type: 'CLIENTS_ACTION_SET_DATA', data: clients });
      });
    }
  }, [state.type]);

  const value: ClientsStateWithDispatch = { state, dispatch };
  return <ClientsContext.Provider value={value}>{props.children}</ClientsContext.Provider>;
};
