import * as React from 'react';
import { Column } from 'react-table';
import { IFileForTable } from '../Files/FileContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  DomainContext,
  FILE_SUB_TYPE_INTAKE_REQUEST_FILE,
  FILE_TYPE_BIRTH_WORKER,
  IStage,
  STAGE_INTAKE_CONVERTED_TO_DRAFT_DEPRECATED,
} from '../../contexts/DomainContext';
import { IClient } from '../Clients/ClientContext';
import { CLIENTS_STATE_LOADED, ClientsContext } from '../../contexts/ClientsContext';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import { IUser } from '../Users/UsersTable';
import { UsersContext } from '../../contexts/UsersContext';
import { formatDateFromISONumber } from '../../formatters/DateTimeFormatter';
import { DateRangeDropdown } from '../DateRangeDropdown/DateRangeDropdown';
import { FaLock } from 'react-icons/fa';

export const useBirthWorkerColumns = (birthWorkerStaff: string[]): Column<IFileForTable>[] => {
  const domainContext = useContext(DomainContext);
  const usersContext = useContext(UsersContext);
  const stages = domainContext.stages;
  const fileTypes = domainContext.fileTypes;
  const clientsContext = useContext(ClientsContext);
  const clients = clientsContext.state.clients;
  const staff = usersContext.users;
  const [filteredStaff, setFilteredStaff] = useState<IUser[]>(staff.filter((s) => birthWorkerStaff.includes(s.userId)));

  useEffect(() => {
    setFilteredStaff(staff.filter((s) => birthWorkerStaff.includes(s.userId)));
  }, [staff, birthWorkerStaff]);

  return useMemo(
    () => [
      {
        Header: 'File #',
        accessor: 'fileNumber',
        Cell: (cell: any) => {
          return cell.row.original.isConfidential ? (
            <div className={'d-flex align-items-center'}>
              {cell.row.original.fileNumber} <FaLock className={'ml-2'} color={'#912f1d'} />
            </div>
          ) : (
            cell.row.original.fileNumber
          );
        },
        width: 150,
        xs: true,
      },
      {
        Header: 'Client',
        id: 'clientIds',
        width: 400,
        xs: true,
        accessor: (file: IFileForTable) => {
          if (file.fileDisplayName) {
            return file.fileDisplayName;
          } else {
            const clientsMapped = file.clientIds.map((pId) => {
              const client = clients.find((i: IClient) => i.clientId === pId);
              if (!client && clientsContext.state.type === CLIENTS_STATE_LOADED) {
                clientsContext.dispatch({ type: 'CLIENTS_ACTION_DO_RELOADED' });
              }
              return client;
            });
            return clientsMapped.length > 0
              ? clientsMapped.map((p) => p?.lastName.toUpperCase() + ', ' + p?.firstName).join(' • ')
              : '';
          }
        },
        filter: (rows: any, id: any, filterValue: any) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const clientsMapped: (IClient | undefined)[] = row.original['clientIds'].map((cId: string) => {
                const client = clients.find((i: IClient) => i.clientId === cId);
                if (!client && clientsContext.state.type === CLIENTS_STATE_LOADED) {
                  clientsContext.dispatch({ type: 'CLIENTS_ACTION_DO_RELOADED' });
                }
                return client;
              });
              const clientsNamesFormatted =
                clientsMapped.length > 0
                  ? clientsMapped.map((p) => p?.lastName.toUpperCase() + ', ' + p?.firstName.toUpperCase()).join(' • ')
                  : '';
              const clientsNamesUnformatted =
                clientsMapped.length > 0
                  ? clientsMapped.map((p) => p?.firstName.toUpperCase() + ' ' + p?.lastName.toUpperCase()).join(' • ')
                  : '';
              return (
                clientsNamesFormatted.includes(filterValue?.toString()?.toUpperCase() ?? '') ||
                clientsNamesUnformatted.includes(filterValue?.toString()?.toUpperCase() ?? '')
              );
            }
          });
        },
      },
      {
        Header: 'Stage',
        id: 'stageId',
        width: 400,
        xs: true,
        accessor: (file: IFileForTable) => {
          return stages.find((s: IStage) => s.stageId === file.stageId)?.name;
        },
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const rowAStage = stages.find((s: IStage) => s.stageId === rowA.original[id]);
          const rowBStage = stages.find((s: IStage) => s.stageId === rowB.original[id]);
          if (rowAStage?.order && rowBStage?.order) {
            if (rowAStage.order > rowBStage.order) return 1;
            if (rowBStage.order > rowAStage.order) return -1;
            if (rowAStage.order === rowBStage?.order) {
              if (rowAStage.name > rowBStage?.name) {
                return 1;
              } else if (rowAStage.name < rowBStage.name) {
                return -1;
              } else {
                return 0;
              }
            }
          }
          return 0;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = stages.map((s) => s.stageId), preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='stageId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={stages
                .filter(
                  (s) =>
                    s.fileTypes.includes(FILE_TYPE_BIRTH_WORKER) ||
                    s.fileSubTypes.includes(FILE_SUB_TYPE_INTAKE_REQUEST_FILE) ||
                    s.stageId === STAGE_INTAKE_CONVERTED_TO_DRAFT_DEPRECATED
                )
                .map((s: IStage) => ({
                  value: s.stageId,
                  display: s.name,
                }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['stageId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'Staff',
        id: 'staffIds',
        width: undefined,
        xs: true,
        accessor: (file: IFileForTable) => {
          return filteredStaff
            .filter((s: IUser) => file.staffIds.includes(s.userId))
            .map((s) => s.lastName.toUpperCase() + ', ' + s.firstName + (s.active ? '' : ' (Inactive)'))
            .join(' • ');
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = filteredStaff.map((s) => s.userId), preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='userId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={filteredStaff.map((s: IUser) => ({
                value: s.userId,
                display: s.lastName + ', ' + s.firstName,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['staffIds'];
              return filterValue.find((val: any) => rowValue.includes(val));
            }
          });
        },
      },
      {
        Header: 'Created Date',
        accessor: 'createdDate',
        id: 'createdDate',
        width: 200,
        xs: true,
        Cell: (c: any) => (c.row.original.createdDate ? formatDateFromISONumber(c.row.original.createdDate) : ''),
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['createdDate'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'createdDate'}
          />
        ),
      },
    ],
    [stages, fileTypes, filteredStaff]
  );
};
