import * as React from 'react';
import { useEffect, useState } from 'react';
import { IIndigenousInformation, IPhoneNumber } from './ClientDetails';
import { AxiosUpdateTuple } from '../../hooks/AxiosPutHook';
import { useUpdateSupportTeam } from '../../hooks/Clients/mutation/useUpdateSupportTeam';
import { useHistory, useParams } from 'react-router';
import { useGetSupportTeam } from '../../hooks/Clients/query/useGetSupportTeam';
import { useClient } from '../../hooks/Clients/query/useClient';
import { useUpdateClientAddresses } from '../../hooks/Clients/mutation/useUpdateClientAddresses';
import * as ToastService from '../../services/ToastService';
import { useClientAddresses } from '../../hooks/Clients/query/useClientAddresses';
import { authAxios } from '../../services/AxiosService';
import { INote } from '../Files/FileNotes';

export interface ISupportPerson {
  name: string;
  relationshipType: string;
  contactDetails: IContactDetails[];
}

export interface IContactDetails {
  contactType?: string;
  details?: string;
}

export interface IClient {
  clientId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  birthDate?: number;
  email: string;
  phoneNumbers: IPhoneNumber[];
  active: boolean;
  genderId?: string;
  lastUpdated: number;
  indigenousInfo: IIndigenousInformation[];
  statusCardNum?: string;
  communityId?: string;
  school?: string;
  addresses?: IAddress[];
  supportTeam: ISupportPerson[];
  flagged: boolean;
  flaggedDescription: string;
  hasFiles: boolean;
  hasWorkshops: boolean;
  inGroupCare?: boolean;
  groupHomeId?: string;
  searchString: string;
}

export interface IAddress {
  addressId: string;
  clientId: string;
  label: string;
  line1: string;
  line2: string;
  city: string;
  territory: string;
  postalCode: string;
  isCurrent: boolean;
}

export interface IClientMeta {
  noteCount: number;
}

type ContextType = {
  client: IClient;
  setClient: React.Dispatch<React.SetStateAction<IClient>>;
  addresses: IAddress[];
  setAddresses: React.Dispatch<React.SetStateAction<IAddress[]>>;
  supportTeam: ISupportPerson[];
  setSupportTeam: React.Dispatch<React.SetStateAction<ISupportPerson[]>>;
  setOverrideLoaded: () => void;
  documentUploaded: boolean;
  setDocumentUploaded: React.Dispatch<React.SetStateAction<boolean>>;
  clientMeta: IClientMeta;
  setClientMeta: React.Dispatch<React.SetStateAction<IClientMeta>>;
  notes: INote[];
  setNotes: React.Dispatch<React.SetStateAction<INote[]>>;
};

export const ClientContext = React.createContext<ContextType>({
  client: {} as IClient,
  setClient: () => {},
  addresses: [] as IAddress[],
  setAddresses: () => {},
  supportTeam: [] as ISupportPerson[],
  setSupportTeam: () => {},
  setOverrideLoaded: () => {},
  documentUploaded: false,
  setDocumentUploaded: () => {},
  clientMeta: {} as IClientMeta,
  setClientMeta: () => {},
  notes: [] as INote[],
  setNotes: () => {},
});

export const ClientProvider = (props: { children: any; clientId?: string }) => {
  const [client, setClient] = useState({} as IClient);
  const [addresses, setAddresses] = useState([] as IAddress[]);
  const [supportTeam, setSupportTeam] = useState<ISupportPerson[]>([]);
  const [supportTeamLoaded, setSupportTeamLoaded] = useState(false);
  const [addressesLoaded, setAddressesLoaded] = useState(false);
  const [documentUploaded, setDocumentUploaded] = useState<boolean>(false);
  const [clientMeta, setClientMeta] = useState({} as IClientMeta);
  const [notes, setNotes] = useState([] as INote[]);
  const params = useParams<{ clientId: string }>();
  const history = useHistory();
  const clientId = params.clientId || props.clientId;

  useEffect(() => {
    if (clientId && clientId !== 'add') {
      authAxios.get('/api/clients/' + clientId + '/notes').then((response) => {
        if (response.data.status === 'OK') {
          setNotes(response.data.notes);
        }
      });
    }
  }, [clientId]);

  const setOverrideLoaded = () => {
    setSupportTeamLoaded(true);
    setAddressesLoaded(true);
  };

  const { data, loading, error } = useClient(clientId);

  useEffect(() => {
    const onCompleted = (response: any) => {
      if (response.status && response.status && response.status.includes('KO')) {
        history.push('/404');
      } else if (response) {
        setClient(response);
      }
    };

    const onError = (e: any) => {
      console.log(`Error loading data ${e}`);
      history.push('/404');
    };

    if (data && !loading && !error) {
      onCompleted(data);
    } else if (!loading && error) {
      onError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  //////////////////////////////////////////////////////////////////////

  const { data: supportTeamData, loading: supportTeamLoading, error: supportTeamError } = useGetSupportTeam(clientId);

  useEffect(() => {
    const onCompleted = (data?: ISupportPerson[]) => {
      if (data) {
        setSupportTeam(data);
        setTimeout(() => {
          setSupportTeamLoaded(true);
        }, 100);
      }
    };

    const onError = (e: any) => {
      console.log(`Error loading data ${e}`);
    };

    if (supportTeamData && !supportTeamLoading && !supportTeamError) {
      onCompleted(supportTeamData);
    } else if (!supportTeamLoading && supportTeamError) {
      onError(supportTeamError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportTeamLoading, supportTeamData, supportTeamError]);

  //////////////////////////////////////////////////////////////////////

  const { data: addressData, loading: addressLoading, error: addressError } = useClientAddresses(clientId);

  useEffect(() => {
    const onCompleted = (data?: IAddress[]) => {
      if (data) {
        setAddresses(data);
        setTimeout(() => {
          setAddressesLoaded(true);
        }, 100);
      }
    };

    const onError = (e: any) => {
      console.log(`Error loading data ${e}`);
    };

    if (addressData && !addressLoading && !addressError) {
      onCompleted(addressData);
    } else if (!addressLoading && addressError) {
      onError(addressError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressLoading, addressData, addressError]);

  //////////////////////////////////////////////////////////////////////

  const [
    updateSupportTeam,
    {
      data: updateSupportTeamData,
      loading: updateSupportTeamLoading,
      called: updateSupportTeamCalled,
      error: updateSupportTeamError,
    },
  ]: AxiosUpdateTuple = useUpdateSupportTeam(client.clientId);

  useEffect(() => {
    if (updateSupportTeamCalled && !updateSupportTeamLoading && !updateSupportTeamError) {
      ToastService.notifySuccess('Support Team Saved.');
    } else if (updateSupportTeamCalled && !updateSupportTeamLoading && !!updateSupportTeamError) {
      ToastService.notifyError('Problem Saving Support Team, Please Try Again');
    }
  }, [updateSupportTeamData, updateSupportTeamLoading, updateSupportTeamCalled, updateSupportTeamError]);

  useEffect(() => {
    if (supportTeamLoaded) {
      const data = {
        clientId: client.clientId,
        supportTeam: supportTeam,
      };

      updateSupportTeam(data);
    }
  }, [supportTeam]);

  //////////////////////////////////////////////////////////////////////

  const [
    updateAddresses,
    {
      data: updateAddressesData,
      loading: updateAddressesLoading,
      called: updateAddressesCalled,
      error: updateAddressesError,
    },
  ]: AxiosUpdateTuple = useUpdateClientAddresses(client.clientId);

  useEffect(() => {
    if (updateAddressesCalled && !updateAddressesLoading && !updateAddressesError) {
      ToastService.notifySuccess('Addresses Saved');
    } else if (updateAddressesCalled && !updateAddressesLoading && !!updateAddressesError) {
      ToastService.notifyError('Problem Saving Addresses, Please Try Again');
    }
  }, [updateAddressesData, updateAddressesLoading, updateAddressesCalled, updateAddressesError]);

  useEffect(() => {
    if (addressesLoaded) {
      const data = {
        clientId: client.clientId,
        addresses: addresses,
      };

      updateAddresses(data).then(() => console.log('ADDRESSES UPDATED'));
    }
  }, [addresses]);

  //////////////////////////////////////////////////////////////////////

  return (
    <ClientContext.Provider
      value={{
        client,
        setClient,
        addresses,
        setAddresses,
        supportTeam,
        setSupportTeam,
        setOverrideLoaded,
        documentUploaded,
        setDocumentUploaded,
        clientMeta,
        setClientMeta,
        notes,
        setNotes,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
};

export const ClientConsumer = ClientContext.Consumer;

export const sortClientsByLastName: (a: IClient, b: IClient) => number = (a, b) => {
  const aName = a.lastName.toLowerCase();
  const bName = b.lastName.toLowerCase();
  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};
