import * as H from 'history';
import * as React from 'react';
import { useContext } from 'react';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';
import { ClientDetails } from './ClientDetails';
import { ClientContext } from './ClientContext';
import { ClientFiles } from './ClientFiles';
import { ClientActivityLog } from './ClientActivityLog';
import { useWindowDimensions } from '../../hooks/WindowHooks';
import { Documents } from '../Files/Documents/Documents';
import { ClientDocumentDetails } from '../Files/Documents/ClientDocumentDetails';
import { ClientNotes } from './ClientNotes';
import { ClientNotesDetails } from './ClientNotesDetails';
import { ClientWorkshops } from './ClientWorkshops';

export const Client = () => {
  const clientContext = useContext(ClientContext);
  const client = clientContext.client;
  const params = useParams<{ clientId: string; tab?: string }>();
  const { xs, sm } = useWindowDimensions();
  const verticalMenu = xs || sm;
  const tab: string = params.tab || 'details';

  const NavItemLink = (to: H.LocationDescriptor, active: boolean, name: any) => {
    return (
      <NavItem>
        <NavLink tag={Link} to={to} active={active}>
          {name}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <article className='page'>
      <div className={'page-header-image d-print-none'} />
      <header className='page-header  d-print-none'>
        <Container fluid={true}>
          {client.clientId !== undefined && (
            <div className='d-flex flex-column'>
              <h3 className='page-header__title order-2'>
                {client.lastName?.toUpperCase() + ', ' + client.firstName + (!client.active ? ' (Inactive)' : '')}
              </h3>
            </div>
          )}
          <div className={verticalMenu ? 'd-block d-md-none pb-4' : 'd-none d-md-block'}>
            <Nav tabs={!verticalMenu} pills={verticalMenu} vertical={verticalMenu}>
              {NavItemLink('/clients/' + params.clientId + '/details', tab === 'details', 'Details')}
              {NavItemLink(
                '/clients/' + params.clientId + '/associated-files',
                tab === 'associated-files',
                'Associated Files'
              )}
              {NavItemLink('/clients/' + params.clientId + '/documents', tab === 'documents', 'Documents')}
              {NavItemLink('/clients/' + params.clientId + '/notes', tab === 'notes', 'Case Notes')}
              {NavItemLink(
                '/clients/' + params.clientId + '/associated-workshops',
                tab === 'associated-workshops',
                'Workshops'
              )}
              {NavItemLink('/clients/' + params.clientId + '/activity-log', tab === 'activity-log', 'Activity Log')}
            </Nav>
          </div>
        </Container>
      </header>

      <div style={{ backgroundColor: 'white' }}>
        <Route
          exact={true}
          path='/clients/:clientId/(details)?'
          render={() => <ClientDetails allowCreateFile={true} editCompleteAction={(c) => clientContext.setClient(c)} />}
        />
        <Route exact={true} path='/clients/:clientId/associated-files' component={ClientFiles} />
        <Route exact={true} path='/clients/:clientId/associated-workshops' component={ClientWorkshops} />
        <Route exact={true} path='/clients/:clientId/activity-log' component={ClientActivityLog} />
        <Switch>
          <Route exact={true} path='/clients/:clientId/documents'>
            <Documents
              newDocumentPath={`/api/documents/add-client-document/${client.clientId}`}
              getPaths={[`/api/clients/${params.clientId}/documents`]}
            />
          </Route>
          <Route exact={true} path='/clients/:clientId/documents/:documentId'>
            <ClientDocumentDetails canDelete={true} />
          </Route>
        </Switch>
        <Route exact={true} path='/clients/:clientId/notes'>
          <ClientNotes />
        </Route>
        <Route exact={true} path='/clients/:clientId/notes/:noteId' component={ClientNotesDetails} />
      </div>
    </article>
  );
};
