import { Button, Card, CardHeader, ListGroup, ListGroupItem, Navbar, NavbarBrand, Row } from 'reactstrap';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { FileContext } from './FileContext';
import Select from 'react-select';
import { formatDateFromISONumber, formatDateTime } from '../../formatters/DateTimeFormatter';
import { ClientProvider, IClient } from '../Clients/ClientContext';
import { ClientDetails } from '../Clients/ClientDetails';
import { ClientsContext } from '../../contexts/ClientsContext';
import { ClientDisplay } from './ClientDisplay';
import { ClientOption, ClientSingleValue } from '../SelectComponents/SelectClientComponents';
import { authAxios } from '../../services/AxiosService';

export const FileOtherPeople: React.FC<{
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  disableEditing?: boolean;
}> = (props) => {
  const fileContext = useContext(FileContext);
  const fileId = fileContext.file.fileId;
  const setFile = fileContext.setFile;
  const clientsContext = useContext(ClientsContext);
  const otherPeople = fileContext.otherPeople;
  const clients = clientsContext.state.clients;

  const [editingClientId, setEditingClientId] = useState<string | undefined>(undefined);
  const [showNewClientOptions, setShowNewClientOptions] = useState<boolean>(false);
  const [addingExistingClientId, setAddingExistingClientId] = useState<string | undefined>(undefined);
  const [showNewClientDetails, setShowNewClientDetails] = useState<boolean>(false);
  const [addingNewClient, setAddingNewClient] = useState(0);
  const [clientInputValue, setClientInputValue] = useState('');

  const clientsData: () => { client?: IClient; clientId: string; relationship: string }[] = () => {
    if (otherPeople) {
      const foundPeople = otherPeople.map((p) => ({ ...p, client: clients.find((i) => i.clientId === p.clientId) }));
      return foundPeople;
    } else {
      return [] as { client: IClient; clientId: string; relationship: string }[];
    }
  };

  useEffect(() => {
    props.setIsEditing &&
      props.setIsEditing(showNewClientOptions || showNewClientDetails || !!editingClientId || !!addingExistingClientId);
  }, [showNewClientOptions, showNewClientDetails, editingClientId, addingExistingClientId]);

  useEffect(() => {
    if (showNewClientOptions) {
      var elmt2 = document.getElementById('select-field');
      if (elmt2) {
        elmt2.focus();
        elmt2.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [showNewClientOptions]);

  const createCompleteAction = (client: IClient, relationship?: string) => {
    const updatedOtherPeople = [
      ...fileContext.otherPeople,
      { clientId: client.clientId, relationship: relationship || '' },
    ];

    fileContext.setOtherPeople(updatedOtherPeople);
    setShowNewClientOptions(false);
    setShowNewClientDetails(false);
    setAddingExistingClientId(undefined);
    authAxios.put('/api/files/set-file-other-people', {
      fileId: fileId,
      otherPeople: updatedOtherPeople,
    });
    props.setIsDirty(false);
  };

  const editCompleteAction = (client: IClient, relationship?: string) => {
    const updatedClientIds = [
      ...fileContext.otherPeople.filter((e) => e.clientId !== client.clientId),
      { clientId: client.clientId, relationship: relationship || '' },
    ];

    fileContext.setOtherPeople(updatedClientIds);
    setFile((f) => ({ ...f, otherPeople: updatedClientIds }));
    setEditingClientId(undefined);
    setShowNewClientOptions(false);
    setShowNewClientDetails(false);
    setAddingExistingClientId(undefined);
    authAxios.put('/api/files/set-file-other-people', {
      fileId: fileId,
      otherPeople: updatedClientIds,
    });
    props.setIsDirty(false);
  };

  useEffect(() => {
    if (addingNewClient > 0) {
      var elmnt = document.getElementById('new-client-add');
      if (elmnt) {
        elmnt.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [addingNewClient]);

  return (
    <Card className={'mb-3'}>
      <CardHeader>Associated Clients</CardHeader>
      <ListGroup flush={true}>
        {clientsData().map((p) => {
          if (p !== undefined) {
            if (editingClientId === p.clientId) {
              return (
                <ListGroupItem className={'p-0'} key={`other_${p.clientId}`}>
                  <ClientProvider clientId={p.clientId}>
                    <ClientDetails
                      allowCreateFile={false}
                      editCompleteAction={editCompleteAction}
                      deleteAction={() => {
                        fileContext.setOtherPeople((pIds) => pIds.filter((e) => e.clientId !== p.clientId));
                        authAxios.put('/api/files/set-file-other-people', {
                          fileId: fileId,
                          otherPeople: fileContext.otherPeople.filter((e) => e.clientId !== p.clientId),
                        });
                        setEditingClientId(undefined);
                      }}
                      clientId={editingClientId}
                      multiplePeopleFormat={true}
                      cancelAction={() => {
                        setEditingClientId(undefined);
                      }}
                      disableActions={false}
                      showRelationship={true}
                      relationship={p.relationship}
                    ></ClientDetails>
                  </ClientProvider>
                </ListGroupItem>
              );
            } else {
              return (
                <React.Fragment key={'fragment_other_' + p.clientId}>
                  <Navbar
                    color={'light'}
                    light={true}
                    expand={'xs'}
                    className={'border-bottom d-flex flex-wrap'}
                    key={'nav_other_' + p.clientId}
                  >
                    <NavbarBrand className={'w-mobile-100'}>
                      {p.client?.lastName?.toUpperCase() + ', ' + p.client?.firstName + ' ' + p.client?.middleName}
                      {p.relationship && ` (${p.relationship})`}
                      <small className={'text-muted'}>
                        &nbsp;&nbsp;Last Updated:{' '}
                        {p.client?.lastUpdated !== undefined ? formatDateTime(new Date(p.client?.lastUpdated)) : ''}
                      </small>
                    </NavbarBrand>
                    <div
                      className={'d-flex flex-grow-1 justify-content-end flex-wrap navbar-actions'}
                      style={{ rowGap: '0.5rem', columnGap: '0.5rem' }}
                    >
                      <Button
                        color={'primary'}
                        className={'w-mobile-100'}
                        disabled={showNewClientOptions || editingClientId !== undefined || props.disableEditing}
                        onClick={() => {
                          setEditingClientId(p.clientId);
                          setAddingNewClient((c) => c + 1);
                        }}
                      >
                        Edit Details
                      </Button>
                    </div>
                  </Navbar>
                  <ListGroupItem
                    key={`other_${p.clientId}`}
                    disabled={showNewClientOptions || editingClientId !== undefined || props.disableEditing}
                  >
                    {p.client && <ClientDisplay client={p.client} relationship={p.relationship} />}
                  </ListGroupItem>
                </React.Fragment>
              );
            }
          }
        })}
        {!showNewClientOptions && (
          <ListGroupItem
            onClick={() => {
              setShowNewClientOptions(true);
              setShowNewClientDetails(false);
              setEditingClientId(undefined);
              setAddingExistingClientId(undefined);
            }}
            disabled={showNewClientOptions || !!editingClientId || props.disableEditing}
            tag={'button'}
          >
            <FaPlus /> Add Person
          </ListGroupItem>
        )}
      </ListGroup>
      {(showNewClientDetails || addingExistingClientId) && (
        <ClientProvider clientId={addingExistingClientId}>
          <ClientDetails
            allowCreateFile={false}
            createCompleteAction={createCompleteAction}
            editCompleteAction={editCompleteAction}
            clientId={addingExistingClientId}
            multiplePeopleFormat={true}
            autoFocus={true}
            cancelAction={() => {
              setShowNewClientOptions(false);
              setShowNewClientDetails(false);
              setAddingExistingClientId(undefined);
            }}
            showRelationship={true}
            newClient={true}
          />
        </ClientProvider>
      )}
      {!(showNewClientDetails || addingExistingClientId) && showNewClientOptions && (
        <div className={'p-3'}>
          <Select
            id={showNewClientOptions ? 'select-field' : ''}
            className={'client-select flex-fill'}
            name={'existingPerson'}
            inputValue={clientInputValue}
            onInputChange={(e) => setClientInputValue(e)}
            options={
              !!clientInputValue && clientInputValue.length >= 3
                ? clients.filter(
                    (c) =>
                      c.searchString?.includes(clientInputValue.toUpperCase()) &&
                      !otherPeople.map((e) => e.clientId).includes(c.clientId) &&
                      c.active
                  )
                : []
            }
            value={clients.find((i: IClient) => i.clientId === addingExistingClientId) || null}
            onChange={(value: any) => {
              setAddingExistingClientId(value?.clientId || undefined);
              setShowNewClientDetails(false);
              props.setIsDirty(true);
              setAddingNewClient((c) => c + 1);
            }}
            getOptionValue={(option: IClient) => option.clientId}
            getOptionLabel={(o: IClient) =>
              `${o.lastName.toUpperCase()}, ${o.firstName} ${o.middleName} ${
                o.birthDate ? formatDateFromISONumber(o.birthDate) : ''
              }`
            }
            placeholder={'Select existing client...'}
            isClearable={true}
            components={{ SingleValue: ClientSingleValue, Option: ClientOption }}
            noOptionsMessage={() =>
              !!clientInputValue && clientInputValue.length >= 3
                ? 'No client match your search. Add a new client or try again.'
                : 'Enter at least 3 characters to search.'
            }
            styles={{
              singleValue: (base) => ({
                ...base,
                position: 'relative',
                top: 0,
                transform: 'translateY(0)',
                height: '100%',

                padding: '0.25em 0',
              }),
            }}
          />
          <Row>
            <p className={'text-muted mb-3 mt-1 ml-4'}>
              or{' '}
              <Button
                color='link'
                onClick={() => {
                  setShowNewClientDetails(true);
                  setAddingNewClient((c) => c + 1);
                }}
                aria-label={'Add New Client'}
                disabled={showNewClientDetails || props.disableEditing}
                className={'p-0 border-0 align-baseline'}
              >
                Add New Client
              </Button>
            </p>
          </Row>
          <Row>
            <Button
              color={'link'}
              className='ml-2'
              onClick={() => {
                setShowNewClientOptions(false);
              }}
            >
              Cancel
            </Button>
          </Row>
        </div>
      )}
    </Card>
  );
};
