import { IAddress } from '../../../components/Clients/ClientContext';
import { useGetAxiosHook } from '../../AxiosHook';

export const useClientAddresses = (clientId?: string) => {
  const URL = `/api/clients/${clientId}/addresses`;

  const { data, loading, error } = useGetAxiosHook<IAddress[]>(URL, {}, !clientId || clientId === 'add');

  return { data, loading, error };
};
