import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, CustomInput, Navbar, NavbarBrand } from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { Column, TableInstance, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import * as DateTimeFormatter from '../../formatters/DateTimeFormatter';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { TablePagination } from '../TablePagination/TablePagination';
import { IClient } from './ClientContext';
import {
  DomainContext,
  INDIGENOUS_GROUP_FIRST_NATION,
  INDIGENOUS_GROUP_INUIT,
  INDIGENOUS_GROUP_METIS,
} from '../../contexts/DomainContext';
import { ClientsTableContext } from './ClientsTableContext';
import { PersistantTableGlobalFilter } from '../TableGlobalFilter/PersistantTableGlobalFilter';
import { useWindowDimensions } from '../../hooks/WindowHooks';
import { IIndigenousInformation } from './ClientDetails';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import { MyUserContext } from '../../contexts/MyUserContext';

interface IProps {
  selectRow?: (clientId: string) => void;
}

export const ClientsTable: React.FC<IProps> = (props) => {
  const [clients, setClients] = useState<IClient[]>([]);
  const history = useHistory();
  const domainContext = useContext(DomainContext);
  const searchContext = useContext(ClientsTableContext);
  const location = useLocation<{ deletedClientId?: string }>();
  const yukonFns = domainContext.yukonFns;
  const { xs } = useWindowDimensions();
  const [activeOnly, setActiveOnly] = useState(false);

  useEffect(() => {
    const deletedClientId = location?.state?.deletedClientId;

    authAxios.get('/api/clients').then((response) => {
      setClients(response.data.filter((c: any) => c.clientId !== deletedClientId));
    });
  }, []);

  const columns: Column<IClient>[] = useMemo(
    () => [
      {
        Header: 'Last Name',
        id: 'lastName',
        xs: true,
        accessor: (client: IClient) => {
          return client.lastName.toUpperCase();
        },
      },
      {
        Header: 'First Name',
        id: 'firstName',
        accessor: (client: IClient) => {
          return client.firstName + (client.active ? '' : ' (Inactive)');
        },
        xs: true,
      },
      {
        Header: 'Date of Birth',
        id: 'birthDate',
        width: 50,
        xs: false,
        accessor: (client: IClient) => {
          return client.birthDate ? DateTimeFormatter.formatDate(new Date(client.birthDate)) : '';
        },
      },
      {
        Header: 'Indigenous Ancestry',
        id: 'firstNation',
        xs: true,
        accessor: (client: IClient) => {
          if (client.indigenousInfo && client.indigenousInfo.length > 0) {
            return client.indigenousInfo
              .map((i: IIndigenousInformation) => {
                if (i.indigenousGroupId === INDIGENOUS_GROUP_FIRST_NATION) {
                  return yukonFns.find((yFN) => yFN.yukonFirstNationId === i.bandNation)
                    ? yukonFns.find((yFN) => yFN.yukonFirstNationId === i.bandNation)?.name
                    : i.bandNation;
                } else {
                  return domainContext.indigenousGroups.find((group) => group.indigenousGroupId === i.indigenousGroupId)
                    ? domainContext.indigenousGroups.find((group) => group.indigenousGroupId === i.indigenousGroupId)
                        ?.name
                    : '';
                }
              })
              .join(', ');
          } else {
            return '';
          }
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='indigenousInfo'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={yukonFns
                .map((yFN) => {
                  return { value: yFN.yukonFirstNationId, display: yFN.name };
                })
                .concat([
                  { value: INDIGENOUS_GROUP_INUIT, display: 'Inuit' },
                  { value: INDIGENOUS_GROUP_METIS, display: 'Metis' },
                ])}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue: IIndigenousInformation[] = row.original['indigenousInfo'];
              return filterValue.find(
                (val: any) =>
                  rowValue.map((rv) => rv.indigenousGroupId).includes(val) ||
                  rowValue.map((rv) => rv.bandNation).includes(val)
              );
            }
          });
        },
      },
      {
        Header: 'Circle of Support',
        id: 'supportTeam',
        accessor: (client: IClient) => {
          return client.supportTeam?.map((s) => `${s.name} (${s.relationshipType})`).join(' • ');
        },
      },
    ],
    [yukonFns]
  );

  const defaultColumn = useDefaultColumn();

  const data: IClient[] = useMemo(() => clients.filter((e) => e.active || !activeOnly), [clients, activeOnly]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    prepareRow,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters },
    setAllFilters,
  }: TableInstance<IClient> = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        filters: searchContext.filters,
        globalFilter: searchContext.selectedGlobalSearch,
        sortBy: [
          {
            id: 'lastName',
            desc: false,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    searchContext.setFilters(filters);
  }, [filters]);

  const selectRow = (clientId: string) => {
    if (props.selectRow) {
      props.selectRow(clientId);
    } else {
      history.push('/clients/' + clientId);
    }
  };

  const { isSuperAdmin } = useContext(MyUserContext);

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>Clients</NavbarBrand>
        <CustomInput
          type='checkbox'
          id={'active'}
          name={'active'}
          label={'Show Active Only'}
          checked={activeOnly}
          onChange={() => setActiveOnly((s) => !s)}
        />
        {isSuperAdmin && (
          <Button className={'ml-2'} color={'warning'} tag={Link} to={{ pathname: '/clients/merge' }}>
            Merge Clients
          </Button>
        )}
        <Button
          className={'mx-2'}
          color={'primary'}
          outline={true}
          onClick={() => {
            searchContext.setSelectedGlobalSearch('');
            setGlobalFilter('');
            setAllFilters([]);
          }}
        >
          Clear Filters
        </Button>
        <Button color={'primary'} tag={Link} to={{ pathname: '/clients/add' }}>
          <FaPlus className='mr-2' />
          Add Client
        </Button>
      </Navbar>
      <div className={'table-responsive'} style={{ overflow: 'visible' }}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`clients-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  return (
                    (!xs || (xs && column.xs)) && (
                      <th
                        key={`clients-table-thead-tr-${index}-${column.id}`}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          width: column.width,
                          ...column.getHeaderProps(column.getSortByToggleProps()).style,
                        }}
                      >
                        {column.render('Header')}
                        <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                        <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </th>
                    )
                  );
                })}
              </tr>
            ))}
            <tr>
              <PersistantTableGlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                colSpan={visibleColumns.length}
                value={searchContext.selectedGlobalSearch}
                setValue={searchContext.setSelectedGlobalSearch}
                label={{ singular: 'Client', plural: 'Clients' }}
              />
            </tr>
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);

              return (
                <tr
                  style={{ fontWeight: row.original.active ? 'initial' : 200 }}
                  key={`clients-table-tr-${row.id}`}
                  {...row.getRowProps()}
                  className={row.original.flagged ? 'flagged-row' : ''}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      (!xs || (xs && cell.column.xs)) && (
                        <td
                          key={`clients-table-td-${cell.row.id}-${cell.column.id}`}
                          {...cell.getCellProps()}
                          onClick={() => selectRow(row.original.clientId)}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
      />
    </div>
  );
};
