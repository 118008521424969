import * as React from 'react';
import { useState } from 'react';
import { TableInstance, useAsyncDebounce } from 'react-table';
import { FaSearch } from 'react-icons/fa';

interface TableGlobalFilterProps {
  preGlobalFilteredRows: TableInstance<any>['preGlobalFilteredRows'];
  globalFilter: TableInstance['globalFilter'];
  setGlobalFilter: TableInstance['setGlobalFilter'];
  colSpan?: number;
  label?: {
    singular: string;
    plural: string;
  }; // label is used to describe the type of thing the user is searching for. Default: 'records'
}

export const TableGlobalFilter: React.ComponentType<TableGlobalFilterProps> = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  colSpan,
  label = { singular: 'Record', plural: 'Records' },
}) => {
  const count = preGlobalFilteredRows?.length || 0;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const inputLabel = count === 1 ? label.singular : label.plural;

  // This component uses a combination of flexbox and absolute positioning to position
  // the search icon "within" the input. This ensures that the icon is within the outline
  // of the input when the input is focused.
  // zIndex is used to ensure that the user can focus the field if they click on the search
  // icon. This is simpler than handling focus using the .focus() handler.
  return (
    <th colSpan={colSpan} className={'p-0'}>
      <div className={'position-relative d-flex align-items-center'}>
        <FaSearch className='ml-3 mr-2 position-absolute' style={{ zIndex: 1 }} />
        <input
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          aria-label={`Search ${count} ${inputLabel}`}
          placeholder={`Search ${count} ${inputLabel}…`}
          className={'border-0 m-0 w-100'}
          style={{ padding: '0.75em 0.75em 0.75em 2.75em', flexGrow: 1, zIndex: 2, background: 'transparent' }}
        />
      </div>
    </th>
  );
};
