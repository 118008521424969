import * as React from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface IFormattedTextProps extends EditorProps {
  name: string;
  editorState: EditorState;
  autoFocus?: boolean;
}

export class FormattedText extends React.Component<IFormattedTextProps, any> {
  private toolbarOptions = {
    options: ['list', 'history'],
    list: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['unordered', 'ordered', 'indent', 'outdent'],
      unordered: { className: undefined },
      ordered: { className: undefined },
      indent: { className: undefined },
      outdent: { className: undefined },
    },
    history: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['undo', 'redo'],
      undo: { className: undefined },
      redo: { className: undefined },
    },
  };

  public static getContent = (editorState: EditorState) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  public render() {
    return (
      <Editor
        toolbarOnFocus={false}
        toolbar={this.toolbarOptions}
        wrapperClassName={'formatted-textbox-wrapper'}
        editorClassName={'formatted-textbox-editor'}
        spellCheck={true}
        stripPastedStyles={true}
        {...this.props}
      />
    );
  }
}
