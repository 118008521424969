import * as React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import { getJwtFromStorage } from '../../services/AuthenticationService';
import { formatDateForServer } from '../../formatters/DateTimeFormatter';

const addAndClickLink = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'download');
  document.body.appendChild(link);
  link.click();
};

export const Exports: React.FC = () => {
  const defaultFromDate = new Date();
  const [fromDate, setFromDate] = useState<Date>(
    new Date(defaultFromDate.getMonth() < 3 ? defaultFromDate.getFullYear() - 1 : defaultFromDate.getFullYear(), 3, 1)
  );
  const defaultToDate = new Date();
  defaultToDate.setUTCHours(23, 59, 59, 999);
  const [toDate, setToDate] = useState<Date>(defaultToDate);

  const exportJPFiles = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/jordans-principle-file-export/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  const exportJPRequests = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/jordans-principle-requests-export/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  const exportFPFiles = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/family-preservation-file-export/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  const exportWCCCFiles = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/wcc-connective-file-export/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  const exportGladueFiles = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/gladue-file-export/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  const exportFPServicesProvided = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/family-preservation-services-provided-export/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  const exportFPFinancialAssistance = () => {
    if (fromDate && toDate) {
      const url = `/api/reports/family-preservation-financial-assistance-export/${getJwtFromStorage()}?fromDate=${formatDateForServer(
        fromDate
      )}&toDate=${formatDateForServer(toDate)}`;
      addAndClickLink(url);
    }
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom d-flex flex-wrap sticky-top'}>
        <NavbarBrand className={'w-mobile-100'}>Exports</NavbarBrand>
      </Navbar>
      <Container className={'my-5 container--no-margin-print'}>
        <Card>
          <CardHeader>Select Date Range</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <Label className={'flex second-label'} style={{ paddingRight: '10px' }}>
                  From
                </Label>
                <DatePicker
                  className={'form-control date-select flex'}
                  selected={fromDate}
                  onChange={(date) => {
                    if (date) {
                      setFromDate(date);
                    }
                  }}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  shouldCloseOnSelect={true}
                  dateFormat={'yyyy-MM-dd'}
                />
              </Col>
              <Col>
                <Label
                  className={'flex second-label'}
                  for={'EndDate'}
                  style={{ paddingRight: '10px', paddingLeft: '10px' }}
                >
                  To
                </Label>
                <DatePicker
                  className={'form-control date-select flex'}
                  selected={toDate}
                  onChange={(date) => {
                    if (date) {
                      setToDate(date);
                    }
                  }}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  shouldCloseOnSelect={true}
                  dateFormat={'yyyy-MM-dd'}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Row className={'mt-5'}>
          <p>
            <b>Jordan&apos;s Principle Files: </b>Export all Jordan&apos;s Principle files created in the date range
            above.
          </p>
        </Row>
        <Row>
          <Button onClick={() => exportJPFiles()}>Export Jordan&apos;s Principle Files</Button>
        </Row>
        <Row className={'mt-5'}>
          <p>
            <b>Jordan&apos;s Principle Requests: </b>Export all Jordan&apos;s Principle requests created in the date
            range above.
          </p>
        </Row>
        <Row>
          <Button onClick={() => exportJPRequests()}>Export Jordan&apos;s Principle Requests</Button>
        </Row>
        <Row className={'mt-5'}>
          <p>
            <b>Client Services Files: </b>Export all Client Services files with a creation date in the range above.
          </p>
        </Row>
        <Row>
          <Button onClick={() => exportFPFiles()}>Export FP Files (Created Date)</Button>
        </Row>
        <Row className={'mt-5'}>
          <p>
            <b>Client Services Services Provided: </b>Export all Client Services services provided with a creation date
            in the range above.
          </p>
        </Row>
        <Row>
          <Button onClick={() => exportFPServicesProvided()}>Export FP Services Provided</Button>
        </Row>
        <Row className={'mt-5'}>
          <p>
            <b>Client Services Financial Assistance: </b>Export all Client Services financial assistance records for
            files created in the date range above.
          </p>
        </Row>
        <Row>
          <Button onClick={() => exportFPFinancialAssistance()}>Export FP Financial Assistance</Button>
        </Row>
        <Row className={'mt-5'}>
          <p>
            <b>WCC/Connective Files: </b>Export all WCC / Connective files with a created date in the range above.
          </p>
        </Row>
        <Row>
          <Button onClick={() => exportWCCCFiles()}>Export WCC and Connective Files (Created Date)</Button>
        </Row>
        <Row className={'mt-5'}>
          <p>
            <b>Gladue Files: </b>Export all Gladue files with a requested date in the range above.
          </p>
        </Row>
        <Row>
          <Button onClick={() => exportGladueFiles()}>Export Gladue Files (Requested Date)</Button>
        </Row>
      </Container>
    </div>
  );
};
