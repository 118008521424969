import * as React from 'react';
import { useState } from 'react';
import { Button, Col, FormGroup, Label, Row, Input } from 'reactstrap';
import { useParams } from 'react-router';
import DatePicker from 'react-datepicker';
import { IUser } from '../Users/UsersTable';
import Select from 'react-select';
import { useContext } from 'react';
import { UsersContext } from '../../contexts/UsersContext';
import { FileContext } from './FileContext';
import { authAxios } from '../../services/AxiosService';
import { getUserId } from '../../services/AuthenticationService';
import { useEffect } from 'react';
import { IReminder } from './FileReminders';
import { DomainContext, INotificationType } from '../../contexts/DomainContext';
import { setMinutes, setHours } from 'date-fns';

interface IFileReminderFormProps {
  reminder: IReminder | undefined;
  setReminder: React.Dispatch<React.SetStateAction<IReminder | undefined>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FileReminderForm: React.FC<IFileReminderFormProps> = (props) => {
  const params = useParams<{ fileId: string; reminderId?: string }>();
  const usersContext = useContext(UsersContext);
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const notificationTypeList = domainContext.notificationTypes;
  const staff = usersContext.users;
  const [date, setDate] = useState<Date | null>();
  const currentDate = new Date();
  const usersToNotifyOptions = staff.filter((s) => fileContext.staffIds.includes(s.userId));

  const SELECT_ALL_ID = 'SELECT_ALL_ID';

  useEffect(() => {
    setDate(props.reminder?.date ? new Date(props.reminder.date) : undefined);
  }, [props.reminder]);

  const resolveReminder = () => {
    authAxios
      .post('/api/files/resolve-reminder', { fileId: params.fileId, reminderId: params.reminderId })
      .then((response) => {
        if (response.data.status === 'OK') {
          props.setReminder((r) => ({ ...r, resolved: true } as IReminder));
          fileContext.setReminders((reminderList) =>
            reminderList.map((r) => {
              if (r.reminderId === params.reminderId) {
                return { ...r, resolved: true } as IReminder;
              } else {
                return r;
              }
            })
          );
        }
      });
  };

  const selectAllOption = { userId: SELECT_ALL_ID, firstName: 'Select All' } as IUser;

  const areAllUserOptionsSelected = () => {
    return usersToNotifyOptions.every((c) => props.reminder?.staffToNotify.includes(c.userId));
  };

  return (
    <Row className={'mt-3'}>
      <Col md={12} lg={2} className={'text-lg-right'}>
        <Label htmlFor={'note'} className={'text-body h6 mb-0 font-weight-bold'}>
          Reminder
        </Label>
        <br />
      </Col>
      <Col md={8} lg={7} xl={6}>
        <FormGroup className={'mb-2'}>
          <Input
            type='textarea'
            placeholder='Type reminder here...'
            name='reminderText'
            style={{ minHeight: '100px' }}
            value={props.reminder ? props.reminder.text : ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setReminder((r) => ({ ...r, text: e.target.value } as IReminder));
              props.setIsDirty(true);
            }}
          />
        </FormGroup>
        <FormGroup className={'mt-0'}>
          <Label htmlFor={'noteDate'}>Reminder Date</Label>
          <DatePicker
            selected={date}
            id={'noteDate'}
            onChange={(dateToSet) => {
              if (dateToSet) {
                props.setReminder((r) => ({ ...r, date: dateToSet.getTime() } as IReminder));
                props.setIsDirty(true);
              }
            }}
            className={'form-control'}
            minDate={currentDate}
            minTime={
              date?.getDate() === currentDate.getDate()
                ? setHours(setMinutes(currentDate, currentDate.getMinutes()), currentDate.getHours())
                : setHours(setMinutes(currentDate, 0), 0)
            }
            maxTime={setHours(setMinutes(currentDate, 59), 23)}
            placeholderText={'MMM dd, yyyy hh:mm aa'}
            peekNextMonth
            showMonthDropdown
            showTimeSelect={true}
            showYearDropdown
            dateFormat='MMM dd, yyyy hh:mm aa'
          />
        </FormGroup>
        {params.reminderId &&
          date &&
          date.getTime() < new Date().getTime() &&
          !props.reminder?.resolved &&
          props.reminder?.staffToNotify.includes(getUserId()) && (
            <FormGroup>
              <Button color={'warning'} onClick={() => resolveReminder()}>
                Resolve
              </Button>
            </FormGroup>
          )}
      </Col>
      <Col>
        <FormGroup>
          <Label>Users to Notify</Label>
          <Select
            name={'staffIds'}
            options={areAllUserOptionsSelected() ? usersToNotifyOptions : [selectAllOption, ...usersToNotifyOptions]}
            onChange={(value: any) => {
              props.setReminder(
                (r) =>
                  ({
                    ...r,
                    staffToNotify: value.map((s: IUser) => s.userId).includes(SELECT_ALL_ID)
                      ? usersToNotifyOptions.map((s: IUser) => s.userId)
                      : value.map((s: IUser) => s.userId),
                  } as IReminder)
              );
              props.setIsDirty(true);
            }}
            value={
              props.reminder && props.reminder.staffToNotify
                ? staff.filter((s) => props.reminder!.staffToNotify.includes(s.userId))
                : []
            }
            getOptionValue={(option: IUser) => option.userId}
            //show only the first name for SELECT_ALL_ID
            getOptionLabel={(option: IUser) =>
              option.lastName ? option.lastName + ', ' + option.firstName : option.firstName
            }
            isClearable={true}
            isMulti={true}
          />
        </FormGroup>
        <FormGroup>
          <Label>Notification Type</Label>
          <Select
            name={'notificationTypes'}
            options={notificationTypeList}
            onChange={(value: any) => {
              props.setReminder(
                (r) =>
                  ({
                    ...r,
                    notificationTypeIds: value.map((s: INotificationType) => s.notificationTypeId),
                  } as IReminder)
              );
              props.setIsDirty(true);
            }}
            value={
              props.reminder
                ? notificationTypeList.filter((s) => props.reminder!.notificationTypeIds.includes(s.notificationTypeId))
                : []
            }
            getOptionValue={(option: INotificationType) => option.notificationTypeId}
            getOptionLabel={(option: INotificationType) => option.name}
            isClearable={true}
            isMulti={true}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};
