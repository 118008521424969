import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import * as ToastService from '../../services/ToastService';
import { useHistory, useParams } from 'react-router';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { ICharge } from './ChargesTable';
import { DomainContext } from '../../contexts/DomainContext';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

export const ChargeDetails: React.FC = () => {
  const params = useParams<{ chargeId: string }>();
  const domainContext = useContext(DomainContext);
  const history = useHistory();

  const [criminalSectionCode, setCriminalSectionCode] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);
  const [isEditingAllowed, setIsEditingAllowed] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [modalFieldChanged, setModalFieldChanged] = useState(false);

  useEffect(() => {
    if (params.chargeId) {
      authAxios
        .get('/api/charges/' + params.chargeId)
        .then((response) => {
          if (response.data && response.data.status && response.data.status.includes('KO')) {
            history.push('/404');
          } else if (response.data) {
            setCriminalSectionCode(response.data.criminalSectionCode);
            setDescription(response.data.description);
            setIsActive(response.data.isActive);
          }
        })
        .catch((response) => {
          history.push('/404');
        });
    }
  }, [params.chargeId]);

  const formFullyFilled = () => {
    return criminalSectionCode !== '' && isActive !== undefined;
  };

  const checkIsSame = () => {
    const matchingChargesList: ICharge[] = domainContext.charges.filter(
      (c: ICharge) =>
        c.criminalSectionCode.toUpperCase().replace(/\s/g, '') === criminalSectionCode.toUpperCase().replace(/\s/g, '')
    );
    return matchingChargesList.length > 0;
  };

  const saveChargeDetails = () => {
    if (!formFullyFilled()) {
      setTrySubmit(true);
      return;
    }
    if (params.chargeId) {
      authAxios
        .post('/api/charges/update', {
          chargeId: params.chargeId,
          criminalSectionCode: criminalSectionCode,
          description: description,
          isActive: isActive,
        } as ICharge)
        .then((response) => {
          if (response.data.status === 'OK') {
            ToastService.notifySuccess('Charge Updated');
            setIsDirty(false);
            domainContext.setCharges((s) => {
              return s.map((charge) => {
                if (charge.chargeId === params.chargeId) {
                  return {
                    chargeId: params.chargeId,
                    criminalSectionCode: criminalSectionCode,
                    description: description,
                    isActive: isActive,
                  } as ICharge;
                } else {
                  return charge;
                }
              });
            });
            history.push('/charges');
          } else {
            ToastService.notifyError(response.data.errorMessage);
          }
        })
        .catch((error) => {
          ToastService.notifyError('Error:' + error);
        });
    } else {
      authAxios
        .post('/api/charges/create', {
          criminalSectionCode,
          description,
          isActive,
        } as ICharge)
        .then((response) => {
          if (response.data.status === 'OK') {
            ToastService.notifySuccess('Charge Created');
            setIsDirty(false);
            domainContext.setCharges((s) => {
              return s.concat({
                chargeId: response.data.chargeId,
                criminalSectionCode: criminalSectionCode,
                description: description,
                isActive: isActive,
              } as ICharge);
            });
            history.push('/charges');
          } else {
            ToastService.notifyError('Error Creating Charge - ' + response.data.desc);
          }
        });
    }
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'}>
          <NavbarBrand>{params.chargeId ? 'Edit Charge' : 'Add New Charge'}</NavbarBrand>

          {params.chargeId ? (
            <>
              {!isEditingAllowed ? (
                <Col className={'d-flex'}>
                  <Button className={'ml-auto'} color={'primary'} onClick={() => setIsEditingAllowed(true)}>
                    Edit Details
                  </Button>
                </Col>
              ) : (
                <Col className={'d-flex'}>
                  <Button color={'light'} className={'ml-auto'} onClick={() => setIsEditingAllowed(false)}>
                    Cancel
                  </Button>
                  <Button
                    color={'primary'}
                    className={'ml-2'}
                    onClick={() => {
                      if (modalFieldChanged && isDirty) {
                        setOpenConfirmModal(true);
                      } else if (isDirty) {
                        saveChargeDetails();
                      }
                    }}
                  >
                    Save Details
                  </Button>
                </Col>
              )}
            </>
          ) : (
            <Col className={'d-flex'}>
              <Button color={'link'} className={'ml-auto'} onClick={() => history.push('/charges')}>
                Cancel
              </Button>
              <Button
                color={'primary'}
                className={'ml-2'}
                onClick={() => {
                  if (checkIsSame()) {
                    ToastService.notifyError('A charge already exists with this criminal section code');
                  } else {
                    saveChargeDetails();
                  }
                }}
              >
                Save Details
              </Button>
            </Col>
          )}
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          <Row>
            <Col>
              <Row className='pt-3'>
                <FormGroup className='col-sm-6'>
                  <Label>
                    Criminal Section Code <span className='text-required'>(required)</span>
                  </Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='criminalSectionCode'
                    value={criminalSectionCode}
                    disabled={!isEditingAllowed && params.chargeId?.length > 0}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setCriminalSectionCode(e.target.value);
                      setModalFieldChanged(true);
                      setIsDirty(true);
                    }}
                    invalid={trySubmit && criminalSectionCode === ''}
                  />
                  <FormFeedback>This field is mandatory</FormFeedback>
                </FormGroup>
                <FormGroup className='col-sm-6'>
                  <Label>Description</Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='description'
                    value={description}
                    disabled={!isEditingAllowed && params.chargeId?.length > 0}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setDescription(e.target.value);
                      setModalFieldChanged(true);
                      setIsDirty(true);
                    }}
                  />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className='col-sm-6'>
                  <CustomInput
                    type='checkbox'
                    id={'active'}
                    name={'active'}
                    label={'Active'}
                    disabled={!isEditingAllowed && params.chargeId?.length > 0}
                    checked={isActive}
                    onChange={() => {
                      setIsActive((s) => !s);
                      setIsDirty(true);
                    }}
                  />
                  <FormFeedback>This field is mandatory</FormFeedback>
                </FormGroup>
              </Row>
            </Col>
          </Row>
        </Container>
      </form>
      <Modal isOpen={openConfirmModal}>
        <ModalHeader>Confirm Your Changes</ModalHeader>
        <ModalBody>
          The changes you have made will be applied to all Files where this Charge has been used. Are you sure you want
          to continue?
        </ModalBody>
        <ModalFooter>
          <Button
            color={'primary'}
            onClick={() => {
              saveChargeDetails();
              setOpenConfirmModal(false);
            }}
          >
            Yes
          </Button>
          <Button color={'link'} onClick={() => setOpenConfirmModal((s: boolean) => !s)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
