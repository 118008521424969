import { ISupportPerson } from '../../../components/Clients/ClientContext';
import { useGetAxiosHook } from '../../AxiosHook';

export const useGetSupportTeam = (clientId?: string) => {
  const URL = `/api/clients/${clientId}/support-team`;

  const { data, loading, error } = useGetAxiosHook<ISupportPerson[]>(URL, {}, !clientId || clientId === 'add');

  return { data, loading, error };
};
