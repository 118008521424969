import * as React from 'react';
import { useEffect, useState } from 'react';
import { authAxios } from '../services/AxiosService';
import { IUser } from '../components/Users/UsersTable';

type ContextType = {
  loaded: boolean;
  users: IUser[];
};

export const UsersContext = React.createContext<ContextType>({
  loaded: false,
  users: [] as IUser[],
});

export const UsersProvider = (props: any) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const loaded = usersLoaded;

  useEffect(() => {
    authAxios.get('/api/users/admin/staff').then((response) => {
      const users: IUser[] = response.data;
      setUsers(users);
      setUsersLoaded(true);
    });
  }, []);

  return (
    <UsersContext.Provider
      value={{
        loaded,
        users,
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
};

export const UsersConsumer = UsersContext.Consumer;
