import * as React from 'react';
import { useEffect, useState } from 'react';
import { authAxios } from '../services/AxiosService';
import { IGladueMember } from '../components/GladueMembers/GladueMembersTable';
import { ICharge } from '../components/Charges/ChargesTable';

export const STAGE_INTAKE_IN_PROGRESS = 'b8d3a454-97e4-4eec-baf4-0a800c3f55a4';
export const STAGE_REJECTED = '0345369f-ac4d-45ad-bf80-f6c37ecd4228';
export const STAGE_DRAFT = '1f8b97fc-b9f3-48cb-94b6-1e0b96ee523d';
export const STAGE_SUBMITTED = 'fd45bce8-312d-4199-84d9-6bfd83d198e6';
export const STAGE_DECISION = '2099cba4-c512-4bb7-8611-971119872f7d';
export const STAGE_ESCALATED = 'ae8ba500-5063-4fb7-9403-d573bd952a8e';
export const STAGE_APPEAL = 'cc927a1a-f1c1-451c-827e-d78988ee5964';
export const STAGE_COMPLETED = 'a92b5a03-494f-4778-83a6-1aca301bde51';
export const STAGE_INQUIRY = '776ea9bd-1b07-41c8-81b7-f7470e956210';
export const STAGE_DENIED = '39a5aa01-83c3-498d-9a27-e07984e2e59d';
export const STAGE_INTAKE_CONVERTED_TO_DRAFT_DEPRECATED = '5487a41a-b27f-4d5e-915f-59fa4561c394';
export const STAGE_ACTIVE = '0bf8602d-1333-4543-9b99-d259ba54bc1e';
export const STAGE_IN_PROGRESS = '7f95c3b7-ca7d-4eff-9ae9-cd14af143cfc';
export const STAGE_REQUESTED = '96ab7330-e611-4afc-affc-656c6e53775e';
export const STAGE_INACTIVE = 'dea8585e-ee40-4527-82a2-8da670869833';
export const STAGE_UNDER_REVIEW = '98c186f6-f348-4a24-ae9c-aae3e969aeee';
export const STAGE_FILED = '6d601021-935b-4fcf-a5dd-fec2b8e99e0e';
export const STAGE_ASSIGNED = 'e3926444-597c-43f2-83b0-c0416c62d6d9';
export const STAGE_FINALIZED = '945d57db-921b-4b07-8baa-13c586339d49';
export const NOTE_DOCUMENT_TYPE = '0432a14c-9fd8-4845-913e-a3b840fc20b9';
export const WORKSHOP_DOCUMENT_TYPE = '9229ddfa-446d-4b4a-948a-f01fbe9de1e3';

export const FILE_TYPE_JORDANS_PRINCIPLE = '8d678a85-7fbb-482c-90b6-c04d8e4137a1';
export const FILE_TYPE_FAMILY_PRESERVATION = '4595c137-f539-4b4a-8e4a-ffd9543189cf';
export const FILE_TYPE_CULTURAL_CONNECTIONS = '2e03b8cd-8386-49d3-be3d-d429c202bf78';
export const FILE_TYPE_BIRTH_WORKER = 'b9530f20-8061-483f-82a0-fcd817023cfd';
export const FILE_TYPE_JUSTICE = '6e592cbd-c8ca-4a3a-933b-6824353fd089';
export const FILE_TYPE_INTAKE = 'c34e5d79-bde9-45e2-9228-7e0e37cb6b31';

export const DEPARTMENT_JORDANS_PRINCIPLE = '38d7fb94-cf79-4f1f-9245-68a5b38bca03';
export const DEPARTMENT_FAMILY_PRESERVATION = 'd62f6baf-25bb-41fb-9362-afe7c0aa3f11';
export const DEPARTMENT_CULTURAL_CONNECTIONS = '9de68b51-40d1-4f01-ab1b-5d4db9d7a0d8';
export const DEPARTMENT_BIRTH_WORKER = 'fdf8928d-0254-4c85-ae53-a8c18653c5b3';
export const DEPARTMENT_JUSTICE = 'b6dd3e09-3c35-4e5d-ae1a-fec31b3d2571';
export const DEPARTMENT_INTAKE = '3480e6da-e15f-4564-ad8e-87830f860b45';

export const FILE_SUB_TYPE_INDIVIDUAL = '109639a3-9e3e-4b02-9acb-3967deb01d2d';
export const FILE_SUB_TYPE_GROUP = 'd12b68f9-a3ff-4454-8214-53766652d80c';
export const FILE_SUB_TYPE_WCC_CONNECTIVE = 'b0997821-f03c-4c18-8b1a-95ec5ee0299b';
export const FILE_SUB_TYPE_GLADUE_REPORT = '0e32de45-0214-4c9f-b5b4-a1a9a954366d';
export const FILE_SUB_TYPE_COMMUNITY_IMPACT = 'b3da53b2-8b3f-4e63-8565-2d5ac8758051';
export const FILE_SUB_TYPE_FAMILY_PRESERVATION = '59b82cb2-e8c6-4c27-8ce8-4defa442ab30';
export const FILE_SUB_TYPE_CULTURAL_CONNECTIONS = 'ae0dd248-f249-449d-9f5d-74bee16788d7';
export const FILE_SUB_TYPE_BIRTH_WORKER = 'f83d724c-98de-47fb-bc60-8b7fba679d19';
export const FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR = 'daa9f791-9147-43b0-a128-d05a8d1755fe';
export const FILE_SUB_TYPE_INTAKE = '2dc2163b-2d22-4033-a80a-bc1f88501d90';
export const FILE_SUB_TYPE_INTAKE_REQUEST_FILE = '8ea5fbde-c975-4982-93d7-2575e1f15919';

export const NOTIFICATION_TYPE_ON_SCREEN = '161388bc-0ed4-4352-947f-9ade933b65c6';
export const NOTIFICATION_TYPE_EMAIL = '15d26932-5bc7-4ab6-b9ad-56cbb8035cb7';

export const REMINDER_TYPE_MANUAL = '19996be7-1565-4b4a-aada-004b5abfc1aa';
export const REMINDER_TYPE_SENTENCING_DATE = 'aa0007ec-19d3-421a-9576-c4ca3b3e28a7';
export const REMINDER_TYPE_APPOINTMENT = '8d648a03-70da-47e5-9263-71503dff9590';
export const REMINDER_TYPE_COURT_DATE = 'b41d0d7d-1836-463b-97e4-b4280770aea0';

export const INDIGENOUS_GROUP_FIRST_NATION = '2783cf5e-f5a6-4d5d-bea7-f198fd999a45';
export const INDIGENOUS_GROUP_INUIT = 'd2fb4d00-30ff-494e-bcf4-2c6b2e668ff5';
export const INDIGENOUS_GROUP_METIS = '583f7d72-baec-468f-9965-f60dcc997df1';
export const INDIGENOUS_GROUP_OTHER = '1652757d-5a92-451f-83f0-e24bc51006b0';

export const REQUEST_OUTCOME_APPROVED = 'dfb120e5-7c1f-4bbd-aa37-afb592891404';
export const REQUEST_OUTCOME_PARTIAL_APPROVAL = 'a73636cf-07df-4bd0-a440-9373305e93fc';
export const REQUEST_CATEGORY_OTHER = '0a27b664-ae1d-40ea-a8b7-c562bd7e8d7d';

export const editableStages = [STAGE_DRAFT];

export const GLADUE_REPORT_SENTENCE_FINE = '37418142-5748-4e59-a15b-4205eb33bd29';
export const GLADUE_REPORT_SENTENCE_RESTITUTION = '1e3e08f5-2572-4744-bc79-b5fa7a37ad36';

export const WORKSHOP_STATUS_UPCOMING = 'b2146654-2eda-4b4f-b73f-546a2c30a3ef';

export const JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE = 'd83b14d6-9600-4724-a7d5-bb7ee64c612b';
export const JORDANS_PRINCIPLE_REQUEST_TYPE_ISC_SUBMISSION = 'bd6c242e-56b0-4778-a28d-6aea9a72edaf';
export const JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER = '426614ef-7694-464d-99d8-63a5a5fbd31e';

export const LEGAL_SYSTEM_NAVIGATOR_TYPE_OF_SERVICE_FAMILY_LAW = '354f8212-9330-4f19-bea6-96f88be41a6b';

export const WORKSHOP_LOCATION_ID_OTHER = 'e0901de7-c696-4b68-ac42-4048e8076443';
export const WORKSHOP_EVENT_ATTENDANCE_ATTENDED = '4d1817a2-6c79-47aa-abbe-c93051434bce';
export const WORKSHOP_REGISTRATION_STATUS_REGISTERED = 'b43fef55-3d02-4cf0-9746-fa996c8d85ab';
export const WORKSHOP_REGISTRATION_STATUS_WAITLIST = '8023f350-5c9f-4152-a41e-8ecd4ce1d494';
export const WORKSHOP_REGISTRATION_STATUS_WITHDRAWN = 'aea2f511-dfac-4a2b-80e5-ba219222501f';

export const INCOME_OR_EMPLOYMENT_TYPE_OTHER = '74e0cd8a-43e5-4991-9928-97e1a02ad0e6';

export const fileTypeIdToDepartmentId = (fileTypeId: string) => {
  switch (fileTypeId) {
    case FILE_TYPE_BIRTH_WORKER:
      return DEPARTMENT_BIRTH_WORKER;
    case FILE_TYPE_CULTURAL_CONNECTIONS:
      return DEPARTMENT_CULTURAL_CONNECTIONS;
    case FILE_TYPE_FAMILY_PRESERVATION:
      return DEPARTMENT_FAMILY_PRESERVATION;
    case FILE_TYPE_JORDANS_PRINCIPLE:
      return DEPARTMENT_JORDANS_PRINCIPLE;
    case FILE_TYPE_JUSTICE:
      return DEPARTMENT_JUSTICE;
    default:
      return '';
  }
};

const isBusinessDay = (checkDate: Date) => {
  const day = checkDate.getDay();
  if (day === 6 || day === 0) {
    return false;
  } else {
    return true;
  }
};
export const getPreviousBusinessDay = (updatedDate: Date) => {
  const dayInMS = 86400000;
  let date = new Date();
  date.setTime(updatedDate.getTime() - dayInMS);
  while (!isBusinessDay(date)) {
    date.setTime(date.getTime() - dayInMS);
  }
  return date;
};

export const sanitizeHTMLConfig = {
  ALLOWED_TAGS: [
    'p',
    'b',
    'i',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'strong',
    'em',
    'ins',
    'del',
    'ul',
    'ol',
    'li',
    'a',
    'table',
    'tr',
    'td',
    'thead',
    'tbody',
    'br',
  ],
};

export interface IDepartment {
  departmentId: string;
  name: string;
  email: string;
  phone: string;
  active: boolean;
}

export interface IFileType {
  fileTypeId: string;
  name: string;
}

export interface IStage {
  stageId: string;
  name: string;
  order: number;
  fileTypes: string[];
  fileSubTypes: string[];
}

export interface IRelationshipType {
  relationshipTypeId: string;
  name: string;
}

export interface IGender {
  genderId: string;
  name: string;
}

export interface IFileSubType {
  fileSubTypeId: string;
  fileTypeId: string;
  name: string;
}

export interface IFileTypeDisplay {
  fileTypeDisplayId: string;
  name: string;
}

export interface IYukonFirstNation {
  yukonFirstNationId: string;
  name: string;
  abbreviation: string;
}

export interface ICommunity {
  communityId: string;
  name: string;
}

export interface IFrequency {
  frequencyId: string;
  name: string;
}

export interface IRequestCategory {
  categoryId: string;
  name: string;
}

export interface IOutcome {
  outcomeId: string;
  name: string;
}

export interface IDocumentType {
  documentTypeId: string;
  name: string;
}

export interface INotificationType {
  notificationTypeId: string;
  name: string;
}

export interface IReminderType {
  reminderTypeId: string;
  name: string;
}

export interface IServiceType {
  serviceTypeId: string;
  name: string;
}

export interface IIndigenousGroup {
  indigenousGroupId: string;
  name: string;
}

export interface ICourtLocation {
  courtLocationId: string;
  name: string;
}

export interface ISentenceType {
  sentenceTypeId: string;
  name: string;
}

export interface IJordansPrincipleRequestType {
  jordansPrincipleRequestTypeId: string;
  name: string;
  abbreviation: string;
}

export interface ITheme {
  themeId: string;
  name: string;
}

export interface IVendorOrServiceProvider {
  vendorOrServiceProviderId: string;
  name: string;
}

export interface IStatus {
  statusId: string;
  name: string;
}

export interface ILegalSystemNavigatorTypeOfService {
  legalSystemNavigatorTypeOfServiceId: string;
  name: string;
}

export interface IWorkshopLocation {
  workshopLocationId: string;
  name: string;
}

export interface IGroupHome {
  groupHomeId: string;
  name: string;
}

export interface IAttendance {
  attendanceId: string;
  name: string;
}

export interface IRegistrationStatus {
  registrationStatusId: string;
  name: string;
}

export interface IIncomeOrEmploymentType {
  incomeOrEmploymentTypeId: string;
  name: string;
}

export interface IResourceSupportType {
  resourceSupportTypeId: string;
  name: string;
}

type ContextType = {
  loaded: boolean;
  departments: IDepartment[];
  setDepartments: React.Dispatch<React.SetStateAction<IDepartment[]>>;
  fileTypes: IFileType[];
  fileSubTypes: IFileSubType[];
  fileTypeDisplay: IFileTypeDisplay[];
  fileRequestTypeDisplay: IFileTypeDisplay[];
  stages: IStage[];
  relationshipTypes: IRelationshipType[];
  roles: string[];
  genders: IGender[];
  yukonFns: IYukonFirstNation[];
  communities: ICommunity[];
  frequencies: IFrequency[];
  allCategories: IRequestCategory[];
  familyPreservationCategories: IRequestCategory[];
  birthWorkerCategories: IRequestCategory[];
  jordansPrincipleISCSubmissionCategories: IRequestCategory[];
  jordansPrincipleNOLCategories: IRequestCategory[];
  jordansPrincipleOtherCategories: IRequestCategory[];
  allOutcomes: IOutcome[];
  nonISCOutcomes: IOutcome[];
  allDocumentTypes: IDocumentType[];
  fileAndClientDocumentTypes: IDocumentType[];
  workshopDocumentTypes: IDocumentType[];
  notificationTypes: INotificationType[];
  reminderTypes: IReminderType[];
  familyPreservationServiceTypes: IServiceType[];
  birthWorkerServiceTypes: IServiceType[];
  gladueMembers: IGladueMember[];
  indigenousGroups: IIndigenousGroup[];
  courtLocations: ICourtLocation[];
  sentenceTypes: ISentenceType[];
  jordansPrincipleRequestTypes: IJordansPrincipleRequestType[];
  themes: ITheme[];
  vendorsOrServiceProviders: IVendorOrServiceProvider[];
  statuses: IStatus[];
  legalSystemNavigatorTypesOfService: ILegalSystemNavigatorTypeOfService[];
  charges: ICharge[];
  setCharges: React.Dispatch<React.SetStateAction<ICharge[]>>;
  workshopLocations: IWorkshopLocation[];
  groupHomes: IGroupHome[];
  attendance: IAttendance[];
  registrationStatuses: IRegistrationStatus[];
  incomeOrEmploymentTypes: IIncomeOrEmploymentType[];
  resourceSupportTypes: IResourceSupportType[];
};

export const DomainContext = React.createContext<ContextType>({
  loaded: false,
  departments: [] as IDepartment[],
  setDepartments: () => {},
  fileTypes: [] as IFileType[],
  fileSubTypes: [] as IFileSubType[],
  fileTypeDisplay: [] as IFileTypeDisplay[],
  fileRequestTypeDisplay: [] as IFileTypeDisplay[],
  stages: [] as IStage[],
  relationshipTypes: [] as IRelationshipType[],
  roles: [] as string[],
  genders: [] as IGender[],
  yukonFns: [] as IYukonFirstNation[],
  communities: [] as ICommunity[],
  frequencies: [] as IFrequency[],
  allCategories: [] as IRequestCategory[],
  familyPreservationCategories: [] as IRequestCategory[],
  birthWorkerCategories: [] as IRequestCategory[],
  jordansPrincipleISCSubmissionCategories: [] as IRequestCategory[],
  jordansPrincipleNOLCategories: [] as IRequestCategory[],
  jordansPrincipleOtherCategories: [] as IRequestCategory[],
  allOutcomes: [] as IOutcome[],
  nonISCOutcomes: [] as IOutcome[],
  allDocumentTypes: [] as IDocumentType[],
  fileAndClientDocumentTypes: [] as IDocumentType[],
  workshopDocumentTypes: [] as IDocumentType[],
  notificationTypes: [] as INotificationType[],
  reminderTypes: [] as IReminderType[],
  familyPreservationServiceTypes: [] as IServiceType[],
  birthWorkerServiceTypes: [] as IServiceType[],
  gladueMembers: [] as IGladueMember[],
  indigenousGroups: [] as IIndigenousGroup[],
  courtLocations: [] as ICourtLocation[],
  sentenceTypes: [] as ISentenceType[],
  jordansPrincipleRequestTypes: [] as IJordansPrincipleRequestType[],
  themes: [] as ITheme[],
  vendorsOrServiceProviders: [] as IVendorOrServiceProvider[],
  statuses: [] as IStatus[],
  legalSystemNavigatorTypesOfService: [] as ILegalSystemNavigatorTypeOfService[],
  charges: [] as ICharge[],
  setCharges: () => {},
  workshopLocations: [] as IWorkshopLocation[],
  groupHomes: [] as IGroupHome[],
  attendance: [] as IAttendance[],
  registrationStatuses: [] as IRegistrationStatus[],
  incomeOrEmploymentTypes: [] as IIncomeOrEmploymentType[],
  resourceSupportTypes: [] as IResourceSupportType[],
});

export const DomainProvider = (props: any) => {
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [fileTypes, setFileTypes] = useState<IFileType[]>([]);
  const [fileSubTypes, setFileSubTypes] = useState<IFileSubType[]>([]);
  const [fileTypeDisplay, setFileTypeDisplay] = useState<IFileTypeDisplay[]>([]);
  const [fileRequestTypeDisplay, setFileRequestTypeDisplay] = useState<IFileTypeDisplay[]>([]);
  const [stages, setStages] = useState<IStage[]>([]);
  const [relationshipTypes, setRelationshipTypes] = useState<IRelationshipType[]>([]);
  const [roles, setRoles] = useState([]);
  const [filesDomainLoaded, setFilesDomainLoaded] = useState(false);
  const [workshopsDomainLoaded, setWorkshopsDomainLoaded] = useState(false);
  const [departmentsLoaded, setDepartmentsLoaded] = useState(false);
  const [rolesLoaded, setRolesLoaded] = useState(false);
  const [gladuesLoaded, setGladuesLoaded] = useState(false);
  const [charges, setCharges] = useState<ICharge[]>([]);
  const [chargesLoaded, setChargesLoaded] = useState(false);
  const loaded =
    filesDomainLoaded && workshopsDomainLoaded && departmentsLoaded && rolesLoaded && gladuesLoaded && chargesLoaded;
  const [genders, setGenders] = useState([] as IGender[]);
  const [yukonFns, setYukonFns] = useState([] as IYukonFirstNation[]);
  const [communities, setCommunities] = useState([] as ICommunity[]);
  const [frequencies, setFrequencies] = useState([] as IFrequency[]);
  const [allCategories, setAllCategories] = useState([] as IRequestCategory[]);
  const [familyPreservationCategories, setFamilyPreservationCategories] = useState([] as IRequestCategory[]);
  const [birthWorkerCategories, setBirthWorkerCategories] = useState<IRequestCategory[]>([]);
  const [jordansPrincipleISCSubmissionCategories, setJordansPrincipleISCSubmissionCategories] = useState<
    IRequestCategory[]
  >([]);
  const [jordansPrincipleNOLCategories, setJordansPrincipleNOLCategories] = useState<IRequestCategory[]>([]);
  const [jordansPrincipleOtherCategories, setJordansPrincipleOtherCategories] = useState<IRequestCategory[]>([]);
  const [allOutcomes, setAllOutcomes] = useState([] as IOutcome[]);
  const [nonISCOutcomes, setNonISCOutcomes] = useState([] as IOutcome[]);
  const [allDocumentTypes, setAllDocumentTypes] = useState<IDocumentType[]>([]);
  const [fileAndClientDocumentTypes, setFileAndClientDocumentTypes] = useState<IDocumentType[]>([]);
  const [workshopDocumentTypes, setWorkshopDocumentTypes] = useState<IDocumentType[]>([]);
  const [notificationTypes, setNotificationTypes] = useState<INotificationType[]>([]);
  const [reminderTypes, setReminderTypes] = useState<IReminderType[]>([]);
  const [familyPreservationServiceTypes, setFamilyPreservationServiceTypes] = useState<IServiceType[]>([]);
  const [birthWorkerServiceTypes, setBirthWorkerServiceTypes] = useState<IServiceType[]>([]);
  const [gladueMembers, setGladueMembers] = useState<IGladueMember[]>([]);
  const [indigenousGroups, setIndigenousGroups] = useState<IIndigenousGroup[]>([]);
  const [courtLocations, setCourtLocations] = useState<ICourtLocation[]>([]);
  const [sentenceTypes, setSentenceTypes] = useState<ISentenceType[]>([]);
  const [jordansPrincipleRequestTypes, setJordansPrincipleRequestTypes] = useState<IJordansPrincipleRequestType[]>([]);
  const [themes, setThemes] = useState<ITheme[]>([]);
  const [vendorsOrServiceProviders, setVendorsOrServiceProviders] = useState<IVendorOrServiceProvider[]>([]);
  const [statuses, setStatuses] = useState<IStatus[]>([]);
  const [workshopLocations, setWorkshopLocations] = useState<IWorkshopLocation[]>([]);
  const [legalSystemNavigatorTypesOfService, setLegalSystemNavigatorTypesOfService] = useState<
    ILegalSystemNavigatorTypeOfService[]
  >([]);
  const [groupHomes, setGroupHomes] = useState<IGroupHome[]>([]);
  const [attendance, setAttendance] = useState<IAttendance[]>([]);
  const [registrationStatuses, setRegistrationStatuses] = useState<IRegistrationStatus[]>([]);
  const [incomeOrEmploymentTypes, setIncomeOrEmploymentTypes] = useState<IIncomeOrEmploymentType[]>([]);
  const [resourceSupportTypes, setResourceSupportTypes] = useState<IResourceSupportType[]>([]);

  useEffect(() => {
    authAxios.get('/api/files/domain').then((response) => {
      let fileTypes = response.data.fileTypes;
      fileTypes = fileTypes.sort((a: IFileType, b: IFileType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IFileType[];
      setFileTypes(fileTypes);

      let fileSubTypes = response.data.fileSubTypes;
      fileSubTypes = fileSubTypes.sort((a: IFileSubType, b: IFileSubType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IFileSubType[];
      setFileSubTypes(fileSubTypes);

      let fileTypeDisplay = response.data.fileTypeDisplay;
      fileTypeDisplay = fileTypeDisplay.sort((a: IFileTypeDisplay, b: IFileTypeDisplay) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IFileTypeDisplay[];
      setFileTypeDisplay(fileTypeDisplay);

      let fileRequestTypeDisplay = response.data.fileRequestTypeDisplay;
      fileRequestTypeDisplay = fileRequestTypeDisplay.sort((a: IFileTypeDisplay, b: IFileTypeDisplay) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IFileTypeDisplay[];
      setFileRequestTypeDisplay(fileRequestTypeDisplay);

      let stages = response.data.stages;
      stages = stages.sort((a: IStage, b: IStage) => {
        if (a.order < b.order) {
          return -1;
        } else if (a.order > b.order) {
          return 1;
        } else {
          return 0;
        }
      }) as IStage[];
      setStages(stages);

      let relationships = response.data.relationshipTypes;
      relationships = relationships.sort((a: IRelationshipType, b: IRelationshipType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IRelationshipType[];
      setRelationshipTypes(relationships);

      let genders = response.data.genders;
      genders = genders.sort((a: IGender, b: IGender) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IGender[];
      setGenders(genders);

      let fns = response.data.yukonFirstNations;
      setYukonFns(fns);

      let communities = response.data.communities;
      communities = communities.sort((a: ICommunity, b: ICommunity) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as ICommunity[];
      setCommunities(communities);

      let frequencies = response.data.frequencies;
      setFrequencies(frequencies);

      let allCategories = response.data.allCategories;
      setAllCategories(allCategories);

      let familyPreservationCategories = response.data.familyPreservationCategories;
      setFamilyPreservationCategories(familyPreservationCategories);

      let birthWorkerCategories = response.data.birthWorkerCategories;
      setBirthWorkerCategories(birthWorkerCategories);

      let jordansPrincipleISCSubmissionCategoriesList = response.data.jordansPrincipleISCSubmissionCategories;
      jordansPrincipleISCSubmissionCategoriesList = jordansPrincipleISCSubmissionCategoriesList.sort(
        (a: IRequestCategory, b: IRequestCategory) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IRequestCategory[];
      setJordansPrincipleISCSubmissionCategories(jordansPrincipleISCSubmissionCategoriesList);

      let jordansPrincipleNOLCategoriesList = response.data.jordansPrincipleNOLCategories;
      jordansPrincipleNOLCategoriesList = jordansPrincipleNOLCategoriesList.sort(
        (a: IRequestCategory, b: IRequestCategory) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IRequestCategory[];
      setJordansPrincipleNOLCategories(jordansPrincipleNOLCategoriesList);

      let jordansPrincipleOtherCategoriesList = response.data.jordansPrincipleOtherCategories;
      jordansPrincipleOtherCategoriesList = jordansPrincipleOtherCategoriesList.sort(
        (a: IRequestCategory, b: IRequestCategory) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IRequestCategory[];
      setJordansPrincipleOtherCategories(jordansPrincipleOtherCategoriesList);

      let allOutcomesList = response.data.allOutcomes;
      allOutcomesList = allOutcomesList.sort((a: IOutcome, b: IOutcome) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IOutcome[];
      setAllOutcomes(allOutcomesList);

      let nonISCOutcomesList = response.data.nonISCOutcomes;
      nonISCOutcomesList = nonISCOutcomesList.sort((a: IOutcome, b: IOutcome) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IOutcome[];
      setNonISCOutcomes(nonISCOutcomesList);

      let notifications = response.data.notificationTypes;
      notifications = notifications.sort((a: INotificationType, b: INotificationType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as INotificationType[];
      setNotificationTypes(notifications);

      let reminderTypes = response.data.reminderTypes;
      reminderTypes = reminderTypes.sort((a: IReminderType, b: IReminderType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IReminderType[];
      setReminderTypes(reminderTypes);

      let serviceTypeList = response.data.familyPreservationServiceTypes;
      serviceTypeList = serviceTypeList.sort((a: IServiceType, b: IServiceType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IServiceType[];
      setFamilyPreservationServiceTypes(serviceTypeList);

      let birthWorkerServiceTypeList = response.data.birthWorkerServiceTypes;
      birthWorkerServiceTypeList = birthWorkerServiceTypeList.sort((a: IServiceType, b: IServiceType) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IServiceType[];
      setBirthWorkerServiceTypes(birthWorkerServiceTypeList);

      let indigenousGroupList = response.data.indigenousGroups;
      indigenousGroupList = indigenousGroupList.sort((a: IIndigenousGroup, b: IIndigenousGroup) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IIndigenousGroup[];
      setIndigenousGroups(indigenousGroupList);

      let courtLocationList = response.data.courtLocations;
      courtLocationList = courtLocationList.sort((a: ICourtLocation, b: ICourtLocation) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as ICourtLocation[];
      setCourtLocations(courtLocationList);

      let sentenceList = response.data.sentenceTypes;
      setSentenceTypes(sentenceList);

      let requestTypesList = response.data.jordansPrincipleRequestTypes;
      setJordansPrincipleRequestTypes(requestTypesList);

      let vendorOrServiceProviderList = response.data.vendorsOrServiceProviders;
      vendorOrServiceProviderList = vendorOrServiceProviderList.sort(
        (a: IVendorOrServiceProvider, b: IVendorOrServiceProvider) =>
          a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IVendorOrServiceProvider[];
      setVendorsOrServiceProviders(vendorOrServiceProviderList);

      let legalSystemNavigatorTypesOfService = response.data.legalSystemNavigatorTypesOfService;
      setLegalSystemNavigatorTypesOfService(legalSystemNavigatorTypesOfService);

      let groupHomesList = response.data.groupHomes;
      groupHomesList = groupHomesList.sort((a: IGroupHome, b: IGroupHome) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IGroupHome[];
      setGroupHomes(groupHomesList);

      let incomeOrEmploymentTypes = response.data.incomeOrEmploymentTypes;
      setIncomeOrEmploymentTypes(incomeOrEmploymentTypes);

      let resourceSupportTypes = response.data.resourceSupportTypes;
      setResourceSupportTypes(resourceSupportTypes);

      setFilesDomainLoaded(true);
    });
    authAxios.get('/api/workshops/domain').then((response) => {
      let themeList = response.data.themes;
      setThemes(themeList);

      let statusList = response.data.statuses;
      setStatuses(statusList);

      let workshopLocationsList = response.data.workshopLocations;
      setWorkshopLocations(workshopLocationsList);

      let attendanceList = response.data.attendance;
      setAttendance(attendanceList);

      let registrationStatusList = response.data.registrationStatuses;
      setRegistrationStatuses(registrationStatusList);

      setWorkshopsDomainLoaded(true);
    });
  }, []);

  useEffect(() => {
    authAxios.get('/api/departments').then((response) => {
      let departments = response.data;
      departments = departments.sort((a: IDepartment, b: IDepartment) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      ) as IDepartment[];
      setDepartments(departments);
      setDepartmentsLoaded(true);
    });

    authAxios.get('/api/gladue-members').then((response) => {
      let members = response.data;
      members = members.sort((a: IGladueMember, b: IGladueMember) =>
        a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())
      ) as IGladueMember[];
      setGladueMembers(members);
      setGladuesLoaded(true);
    });

    authAxios.get('/api/users/roles').then((response) => {
      const roles: [] = response.data;
      setRoles(roles);
      setRolesLoaded(true);
    });

    authAxios.get('/api/documents/document-types').then((response) => {
      if (response.data) {
        setFileAndClientDocumentTypes(response.data.fileAndClientDocumentTypes);
        setWorkshopDocumentTypes(response.data.workshopDocumentTypes);
        setAllDocumentTypes(response.data.allDocumentTypes);
      }
    });

    authAxios.get('/api/charges').then((response) => {
      if (response.data) {
        setCharges(
          response.data.sort((a: ICharge, b: ICharge) =>
            a.criminalSectionCode.toUpperCase().localeCompare(b.criminalSectionCode.toUpperCase())
          ) as ICharge[]
        );
        setChargesLoaded(true);
      }
    });
  }, []);

  return (
    <DomainContext.Provider
      value={{
        loaded,
        departments,
        setDepartments,
        fileTypes,
        fileSubTypes,
        stages,
        relationshipTypes,
        roles,
        genders,
        yukonFns,
        communities,
        frequencies,
        allCategories,
        familyPreservationCategories,
        birthWorkerCategories,
        jordansPrincipleISCSubmissionCategories,
        jordansPrincipleNOLCategories,
        jordansPrincipleOtherCategories,
        allOutcomes,
        nonISCOutcomes,
        allDocumentTypes,
        fileAndClientDocumentTypes,
        workshopDocumentTypes,
        notificationTypes,
        reminderTypes,
        familyPreservationServiceTypes,
        birthWorkerServiceTypes,
        fileTypeDisplay,
        fileRequestTypeDisplay,
        gladueMembers,
        indigenousGroups,
        courtLocations,
        sentenceTypes,
        jordansPrincipleRequestTypes,
        themes,
        vendorsOrServiceProviders,
        statuses,
        workshopLocations,
        legalSystemNavigatorTypesOfService,
        attendance,
        registrationStatuses,
        charges,
        setCharges,
        groupHomes,
        incomeOrEmploymentTypes,
        resourceSupportTypes,
      }}
    >
      {props.children}
    </DomainContext.Provider>
  );
};

export const DomainConsumer = DomainContext.Consumer;
