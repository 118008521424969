import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  CustomInput,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import {
  DomainContext,
  FILE_SUB_TYPE_COMMUNITY_IMPACT,
  FILE_SUB_TYPE_GLADUE_REPORT,
  FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR,
  FILE_SUB_TYPE_WCC_CONNECTIVE,
  FILE_TYPE_BIRTH_WORKER,
  FILE_TYPE_CULTURAL_CONNECTIONS,
  FILE_TYPE_FAMILY_PRESERVATION,
  FILE_TYPE_INTAKE,
  FILE_TYPE_JORDANS_PRINCIPLE,
  FILE_TYPE_JUSTICE,
  getPreviousBusinessDay,
  IFileType,
  JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE,
  JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER,
  NOTIFICATION_TYPE_EMAIL,
  NOTIFICATION_TYPE_ON_SCREEN,
  REMINDER_TYPE_COURT_DATE,
  REMINDER_TYPE_SENTENCING_DATE,
  REQUEST_CATEGORY_OTHER,
  STAGE_DRAFT,
  STAGE_SUBMITTED,
} from '../../contexts/DomainContext';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { FileContext, ICourtDate } from './FileContext';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';

import { Requests } from './Actions/Requests';
import { UsersContext } from '../../contexts/UsersContext';
import { IUser } from '../Users/UsersTable';
import { IReminder } from './FileReminders';
import { getUserId } from '../../services/AuthenticationService';
import { Referrals } from './FileReferrals';
import { ServicesProvided } from './FileServicesProvided';
import { JordansPrincipleFileDetails } from './JordansPrincipleFileDetails';
import { FamilyPreservationFileDetails } from './FamilyPreservationFileDetails';
import { WCCConnectiveFileDetails } from './WCCConnectiveFileDetails';
import { CommunityImpactFileDetails } from './CommunityImpactFileDetails';
import { GladueReportFileDetails } from './GladueReportFileDetails';
import { MyUserContext } from '../../contexts/MyUserContext';
import { CulturalConnectionsFileDetails } from './CulturalConnectionsFileDetails';
import { BirthWorkerFileDetails } from './BirthWorkerFileDetails';
import { LegalSystemNavigatorFileDetails } from './LegalSystemNavigatorFileDetails';
import { formatDate } from '../../formatters/DateTimeFormatter';
import { ConfidentialityModals } from '../../components/Files/Actions/ConfidentialityModals';
import { IntakeFileDetails } from '../../components/Files/IntakeFileDetails';
import { IncomeOrEmployments } from '../../components/Files/FileIncomeOrEmployments';
import { ResourceSupports } from '../../components/Files/FileResourceSupports';

export const FileDetails: React.FC = () => {
  const domainContext = useContext(DomainContext);
  const fileContext = useContext(FileContext);
  const usersContext = useContext(UsersContext);
  const myUserContext = useContext(MyUserContext);

  const fileTypes = domainContext.fileTypes;

  const staff = usersContext.users;
  const [selectedStaff, setSelectedStaff] = useState<IUser[]>([]);

  const [trySave, setTrySave] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [activeReminders, setActiveReminders] = useState<IReminder[]>([]);

  const [updateReminder, setUpdateReminder] = useState(false);
  const [intakeFileStageChangedToCompleted, setIntakeFileStageChangedToCompleted] = useState<boolean>(false);

  const [openCheckConfidentialityModal, setOpenCheckConfidentialityModal] = useState(false);
  const [openUncheckConfidentialityModal, setOpenUncheckConfidentialityModal] = useState(false);
  const [openStageCompletedModal, setOpenStageCompletedModal] = useState<boolean>(false);

  const [openMissingRequestInfoModal, setOpenMissingRequestInfoModal] = useState(false);

  //JP Request fields
  const numberOfChildrenMissing =
    fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE &&
    fileContext.stageId !== STAGE_DRAFT &&
    (fileContext.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE ||
      fileContext.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER) &&
    fileContext.requests.filter((r) => !r.numberOfChildren).length > 0;
  const categoryMissing =
    fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE &&
    fileContext.stageId !== STAGE_DRAFT &&
    fileContext.requests.filter((r) => !r.categoryId).length > 0;

  //gladue fields
  const [gladueSentencingDateChanged, setGladueSentencingDateChanged] = useState(false);
  const [crownLawyerChanged, setCrownLawyerChanged] = useState(false);
  const [defenceLawyerChanged, setDefenceLawyerChanged] = useState(false);
  const [openLogGladueChangeModal, setOpenLogGladueChangeModal] = useState(false);
  const [logGladueSentencingDateChange, setLogGladueSentencingDateChange] = useState(false);
  const [logCrownLawyerChange, setLogCrownLawyerChange] = useState(false);
  const [logDefenceLawyerChange, setLogDefenceLawyerChange] = useState(false);

  //family preservation fields
  const [openLogFPChangeModal, setOpenLogFPChangeModal] = useState(false);
  const [logFCSAssignedSocialWorkerChange, setLogFCSAssignedSocialWorkerChange] = useState(false);
  const [fcsAssignedSocialWorkerChanged, setFCSAssignedSocialWorkerChanged] = useState(false);

  const history = useHistory();
  const params = useParams<{ fileId: string }>();
  const location = useLocation<{
    dutyCounsel?: boolean;
    counselRequest?: boolean;
    overrideReload?: boolean;
    createClientId?: string;
    fileTypeId?: string;
  }>();

  // const [isEditingAllowed, setIsEditingAllowed] = useState(
  //   params.fileId ? editableStages.includes(file.stageId) : true
  // );
  const [isEditingAllowed, setIsEditingAllowed] = useState(true);

  const [moveToApplicationModal, setMoveToApplicationModal] = useState(false);

  useEffect(() => {
    if (location.state?.fileTypeId) {
      fileContext.setFileTypeId(location.state.fileTypeId);
      if (location.state?.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION) {
        fileContext.setStageId(STAGE_DRAFT);
      }
    }
  }, []);

  useEffect(() => {
    setSelectedStaff(staff.filter((s) => fileContext.staffIds.includes(s.userId)));
  }, [staff, fileContext.staffIds]);

  useEffect(() => {
    if (location.state?.createClientId) {
      fileContext.setClientIds((pids) => [
        ...pids,
        location.state?.createClientId ? location.state?.createClientId : '',
      ]);
      fileContext.setFile((f) => ({
        ...f,
        clientIds: location.state?.createClientId ? f.clientIds.concat([location.state?.createClientId]) : f.clientIds,
      }));
      history.replace(history.location.pathname, { createClientId: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveReminders(
      fileContext.reminders.filter((r: IReminder) =>
        r.date
          ? r.date <= new Date().getTime() &&
            !r.resolved &&
            r.staffToNotify.includes(getUserId()) &&
            r.notificationTypeIds.includes(NOTIFICATION_TYPE_ON_SCREEN)
          : false
      )
    );
  }, [fileContext.reminders]);

  useEffect(() => {
    // setIsEditingAllowed(params.fileId ? editableStages.includes(file.stageId) : true);
    setIsEditingAllowed(true);
  }, []);

  const saveFile = (
    logGladueSentencingDateChange?: boolean,
    logCrownLawyerChange?: boolean,
    logDefenceLawyerChange?: boolean,
    logFCSAssignedSocialWorkerChange?: boolean
  ) => {
    if (
      fileContext.fileTypeId &&
      fileContext.stageId &&
      fileContext.createdDate &&
      !fileContext.requests.find((r) => r.categoryId === REQUEST_CATEGORY_OTHER && r.description === '')
    ) {
      if (
        fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE &&
        !fileContext.jordansPrincipleFields?.requestTypeId
      ) {
        setTrySave(true);
      } else if (params.fileId) {
        const data = fileContext.getEditData();

        authAxios.post('/api/files/update', data).then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('File updated.');
            // if (!editableStages.includes(file.stageId)) {
            //   setIsEditingAllowed(false);
            // }
            fileContext.setFileFromAttributes();
          } else {
            notifyError(response.data.errorMessage);
          }
        });
        if (fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION) {
          const FPData = fileContext.getFamilyPreservationData(params.fileId);
          authAxios
            .post('/api/files/update-family-preservation', {
              ...FPData,
              logFCSAssignedSocialWorkerChange: logFCSAssignedSocialWorkerChange
                ? logFCSAssignedSocialWorkerChange
                : false,
            })
            .then((response) => {
              if (response.data.status === 'OK') {
                fileContext.setRequests(response.data.requests);
                console.log('FP updated :)');
              } else {
                console.log('FP not updated :(');
              }
            });
        } else if (fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE) {
          const JPData = fileContext.getJordansPrincipleData(params.fileId);
          authAxios.post('/api/files/update-jordans-principle', JPData).then((response) => {
            if (response.data.status === 'OK') {
              fileContext.setRequests(response.data.requests);
              console.log('JP updated :)');
            } else {
              console.log('JP not updated :(');
            }
          });
        } else if (fileContext.fileTypeId === FILE_TYPE_INTAKE) {
          const INData = fileContext.getIntakeData(params.fileId);
          authAxios.post('/api/files/update-intake', INData).then((response) => {
            if (response.data.status === 'OK') {
              console.log('IN updated :)');
            } else {
              console.log('IN not updated :(');
            }
          });
        } else if (fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER) {
          const BWData = fileContext.getBirthWorkersData(params.fileId);
          authAxios.post('/api/files/update-birth-workers', BWData).then((response) => {
            if (response.data.status === 'OK') {
              console.log('BW updated :)');
            } else {
              console.log('BW not updated :(');
            }
          });
        } else if (fileContext.fileSubTypeId === FILE_SUB_TYPE_WCC_CONNECTIVE) {
          const WCCCData = fileContext.getWCCConnectiveData(params.fileId);
          authAxios.post('/api/files/update-wcc-connective', WCCCData).then((response) => {
            if (response.data.status === 'OK') {
              if (updateReminder) {
                const updatedSentencingDate = new Date(WCCCData.sentencingDate);
                const businessDay = getPreviousBusinessDay(updatedSentencingDate);
                businessDay.setHours(7, 0, 0);

                // update or create reminders for selected staff
                selectedStaff.forEach((staff) => {
                  const sdReminder = fileContext.reminders.find(
                    (r) => r.staffToNotify.includes(staff.userId) && r.reminderTypeId === REMINDER_TYPE_SENTENCING_DATE
                  );

                  if (sdReminder) {
                    authAxios
                      .post('/api/files/update-reminder', {
                        fileId: params.fileId,
                        reminderId: sdReminder.reminderId,
                        text: 'Sentencing Date',
                        //7am of sentencing date
                        date: businessDay.getTime(),
                        staffToNotify: sdReminder.staffToNotify,
                        notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                        reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                      })
                      .then((response) => {
                        if (response.data.status === 'OK') {
                          notifySuccess('Reminder updated.');
                          //update fileContext with updated reminder
                          const rIndex: number = fileContext.reminders.findIndex(
                            (r) => r.reminderId === response.data.noteId
                          );
                          fileContext.reminders[rIndex].staffToNotify = sdReminder.staffToNotify;
                          fileContext.reminders[rIndex].date = businessDay.getTime();
                          fileContext.setReminders(fileContext.reminders);
                        } else {
                          notifyError('Error updating reminder.');
                        }
                      });
                  } else {
                    authAxios
                      .post('/api/files/create-reminder', {
                        fileId: params.fileId,
                        text: 'Sentencing Date',
                        date: businessDay.getTime(),
                        staffToNotify: [staff.userId],
                        notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                        reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                      })
                      .then((response) => {
                        if (response.data.status === 'OK') {
                          notifySuccess('Reminder created.');
                          fileContext.setReminders((r) =>
                            r.concat([
                              {
                                reminderId: response.data.reminderId,
                                text: 'Sentencing Date',
                                date: businessDay.getTime(),
                                staffToNotify: [staff.userId],
                                notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                                reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                                resolved: false,
                              },
                            ])
                          );
                        } else {
                          notifyError('Error creating Reminder.');
                        }
                      });
                  }
                });

                // delete Sentencing Date Reminders for staff that are no longer in selected staff
                fileContext.reminders.forEach((r) => {
                  if (
                    r.reminderTypeId === REMINDER_TYPE_SENTENCING_DATE &&
                    !r.staffToNotify.every((id) => selectedStaff.some((s) => s.userId === id))
                  ) {
                    authAxios
                      .post('/api/files/delete-reminder', { fileId: params.fileId, reminderId: r.reminderId })
                      .then((response) => {
                        if (response.data.status === 'OK') {
                          setIsDirty(false);
                          notifySuccess('Reminder deleted.');
                          fileContext.setReminders((rArray) => rArray.filter((rem) => rem.reminderId !== r.reminderId));
                        } else {
                          notifyError('Error deleting reminder.');
                        }
                      });
                  }
                });
              }
              console.log('WCC updated :)');
            } else {
              console.log('WCC not updated :(');
            }
          });
        } else if (fileContext.fileSubTypeId === FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR) {
          let LSNData = fileContext.getLegalSystemNavigatorData(params.fileId);
          LSNData.courtDatesList = LSNData.courtDatesList?.sort((a: ICourtDate, b: ICourtDate) => {
            if (a.date && b.date) {
              return a.date - b.date;
            } else {
              if (a.date === (0 || undefined)) {
                return 1;
              }
              if (b.date === (0 || undefined)) {
                return -1;
              }
              return 0;
            }
          });
          authAxios.post('/api/files/update-legal-system-navigator', LSNData).then((response) => {
            if (response.data.status === 'OK') {
              fileContext.setLegalSystemNavigatorFields(LSNData);
              if (updateReminder) {
                let courtDatesUpdateSuccess = true;
                response.data.courtDatesList.forEach((c: ICourtDate) => {
                  if (c.date && c.addReminder) {
                    const updatedCourtDate = new Date(c.date);
                    const businessDay = getPreviousBusinessDay(updatedCourtDate);
                    businessDay.setHours(7, 0, 0);

                    // update or create reminders for selected staff
                    selectedStaff.forEach((staff) => {
                      const cdReminder = fileContext.reminders.find(
                        (r) =>
                          r.staffToNotify.includes(staff.userId) &&
                          r.reminderTypeId === REMINDER_TYPE_COURT_DATE &&
                          r.courtDateId === c.courtDateId
                      );

                      if (cdReminder && businessDay.getTime() !== cdReminder.date) {
                        authAxios
                          .post('/api/files/update-reminder', {
                            fileId: params.fileId,
                            reminderId: cdReminder.reminderId,
                            text: 'Court Date on: ' + formatDate(updatedCourtDate),
                            //7am of court date
                            date: businessDay.getTime(),
                            staffToNotify: cdReminder.staffToNotify,
                            notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                            reminderTypeId: REMINDER_TYPE_COURT_DATE,
                            courtDateId: c.courtDateId,
                          })
                          .then((response) => {
                            if (response.data.status === 'OK') {
                              //update fileContext with updated reminder
                              const rIndex: number = fileContext.reminders.findIndex(
                                (r) => r.reminderId === response.data.noteId
                              );
                              fileContext.reminders[rIndex].staffToNotify = cdReminder.staffToNotify;
                              fileContext.reminders[rIndex].text = 'Court Date on: ' + formatDate(updatedCourtDate);
                              fileContext.reminders[rIndex].date = businessDay.getTime();
                              fileContext.setReminders(fileContext.reminders);
                            } else {
                              courtDatesUpdateSuccess = false;
                            }
                          });
                      } else if (!cdReminder) {
                        authAxios
                          .post('/api/files/create-reminder', {
                            fileId: params.fileId,
                            text: 'Court Date on: ' + formatDate(updatedCourtDate),
                            date: businessDay.getTime(),
                            staffToNotify: [staff.userId],
                            notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                            reminderTypeId: REMINDER_TYPE_COURT_DATE,
                            courtDateId: c.courtDateId,
                          })
                          .then((response) => {
                            if (response.data.status === 'OK') {
                              fileContext.setReminders((r) =>
                                r.concat([
                                  {
                                    reminderId: response.data.reminderId,
                                    text: 'Court Date on: ' + formatDate(updatedCourtDate),
                                    date: businessDay.getTime(),
                                    staffToNotify: [staff.userId],
                                    notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                                    reminderTypeId: REMINDER_TYPE_COURT_DATE,
                                    resolved: false,
                                    courtDateId: c.courtDateId,
                                  },
                                ])
                              );
                            } else {
                              courtDatesUpdateSuccess = false;
                            }
                          });
                      }
                    });

                    // delete Court Date Reminders for staff that are no longer in selected staff
                    fileContext.reminders.forEach((r) => {
                      if (
                        r.reminderTypeId === REMINDER_TYPE_COURT_DATE &&
                        !r.staffToNotify.every((id) => selectedStaff.some((s) => s.userId === id))
                      ) {
                        authAxios
                          .post('/api/files/delete-reminder', { fileId: params.fileId, reminderId: r.reminderId })
                          .then((response) => {
                            if (response.data.status === 'OK') {
                              setIsDirty(false);
                              fileContext.setReminders((rArray) =>
                                rArray.filter((rem) => rem.reminderId !== r.reminderId)
                              );
                            } else {
                              courtDatesUpdateSuccess = false;
                            }
                          });
                      }
                    });
                  }
                });
                // delete Court Date Reminders for CourtDate objects that no longer exist,
                // had their date field cleared or had their Reminder checkbox unchecked
                fileContext.reminders.forEach((r) => {
                  if (
                    r.reminderTypeId === REMINDER_TYPE_COURT_DATE &&
                    (!response.data.courtDatesList.some((cd: ICourtDate) => cd.courtDateId === r.courtDateId) ||
                      response.data.courtDatesList.find(
                        (cd: ICourtDate) => cd.courtDateId === r.courtDateId && (!cd.date || !cd.addReminder)
                      ))
                  ) {
                    authAxios
                      .post('/api/files/delete-reminder', { fileId: params.fileId, reminderId: r.reminderId })
                      .then((response) => {
                        if (response.data.status === 'OK') {
                          setIsDirty(false);
                          fileContext.setReminders((rArray) => rArray.filter((rem) => rem.reminderId !== r.reminderId));
                        } else {
                          courtDatesUpdateSuccess = false;
                        }
                      });
                  }
                });
                fileContext.setCourtDatesList(response.data.courtDatesList);
                if (courtDatesUpdateSuccess) {
                  notifySuccess('Court date reminders updated');
                } else {
                  notifyError('Court date reminders not updated');
                }
              }
              console.log('LSN updated :)');
            } else {
              console.log('LSN not updated :(');
            }
          });
        } else if (fileContext.fileSubTypeId === FILE_SUB_TYPE_GLADUE_REPORT) {
          const GRData = fileContext.getGladueReportData(params.fileId);
          if (GRData.requestedDate === undefined) {
            GRData.requestedDate = fileContext.createdDate.getTime();
          }
          authAxios
            .post('/api/files/update-gladue-report', {
              ...GRData,
              logGladueSentencingDateChange: logGladueSentencingDateChange ? logGladueSentencingDateChange : false,
              logCrownLawyerChange: logCrownLawyerChange ? logCrownLawyerChange : false,
              logDefenceLawyerChange: logDefenceLawyerChange ? logDefenceLawyerChange : false,
            })
            .then((response) => {
              if (response.data.status === 'OK') {
                fileContext.setOriginalGladueReportFields(GRData);
                if (updateReminder) {
                  const updatedSentencingDate = new Date(GRData.sentencingDate);
                  const businessDay = getPreviousBusinessDay(updatedSentencingDate);
                  businessDay.setHours(7, 0, 0);

                  // update or create reminders for selected staff
                  selectedStaff.forEach((staff) => {
                    const sdReminder = fileContext.reminders.find(
                      (r) =>
                        r.staffToNotify.includes(staff.userId) && r.reminderTypeId === REMINDER_TYPE_SENTENCING_DATE
                    );

                    if (sdReminder) {
                      authAxios
                        .post('/api/files/update-reminder', {
                          fileId: params.fileId,
                          reminderId: sdReminder.reminderId,
                          text: 'Sentencing Date',
                          //7am of sentencing date
                          date: businessDay.getTime(),
                          staffToNotify: sdReminder.staffToNotify,
                          notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                          reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                        })
                        .then((response) => {
                          if (response.data.status === 'OK') {
                            notifySuccess('Reminder updated.');
                            //update fileContext with updated reminder
                            const rIndex: number = fileContext.reminders.findIndex(
                              (r) => r.reminderId === response.data.noteId
                            );
                            fileContext.reminders[rIndex].staffToNotify = sdReminder.staffToNotify;
                            fileContext.reminders[rIndex].date = businessDay.getTime();
                            fileContext.setReminders(fileContext.reminders);
                          } else {
                            notifyError('Error updating reminder.');
                          }
                        });
                    } else {
                      authAxios
                        .post('/api/files/create-reminder', {
                          fileId: params.fileId,
                          text: 'Sentencing Date',
                          date: businessDay.getTime(),
                          staffToNotify: [staff.userId],
                          notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                          reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                        })
                        .then((response) => {
                          if (response.data.status === 'OK') {
                            notifySuccess('Reminder created.');
                            fileContext.setReminders((r) =>
                              r.concat([
                                {
                                  reminderId: response.data.reminderId,
                                  text: 'Sentencing Date',
                                  date: businessDay.getTime(),
                                  staffToNotify: [staff.userId],
                                  notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                                  reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                                  resolved: false,
                                },
                              ])
                            );
                          } else {
                            notifyError('Error creating Reminder.');
                          }
                        });
                    }
                  });

                  // delete Sentencing Date Reminders for staff that are no longer in selected staff
                  fileContext.reminders.forEach((r) => {
                    if (
                      r.reminderTypeId === REMINDER_TYPE_SENTENCING_DATE &&
                      !r.staffToNotify.every((id) => selectedStaff.some((s) => s.userId === id))
                    ) {
                      authAxios
                        .post('/api/files/delete-reminder', { fileId: params.fileId, reminderId: r.reminderId })
                        .then((response) => {
                          if (response.data.status === 'OK') {
                            setIsDirty(false);
                            notifySuccess('Reminder deleted.');
                            fileContext.setReminders((rArray) =>
                              rArray.filter((rem) => rem.reminderId !== r.reminderId)
                            );
                          } else {
                            notifyError('Error deleting reminder.');
                          }
                        });
                    }
                  });
                }
                console.log('GR updated :)');
              } else {
                console.log('GR not updated :(');
              }
            });
        } else if (fileContext.fileSubTypeId === FILE_SUB_TYPE_COMMUNITY_IMPACT) {
          const ciData = fileContext.getCommunityImpactData(params.fileId);
          authAxios.post('/api/files/update-community-impact', ciData).then((response) => {
            if (response.data.status === 'OK') {
              if (updateReminder) {
                const updatedSentencingDate = new Date(ciData.sentencingDate);
                const businessDay = getPreviousBusinessDay(updatedSentencingDate);
                businessDay.setHours(7, 0, 0);

                // update or create reminders for selected staff
                selectedStaff.forEach((staff) => {
                  const sdReminder = fileContext.reminders.find(
                    (r) => r.staffToNotify.includes(staff.userId) && r.reminderTypeId === REMINDER_TYPE_SENTENCING_DATE
                  );

                  if (sdReminder) {
                    authAxios
                      .post('/api/files/update-reminder', {
                        fileId: params.fileId,
                        reminderId: sdReminder.reminderId,
                        text: 'Sentencing Date',
                        //7am of sentencing date
                        date: businessDay.getTime(),
                        staffToNotify: sdReminder.staffToNotify,
                        notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                        reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                      })
                      .then((response) => {
                        if (response.data.status === 'OK') {
                          notifySuccess('Reminder updated.');
                          //update fileContext with updated reminder
                          const rIndex: number = fileContext.reminders.findIndex(
                            (r) => r.reminderId === response.data.noteId
                          );
                          fileContext.reminders[rIndex].staffToNotify = sdReminder.staffToNotify;
                          fileContext.reminders[rIndex].date = businessDay.getTime();
                          fileContext.setReminders(fileContext.reminders);
                        } else {
                          notifyError('Error updating reminder.');
                        }
                      });
                  } else {
                    authAxios
                      .post('/api/files/create-reminder', {
                        fileId: params.fileId,
                        text: 'Sentencing Date',
                        date: businessDay.getTime(),
                        staffToNotify: [staff.userId],
                        notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                        reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                      })
                      .then((response) => {
                        if (response.data.status === 'OK') {
                          notifySuccess('Reminder created.');
                          fileContext.setReminders((r) =>
                            r.concat([
                              {
                                reminderId: response.data.reminderId,
                                text: 'Sentencing Date',
                                date: businessDay.getTime(),
                                staffToNotify: [staff.userId],
                                notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                                reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                                resolved: false,
                              },
                            ])
                          );
                        } else {
                          notifyError('Error creating Reminder.');
                        }
                      });
                  }
                });

                // delete Sentencing Date Reminders for staff that are no longer in selected staff
                fileContext.reminders.forEach((r) => {
                  if (
                    r.reminderTypeId === REMINDER_TYPE_SENTENCING_DATE &&
                    !r.staffToNotify.every((id) => selectedStaff.some((s) => s.userId === id))
                  ) {
                    authAxios
                      .post('/api/files/delete-reminder', { fileId: params.fileId, reminderId: r.reminderId })
                      .then((response) => {
                        if (response.data.status === 'OK') {
                          setIsDirty(false);
                          notifySuccess('Reminder deleted.');
                          fileContext.setReminders((rArray) => rArray.filter((rem) => rem.reminderId !== r.reminderId));
                        } else {
                          notifyError('Error deleting reminder.');
                        }
                      });
                  }
                });
              }
              console.log('Community Impact updated :)');
            } else {
              console.log('Community Impact not updated :(');
            }
          });
        }
      }
      setGladueSentencingDateChanged(false);
      setCrownLawyerChanged(false);
      setDefenceLawyerChanged(false);
      setUpdateReminder(false);
      setFCSAssignedSocialWorkerChanged(false);
    } else {
      setTrySave(true);
    }
  };

  const moveToApplication = () => {
    const stageId = STAGE_SUBMITTED;
    if (params.fileId) {
      const data = { fileId: params.fileId, stageId };
      authAxios.post('/api/files/admin/start-stage', data).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File stage has been updated.');
          fileContext.setFile((f) => ({ ...f, stageId: stageId }));
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  const deleteFile = () => {
    if (params.fileId) {
      authAxios.post('/api/files/admin/delete-file/' + params.fileId).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File has been deleted.');
          history.push({ pathname: '/files', state: { deletedFileId: params.fileId } });
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  const resolveReminder = (reminderId: string) => {
    authAxios
      .post('/api/files/resolve-reminder', { fileId: params.fileId, reminderId: reminderId })
      .then((response) => {
        if (response.data.status === 'OK') {
          setActiveReminders((r) => r.filter((reminder) => reminder.reminderId !== reminderId));
          fileContext.setReminders((reminderList) =>
            reminderList.map((r) => {
              if (r.reminderId === reminderId) {
                return { ...r, resolved: true } as IReminder;
              } else {
                return r;
              }
            })
          );
        }
      });
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom d-flex flex-wrap sticky-top'}>
          <NavbarBrand className={'w-mobile-100'}>
            {params.fileId
              ? 'Edit File Information'
              : 'New' +
                (fileContext.fileTypeId
                  ? ' ' + fileTypes.find((ft: IFileType) => ft.fileTypeId === fileContext.fileTypeId)?.name
                  : '') +
                ' File'}
          </NavbarBrand>
          <div
            className={'d-flex flex-grow-1 justify-content-end flex-wrap navbar-actions'}
            style={{ rowGap: '0.5rem', columnGap: '0.5rem' }}
          >
            {myUserContext.isSuperAdmin && (
              <Button color='danger' className={'w-mobile-100'} onClick={() => setOpenDeleteModal(true)}>
                Delete File
              </Button>
            )}
            {params.fileId && (
              <Button
                color={'primary'}
                className={'w-mobile-100'}
                onClick={() => {
                  if (
                    fileContext.fileSubTypeId === FILE_SUB_TYPE_GLADUE_REPORT &&
                    (gladueSentencingDateChanged || defenceLawyerChanged || crownLawyerChanged)
                  ) {
                    if (gladueSentencingDateChanged) {
                      setLogGladueSentencingDateChange(true);
                    }
                    if (defenceLawyerChanged) {
                      setLogDefenceLawyerChange(true);
                    }
                    if (crownLawyerChanged) {
                      setLogCrownLawyerChange(true);
                    }
                    setOpenLogGladueChangeModal(true);
                  } else if (
                    fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION &&
                    fcsAssignedSocialWorkerChanged
                  ) {
                    if (fcsAssignedSocialWorkerChanged) {
                      setLogFCSAssignedSocialWorkerChange(true);
                    }
                    setOpenLogFPChangeModal(true);
                  } else if (categoryMissing || numberOfChildrenMissing) {
                    setOpenMissingRequestInfoModal(true);
                    setTrySave(true);
                  } else if (fileContext.fileTypeId === FILE_TYPE_INTAKE && intakeFileStageChangedToCompleted) {
                    setOpenStageCompletedModal(true);
                  } else {
                    saveFile();
                  }
                }}
              >
                Save File
              </Button>
            )}
            {!params.fileId && (
              <>
                <Button color={'link'} className={'w-mobile-100'} onClick={() => history.push('/files')}>
                  Cancel
                </Button>
                <Button color={'primary'} className={'w-mobile-100'} onClick={() => saveFile()}>
                  Save File
                </Button>
              </>
            )}
          </div>
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          {activeReminders.map((r) => (
            <Alert color='warning' isOpen={true} key={r.reminderId}>
              <div className={'d-flex'}>
                REMINDER: {r.text}
                <Button className={'ml-auto p-0'} color={'link'} onClick={() => resolveReminder(r.reminderId)}>
                  Resolve
                </Button>
              </div>
            </Alert>
          ))}
          {fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE && (
            <JordansPrincipleFileDetails
              setIsDirty={setIsDirty}
              isEditingAllowed={isEditingAllowed}
              trySave={trySave}
              setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
              setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
            />
          )}
          {fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION && (
            <FamilyPreservationFileDetails
              setIsDirty={setIsDirty}
              isEditingAllowed={isEditingAllowed}
              trySave={trySave}
              setFCSAssignedSocialWorkerChanged={setFCSAssignedSocialWorkerChanged}
              setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
              setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
            />
          )}
          {fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && (
            <BirthWorkerFileDetails
              setIsDirty={setIsDirty}
              isEditingAllowed={isEditingAllowed}
              trySave={trySave}
              setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
              setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
            />
          )}
          {fileContext.fileTypeId === FILE_TYPE_CULTURAL_CONNECTIONS && (
            <CulturalConnectionsFileDetails
              setIsDirty={setIsDirty}
              isEditingAllowed={isEditingAllowed}
              trySave={trySave}
              setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
              setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
            />
          )}
          {fileContext.fileTypeId === FILE_TYPE_INTAKE && (
            <IntakeFileDetails
              setIsDirty={setIsDirty}
              isEditingAllowed={isEditingAllowed}
              trySave={trySave}
              setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
              setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
              setIntakeFileStageChangedToCompleted={setIntakeFileStageChangedToCompleted}
            />
          )}
          {fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
            fileContext.fileSubTypeId === FILE_SUB_TYPE_GLADUE_REPORT && (
              <GladueReportFileDetails
                setGladueSentencingDateChanged={setGladueSentencingDateChanged}
                setCrownLawyerChanged={setCrownLawyerChanged}
                setDefenceLawyerChanged={setDefenceLawyerChanged}
                setIsDirty={setIsDirty}
                isEditingAllowed={isEditingAllowed}
                trySave={trySave}
                setUpdateReminder={setUpdateReminder}
                setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
                setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
              />
            )}
          {fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
            fileContext.fileSubTypeId === FILE_SUB_TYPE_WCC_CONNECTIVE && (
              <WCCConnectiveFileDetails
                setIsDirty={setIsDirty}
                isEditingAllowed={isEditingAllowed}
                trySave={trySave}
                setUpdateReminder={setUpdateReminder}
                setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
                setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
              />
            )}
          {fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
            fileContext.fileSubTypeId === FILE_SUB_TYPE_COMMUNITY_IMPACT && (
              <CommunityImpactFileDetails
                setIsDirty={setIsDirty}
                isEditingAllowed={isEditingAllowed}
                trySave={trySave}
                setUpdateReminder={setUpdateReminder}
                setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
                setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
              />
            )}
          {fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
            fileContext.fileSubTypeId === FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR && (
              <LegalSystemNavigatorFileDetails
                setIsDirty={setIsDirty}
                isEditingAllowed={isEditingAllowed}
                trySave={trySave}
                setUpdateReminder={setUpdateReminder}
                setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
                setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
              />
            )}
          {(fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION ||
            fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER ||
            (fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
              !(
                fileContext.fileSubTypeId === FILE_SUB_TYPE_GLADUE_REPORT ||
                fileContext.fileSubTypeId === FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR
              ))) && (
            <Row className={'mb-3'}>
              <Col>
                <Referrals setIsDirty={setIsDirty} fromCYFN={false} />
              </Col>
            </Row>
          )}
          {fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && (
            <Row className={'mb-3'}>
              <Col>
                <Referrals setIsDirty={setIsDirty} fromCYFN={true} />
              </Col>
            </Row>
          )}
          {fileContext.fileTypeId === FILE_TYPE_INTAKE && (
            <Row className={'mb-3'}>
              <Col className={'mb-2'} lg={6} md={12}>
                <IncomeOrEmployments setIsDirty={setIsDirty} isEditingAllowed={true} />
              </Col>
              <Col lg={6} md={12}>
                <ResourceSupports setIsDirty={setIsDirty} isEditingAllowed={true} />
              </Col>
            </Row>
          )}
          {(fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION ||
            fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER) && (
            <Row className={'mb-3'}>
              <Col>
                <ServicesProvided setIsDirty={setIsDirty} />
              </Col>
            </Row>
          )}
          {(fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE ||
            fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION ||
            fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER) && (
            <Row>
              <Col>
                <Requests setIsDirty={setIsDirty} isEditingAllowed={isEditingAllowed} trySave={trySave} />
              </Col>
            </Row>
          )}
        </Container>
      </form>
      <Modal isOpen={openDeleteModal} toggle={() => setOpenDeleteModal(!openDeleteModal)} className='logout-modal'>
        <ModalHeader toggle={() => setOpenDeleteModal(!openDeleteModal)}>Delete File</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            Are you sure you want to delete this file and all associated case notes, documents and reminders? This
            action cannot be undone.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              deleteFile();
              setOpenDeleteModal(false);
            }}
          >
            Delete File
          </Button>
          <Button color={'light'} onClick={() => setOpenDeleteModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={moveToApplicationModal}
        toggle={() => setMoveToApplicationModal(!moveToApplicationModal)}
        className='logout-modal'
      >
        <ModalHeader toggle={() => setMoveToApplicationModal(!moveToApplicationModal)}>Move To Application</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>Are you sure you want to move this duty counsel file to an application?</div>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'primary'}
            onClick={() => {
              moveToApplication();
              setMoveToApplicationModal(false);
            }}
          >
            Move To Application
          </Button>
          <Button color={'light'} onClick={() => setMoveToApplicationModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={openLogGladueChangeModal}
        toggle={() => setOpenLogGladueChangeModal(!openLogGladueChangeModal)}
        className='logout-modal'
      >
        <ModalHeader toggle={() => setOpenLogGladueChangeModal(!openLogGladueChangeModal)}>Log Change</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            The sentencing date and/or lawyer information for this file have been changed. Select which of the changes
            below should be recorded in the Activity Log for this file. If you are simply making a correction, do not
            log the change.
          </div>
          {gladueSentencingDateChanged && (
            <FormGroup>
              <CustomInput
                type='checkbox'
                id={'logGladueSentencingDateChange'}
                name={'logGladueSentencingDateChange'}
                label={'Log Sentencing Date Change'}
                checked={logGladueSentencingDateChange}
                onChange={() => {
                  setLogGladueSentencingDateChange((s) => !s);
                }}
              />
            </FormGroup>
          )}
          {crownLawyerChanged && (
            <FormGroup>
              <CustomInput
                type='checkbox'
                id={'logCrownLawyerChange'}
                name={'logCrownLawyerChange'}
                label={'Log Crown Lawyer Change'}
                checked={logCrownLawyerChange}
                onChange={() => {
                  setLogCrownLawyerChange((s) => !s);
                }}
              />
            </FormGroup>
          )}
          {defenceLawyerChanged && (
            <FormGroup>
              <CustomInput
                type='checkbox'
                id={'logDefenceLawyerChange'}
                name={'logDefenceLawyerChange'}
                label={'Log Defence Lawyer Change'}
                checked={logDefenceLawyerChange}
                onChange={() => {
                  setLogDefenceLawyerChange((s) => !s);
                }}
              />
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color={'primary'}
            onClick={() => {
              setOpenLogGladueChangeModal(false);
              saveFile(
                logGladueSentencingDateChange,
                logCrownLawyerChange,
                logDefenceLawyerChange,
                logFCSAssignedSocialWorkerChange
              );
            }}
          >
            Save
          </Button>
          <Button color={'link'} className={'ml-auto'} onClick={() => setOpenLogGladueChangeModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={openLogFPChangeModal}
        toggle={() => setOpenLogFPChangeModal(!openLogFPChangeModal)}
        className='logout-modal'
      >
        <ModalHeader toggle={() => setOpenLogFPChangeModal(!openLogFPChangeModal)}>Log Change</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            The FCS assigned social worker for this file has been changed. Select whether the changes below should be
            recorded in the Activity Log for this file. If you are simply making a correction, do not log the change.
          </div>
          {fcsAssignedSocialWorkerChanged && (
            <FormGroup>
              <CustomInput
                type='checkbox'
                id={'logFCSAssignedSocialWorkerChange'}
                name={'logFCSAssignedSocialWorkerChange'}
                label={'Log FCS Assigned Social Worker Change'}
                checked={logFCSAssignedSocialWorkerChange}
                onChange={() => {
                  setLogFCSAssignedSocialWorkerChange((s) => !s);
                }}
              />
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color={'primary'}
            onClick={() => {
              setOpenLogFPChangeModal(false);
              saveFile(
                logGladueSentencingDateChange,
                logCrownLawyerChange,
                logDefenceLawyerChange,
                logFCSAssignedSocialWorkerChange
              );
            }}
          >
            Save
          </Button>
          <Button color={'link'} className={'ml-auto'} onClick={() => setOpenLogFPChangeModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={openMissingRequestInfoModal} className='logout-modal'>
        <ModalHeader>Missing Request Information</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>File cannot be saved. There are requests with missing information.</div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              setOpenMissingRequestInfoModal(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={openStageCompletedModal} className='logout-modal'>
        <ModalHeader>Complete Intake File</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            Are you sure you want to update the file stage? The intake details will no longer be editable.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              setOpenStageCompletedModal(false);
              setIntakeFileStageChangedToCompleted(false);
              saveFile();
            }}
          >
            Proceed
          </Button>
          <Button
            color={'light'}
            onClick={() => {
              setOpenStageCompletedModal(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <ConfidentialityModals
        setIsDirty={setIsDirty}
        openCheckConfidentialityModal={openCheckConfidentialityModal}
        setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
        openUncheckConfidentialityModal={openUncheckConfidentialityModal}
        setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
        staff={selectedStaff}
      />
    </div>
  );
};
