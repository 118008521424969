import { Col, Container, Navbar, NavbarBrand, Row } from 'reactstrap';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import * as React from 'react';
import { useContext, useState } from 'react';
import { FileClients } from './FileClients';
import { FileOtherPeople } from './FileOtherPeople';
import { FileContext } from './FileContext';
import { FILE_SUB_TYPE_COMMUNITY_IMPACT } from '../../contexts/DomainContext';

export const FileClientsContainer = () => {
  const [isDirty, setIsDirty] = useState(false);
  const [isEditingClients, setIsEditingClients] = useState(false);
  const [isEditingOtherPeople, setIsEditingOtherPeople] = useState(false);
  const fileContext = useContext(FileContext);
  return (
    <>
      <LeavePageConfirm isDirty={isDirty} />
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom d-flex flex-wrap sticky-top'}>
        <NavbarBrand className={'w-mobile-100'}>Client Information</NavbarBrand>
      </Navbar>
      <Container fluid={true} className={'my-3'}>
        <Row>
          <Col xs={12}>
            <FileClients
              setIsDirty={setIsDirty}
              setIsEditing={setIsEditingClients}
              disableEditing={isEditingOtherPeople}
            />
          </Col>
          {fileContext.fileSubTypeId !== FILE_SUB_TYPE_COMMUNITY_IMPACT && (
            <Col xs={12} style={{ marginBottom: '80px' }}>
              <FileOtherPeople
                setIsDirty={setIsDirty}
                setIsEditing={setIsEditingOtherPeople}
                disableEditing={isEditingClients}
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};
