import * as React from 'react';
import { useEffect, useState } from 'react';
import { authAxios } from '../services/AxiosService';

type ContextType = {
  loaded: boolean;
  departmentAccessIds: string[];
  displayName: string;
  initials: string;
  role: string;
  isLegalAssistant: boolean;
  isLawyer: boolean;
  isSuperAdmin: boolean;
  homeDepartmentId: string;
  userId: string;
};

export const MyUserContext = React.createContext<ContextType>({
  loaded: false,
  role: '',
  displayName: '',
  initials: '',
  departmentAccessIds: [],
  isLegalAssistant: false,
  isLawyer: false,
  isSuperAdmin: false,
  homeDepartmentId: '',
  userId: '',
});

export const MyUserProvider = (props: any) => {
  const [role, setRole] = useState('');
  const [departmentAccessIds, setDepartmentAccessIds] = useState<string[]>([]);
  const [initials, setInitials] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [loaded, setLoaded] = useState(false);
  const [homeDepartmentId, setHomeDepartmentId] = useState('');
  const [userId, setUserId] = useState('');

  const isLegalAssistant = role === 'LegalAssistant';
  const isLawyer = role === 'Lawyer';
  const isSuperAdmin = role === 'SuperAdmin';

  useEffect(() => {
    authAxios.get('/api/users/admin/my-user').then((response) => {
      setRole(response.data.role);
      setDepartmentAccessIds(response.data.departmentAccessIds);
      setInitials(response.data.initials);
      setDisplayName(response.data.displayName);
      setLoaded(true);
      setHomeDepartmentId(response.data.homeDepartmentId);
      setUserId(response.data.userId);
    });
  }, []);

  return (
    <MyUserContext.Provider
      value={{
        loaded,
        displayName,
        initials,
        role,
        departmentAccessIds,
        isLegalAssistant,
        isLawyer,
        isSuperAdmin,
        homeDepartmentId,
        userId,
      }}
    >
      {props.children}
    </MyUserContext.Provider>
  );
};

export const MyUserConsumer = MyUserContext.Consumer;
