import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import {
  DEPARTMENT_JORDANS_PRINCIPLE,
  DomainContext,
  FILE_SUB_TYPE_GROUP,
  IIndigenousGroup,
  IJordansPrincipleRequestType,
  INDIGENOUS_GROUP_FIRST_NATION,
  INDIGENOUS_GROUP_INUIT,
  INDIGENOUS_GROUP_METIS,
  INDIGENOUS_GROUP_OTHER,
  IStage,
  JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE,
  JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER,
  STAGE_COMPLETED,
  STAGE_DRAFT,
  STAGE_SUBMITTED,
} from '../../contexts/DomainContext';
import { FileContext, IJordansPrincipleFields } from './FileContext';
import DatePicker from 'react-datepicker';

import { RequiredLabel } from '../RequiredLabel';
import { UsersContext } from '../../contexts/UsersContext';
import { IUser } from '../Users/UsersTable';
import CreatableSelect from 'react-select/creatable';
import { useParams } from 'react-router';
import { MyUserContext } from '../../contexts/MyUserContext';

export const JordansPrincipleFileDetails: React.FC<{
  isEditingAllowed: boolean;
  trySave: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenUncheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const params = useParams<{ fileId: string }>();
  const domainContext = useContext(DomainContext);
  const fileContext = useContext(FileContext);
  const usersContext = useContext(UsersContext);
  const myUser = useContext(MyUserContext);
  const setRequests = fileContext.setRequests;

  const stages = domainContext.stages;
  const requestTypes = domainContext.jordansPrincipleRequestTypes;
  const indigenousGroups = domainContext.indigenousGroups;
  const yukonFns = domainContext.yukonFns;
  const firstNationOptions = yukonFns.map((fn) => ({ value: fn.yukonFirstNationId, label: fn.name }));

  const [openChangeRequestTypeModal, setOpenChangeRequestTypeModal] = useState(false);

  const staff = usersContext.users;
  const [selectedStaff, setSelectedStaff] = useState<IUser[]>([]);

  useEffect(() => {
    setSelectedStaff(staff.filter((s) => fileContext.staffIds.includes(s.userId)));
  }, [staff, fileContext.staffIds]);

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
        borderRadius: '1.25rem',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
    menu: (provided: any) => {
      return {
        ...provided,
        zIndex: 9999,
      };
    },
  };

  // commented out as part of CYFN-405, will be added back in at a later date
  // const boolOptions = [
  //   { val: true, display: 'Yes' },
  //   { val: false, display: 'No' },
  // ];

  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <Card className={'mb-4'}>
            <CardHeader>Request Details</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <RequiredLabel for={'requestTypeId'} required={!fileContext.jordansPrincipleFields?.requestTypeId}>
                      Request Type
                    </RequiredLabel>
                    <Select
                      styles={disabledSelectStyles}
                      name={'requestTypeId'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={requestTypes}
                      value={
                        requestTypes.find(
                          (s: IJordansPrincipleRequestType) =>
                            s.jordansPrincipleRequestTypeId === fileContext.jordansPrincipleFields?.requestTypeId
                        ) ?? undefined
                      }
                      type={'text'}
                      onChange={(value: any) => {
                        fileContext.setJordansPrincipleFields(
                          (s) =>
                            ({ ...s, requestTypeId: value?.jordansPrincipleRequestTypeId } as IJordansPrincipleFields)
                        );
                        if (
                          value?.jordansPrincipleRequestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE ||
                          value?.jordansPrincipleRequestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER
                        ) {
                          fileContext.setIscNumber('');
                          fileContext.setSubmittedDate(undefined);
                          fileContext.setDecisionDate(undefined);
                        }
                        setRequests((oldR) =>
                          oldR.map((r) => {
                            return {
                              ...r,
                              categoryId: '',
                              numberOfChildren: undefined,
                            };
                          })
                        );
                        if (params.fileId) {
                          setOpenChangeRequestTypeModal(true);
                        }
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: any) => option.jordansPrincipleRequestTypeId}
                      getOptionLabel={(option: any) => option.name}
                      isClearable={false}
                      //disabled for CYFN-434, will put back in after CYFN is done with their changes
                      //isDisabled={params.fileId !== undefined}
                    />
                    {props.trySave && fileContext.jordansPrincipleFields?.requestTypeId === undefined && (
                      <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>ISC Number</Label>
                    <Input
                      type='text'
                      placeholder='Type here...'
                      name='isc-number'
                      value={fileContext.iscNumber}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setIscNumber(e.target.value);
                        props.setIsDirty(true);
                      }}
                      disabled={
                        fileContext.jordansPrincipleFields?.requestTypeId ===
                          JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE ||
                        fileContext.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER ||
                        !props.isEditingAllowed
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>Request Title</Label>
                    <Input
                      type='text'
                      placeholder={'Type request title here...'}
                      value={fileContext.requestName}
                      onChange={(e) => {
                        const detail = e.target.value;
                        fileContext.setRequestName(detail);
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <FormGroup className={'w-100 mb-2 mr-3'}>
                    <Label for={'submitted-date'}>Submitted Date</Label>
                    <DatePicker
                      selected={fileContext.submittedDate}
                      name={'application-date'}
                      onChange={(date) => {
                        fileContext.setSubmittedDate(date ? date : undefined);
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      isClearable={true}
                      disabled={
                        fileContext.stageId === STAGE_DRAFT ||
                        fileContext.jordansPrincipleFields?.requestTypeId ===
                          JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE ||
                        fileContext.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER ||
                        !props.isEditingAllowed
                      }
                      showYearDropdown
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <Label for={'decision-date'}>Decision Date</Label>
                    <DatePicker
                      selected={fileContext.decisionDate}
                      name={'application-date'}
                      onChange={(date) => {
                        fileContext.setDecisionDate(date ? date : undefined);
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      disabled={
                        fileContext.stageId === STAGE_DRAFT ||
                        fileContext.stageId === STAGE_SUBMITTED ||
                        fileContext.jordansPrincipleFields?.requestTypeId ===
                          JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE ||
                        fileContext.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER ||
                        !props.isEditingAllowed
                      }
                      showYearDropdown
                      isClearable={true}
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <Label for={'completed-date'}>Completed Date</Label>
                    <DatePicker
                      selected={fileContext.completedDate}
                      name={'application-date'}
                      onChange={(date) => {
                        fileContext.setCompletedDate(date ? date : undefined);
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      disabled={fileContext.stageId !== STAGE_COMPLETED || !props.isEditingAllowed}
                      showYearDropdown
                      isClearable={true}
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {fileContext.fileSubTypeId === FILE_SUB_TYPE_GROUP && (
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2'}>
                      <Label>File Display Name</Label>
                      <Input
                        value={fileContext.fileName}
                        type={'text'}
                        onChange={(e: any) => {
                          fileContext.setFileName(e.target.value);
                          props.setIsDirty(true);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
          {/*commented out as part of CYFN-405, will be added back in at a later date*/}
          {/*<Card className={'mb-4'}>*/}
          {/*  <CardHeader>Eligibility</CardHeader>*/}
          {/*  <CardBody>*/}
          {/*    <Row>*/}
          {/*      <Col md={5}>*/}
          {/*        <FormGroup className={'w-100 mb-2'}>*/}
          {/*          <Label>Children with Complex Medical Needs</Label>*/}
          {/*          <Select*/}
          {/*            styles={disabledSelectStyles}*/}
          {/*            name={'children-with-complex-medical-needs'}*/}
          {/*            options={boolOptions}*/}
          {/*            placeholder={<span className='placeholder'>Select...</span>}*/}
          {/*            value={boolOptions.find(*/}
          {/*              (g) => g.val === fileContext.jordansPrincipleFields?.childrenWithComplexMedicalNeeds*/}
          {/*            )}*/}
          {/*            type={'text'}*/}
          {/*            onChange={(value: any) => {*/}
          {/*              fileContext.setJordansPrincipleFields(*/}
          {/*                (s) => ({ ...s, childrenWithComplexMedicalNeeds: value?.val } as IJordansPrincipleFields)*/}
          {/*              );*/}
          {/*              props.setIsDirty(true);*/}
          {/*            }}*/}
          {/*            getOptionValue={(option: any) => option.val}*/}
          {/*            getOptionLabel={(option: any) => option.display}*/}
          {/*            isClearable={true}*/}
          {/*          />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*      <Col md={7}>*/}
          {/*        <FormGroup className={'w-100 mb-2'}>*/}
          {/*          <Label>Description</Label>*/}
          {/*          <Input*/}
          {/*            type='text'*/}
          {/*            placeholder='Type here...'*/}
          {/*            name='children-with-complex-medical-needs-description'*/}
          {/*            value={fileContext.jordansPrincipleFields?.childrenWithComplexMedicalNeedsDescription}*/}
          {/*            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {*/}
          {/*              fileContext.setJordansPrincipleFields(*/}
          {/*                (s) =>*/}
          {/*                  ({*/}
          {/*                    ...s,*/}
          {/*                    childrenWithComplexMedicalNeedsDescription: e.target.value,*/}
          {/*                  } as IJordansPrincipleFields)*/}
          {/*              );*/}
          {/*              props.setIsDirty(true);*/}
          {/*            }}*/}
          {/*            disabled={!props.isEditingAllowed}*/}
          {/*          />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*    <Row>*/}
          {/*      <Col md={5}>*/}
          {/*        <FormGroup className={'w-100 mb-2'}>*/}
          {/*          <Label>Children Living in Poverty</Label>*/}
          {/*          <Select*/}
          {/*            styles={disabledSelectStyles}*/}
          {/*            name={'children-living-in-poverty'}*/}
          {/*            options={boolOptions}*/}
          {/*            placeholder={<span className='placeholder'>Select...</span>}*/}
          {/*            value={boolOptions.find(*/}
          {/*              (g) => g.val === fileContext.jordansPrincipleFields?.childrenLivingInPoverty*/}
          {/*            )}*/}
          {/*            type={'text'}*/}
          {/*            onChange={(value: any) => {*/}
          {/*              fileContext.setJordansPrincipleFields(*/}
          {/*                (s) => ({ ...s, childrenLivingInPoverty: value?.val } as IJordansPrincipleFields)*/}
          {/*              );*/}
          {/*              props.setIsDirty(true);*/}
          {/*            }}*/}
          {/*            getOptionValue={(option: any) => option.val}*/}
          {/*            getOptionLabel={(option: any) => option.display}*/}
          {/*            isClearable={true}*/}
          {/*          />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*      <Col md={7}>*/}
          {/*        <FormGroup className={'w-100 mb-2'}>*/}
          {/*          <Label>Description</Label>*/}
          {/*          <Input*/}
          {/*            type='text'*/}
          {/*            placeholder='Type here...'*/}
          {/*            name='children-living-in-poverty-description'*/}
          {/*            value={fileContext.jordansPrincipleFields?.childrenLivingInPovertyDescription}*/}
          {/*            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {*/}
          {/*              fileContext.setJordansPrincipleFields(*/}
          {/*                (s) =>*/}
          {/*                  ({*/}
          {/*                    ...s,*/}
          {/*                    childrenLivingInPovertyDescription: e.target.value,*/}
          {/*                  } as IJordansPrincipleFields)*/}
          {/*              );*/}
          {/*              props.setIsDirty(true);*/}
          {/*            }}*/}
          {/*            disabled={!props.isEditingAllowed}*/}
          {/*          />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*    <Row style={{ display: 'flex', alignItems: 'flex-end' }}>*/}
          {/*      <Col md={5}>*/}
          {/*        <FormGroup className={'w-100 mb-2'}>*/}
          {/*          <Label>Children in Families who have Experienced Recent Emergency</Label>*/}
          {/*          <Select*/}
          {/*            styles={disabledSelectStyles}*/}
          {/*            name={'experienced-recent-emergency'}*/}
          {/*            options={boolOptions}*/}
          {/*            placeholder={<span className='placeholder'>Select...</span>}*/}
          {/*            value={boolOptions.find(*/}
          {/*              (g) => g.val === fileContext.jordansPrincipleFields?.experiencedRecentEmergency*/}
          {/*            )}*/}
          {/*            type={'text'}*/}
          {/*            onChange={(value: any) => {*/}
          {/*              fileContext.setJordansPrincipleFields(*/}
          {/*                (s) => ({ ...s, experiencedRecentEmergency: value?.val } as IJordansPrincipleFields)*/}
          {/*              );*/}
          {/*              props.setIsDirty(true);*/}
          {/*            }}*/}
          {/*            getOptionValue={(option: any) => option.val}*/}
          {/*            getOptionLabel={(option: any) => option.display}*/}
          {/*            isClearable={true}*/}
          {/*          />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*      <Col md={7}>*/}
          {/*        <FormGroup className={'w-100 mb-2'}>*/}
          {/*          <Label>Description</Label>*/}
          {/*          <Input*/}
          {/*            type='text'*/}
          {/*            placeholder='Type here...'*/}
          {/*            name='experienced-recent-emergency-description'*/}
          {/*            value={fileContext.jordansPrincipleFields?.experiencedRecentEmergencyDescription}*/}
          {/*            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {*/}
          {/*              fileContext.setJordansPrincipleFields(*/}
          {/*                (s) =>*/}
          {/*                  ({*/}
          {/*                    ...s,*/}
          {/*                    experiencedRecentEmergencyDescription: e.target.value,*/}
          {/*                  } as IJordansPrincipleFields)*/}
          {/*              );*/}
          {/*              props.setIsDirty(true);*/}
          {/*            }}*/}
          {/*            disabled={!props.isEditingAllowed}*/}
          {/*          />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*    <Row>*/}
          {/*      <Col md={5}>*/}
          {/*        <FormGroup className={'w-100 mb-2'}>*/}
          {/*          <Label>Family Reunification</Label>*/}
          {/*          <Select*/}
          {/*            styles={disabledSelectStyles}*/}
          {/*            name={'family-reunification'}*/}
          {/*            options={boolOptions}*/}
          {/*            placeholder={<span className='placeholder'>Select...</span>}*/}
          {/*            value={boolOptions.find((g) => g.val === fileContext.jordansPrincipleFields?.familyReunification)}*/}
          {/*            type={'text'}*/}
          {/*            onChange={(value: any) => {*/}
          {/*              fileContext.setJordansPrincipleFields(*/}
          {/*                (s) => ({ ...s, familyReunification: value?.val } as IJordansPrincipleFields)*/}
          {/*              );*/}
          {/*              props.setIsDirty(true);*/}
          {/*            }}*/}
          {/*            getOptionValue={(option: any) => option.val}*/}
          {/*            getOptionLabel={(option: any) => option.display}*/}
          {/*            isClearable={true}*/}
          {/*          />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*      <Col md={7}>*/}
          {/*        <FormGroup className={'w-100 mb-2'}>*/}
          {/*          <Label>Description</Label>*/}
          {/*          <Input*/}
          {/*            type='text'*/}
          {/*            placeholder='Type here...'*/}
          {/*            name='family-reunification-description'*/}
          {/*            value={fileContext.jordansPrincipleFields?.familyReunificationDescription}*/}
          {/*            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {*/}
          {/*              fileContext.setJordansPrincipleFields(*/}
          {/*                (s) =>*/}
          {/*                  ({*/}
          {/*                    ...s,*/}
          {/*                    familyReunificationDescription: e.target.value,*/}
          {/*                  } as IJordansPrincipleFields)*/}
          {/*              );*/}
          {/*              props.setIsDirty(true);*/}
          {/*            }}*/}
          {/*            disabled={!props.isEditingAllowed}*/}
          {/*          />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*    <Row>*/}
          {/*      <Col md={5}>*/}
          {/*        <FormGroup className={'w-100 mb-2'}>*/}
          {/*          <Label>Cultural Experiences</Label>*/}
          {/*          <Select*/}
          {/*            styles={disabledSelectStyles}*/}
          {/*            name={'cultural-experiences'}*/}
          {/*            options={boolOptions}*/}
          {/*            placeholder={<span className='placeholder'>Select...</span>}*/}
          {/*            value={boolOptions.find((g) => g.val === fileContext.jordansPrincipleFields?.culturalExperiences)}*/}
          {/*            type={'text'}*/}
          {/*            onChange={(value: any) => {*/}
          {/*              fileContext.setJordansPrincipleFields(*/}
          {/*                (s) => ({ ...s, culturalExperiences: value?.val } as IJordansPrincipleFields)*/}
          {/*              );*/}
          {/*              props.setIsDirty(true);*/}
          {/*            }}*/}
          {/*            getOptionValue={(option: any) => option.val}*/}
          {/*            getOptionLabel={(option: any) => option.display}*/}
          {/*            isClearable={true}*/}
          {/*          />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*      <Col md={7}>*/}
          {/*        <FormGroup className={'w-100 mb-2'}>*/}
          {/*          <Label>Description</Label>*/}
          {/*          <Input*/}
          {/*            type='text'*/}
          {/*            placeholder='Type here...'*/}
          {/*            name='cultural-experiences-description'*/}
          {/*            value={fileContext.jordansPrincipleFields?.culturalExperiencesDescription}*/}
          {/*            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {*/}
          {/*              fileContext.setJordansPrincipleFields(*/}
          {/*                (s) =>*/}
          {/*                  ({*/}
          {/*                    ...s,*/}
          {/*                    culturalExperiencesDescription: e.target.value,*/}
          {/*                  } as IJordansPrincipleFields)*/}
          {/*              );*/}
          {/*              props.setIsDirty(true);*/}
          {/*            }}*/}
          {/*            disabled={!props.isEditingAllowed}*/}
          {/*          />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*    <Row>*/}
          {/*      <Col>*/}
          {/*        <FormGroup className={'w-100 mb-2'}>*/}
          {/*          <Label>Overall Eligibility</Label>*/}
          {/*          <Select*/}
          {/*            styles={disabledSelectStyles}*/}
          {/*            name={'overall-eligibility'}*/}
          {/*            options={boolOptions}*/}
          {/*            placeholder={<span className='placeholder'>Select...</span>}*/}
          {/*            value={boolOptions.find((g) => g.val === fileContext.jordansPrincipleFields?.overallEligibility)}*/}
          {/*            type={'text'}*/}
          {/*            onChange={(value: any) => {*/}
          {/*              fileContext.setJordansPrincipleFields(*/}
          {/*                (s) => ({ ...s, overallEligibility: value?.val } as IJordansPrincipleFields)*/}
          {/*              );*/}
          {/*              props.setIsDirty(true);*/}
          {/*            }}*/}
          {/*            getOptionValue={(option: any) => option.val}*/}
          {/*            getOptionLabel={(option: any) => option.display}*/}
          {/*            isClearable={true}*/}
          {/*          />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*  </CardBody>*/}
          {/*</Card>*/}
        </Col>
        <Col sm={12} md={6}>
          <Card className={'mb-4'}>
            <CardHeader>File Details</CardHeader>
            <CardBody>
              {myUser.isSuperAdmin && (
                <Row className='square border-bottom mb-2'>
                  <Col>
                    <FormGroup>
                      <Label for={'isConfidential'}>Confidentiality</Label>
                      <CustomInput
                        type='checkbox'
                        id={'isConfidential'}
                        name={'isConfidential'}
                        label={'Highly Confidential File'}
                        checked={fileContext.isConfidential}
                        onChange={() => {
                          if (fileContext.isConfidential) {
                            props.setOpenUncheckConfidentialityModal(true);
                          } else {
                            props.setOpenCheckConfidentialityModal(true);
                          }
                          fileContext.setIsConfidential(!fileContext.isConfidential);
                          props.setIsDirty(true);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <RequiredLabel for={'stageId'} required={!fileContext.stageId}>
                      Stage
                    </RequiredLabel>
                    <Select
                      styles={disabledSelectStyles}
                      name={'stageId'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={stages.filter(
                        (s) => s.fileTypes && s.fileTypes.includes(fileContext.fileTypeId ? fileContext.fileTypeId : '')
                      )}
                      value={stages.find((s: IStage) => s.stageId === fileContext.stageId)}
                      onChange={(value: any) => {
                        fileContext.setStageId(value?.stageId || '');
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: IStage) => option.stageId}
                      getOptionLabel={(option: IStage) => option.name}
                      isDisabled={!props.isEditingAllowed}
                    />
                    <FormFeedback>This field is mandatory</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <RequiredLabel for={'application-date'} required={fileContext.createdDate === undefined}>
                      Created Date
                    </RequiredLabel>
                    <DatePicker
                      selected={fileContext.createdDate}
                      name={'application-date'}
                      onChange={(date) => {
                        fileContext.setCreatedDate(date ? date : undefined);
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      disabled={!props.isEditingAllowed}
                      showYearDropdown
                      isClearable={true}
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                    {props.trySave && fileContext.createdDate === undefined && (
                      <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'staffIds'}>Staff</Label>
                    <Select
                      styles={disabledSelectStyles}
                      name={'staffIds'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={
                        fileContext.isConfidential
                          ? staff.filter(
                              (u) =>
                                (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                u.departmentAccessIds.includes(DEPARTMENT_JORDANS_PRINCIPLE) &&
                                u.role === 'SuperAdmin'
                            )
                          : staff.filter(
                              (u) =>
                                (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                u.departmentAccessIds.includes(DEPARTMENT_JORDANS_PRINCIPLE)
                            )
                      }
                      onChange={(value: any) => {
                        fileContext.setStaffIds(value.map((v: IUser) => v.userId));
                        props.setIsDirty(true);
                      }}
                      value={selectedStaff}
                      getOptionValue={(option: IUser) => option.userId}
                      getOptionLabel={(option: IUser) =>
                        option.lastName + ', ' + option.firstName + (option.active ? '' : ' (Inactive)')
                      }
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                      isMulti={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {fileContext.fileSubTypeId === FILE_SUB_TYPE_GROUP && (
                <Row>
                  <Col>
                    <FormGroup className={'mb-2'}>
                      <Label>Indigenous Group</Label>
                      <Select
                        name={'indigenousGroup'}
                        styles={disabledSelectStyles}
                        options={indigenousGroups}
                        placeholder={<span className='placeholder'>Select...</span>}
                        value={
                          indigenousGroups.find(
                            (d: any) => d.indigenousGroupId === fileContext.jordansPrincipleFields?.indigenousGroupId
                          ) ?? undefined
                        }
                        onChange={(value) => {
                          fileContext.setJordansPrincipleFields(
                            (s) =>
                              ({
                                ...s,
                                indigenousGroupId: value ? value.indigenousGroupId : undefined,
                                //fixes issue with switching from Metis to Inuit
                                //tries to set a textbox to undefined which clears the value but does not update the textbox itself
                                bandNation: value
                                  ? value.indigenousGroupId === INDIGENOUS_GROUP_FIRST_NATION
                                    ? undefined
                                    : ''
                                  : undefined,
                              } as IJordansPrincipleFields)
                          );
                          props.setIsDirty(true);
                        }}
                        getOptionValue={(option: IIndigenousGroup) => option.indigenousGroupId}
                        getOptionLabel={(option: IIndigenousGroup) => option.name}
                        isDisabled={!props.isEditingAllowed}
                        isClearable={true}
                      />
                    </FormGroup>
                  </Col>
                  {fileContext.jordansPrincipleFields?.indigenousGroupId === INDIGENOUS_GROUP_FIRST_NATION && (
                    <Col>
                      <FormGroup className={'mb-2'}>
                        <Label>First Nation</Label>
                        <CreatableSelect
                          formatCreateLabel={(inputValue: string) => inputValue}
                          placeholder={<span className='placeholder'>Select or Type...</span>}
                          styles={disabledSelectStyles}
                          options={firstNationOptions}
                          value={
                            firstNationOptions.find(
                              (firstNation) => firstNation.value === fileContext.jordansPrincipleFields?.bandNation
                            ) ?? undefined
                          }
                          onChange={(val) => {
                            fileContext.setJordansPrincipleFields(
                              (s) => ({ ...s, bandNation: val ? val.value : '' } as IJordansPrincipleFields)
                            );
                          }}
                          isMulti={false}
                          isClearable={true}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {(fileContext.jordansPrincipleFields?.indigenousGroupId === INDIGENOUS_GROUP_INUIT ||
                    fileContext.jordansPrincipleFields?.indigenousGroupId === INDIGENOUS_GROUP_METIS ||
                    fileContext.jordansPrincipleFields?.indigenousGroupId === INDIGENOUS_GROUP_OTHER) && (
                    <Col>
                      <FormGroup className={'mb-2'}>
                        <Label>Band / Council</Label>
                        <Input
                          type='text'
                          placeholder='Type... '
                          name='band'
                          autoComplete={'off'}
                          disabled={!props.isEditingAllowed}
                          value={fileContext.jordansPrincipleFields?.bandNation}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const detail = e.target.value;
                            fileContext.setJordansPrincipleFields(
                              (s) => ({ ...s, bandNation: detail } as IJordansPrincipleFields)
                            );
                            props.setIsDirty(true);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {fileContext.jordansPrincipleFields?.indigenousGroupId === undefined && <Col></Col>}
                </Row>
              )}
              <FormGroup>
                <Label>Request Rationale</Label>
                <Input
                  type='textarea'
                  placeholder=''
                  style={{ minHeight: '226px' }}
                  name='name'
                  value={fileContext.details}
                  disabled={!props.isEditingAllowed}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    fileContext.setDetails(e.target.value);
                    props.setIsDirty(true);
                  }}
                />
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={openChangeRequestTypeModal} className='logout-modal'>
        <ModalHeader>Change Request Type</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            Changing the Request Type for this file has automatically cleared/altered some fields for this file and
            it&apos;s associated Requests. Please review and update this file and it&apos;s associated requests or else
            information could be lost.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              setOpenChangeRequestTypeModal(false);
            }}
          >
            Proceed
          </Button>
          <Button
            color={'light'}
            onClick={() => {
              fileContext.setFileAndAttributes(fileContext.file);
              props.setIsDirty(false);
              setOpenChangeRequestTypeModal(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
