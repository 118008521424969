import * as React from 'react';
import { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { FileUpload } from '../../FileUpload/FileUpload';
import LoadingService from '../../Loading/LoadingService';
import * as FileUploadService from '../../FileUpload/FileUploadService';
import { authAxios } from '../../../services/AxiosService';
import { useLocation } from 'react-router';
import { Column, TableInstance, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { formatDateTime } from '../../../formatters/DateTimeFormatter';
import { useDefaultColumn } from '../../../hooks/ReactTableHooks';
import { Col, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { TablePagination } from '../../TablePagination/TablePagination';
import { useHistory } from 'react-router-dom';
import { DomainContext, IDocumentType } from '../../../contexts/DomainContext';
import { DropdownMultiSelectCheckboxes } from '../../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import { DateRangeDropdown } from '../../DateRangeDropdown/DateRangeDropdown';

export interface IDocument {
  documentId: string;
  documentTypeId: string;
  description?: string;
  documentNumber?: string;
  fileName: string;
  documentState: string;
  uploadId: string;
  uploadDate?: number;
  uploadedBy?: string;
  documentOwner?: string;
  documentOwnerId?: string;
}

export const Documents: React.FC<{
  newDocumentPath: string;
  getPaths: string[];
  clientDetailsPath?: string;
  forWorkshop?: boolean;
}> = (props) => {
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadSuccessful, setUploadSuccessful] = useState<boolean>(false);

  const domainContext = useContext(DomainContext);
  const allDocumentTypes = domainContext.allDocumentTypes;
  const fileAndClientDocumentTypes = domainContext.fileAndClientDocumentTypes;
  const workshopDocumentTypes = domainContext.workshopDocumentTypes;

  const history = useHistory();
  const location = useLocation();

  const newDocumentPath = props.newDocumentPath;

  useEffect(() => {
    let deletedDocumentIds = [] as string[];
    const state: any = location.state;
    if (state !== undefined && state.deletedDocumentIds) {
      deletedDocumentIds = state.deletedDocumentIds;
    }
    props.getPaths.forEach((getPath) => {
      authAxios.get(getPath).then((response) => {
        if (response.data && response.data.status && response.data.status.includes('KO')) {
          history.push('/404');
        } else if (response.data) {
          const docs: IDocument[] = response.data;
          setDocuments((oldDocs) => [...oldDocs, ...docs.filter((d) => !deletedDocumentIds.includes(d.documentId))]);
        }
      });
    });
  }, []);

  const columns: Column<IDocument>[] = useMemo(
    () => [
      {
        Header: 'File Name',
        accessor: 'fileName',
        width: undefined,
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: undefined,
      },
      {
        Header: 'Client / File',
        accessor: 'documentOwner',
        width: 400,
      },
      {
        Header: 'Document Type',
        id: 'documentTypeId',
        width: 175,
        accessor: (cell: any) => {
          return allDocumentTypes.find((dt: IDocumentType) => dt.documentTypeId === cell.documentTypeId)?.name;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='documentTypeId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={
                props.forWorkshop
                  ? workshopDocumentTypes.map((s: IDocumentType) => ({
                      value: s.documentTypeId,
                      display: s.name,
                    }))
                  : fileAndClientDocumentTypes.map((s: IDocumentType) => ({
                      value: s.documentTypeId,
                      display: s.name,
                    }))
              }
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['documentTypeId'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'User',
        accessor: 'uploadedBy',
        width: 225,
      },
      {
        Header: 'Date Uploaded',
        id: 'uploadDate',
        width: 250,
        accessor: 'uploadDate',
        Cell: (cell: any) => {
          if (cell && cell.row.original && cell.row.original.uploadDate) {
            return <span>{formatDateTime(new Date(cell.row.original.uploadDate))}</span>;
          } else {
            return <span />;
          }
        },
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['uploadDate'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'uploadDate'}
          />
        ),
      },
    ],
    [allDocumentTypes]
  );

  const data: IDocument[] = useMemo(() => documents, [documents]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IDocument> = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        hiddenColumns: props.forWorkshop ? ['documentOwner'] : [],
        sortBy: [
          {
            id: 'uploadDate',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    // useGlobalFilter,
    useSortBy,
    usePagination
  );

  const selectRow = (uploadId: string, documentOwner: string, documentOwnerId: string) => {
    if (documentOwner === 'File') {
      history.push(`/files/${documentOwnerId}/documents/${uploadId}`);
    } else if (documentOwner === 'Workshop') {
      history.push(`/workshops/${documentOwnerId}/documents/${uploadId}`);
    } else if (props.clientDetailsPath) {
      history.push(props.clientDetailsPath + '/' + documentOwnerId + '/' + uploadId);
    } else {
      history.push(`/clients/${documentOwnerId}/documents/${uploadId}`);
    }
  };

  const doNewDocumentToUpload = (files: File[]) => {
    if (!newDocumentPath) {
      console.log('Nowhere to upload these... bailing!', files);
      return;
    }
    console.log("Let's upload these ", files);
    setIsUploading(true);

    const loadingKey: string = LoadingService.add();

    const doUploadOneFile = (file: File) => {
      return FileUploadService.uploadFile(file, newDocumentPath).then((response: any) => {
        if (response.data.status === 'OK') {
          console.log(response.data.document);
          setDocuments((docs: IDocument[]) => [...docs, response.data.document as IDocument]);
        }
      });
    };

    const sequenceUploads = Array.from(files).reduce((p, file: File) => {
      return p.then(() => doUploadOneFile(file).catch((err) => console.log('Upload failed.', err)));
    }, Promise.resolve());

    sequenceUploads
      .then(() => {
        console.log('Uploaded all files.');
        LoadingService.remove(loadingKey);
        setIsUploading(true);
        setUploadSuccessful(true);
        setTimeout(() => {
          setUploadSuccessful(false);
        }, 3000);
      })
      .catch((error) => {
        console.log('One or more upload failed.', error);
        LoadingService.remove(loadingKey);
        setIsUploading(false);
        setUploadSuccessful(false);
      });
  };

  return (
    <>
      <div className={'p-3'}>
        <FileUpload
          onDrop={(e: any) => doNewDocumentToUpload(e)}
          isUploading={isUploading}
          uploadSuccessful={uploadSuccessful}
        />
      </div>
      <div>
        <form
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }}
        >
          <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
            <Row className={'flex-fill'}>
              <Col md={3} xl={2}>
                <NavbarBrand>Documents</NavbarBrand>
              </Col>
            </Row>
          </Navbar>
          <div className={'table-responsive'} style={{ minHeight: '500px' }}>
            <table className={'table table-bordered table-hover'} {...getTableProps()}>
              <thead style={{ overflow: 'visible' }}>
                {headerGroups.map((headerGroup: any, index: number) => (
                  <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th
                        key={`files-table-thead-tr-${index}-${column.id}`}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          width: column.width,
                          ...column.getHeaderProps(column.getSortByToggleProps()).style,
                        }}
                      >
                        {column.render('Header')}
                        <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                        <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
                {page.map((row: any) => {
                  prepareRow(row);
                  return (
                    <tr key={`files-table-tr-${row.id}`} {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                            {...cell.getCellProps()}
                            onClick={() => {
                              console.log('row.original: ', row.original);
                              return selectRow(
                                row.original.documentId,
                                row.original.documentOwner,
                                row.original.documentOwnerId
                              );
                            }}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <TablePagination
            pageCount={pageCount}
            pageOptions={pageOptions}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageSizes={[20, 50, 100, 500]}
          />
        </form>
      </div>
    </>
  );
};
