import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  ButtonDropdown,
  ButtonGroup,
  Card,
  CardHeader,
  Col,
  Container,
  CustomInput,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import * as ToastService from '../../services/ToastService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { useHistory, useParams } from 'react-router';
import * as FormHelpers from '../../services/FormHelpers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { ClientContext, IAddress, IClient } from './ClientContext';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { RequiredLabel } from '../RequiredLabel';
import CreatableSelect from 'react-select/creatable';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { ClientsContext } from '../../contexts/ClientsContext';
import {
  DEPARTMENT_BIRTH_WORKER,
  DEPARTMENT_CULTURAL_CONNECTIONS,
  DEPARTMENT_FAMILY_PRESERVATION,
  DEPARTMENT_INTAKE,
  DEPARTMENT_JORDANS_PRINCIPLE,
  DEPARTMENT_JUSTICE,
  DomainContext,
  FILE_SUB_TYPE_COMMUNITY_IMPACT,
  FILE_SUB_TYPE_GLADUE_REPORT,
  FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR,
  FILE_SUB_TYPE_WCC_CONNECTIVE,
  FILE_TYPE_BIRTH_WORKER,
  FILE_TYPE_CULTURAL_CONNECTIONS,
  FILE_TYPE_FAMILY_PRESERVATION,
  FILE_TYPE_INTAKE,
  FILE_TYPE_JORDANS_PRINCIPLE,
  FILE_TYPE_JUSTICE,
  ICommunity,
  IGender,
  IGroupHome,
  IIndigenousGroup,
  INDIGENOUS_GROUP_FIRST_NATION,
  INDIGENOUS_GROUP_INUIT,
  INDIGENOUS_GROUP_METIS,
  INDIGENOUS_GROUP_OTHER,
} from '../../contexts/DomainContext';
import { ClientAddress } from './ClientAddress';
import { AxiosUpdateTuple } from '../../hooks/AxiosPutHook';
import { useCreateClient } from '../../hooks/Clients/mutation/useCreateClient';
import { ClientSupportTeam } from './ClientSupportTeam';
import { useUpdateClient } from '../../hooks/Clients/mutation/useUpdateClient';
import { Link } from 'react-router-dom';
import { MyUserContext } from '../../contexts/MyUserContext';
import { useUpdateClientFlagged } from '../../hooks/Clients/mutation/useUpdateClientFlagged';
import { useWindowDimensions } from '../../hooks/WindowHooks';
import { authAxios } from '../../services/AxiosService';
import { ClientDisplay } from '../Files/ClientDisplay';
import { getAge } from '../../utils/DateUtils';

interface IProps {
  createCompleteAction?: (client: IClient, relationship?: string) => void;
  editCompleteAction?: (client: IClient, relationship?: string) => void;
  deleteAction?: () => void;
  cancelAction?: () => void;
  clientId?: string;
  setClientIsDirty?: Dispatch<SetStateAction<boolean>>;
  dateOfBirthRequired?: boolean;
  allowCreateFile: boolean;
  multiplePeopleFormat?: boolean;
  autoFocus?: boolean;
  disableActions?: boolean;
  showRelationship?: boolean;
  relationship?: string;
  newClient?: boolean;
  //Workshop uses a pared down version of this component, this prop determines which parts are used
  forWorkshop?: boolean;
  //Used to disable LeavePageConfirm component, if two LeavePageConfirm components both try to open at the same time, neither will open
  disableLeaveConfirm?: boolean;
}

export interface IPhoneNumber {
  number: string;
  description: string;
}

export interface IMatchingClient {
  client: IClient;
  numFiles: number;
}

export interface IIndigenousInformation {
  indigenousGroupId: string;
  bandNation?: string;
  indigenousType?: string;
  heritage?: string;
}

export const ClientDetails: React.FC<IProps> = (props) => {
  const params = useParams<{ fileId?: string; clientId: string }>();
  const clientId = props.clientId || params.clientId;
  const clientContext = useContext(ClientContext);
  const clientsContext = useContext(ClientsContext);
  const domainContext = useContext(DomainContext);
  const myUserContext = useContext(MyUserContext);
  const client = clientContext.client;
  const addresses = clientContext.addresses;
  const setAddresses = clientContext.setAddresses;
  const genders = domainContext.genders;
  const yukonFns = domainContext.yukonFns;
  const communities = domainContext.communities;
  const indigenousGroups = domainContext.indigenousGroups;
  const groupHomes = domainContext.groupHomes;
  const history = useHistory();
  const myUser = useContext(MyUserContext);
  const { xs, sm } = useWindowDimensions();
  const mobile = xs || sm;

  const [editingSupportPerson, setEditingSupportPerson] = useState(false);
  const [relationship, setRelationship] = useState(props.relationship);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [originalFirstName, setOriginalFirstName] = useState('');
  const [originalLastName, setOriginalLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState<IPhoneNumber[]>([{ number: '', description: '' } as IPhoneNumber]);
  const [active, setActive] = useState(true);
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>();
  const [indigenousInfo, setIndigenousInfo] = useState<IIndigenousInformation[]>(
    props.forWorkshop ? [{ indigenousGroupId: '', bandNation: '' } as IIndigenousInformation] : []
  );
  const [statusCardNum, setStatusCardNum] = useState('');
  const [communityId, setCommunityId] = useState('');
  const [school, setSchool] = useState('');
  const [flaggedDescription, setFlaggedDescription] = useState('');
  const [hasFiles, setHasFiles] = useState(false);
  const [hasWorkshops, setHasWorkshops] = useState(false);
  const [flagged, setFlagged] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isSupportDirty, setIsSupportDirty] = useState(false);
  const [isAddressesDirty, setIsAddressesDirty] = useState(false);
  const [inGroupCare, setInGroupCare] = useState<boolean | undefined>(undefined);
  const [groupHomeId, setGroupHomeId] = useState('');
  const [trySubmit, setTrySubmit] = useState(false);

  const [showNewAddressFields, setShowNewAddressFields] = useState(false);
  const [leavePageConfirmOpen, setLeavePageConfirmOpen] = useState<boolean>(false);
  const [sameUserConfirmOpen, setSameUserConfirmOpen] = useState<boolean>(false);
  const [matchingClientList, setMatchingClientList] = useState<IClient[]>([]);
  const [genderId, setGenderId] = useState('');
  const [deleteAddressModal, setDeleteAddressModal] = useState(false);
  const [deleteAddressId, setDeleteAddressId] = useState<string | undefined>('');
  const [editAddressId, setEditAddressId] = useState<string | undefined>('');
  const [lastUpdated, setLastUpdated] = useState<number | undefined>();
  const [isEditingAllowed, setIsEditingAllowed] = useState(!!props.clientId || props.newClient);
  const [justiceCreateDropDownOpen, setJusticeCreateDropDownOpen] = useState(false);
  const [addingAncestry, setAddingAncestry] = useState(0);
  const [addingAddress, setAddingAddress] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAssociatedFilesModal, setOpenAssociatedFilesModal] = useState(false);
  const [openAssociatedWorkshopsModal, setOpenAssociatedWorkshopsModal] = useState(false);

  let ref: any = undefined;

  const [
    createClient,
    { data: createData, loading: createLoading, called: createCalled, error: createError },
  ]: AxiosUpdateTuple = useCreateClient();

  useEffect(() => {
    console.log('---------------------------------');
    console.log(`Create Called ${createCalled}`);
    console.log(`Create Loading ${createLoading}`);
    console.log(`Create Error ${createError}`);
    console.log(createData);
    console.log('---------------------------------');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCalled, createLoading, createError, createData]);

  const [
    updateClient,
    { data: updateData, loading: updateLoading, called: updateCalled, error: updateError },
  ]: AxiosUpdateTuple = useUpdateClient();

  useEffect(() => {
    console.log('---------------------------------');
    console.log(`Update Called ${updateCalled}`);
    console.log(`Update Loading ${updateLoading}`);
    console.log(`Update Error ${updateError}`);
    console.log(updateData);
    console.log('---------------------------------');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCalled, updateLoading, updateError, updateData]);

  const [
    updateClientFlagged,
    { data: updateFlaggedData, loading: updateFlaggedLoading, called: updateFlaggedCalled, error: updateFlaggedError },
  ]: AxiosUpdateTuple = useUpdateClientFlagged();

  useEffect(() => {
    console.log('---------------------------------');
    console.log(`Update Flagged Called ${updateFlaggedCalled}`);
    console.log(`Update Flagged Loading ${updateFlaggedLoading}`);
    console.log(`Update Flagged Error ${updateFlaggedError}`);
    console.log(updateFlaggedData);
    console.log('---------------------------------');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFlaggedCalled, updateFlaggedLoading, updateFlaggedError, updateFlaggedData]);

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
        borderRadius: '1.25rem',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
    menu: (provided: any) => {
      return {
        ...provided,
        zIndex: 9999,
      };
    },
  };

  const createClientAction = (p: IClient) => {
    clientsContext.dispatch({ type: 'CLIENTS_ACTION_CREATE_CLIENT', data: p });
  };

  const editClientAction = (p: IClient) => {
    clientsContext.dispatch({ type: 'CLIENTS_ACTION_UPDATE_CLIENT', data: p });
  };

  const phoneOptions = [
    { value: 'Cell', label: 'Cell' },
    { value: 'Home', label: 'Home' },
    { value: 'Work', label: 'Work' },
  ];

  useEffect(() => {
    if (client.clientId) {
      setFirstName(client.firstName);
      setOriginalFirstName(client.firstName);
      setMiddleName(client.middleName);
      setLastName(client.lastName);
      setOriginalLastName(client.lastName);
      setEmail(client.email);
      setGenderId(client.genderId || '');
      setPhoneNumbers(client.phoneNumbers || []);
      if (client.birthDate) {
        setDateOfBirth(new Date(client.birthDate));
      } else {
        setDateOfBirth(undefined);
      }
      setLastUpdated(client.lastUpdated);
      setIndigenousInfo(client.indigenousInfo);
      setStatusCardNum(client.statusCardNum || '');
      setCommunityId(client.communityId || '');
      setSchool(client.school || '');
      setFlagged(client.flagged);
      setFlaggedDescription(client.flaggedDescription);
      setActive(client.active ? client.active : false);
      setHasFiles(client.hasFiles);
      setHasWorkshops(client.hasWorkshops);
      setInGroupCare(client.inGroupCare);
      setGroupHomeId(client.groupHomeId || '');
    }
  }, [client]);

  const setClientIsDirty = props.setClientIsDirty;

  useEffect(() => {
    setClientIsDirty && setClientIsDirty(isDirty);
  }, [isDirty, setClientIsDirty]);

  const formFullyFilled = () => {
    if (props.showRelationship && !relationship) {
      return false;
    }
    return (
      firstName !== '' &&
      lastName !== '' &&
      FormHelpers.isValidEmail(email) &&
      (!props.dateOfBirthRequired || dateOfBirth !== undefined)
    );
  };

  const saveClientDetails = () => {
    if (!formFullyFilled()) {
      setTrySubmit(true);
      return;
    }

    const baseData = {
      firstName: firstName,
      middleName,
      lastName,
      email,
      phoneNumbers: phoneNumbers.filter((ph) => !!ph.number || !!ph.description),
      birthDate: dateOfBirth ? dateOfBirth.getTime() : undefined,
      genderId,
      indigenousInfo: indigenousInfo.filter((i) => !!i.indigenousGroupId || !!i.bandNation),
      statusCardNum,
      communityId,
      school,
      active,
      inGroupCare,
      groupHomeId,
    };

    setIndigenousInfo((l) => l.filter((i) => !!i.indigenousGroupId || !!i.bandNation));
    setPhoneNumbers((s) => s.filter((ph) => !!ph.number || !!ph.description));
    if (clientId) {
      if (myUser.isSuperAdmin) {
        updateClientFlagged({ clientId: clientId, flagged, description: flaggedDescription });
      }
      updateClient({ clientId: clientId, ...baseData })
        .then((response) => {
          if (response.data.status === 'OK') {
            ToastService.notifySuccess('Client Updated');
            setIsDirty(false);
            const lastUpdatedUpdated = new Date().getTime();
            setLastUpdated(lastUpdatedUpdated);
            const editedClient = {
              clientId: clientId,
              lastUpdated: lastUpdatedUpdated,
              supportTeam: clientContext.client.supportTeam,
              flagged: flagged,
              flaggedDescription: flaggedDescription,
              ...baseData,
            } as IClient;

            setOriginalFirstName(firstName);
            setOriginalLastName(lastName);
            editClientAction(editedClient);

            if (props.editCompleteAction) {
              props.editCompleteAction(editedClient, relationship);
            } else {
              setIsEditingAllowed(false);
            }
          } else {
            ToastService.notifyError(response.data.errorMessage);
          }
        })
        .catch((error) => {
          ToastService.notifyError('Error:' + error);
        });
    } else {
      createClient({ ...baseData, addresses: clientContext.addresses, supportTeam: clientContext.supportTeam }).then(
        (response) => {
          if (response.data.status === 'OK') {
            ToastService.notifySuccess('Client Created');
            setIsDirty(false);
            const lastUpdatedUpdated = new Date().getTime();
            setLastUpdated(lastUpdatedUpdated);

            const createdClient = {
              clientId: response.data.clientId,
              lastUpdated: lastUpdatedUpdated,
              flagged: false,
              flaggedDescription: '',
              ...baseData,
            } as IClient;

            createClientAction(createdClient);
            if (props.createCompleteAction) {
              props.createCompleteAction(createdClient, relationship);
              setOriginalFirstName(firstName);
              setOriginalLastName(lastName);
            } else {
              clientContext.setClient(createdClient);
              clientContext.setOverrideLoaded();
              history.push('/clients/' + response.data.clientId);
            }
          } else {
            ToastService.notifyError('Error Creating Client - ' + response.data.desc);
          }
        }
      );
    }
  };

  const checkIsSame = () => {
    const matchingClientList: IClient[] = clientsContext.state.clients.filter(
      (c: IClient) =>
        c.firstName.toUpperCase().replace(/\s/g, '') === firstName.toUpperCase().replace(/\s/g, '') &&
        c.lastName.toUpperCase().replace(/\s/g, '') === lastName.toUpperCase().replace(/\s/g, '')
    );
    setMatchingClientList(matchingClientList);
    return matchingClientList.length > 0;
  };

  const deleteAddress = (addressId?: string) => {
    if (addressId) {
      setIsDirty(true);
      setAddresses((s) => {
        const newList = s.filter((e) => e.addressId !== addressId);
        if (newList.length === 0) {
          setShowNewAddressFields(true);
        }
        return newList;
      });
    }
  };

  const relationshipOptions = [
    { value: 'Parent', label: 'Parent' },
    { value: 'Care Giver', label: 'Care Giver' },
    { value: 'Child', label: 'Child' },
    { value: 'Grandparent', label: 'Grandparent' },
    { value: 'Extended Family', label: 'Extended Family' },
    { value: 'Aunt', label: 'Aunt' },
    { value: 'Uncle', label: 'Uncle' },
    { value: 'Partner', label: 'Partner' },
  ].sort((a, b) => a.value.toUpperCase().localeCompare(b.value.toUpperCase()));

  const firstNationOptions = yukonFns.map((fn) => ({ value: fn.yukonFirstNationId, label: fn.name }));

  const indigenousTypeOptions = [
    { value: 'Registered', label: 'Registered' },
    { value: 'Affiliated', label: 'Affiliated' },
  ];

  const ancestrySideOptions = [
    { value: 'Mother', label: 'Mother' },
    { value: 'Father', label: 'Father' },
  ];

  const inGroupCareOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  const sortAddresses = (ads: IAddress[]) => {
    const activeAddresses = ads.filter((ad) => ad.isCurrent);
    const nonActiveAddresses = ads.filter((ad) => !ad.isCurrent);
    return activeAddresses.concat(nonActiveAddresses);
  };

  const setConfirmModalOpen = () => {
    setLeavePageConfirmOpen(true);
  };

  useEffect(() => {
    if (addingAncestry > 0) {
      var elmnt = document.getElementById('adding-new-ancestry');
      if (elmnt) {
        elmnt.scrollIntoView({ behavior: 'smooth' });
        ref.focus();
      }
    }
  }, [addingAncestry, ref]);

  useEffect(() => {
    if (addingAddress > 0) {
      var elmnt = document.getElementById('adding-new-address');
      if (elmnt) {
        elmnt.scrollIntoView({ behavior: 'smooth' });
      }

      var elmnt2 = document.getElementById('address-label');
      if (elmnt2) {
        elmnt2.focus();
      }
    }
  }, [addingAddress]);

  const deleteClient = () => {
    if (params.clientId) {
      if (!(hasFiles || hasWorkshops)) {
        authAxios.post('/api/clients/delete-client/' + params.clientId).then((response) => {
          if (response.data.status === 'OK') {
            notifySuccess('Client has been deleted.');
            history.push({ pathname: '/clients', state: { deletedClientId: params.clientId } });
          } else {
            notifyError(response.data.status);
          }
        });
      } else if (client.hasFiles) {
        setOpenAssociatedFilesModal(true);
      } else if (client.hasWorkshops) {
        setOpenAssociatedWorkshopsModal(true);
      }
    }
  };

  return (
    <div className={props.multiplePeopleFormat ? 'p-0' : ''}>
      {!props.disableLeaveConfirm && <LeavePageConfirm isDirty={isDirty || isAddressesDirty || isSupportDirty} />}
      <Navbar
        color={'light'}
        light={true}
        expand={'xs'}
        className={'border-bottom d-flex flex-wrap sticky-top'}
        id={'new-client-add'}
      >
        <NavbarBrand className={'w-mobile-100'}>
          {clientId
            ? props.multiplePeopleFormat
              ? originalLastName?.toUpperCase() + ', ' + originalFirstName
              : 'Edit Client'
            : 'Add New Client'}
          {!props.forWorkshop && clientId && (
            <small className={'text-muted'}>
              &nbsp;&nbsp;Last Updated: {lastUpdated !== undefined ? formatDateTime(new Date(lastUpdated)) : ''}
            </small>
          )}
        </NavbarBrand>
        <div
          className={'d-flex flex-grow-1 justify-content-end flex-wrap navbar-actions'}
          style={{ rowGap: '0.5rem', columnGap: '0.5rem' }}
        >
          {!clientId && !props.createCompleteAction ? (
            <>
              <Button color={'light'} className={'w-mobile-100'} onClick={() => history.push('/clients')}>
                Cancel
              </Button>
              <Button
                color={'primary'}
                className={'w-mobile-100'}
                onClick={() => {
                  if (checkIsSame()) {
                    setSameUserConfirmOpen(true);
                  } else {
                    saveClientDetails();
                  }
                }}
                disabled={editingSupportPerson || showNewAddressFields || !!editAddressId}
              >
                Save
              </Button>
            </>
          ) : isEditingAllowed ? (
            <>
              {props.cancelAction !== undefined ? (
                <Button
                  color={'light'}
                  className={'w-mobile-100'}
                  onClick={isDirty ? setConfirmModalOpen : props.cancelAction}
                >
                  Cancel
                </Button>
              ) : (
                <Button color={'light'} className={'w-mobile-100'} onClick={() => setIsEditingAllowed(false)}>
                  Cancel
                </Button>
              )}
              {props.multiplePeopleFormat && props.deleteAction && (
                <Button
                  color='danger'
                  className='w-mobile-100'
                  onClick={() => {
                    props.deleteAction && props.deleteAction();
                  }}
                >
                  {props.forWorkshop ? 'Remove Participant' : 'Remove Client'}
                </Button>
              )}

              {myUserContext.isSuperAdmin && params.clientId && (
                <Button color='danger' className={'w-mobile-100'} onClick={() => setOpenDeleteModal(true)}>
                  Delete Client
                </Button>
              )}

              <Button
                color={'primary'}
                className={'w-mobile-100'}
                onClick={() => {
                  if (!clientId && checkIsSame()) {
                    setSameUserConfirmOpen(true);
                  } else {
                    saveClientDetails();
                  }
                }}
                disabled={
                  (!isDirty && !props.multiplePeopleFormat) ||
                  props.disableActions ||
                  editingSupportPerson ||
                  showNewAddressFields ||
                  !!editAddressId
                }
              >
                {props.forWorkshop ? 'Save Client' : 'Save'}
              </Button>
            </>
          ) : (
            <>
              {myUser.departmentAccessIds.includes(DEPARTMENT_INTAKE) && params.clientId && (
                <Button
                  color={'primary'}
                  className={'w-mobile-100'}
                  tag={Link}
                  disabled={!active}
                  to={{
                    pathname: '/files/add',
                    state: { fileTypeId: FILE_TYPE_INTAKE, clientId: params.clientId },
                  }}
                >
                  <FaPlus className='mr-2' />
                  Intake
                </Button>
              )}
              {myUser.departmentAccessIds.includes(DEPARTMENT_BIRTH_WORKER) && params.clientId && (
                <Button
                  color={'primary'}
                  className={'w-mobile-100'}
                  tag={Link}
                  disabled={!active}
                  to={{
                    pathname: '/files/add',
                    state: { fileTypeId: FILE_TYPE_BIRTH_WORKER, clientId: params.clientId },
                  }}
                >
                  <FaPlus className='mr-2' />
                  Birth Worker File
                </Button>
              )}
              {myUser.departmentAccessIds.includes(DEPARTMENT_CULTURAL_CONNECTIONS) && params.clientId && (
                <Button
                  color={'primary'}
                  className={'w-mobile-100'}
                  tag={Link}
                  disabled={!active}
                  to={{
                    pathname: '/files/add',
                    state: { fileTypeId: FILE_TYPE_CULTURAL_CONNECTIONS, clientId: params.clientId },
                  }}
                >
                  <FaPlus className='mr-2' />
                  Cultural Connections File
                </Button>
              )}
              {myUser.departmentAccessIds.includes(DEPARTMENT_FAMILY_PRESERVATION) && params.clientId && (
                <Button
                  color={'primary'}
                  className={'w-mobile-100'}
                  tag={Link}
                  disabled={!active}
                  to={{
                    pathname: '/files/add',
                    state: { fileTypeId: FILE_TYPE_FAMILY_PRESERVATION, clientId: params.clientId },
                  }}
                >
                  <FaPlus className='mr-2' />
                  Client Services File
                </Button>
              )}
              {myUser.departmentAccessIds.includes(DEPARTMENT_JORDANS_PRINCIPLE) && params.clientId && (
                <Button
                  color={'primary'}
                  className={'w-mobile-100'}
                  tag={Link}
                  to={{
                    pathname: '/files/add',
                    state: { fileTypeId: FILE_TYPE_JORDANS_PRINCIPLE, clientId: params.clientId },
                  }}
                  disabled={!active}
                >
                  <FaPlus className='mr-2' />
                  Jordan&apos;s Principle File
                </Button>
              )}
              {myUser.departmentAccessIds.includes(DEPARTMENT_JUSTICE) && params.clientId && mobile && (
                <>
                  <Button
                    color={'primary'}
                    tag={Link}
                    className={'w-mobile-100'}
                    to={{
                      pathname: '/files/add',
                      state: {
                        fileTypeId: FILE_TYPE_JUSTICE,
                        fileSubTypeId: FILE_SUB_TYPE_WCC_CONNECTIVE,
                        clientId: params.clientId,
                      },
                    }}
                    disabled={!active}
                  >
                    <FaPlus className='mr-2' />
                    WCC/Connective File
                  </Button>
                  <Button
                    color={'primary'}
                    tag={Link}
                    className={'w-mobile-100'}
                    to={{
                      pathname: '/files/add',
                      state: {
                        fileTypeId: FILE_TYPE_JUSTICE,
                        fileSubTypeId: FILE_SUB_TYPE_GLADUE_REPORT,
                        clientId: params.clientId,
                      },
                    }}
                    disabled={!active}
                  >
                    <FaPlus className='mr-2' />
                    Gladue Report
                  </Button>
                </>
              )}
              {myUser.departmentAccessIds.includes(DEPARTMENT_JUSTICE) && params.clientId && !mobile && (
                <ButtonDropdown
                  isOpen={justiceCreateDropDownOpen}
                  toggle={() => setJusticeCreateDropDownOpen((s) => !s)}
                  style={{ width: 'auto', height: 'auto' }}
                  disabled={!active}
                >
                  <DropdownToggle color={'primary'} caret={true} disabled={!active}>
                    <FaPlus className='mr-2' />
                    Justice File
                  </DropdownToggle>
                  <DropdownMenu style={{ width: '250px', overflowY: 'auto' }} className={'p-0'} right={true}>
                    <ButtonGroup vertical={true} align={'left'}>
                      <Button
                        color={'primary'}
                        className={'text-left'}
                        tag={Link}
                        to={{
                          pathname: '/files/add',
                          state: {
                            fileTypeId: FILE_TYPE_JUSTICE,
                            fileSubTypeId: FILE_SUB_TYPE_WCC_CONNECTIVE,
                            clientId: params.clientId,
                          },
                        }}
                      >
                        <FaPlus className='mr-2' />
                        WCC/Connective
                      </Button>
                      <Button
                        color={'primary'}
                        className={'text-left'}
                        tag={Link}
                        to={{
                          pathname: '/files/add',
                          state: {
                            fileTypeId: FILE_TYPE_JUSTICE,
                            fileSubTypeId: FILE_SUB_TYPE_GLADUE_REPORT,
                            clientId: params.clientId,
                          },
                        }}
                      >
                        <FaPlus className='mr-2' />
                        Gladue Report
                      </Button>
                      <Button
                        color={'primary'}
                        className={'text-left'}
                        tag={Link}
                        to={{
                          pathname: '/files/add',
                          state: {
                            fileTypeId: FILE_TYPE_JUSTICE,
                            fileSubTypeId: FILE_SUB_TYPE_COMMUNITY_IMPACT,
                            clientId: params.clientId,
                          },
                        }}
                      >
                        <FaPlus className='mr-2' />
                        Community Impact
                      </Button>
                      <Button
                        color={'primary'}
                        className={'text-left'}
                        tag={Link}
                        to={{
                          pathname: '/files/add',
                          state: {
                            fileTypeId: FILE_TYPE_JUSTICE,
                            fileSubTypeId: FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR,
                            clientId: params.clientId,
                          },
                        }}
                      >
                        <FaPlus className='mr-2' />
                        Legal System Navigator
                      </Button>
                    </ButtonGroup>
                  </DropdownMenu>
                </ButtonDropdown>
              )}
              <Button color={'primary'} onClick={() => setIsEditingAllowed(true)}>
                Edit Details
              </Button>
            </>
          )}
        </div>
      </Navbar>
      {!isEditingAllowed && (
        <Container fluid={true} className={'my-3'}>
          {client && client.clientId && <ClientDisplay client={client} />}
        </Container>
      )}
      {isEditingAllowed && (
        <Container fluid={true} className={'my-3'}>
          {props.showRelationship && (
            <Row className={'mb-3'}>
              <Col md={3}>
                <RequiredLabel required={!relationship || relationship.length === 0}>Relationship</RequiredLabel>
                <CreatableSelect
                  autoFocus={props.autoFocus}
                  formatCreateLabel={(inputValue: string) => inputValue}
                  styles={disabledSelectStyles}
                  placeholder={<span className='placeholder'>Select...</span>}
                  options={[
                    ...relationshipOptions,
                    ...(!relationship || relationshipOptions.some((e) => e.value === relationship)
                      ? []
                      : [{ value: relationship, label: relationship }]),
                  ]}
                  value={relationship ? { value: relationship, label: relationship } : undefined}
                  onChange={(val) => {
                    setIsDirty(true);
                    setRelationship(val ? val.value : undefined);
                  }}
                  isClearable={true}
                  isMulti={false}
                  // isDisabled={!props.isEditingAllowed}
                  // styles={disabledSelectStyles}
                />
                <FormFeedback>This field is mandatory</FormFeedback>
                {trySubmit && !relationship && (
                  <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                )}
              </Col>
            </Row>
          )}
          {!props.forWorkshop && (
            <>
              <Row className={'mb-3'}>
                <Col md={3}>
                  <RequiredLabel required={!firstName || firstName.length === 0}>First Name</RequiredLabel>
                  <Input
                    type='text'
                    autoFocus={!props.showRelationship && props.autoFocus}
                    placeholder=''
                    name='name'
                    autoComplete={'off'}
                    disabled={!isEditingAllowed}
                    value={firstName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFirstName(e.target.value);
                      setIsDirty(true);
                    }}
                    invalid={trySubmit && firstName === ''}
                  />
                  <FormFeedback>This field is mandatory</FormFeedback>
                </Col>
                <Col md={3}>
                  <Label>Middle Name</Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='middleName'
                    autoComplete={'off'}
                    disabled={!isEditingAllowed}
                    value={middleName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setMiddleName(e.target.value);
                      setIsDirty(true);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <RequiredLabel required={!lastName || lastName.length === 0}>Last Name</RequiredLabel>
                  <Input
                    type='text'
                    placeholder=''
                    name='lastName'
                    autoComplete={'off'}
                    disabled={!isEditingAllowed}
                    value={lastName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setLastName(e.target.value);
                      setIsDirty(true);
                    }}
                    invalid={trySubmit && lastName === ''}
                  />
                  <FormFeedback>This field is mandatory</FormFeedback>
                </Col>
                <Col md={3}>
                  <RequiredLabel required={dateOfBirth === undefined && !!props.dateOfBirthRequired}>
                    Date of Birth {dateOfBirth ? ' (Current Age: ' + getAge(dateOfBirth) + ')' : ''}
                  </RequiredLabel>
                  <DatePicker
                    selected={dateOfBirth}
                    onChange={(date) => {
                      if (date) {
                        setDateOfBirth(date);
                        setIsDirty(true);
                      } else {
                        setDateOfBirth(undefined);
                        setIsDirty(true);
                      }
                    }}
                    popperPlacement={'bottom-end'}
                    isClearable={true}
                    peekNextMonth
                    showMonthDropdown
                    disabled={!isEditingAllowed}
                    showYearDropdown
                    dateFormat='MMM dd, yyyy'
                    dropdownMode='select'
                    maxDate={new Date()}
                    className={isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'}
                    placeholderText={'MMM dd, yyyy'}
                  />
                  {trySubmit && dateOfBirth === undefined && !!props.dateOfBirthRequired && (
                    <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                  )}
                </Col>
              </Row>

              <Row className={'mb-3'}>
                <Col md={3}>
                  <Label>Gender</Label>
                  <Select
                    styles={disabledSelectStyles}
                    name={'gender'}
                    placeholder={<span className='placeholder'>Select...</span>}
                    options={genders}
                    value={genders.find((g) => g.genderId === genderId) || null}
                    onChange={(value: any) => {
                      setGenderId(value?.genderId || '');
                      setIsDirty(true);
                    }}
                    getOptionValue={(option: IGender) => option.genderId}
                    getOptionLabel={(option: IGender) => option.name}
                    isClearable={true}
                    isDisabled={!isEditingAllowed}
                  />
                </Col>
                <Col md={3}>
                  <Label>Email</Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='email'
                    autoComplete={'off'}
                    disabled={!isEditingAllowed}
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value);
                      setIsDirty(true);
                    }}
                    invalid={trySubmit && !FormHelpers.isValidEmail(email)}
                  />
                  <FormFeedback>Invalid Email</FormFeedback>
                </Col>

                <Col md={3}>
                  <Label>Community</Label>
                  <Select
                    styles={disabledSelectStyles}
                    name={'fileTypeId'}
                    placeholder={<span className='placeholder'>Select...</span>}
                    options={communities}
                    value={communities.find((c: ICommunity) => c.communityId === communityId) || null}
                    onChange={(value: any) => {
                      setCommunityId(value?.communityId || '');
                      setIsDirty(true);
                    }}
                    getOptionValue={(option: ICommunity) => option.communityId}
                    getOptionLabel={(option: ICommunity) => option.name}
                    isDisabled={!isEditingAllowed}
                    isClearable={true}
                  />
                </Col>
                <Col md={3}>
                  <Label>School</Label>
                  <Input
                    type='text'
                    placeholder='Enter School... '
                    name='school'
                    autoComplete={'off'}
                    disabled={!isEditingAllowed}
                    value={school}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSchool(e.target.value);
                      setIsDirty(true);
                    }}
                  />
                </Col>
              </Row>
              <Row className={'mb-3 align-items-end'}>
                <Col md={3}>
                  <Label>In Group Care</Label>
                  <Select
                    name={'inGroupCare'}
                    options={inGroupCareOptions}
                    styles={disabledSelectStyles}
                    placeholder={<span className='placeholder'>Select...</span>}
                    value={inGroupCareOptions.find((d: any) => d.value === inGroupCare)}
                    type={'text'}
                    onChange={(value: any) => {
                      setIsDirty(true);
                      setInGroupCare(value.value);
                      setGroupHomeId('');
                    }}
                    getOptionValue={(option: any) => option.value}
                    getOptionLabel={(option: any) => option.label}
                  />
                </Col>
                <Col md={3}>
                  <Label>Group Home</Label>
                  <Select
                    name={'groupCareId'}
                    styles={disabledSelectStyles}
                    isDisabled={!inGroupCare}
                    options={groupHomes}
                    placeholder={<span className='placeholder'>Select...</span>}
                    value={groupHomes.find((d: any) => d.groupHomeId === groupHomeId) ?? null}
                    onChange={(value: any) => {
                      setIsDirty(true);
                      setGroupHomeId(value.groupHomeId);
                    }}
                    getOptionValue={(option: IGroupHome) => option.groupHomeId}
                    getOptionLabel={(option: IGroupHome) => option.name}
                  />
                </Col>
                <Col md={3}>
                  <Label>Status #</Label>
                  <Input
                    type='text'
                    placeholder='Enter First Nation... '
                    name='statusNumber'
                    autoComplete={'off'}
                    disabled={!isEditingAllowed}
                    value={statusCardNum}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setStatusCardNum(e.target.value);
                      setIsDirty(true);
                    }}
                  />
                </Col>
                {myUser.isSuperAdmin && (
                  <Col>
                    <CustomInput
                      className={'mt-3 mb-2'}
                      type='checkbox'
                      id={'active'}
                      name={'active'}
                      label={'Active'}
                      checked={active}
                      onChange={() => {
                        setActive((s) => !s);
                        setIsDirty(true);
                      }}
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
          {props.forWorkshop && (
            <>
              <Row>
                <Col md={3}>
                  <RequiredLabel required={!firstName || firstName.length === 0}>First Name</RequiredLabel>
                  <Input
                    type='text'
                    autoFocus={!props.showRelationship && props.autoFocus}
                    placeholder=''
                    name='name'
                    autoComplete={'off'}
                    disabled={!isEditingAllowed}
                    value={firstName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFirstName(e.target.value);
                      setIsDirty(true);
                    }}
                    invalid={trySubmit && firstName === ''}
                  />
                  <FormFeedback>This field is mandatory</FormFeedback>
                </Col>
                <Col md={3}>
                  <Label>Middle Name</Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='middleName'
                    autoComplete={'off'}
                    disabled={!isEditingAllowed}
                    value={middleName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setMiddleName(e.target.value);
                      setIsDirty(true);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <RequiredLabel required={!lastName || lastName.length === 0}>Last Name</RequiredLabel>
                  <Input
                    type='text'
                    placeholder=''
                    name='lastName'
                    autoComplete={'off'}
                    disabled={!isEditingAllowed}
                    value={lastName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setLastName(e.target.value);
                      setIsDirty(true);
                    }}
                    invalid={trySubmit && lastName === ''}
                  />
                  <FormFeedback>This field is mandatory</FormFeedback>
                </Col>
                <Col md={3}>
                  <Label>Email</Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='email'
                    autoComplete={'off'}
                    disabled={!isEditingAllowed}
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value);
                      setIsDirty(true);
                    }}
                    invalid={trySubmit && !FormHelpers.isValidEmail(email)}
                  />
                  <FormFeedback>Invalid Email</FormFeedback>
                </Col>
              </Row>
              <Row className={'my-2'}>
                <Col md={3}>
                  <Label>In Group Care</Label>
                  <Select
                    name={'inGroupCare'}
                    options={inGroupCareOptions}
                    styles={disabledSelectStyles}
                    placeholder={<span className='placeholder'>Select...</span>}
                    value={inGroupCareOptions.find((d: any) => d.value === inGroupCare)}
                    type={'text'}
                    onChange={(value: any) => {
                      setIsDirty(true);
                      setInGroupCare(value.value);
                      setGroupHomeId('');
                    }}
                    getOptionValue={(option: any) => option.value}
                    getOptionLabel={(option: any) => option.label}
                  />
                </Col>
                <Col md={3}>
                  <Label>Group Home</Label>
                  <Select
                    name={'groupCareId'}
                    styles={disabledSelectStyles}
                    isDisabled={!inGroupCare}
                    options={groupHomes}
                    placeholder={<span className='placeholder'>Select...</span>}
                    value={groupHomes.find((d: any) => d.groupHomeId === groupHomeId) ?? null}
                    onChange={(value: any) => {
                      setIsDirty(true);
                      setGroupHomeId(value.groupHomeId);
                    }}
                    getOptionValue={(option: IGroupHome) => option.groupHomeId}
                    getOptionLabel={(option: IGroupHome) => option.name}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Card className={'mb-3 p-2'}>
                    <Row style={{ marginLeft: -12, marginRight: 42 }}>
                      <Col md={6}>
                        <Label>Indigenous Group</Label>
                      </Col>
                      <Col md={6}>
                        <Label>First Nation / Band / Council</Label>
                      </Col>
                    </Row>
                    <ListGroup flush={true} style={{ borderTop: 0 }}>
                      {indigenousInfo.map((ii: IIndigenousInformation, index: number) => {
                        return (
                          <ListGroupItem
                            key={index}
                            className={'d-flex flex-row align-items-center border-0 pt-0'}
                            id={'adding-new-ancestry'}
                          >
                            <div className={'flex-fill'}>
                              <Row>
                                <Col md={6} className={'pl-0'}>
                                  <Select
                                    name={'indigenousGroup'}
                                    styles={disabledSelectStyles}
                                    options={indigenousGroups}
                                    placeholder={<span className='placeholder'>Select...</span>}
                                    value={indigenousGroups.find(
                                      (d: any) => d.indigenousGroupId === ii.indigenousGroupId
                                    )}
                                    onChange={(value: any) => {
                                      setIsDirty(true);
                                      setIndigenousInfo((existingII) => [
                                        ...existingII.slice(0, index),
                                        {
                                          ...existingII[index],
                                          indigenousGroupId: value ? value.indigenousGroupId : '',
                                          bandNation: '',
                                        },
                                        ...existingII.slice(index + 1),
                                      ]);
                                    }}
                                    getOptionValue={(option: IIndigenousGroup) => option.indigenousGroupId}
                                    getOptionLabel={(option: IIndigenousGroup) => option.name}
                                  />
                                </Col>
                                {(ii.indigenousGroupId === INDIGENOUS_GROUP_FIRST_NATION ||
                                  ii.indigenousGroupId === '') && (
                                  <Col md={6} className={'pl-3'}>
                                    {/*<Label>First Nation</Label>*/}
                                    <CreatableSelect
                                      formatCreateLabel={(inputValue: string) => inputValue}
                                      placeholder={<span className='placeholder'>Select or Type...</span>}
                                      styles={disabledSelectStyles}
                                      options={[
                                        ...firstNationOptions,
                                        ...(!ii.bandNation || firstNationOptions.some((e) => e.value === ii.bandNation)
                                          ? []
                                          : [{ value: ii.bandNation, label: ii.bandNation }]),
                                      ]}
                                      ref={(r) => {
                                        ref = r;
                                      }}
                                      value={
                                        ii.bandNation
                                          ? firstNationOptions
                                              .map((firstNation) => firstNation.value)
                                              .includes(ii.bandNation)
                                            ? {
                                                value: ii.bandNation,
                                                label: firstNationOptions.find(
                                                  (firstNation) => firstNation.value === ii.bandNation
                                                )?.label,
                                              }
                                            : { value: ii.bandNation, label: ii.bandNation }
                                          : undefined
                                      }
                                      onChange={(val) => {
                                        setIsDirty(true);
                                        setIndigenousInfo((existingFN) => [
                                          ...existingFN.slice(0, index),
                                          { ...existingFN[index], bandNation: val ? val.value : '' },
                                          ...existingFN.slice(index + 1),
                                        ]);
                                      }}
                                      isMulti={false}
                                      isClearable={true}
                                    />
                                  </Col>
                                )}
                                {(ii.indigenousGroupId === INDIGENOUS_GROUP_INUIT ||
                                  ii.indigenousGroupId === INDIGENOUS_GROUP_METIS ||
                                  ii.indigenousGroupId === INDIGENOUS_GROUP_OTHER) && (
                                  <Col md={6}>
                                    <Input
                                      type='text'
                                      placeholder='Type... '
                                      name='band'
                                      autoComplete={'off'}
                                      disabled={!isEditingAllowed}
                                      value={ii.bandNation}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const detail = e.target.value;
                                        setIsDirty(true);
                                        setIndigenousInfo((existingFN) => [
                                          ...existingFN.slice(0, index),
                                          { ...existingFN[index], bandNation: detail },
                                          ...existingFN.slice(index + 1),
                                        ]);
                                      }}
                                    />
                                  </Col>
                                )}
                                <Col md={1}></Col>
                              </Row>
                            </div>
                            <Button
                              color={'link'}
                              className={'text-danger '}
                              onClick={() => {
                                setIsDirty(true);
                                setIndigenousInfo((existingFN) => [
                                  ...existingFN.slice(0, index),
                                  ...existingFN.slice(index + 1),
                                ]);
                              }}
                            >
                              <FaTimes />
                            </Button>
                          </ListGroupItem>
                        );
                      })}
                      <ListGroupItem
                        tag={'button'}
                        className={indigenousInfo.length === 0 ? 'pl-0 pb-0 pt-2' : 'pl-0 py-0'}
                        onClick={() => {
                          setIndigenousInfo((ii) => ii.concat([{ indigenousGroupId: INDIGENOUS_GROUP_FIRST_NATION }]));
                          setAddingAncestry((a) => a + 1);
                        }}
                        disabled={showNewAddressFields}
                      >
                        <FaPlus /> Add Indigenous Ancestry
                      </ListGroupItem>
                    </ListGroup>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className={'mb-3 p-2'}>
                    <Row style={{ marginLeft: -12, marginRight: 42 }}>
                      <Col>
                        <Label>Phone Number{phoneNumbers.length > 1 ? 's' : ''}</Label>
                      </Col>
                    </Row>
                    <ListGroup flush={true} style={{ borderTop: 0 }}>
                      {phoneNumbers.map((p: IPhoneNumber, index: number) => (
                        <ListGroupItem
                          key={`phoneNumber_${index}`}
                          className={'d-flex flex-row align-items-center border-0 pt-0'}
                        >
                          <div className={'flex-fill'}>
                            <Row>
                              <Col md={6} className={'pl-0'}>
                                <CreatableSelect
                                  formatCreateLabel={(inputValue: string) => inputValue}
                                  placeholder={<span className='placeholder'>Select...</span>}
                                  options={[
                                    ...phoneOptions,
                                    ...(!p.description || phoneOptions.some((e) => e.value === p.description)
                                      ? []
                                      : [{ value: p.description, label: p.description }]),
                                  ]}
                                  value={p.description ? { value: p.description, label: p.description } : undefined}
                                  onChange={(val) => {
                                    setIsDirty(true);
                                    setPhoneNumbers((s: IPhoneNumber[]) => {
                                      return [
                                        ...s.slice(0, index),
                                        { ...s[index], description: (val as { value: string })?.value || '' },
                                        ...s.slice(index + 1),
                                      ];
                                    });
                                  }}
                                  isDisabled={!isEditingAllowed}
                                  styles={disabledSelectStyles}
                                />
                              </Col>
                              <Col md={6} className={'pl-3'}>
                                <Input
                                  type='tel'
                                  placeholder='Enter number…'
                                  name='phone'
                                  autoComplete={'off'}
                                  value={p.number || ''}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const number = e.target.value;
                                    setPhoneNumbers((s: IPhoneNumber[]) => {
                                      return [...s.slice(0, index), { ...s[index], number }, ...s.slice(index + 1)];
                                    });
                                    setIsDirty(true);
                                  }}
                                  disabled={!isEditingAllowed}
                                />
                                <FormFeedback>This field is mandatory</FormFeedback>
                              </Col>
                            </Row>
                          </div>
                          {isEditingAllowed && (
                            <Button
                              color={'link'}
                              className={'pr-0 text-danger'}
                              onClick={() => {
                                setPhoneNumbers((s) => [...s.slice(0, index), ...s.slice(index + 1)]);
                              }}
                              aria-label={'Remove Phone Number'}
                              title={'Remove Phone Number'}
                            >
                              <FaTimes />
                            </Button>
                          )}
                        </ListGroupItem>
                      ))}
                      {isEditingAllowed && (
                        <ListGroupItem
                          tag={'button'}
                          className={phoneNumbers.length === 0 ? 'pl-0 pb-0 pt-2' : 'pl-0 py-0'}
                          onClick={() => {
                            setPhoneNumbers((s) => [...s, { number: '', description: '' } as IPhoneNumber]);
                            setIsDirty(true);
                          }}
                        >
                          <FaPlus /> Add Phone Number
                        </ListGroupItem>
                      )}
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
            </>
          )}

          {myUser.isSuperAdmin && clientId && !props.forWorkshop && (
            <Row>
              <Col xs={12}>
                <CustomInput
                  type='checkbox'
                  id={'flagged'}
                  name={'flagged'}
                  label={'Flagged'}
                  checked={flagged}
                  onChange={() => {
                    setFlagged((s) => {
                      if (s) {
                        setFlaggedDescription('');
                      }
                      return !s;
                    });
                    setIsDirty(true);
                  }}
                />
              </Col>
              {flagged && (
                <Col xs={12}>
                  <Label>Flagged Description</Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='description'
                    autoComplete={'off'}
                    disabled={!isEditingAllowed}
                    value={flaggedDescription}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFlaggedDescription(e.target.value);
                      setIsDirty(true);
                    }}
                  />
                </Col>
              )}
            </Row>
          )}
          {!props.forWorkshop && (
            <>
              <Row className={'mt-3'}>
                <Col>
                  <Card>
                    <CardHeader>Indigenous Ancestry</CardHeader>
                    <ListGroup flush={true}>
                      {indigenousInfo.map((ii: IIndigenousInformation, index: number) => {
                        return (
                          <ListGroupItem
                            key={index}
                            className={'d-flex list-group-item--borderless'}
                            id={'adding-new-ancestry'}
                          >
                            <Row className={'flex-grow-1'}>
                              <Col md={3}>
                                <Label>Indigenous Group</Label>
                                <Select
                                  name={'indigenousGroup'}
                                  styles={disabledSelectStyles}
                                  options={indigenousGroups}
                                  placeholder={<span className='placeholder'>Select...</span>}
                                  value={indigenousGroups.find(
                                    (d: any) => d.indigenousGroupId === ii.indigenousGroupId
                                  )}
                                  onChange={(value: any) => {
                                    setIsDirty(true);
                                    setIndigenousInfo((existingII) => [
                                      ...existingII.slice(0, index),
                                      {
                                        ...existingII[index],
                                        indigenousGroupId: value ? value.indigenousGroupId : '',
                                        bandNation: '',
                                      },
                                      ...existingII.slice(index + 1),
                                    ]);
                                  }}
                                  getOptionValue={(option: IIndigenousGroup) => option.indigenousGroupId}
                                  getOptionLabel={(option: IIndigenousGroup) => option.name}
                                />
                              </Col>
                              {ii.indigenousGroupId === INDIGENOUS_GROUP_FIRST_NATION && (
                                <Col md={3}>
                                  <Label>First Nation</Label>
                                  <CreatableSelect
                                    formatCreateLabel={(inputValue: string) => inputValue}
                                    placeholder={<span className='placeholder'>Select or Type...</span>}
                                    styles={disabledSelectStyles}
                                    options={[
                                      ...firstNationOptions,
                                      ...(!ii.bandNation || firstNationOptions.some((e) => e.value === ii.bandNation)
                                        ? []
                                        : [{ value: ii.bandNation, label: ii.bandNation }]),
                                    ]}
                                    ref={(r) => {
                                      ref = r;
                                    }}
                                    value={
                                      ii.bandNation
                                        ? firstNationOptions
                                            .map((firstNation) => firstNation.value)
                                            .includes(ii.bandNation)
                                          ? {
                                              value: ii.bandNation,
                                              label: firstNationOptions.find(
                                                (firstNation) => firstNation.value === ii.bandNation
                                              )?.label,
                                            }
                                          : { value: ii.bandNation, label: ii.bandNation }
                                        : undefined
                                    }
                                    onChange={(val) => {
                                      setIsDirty(true);
                                      setIndigenousInfo((existingFN) => [
                                        ...existingFN.slice(0, index),
                                        { ...existingFN[index], bandNation: val ? val.value : '' },
                                        ...existingFN.slice(index + 1),
                                      ]);
                                    }}
                                    isMulti={false}
                                    isClearable={true}
                                  />
                                </Col>
                              )}
                              {(ii.indigenousGroupId === INDIGENOUS_GROUP_INUIT ||
                                ii.indigenousGroupId === INDIGENOUS_GROUP_METIS ||
                                ii.indigenousGroupId === INDIGENOUS_GROUP_OTHER) && (
                                <Col md={3}>
                                  <Label>Band / Council</Label>
                                  <Input
                                    type='text'
                                    placeholder='Type... '
                                    name='band'
                                    autoComplete={'off'}
                                    disabled={!isEditingAllowed}
                                    value={ii.bandNation}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      const detail = e.target.value;
                                      setIsDirty(true);
                                      setIndigenousInfo((existingFN) => [
                                        ...existingFN.slice(0, index),
                                        { ...existingFN[index], bandNation: detail },
                                        ...existingFN.slice(index + 1),
                                      ]);
                                    }}
                                  />
                                </Col>
                              )}
                              <Col md={3}>
                                <Label>Indigenous Type</Label>
                                <Select
                                  name={'indigenousType'}
                                  options={indigenousTypeOptions}
                                  styles={disabledSelectStyles}
                                  placeholder={<span className='placeholder'>Select...</span>}
                                  value={indigenousTypeOptions.find((d: any) => d.value === ii.indigenousType)}
                                  onChange={(value: any) => {
                                    setIsDirty(true);
                                    setIndigenousInfo((existingFN) => [
                                      ...existingFN.slice(0, index),
                                      { ...existingFN[index], indigenousType: value ? value.value : '' },
                                      ...existingFN.slice(index + 1),
                                    ]);
                                  }}
                                  getOptionValue={(option: any) => option.value}
                                  getOptionLabel={(option: any) => option.label}
                                />
                              </Col>
                              <Col md={3}>
                                <Label>Ancestry Side</Label>
                                <Select
                                  name={'ancestrySide'}
                                  options={ancestrySideOptions}
                                  styles={disabledSelectStyles}
                                  placeholder={<span className='placeholder'>Select...</span>}
                                  value={ancestrySideOptions.find((d: any) => d.value === ii.heritage)}
                                  onChange={(value: any) => {
                                    setIsDirty(true);
                                    setIndigenousInfo((existingFN) => [
                                      ...existingFN.slice(0, index),
                                      { ...existingFN[index], heritage: value ? value.value : '' },
                                      ...existingFN.slice(index + 1),
                                    ]);
                                  }}
                                  getOptionValue={(option: any) => option.value}
                                  getOptionLabel={(option: any) => option.label}
                                  isClearable={true}
                                />
                              </Col>
                            </Row>
                            <Button
                              color={'link'}
                              className={'text-danger w-20 mt-4'}
                              onClick={() => {
                                setIsDirty(true);
                                setIndigenousInfo((existingFN) => [
                                  ...existingFN.slice(0, index),
                                  ...existingFN.slice(index + 1),
                                ]);
                              }}
                            >
                              <FaTimes />
                            </Button>
                          </ListGroupItem>
                        );
                      })}
                      <ListGroupItem
                        tag={'button'}
                        className='border-top'
                        onClick={() => {
                          setIndigenousInfo((ii) => ii.concat([{ indigenousGroupId: INDIGENOUS_GROUP_FIRST_NATION }]));
                          setAddingAncestry((a) => a + 1);
                        }}
                        disabled={showNewAddressFields}
                      >
                        <FaPlus /> Add Indigenous Ancestry
                      </ListGroupItem>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col>
                  <Card className={'mb-3'}>
                    <CardHeader className={'d-flex align-items-center'}>Addresses</CardHeader>
                    <ListGroup flush={true}>
                      {sortAddresses(addresses).map((address: IAddress) => {
                        if (editAddressId === address.addressId) {
                          return (
                            <ListGroupItem key={`addresses-listGroupItem-edit`} className={'p-0'}>
                              <ClientAddress
                                clientId={clientId}
                                editAddress={address}
                                deleteAction={(deleteAddress: IAddress) => {
                                  setAddresses((a) => [...a.filter((e) => e.addressId !== deleteAddress.addressId)]);
                                  setIsAddressesDirty(false);
                                }}
                                saveAction={(updatedAddress: IAddress) => {
                                  setAddresses((a) => [
                                    ...a.filter((e) => e.addressId !== updatedAddress.addressId),
                                    updatedAddress,
                                  ]);
                                  setEditAddressId(undefined);
                                  setIsAddressesDirty(false);
                                }}
                                cancel={() => {
                                  setEditAddressId(undefined);
                                  setIsAddressesDirty(false);
                                }}
                                setIsDirty={setIsAddressesDirty}
                              />
                            </ListGroupItem>
                          );
                        } else {
                          return (
                            <ListGroupItem
                              key={`addresses-listGroupItem-${address.addressId}`}
                              className={'d-flex align-items-start'}
                              onClick={() => setEditAddressId(address.addressId)}
                              tag={'button'}
                              disabled={!isEditingAllowed || showNewAddressFields}
                            >
                              <div>
                                <span>
                                  <b>{address.label}</b>
                                </span>
                                {address.isCurrent ? (
                                  <Badge color='secondary' className='ml-2'>
                                    Current Address
                                  </Badge>
                                ) : (
                                  <Badge color='light' className='ml-2'>
                                    Past Address
                                  </Badge>
                                )}
                                <div className={'street-address'}>{address.line1}</div>
                                {address.line2 && <div className={'extended-address'}>{address.line2}</div>}
                                {(address.city !== '' || address.territory !== '' || address.postalCode !== '') && (
                                  <div>{address.city + ', ' + address.territory + ', ' + address.postalCode}</div>
                                )}
                              </div>
                            </ListGroupItem>
                          );
                        }
                      })}
                      {isEditingAllowed && !showNewAddressFields && (
                        <ListGroupItem
                          tag={'button'}
                          onClick={() => {
                            setEditAddressId(undefined);
                            setShowNewAddressFields(true);
                            setAddingAddress((a) => a + 1);
                          }}
                          disabled={showNewAddressFields}
                        >
                          <FaPlus /> Add Address
                        </ListGroupItem>
                      )}
                      {isEditingAllowed && showNewAddressFields && (
                        <ListGroupItem className={'p-0'}>
                          <ClientAddress
                            clientId={clientId}
                            saveAction={(newAddress: IAddress) => {
                              setAddresses((a) => [...a, newAddress]);
                              setShowNewAddressFields(false);
                              setIsAddressesDirty(false);
                            }}
                            setIsDirty={setIsAddressesDirty}
                            cancel={() => {
                              setShowNewAddressFields(false);
                              setIsAddressesDirty(false);
                            }}
                          />
                        </ListGroupItem>
                      )}
                    </ListGroup>
                  </Card>
                </Col>
                <Col>
                  <Card className={'mb-3'}>
                    <CardHeader className={'d-flex align-items-center'}>Phone Numbers</CardHeader>
                    <ListGroup flush={true}>
                      {(phoneNumbers || []).map((p: IPhoneNumber, index: number) => (
                        <ListGroupItem key={`phoneNumber_${index}`} className={'d-flex flex-row align-items-center'}>
                          <div className={'mr-2 flex-fill'}>
                            <Row>
                              <Col>
                                <CreatableSelect
                                  formatCreateLabel={(inputValue: string) => inputValue}
                                  placeholder={<span className='placeholder'>Select...</span>}
                                  options={[
                                    ...phoneOptions,
                                    ...(!p.description || phoneOptions.some((e) => e.value === p.description)
                                      ? []
                                      : [{ value: p.description, label: p.description }]),
                                  ]}
                                  value={p.description ? { value: p.description, label: p.description } : undefined}
                                  onChange={(val) => {
                                    setIsDirty(true);
                                    setPhoneNumbers((s: IPhoneNumber[]) => {
                                      return [
                                        ...s.slice(0, index),
                                        { ...s[index], description: (val as { value: string })?.value || '' },
                                        ...s.slice(index + 1),
                                      ];
                                    });
                                  }}
                                  isDisabled={!isEditingAllowed}
                                  styles={disabledSelectStyles}
                                />
                              </Col>
                              <Col>
                                <Input
                                  type='tel'
                                  placeholder='Enter number…'
                                  name='phone'
                                  autoComplete={'off'}
                                  value={p.number || ''}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const number = e.target.value;
                                    setPhoneNumbers((s: IPhoneNumber[]) => {
                                      return [...s.slice(0, index), { ...s[index], number }, ...s.slice(index + 1)];
                                    });
                                    setIsDirty(true);
                                  }}
                                  disabled={!isEditingAllowed}
                                />
                                <FormFeedback>This field is mandatory</FormFeedback>
                              </Col>
                            </Row>
                          </div>
                          {isEditingAllowed && (
                            <Button
                              color={'link'}
                              className={'pr-0 text-danger'}
                              onClick={() => {
                                setPhoneNumbers((s) => [...s.slice(0, index), ...s.slice(index + 1)]);
                                setIsDirty(true);
                              }}
                            >
                              <FaTimes />
                            </Button>
                          )}
                        </ListGroupItem>
                      ))}
                      {isEditingAllowed && (
                        <ListGroupItem
                          tag={'button'}
                          onClick={() => {
                            setPhoneNumbers((s) => [...s, { number: '', description: '' } as IPhoneNumber]);
                            setIsDirty(true);
                          }}
                        >
                          <FaPlus /> Add Phone Number
                        </ListGroupItem>
                      )}
                    </ListGroup>
                  </Card>
                </Col>
                <Col xs={12}>
                  <ClientSupportTeam
                    setEditingSupportPerson={setEditingSupportPerson}
                    disableActions={false}
                    setIsDirty={setIsSupportDirty}
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      )}
      <Modal isOpen={openDeleteModal} toggle={() => setOpenDeleteModal(!openDeleteModal)} className='logout-modal'>
        <ModalHeader toggle={() => setOpenDeleteModal(!openDeleteModal)}>Delete Client</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>Are you sure you want to delete this client? This action cannot be undone.</div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              deleteClient();
              setOpenDeleteModal(false);
            }}
          >
            Delete Client
          </Button>
          <Button color={'light'} onClick={() => setOpenDeleteModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={openAssociatedFilesModal}
        toggle={() => setOpenAssociatedFilesModal(!openAssociatedFilesModal)}
        className='logout-modal'
      >
        <ModalHeader toggle={() => setOpenAssociatedFilesModal(!openAssociatedFilesModal)}>
          Unable to Delete Client
        </ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            This client is associated with at least one file. Please remove this client from all files before deleting.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'primary'}
            onClick={() => {
              setOpenAssociatedFilesModal(false);
            }}
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={openAssociatedWorkshopsModal}
        toggle={() => setOpenAssociatedWorkshopsModal(!openAssociatedWorkshopsModal)}
        className='logout-modal'
      >
        <ModalHeader toggle={() => setOpenAssociatedWorkshopsModal(!openAssociatedWorkshopsModal)}>
          Unable to Delete Client
        </ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            This client is a participant in at least one workshop. Please remove this client from all workshops before
            deleting.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'primary'}
            onClick={() => {
              setOpenAssociatedWorkshopsModal(false);
            }}
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={leavePageConfirmOpen}>
        <ModalHeader>You have unsaved changes, are you sure you want to leave?</ModalHeader>
        <ModalBody>Your current changes will be discarded. You won&apos;t be able to undo this action.</ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={props.cancelAction}>
            Yes, Leave Page
          </Button>
          <Button color={'link'} onClick={() => setLeavePageConfirmOpen((s: boolean) => !s)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={sameUserConfirmOpen} clientList={matchingClientList}>
        <ModalHeader>You are trying to create a client that may already exist in the system.</ModalHeader>
        <ModalBody>
          A client with the same name already exists. Are you sure you want to continue?
          <div className='d-flex flex-column'>
            {matchingClientList.map((matchingClient, i) => (
              <div key={i}>
                <Link target='_blank' to={{ pathname: '/clients/' + matchingClient.clientId }}>
                  {matchingClient.firstName + ' ' + (matchingClient.middleName + ' ') + matchingClient.lastName}
                </Link>
                <ul>
                  <li>Last Updated: {formatDateTime(new Date(matchingClient.lastUpdated))}</li>
                </ul>
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              saveClientDetails();
            }}
          >
            Yes
          </Button>
          <Button color={'link'} onClick={() => setSameUserConfirmOpen((s: boolean) => !s)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <ConfirmModal
        isOpen={deleteAddressModal}
        title={'Delete Address'}
        onConfirm={() => {
          setDeleteAddressModal(false);
          deleteAddress(deleteAddressId);
          setDeleteAddressId(undefined);
        }}
        onCancel={() => setDeleteAddressModal(false)}
        confirmButton={'Delete'}
      >
        <div>Are you sure you want to delete this address?</div>
      </ConfirmModal>
    </div>
  );
};
