import * as React from 'react';
import { useContext } from 'react';
import { Cell, CellValue, ColumnInstance, Row as RTRow, TableInstance } from 'react-table';
import { Button, Col, CustomInput, Row } from 'reactstrap';
import { IUser } from '../Users/UsersTable';
import { UsersContext } from '../../contexts/UsersContext';
import { sanitizeHTMLConfig } from '../../contexts/DomainContext';
import { useHistory } from 'react-router-dom';
import { getJwtFromStorage } from '../../services/AuthenticationService';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { INote } from '../Files/FileNotes';
import { IDocument } from '../Files/Documents/Documents';

const DOMPurify = require('dompurify');

export type IClientNoteCell =
  | (TableInstance<INote> & {
      column: ColumnInstance<INote>;
      row: RTRow<INote>;
      cell: Cell<INote, string>;
      value: CellValue<string>;
    } & { children?: React.ReactNode })
  | (TableInstance<INote> & {
      column: ColumnInstance<INote>;
      row: RTRow<INote>;
      cell: Cell<INote, number>;
      value: CellValue<number>;
    } & { children?: React.ReactNode })
  | (TableInstance<INote> & {
      column: ColumnInstance<INote>;
      row: RTRow<INote>;
      cell: Cell<INote, any>;
      value: CellValue<any>;
    } & { children?: React.ReactNode });

interface INoteCellProps {
  cell: IClientNoteCell;
  printNote?: () => void;
  selectedCaseNotes?: string[];
  setSelectedCaseNotes?: (noteIds: any) => any;
}

export const ClientNoteCell: React.FC<INoteCellProps> = ({ cell }) => {
  const usersContext = useContext(UsersContext);
  const users = usersContext.users;

  let userName = '';

  if (cell && cell.row.original && cell.row.original.dateCreated) {
    const userCreated = cell.row.original.userCreated;
    if (userCreated) {
      const user = users.find((l: IUser) => l.userId === userCreated);
      userName = user?.lastName.toUpperCase() + ', ' + user?.firstName;
    }
    return (
      <header className={'text-right'}>
        {userName}
        <div className={'text-muted small'}>{formatDateTime(new Date(cell.row.original.dateCreated))}</div>
      </header>
    );
  } else {
    return <></>;
  }
};

export const ClientNoteTextCell: React.FC<INoteCellProps> = ({ cell, printNote }) => {
  const history = useHistory();

  const { fileId, fileNumber, noteId, clientId, documents, text } = cell.row.original;

  const editNote = !!clientId;

  const isFileNote = !!fileId && !!fileNumber;
  const editClientNote = () => {
    if (clientId) {
      history.push('/clients/' + clientId + '/notes/' + noteId);
    }
  };

  const goToFile = () => {
    history.push('/files/' + fileId);
  };

  const goToFileNotes = () => {
    history.push('/files/' + fileId + '/notes');
  };
  const downloadDocument = (uploadId: string) => {
    const getUrl = (uId: string) => '/api/uploads/download/' + uId + '/' + getJwtFromStorage();
    const link = document.createElement('a');
    console.log(getUrl(uploadId));
    link.href = getUrl(uploadId);
    link.setAttribute('download', 'download');
    document.body.appendChild(link);
    link.click();
  };

  if (cell && cell.row.original) {
    return (
      <Row className={'mr-0'}>
        <Col md={9} className={'mb-3 mb-md-0'}>
          {isFileNote && (
            <p className={'d-inline-flex align-items-baseline px-2 bg-primary-medium rounded'}>
              <span className={'small'}>File Case Note for</span>
              <Button size={'sm'} color={'link'} className={'p-0 ml-1 font-weight-bold'} onClick={goToFile}>
                {fileNumber}
              </Button>
            </p>
          )}
          <main
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(text, sanitizeHTMLConfig),
            }}
          />
          <footer className={'d-flex'} style={{ gap: 8 }}>
            {printNote && (
              <Button size={'sm'} color={'link'} className={'pl-0'} onClick={printNote} title={'Print Case Note'}>
                Print
              </Button>
            )}
            {editNote ? (
              <Button size={'sm'} color={'link'} className={'p-0'} onClick={editClientNote} title={'Edit Case Note'}>
                Edit
              </Button>
            ) : (
              <Button size={'sm'} color={'link'} className={'p-0'} onClick={() => goToFileNotes()}>
                View Case Notes for {fileNumber}
              </Button>
            )}
          </footer>
        </Col>
        <Col md={3}>
          {documents && documents.length > 0 && (
            <div>
              <h6>Documents</h6>
              <ul>
                {documents.map((d: IDocument) => (
                  <li key={d.documentId}>
                    <Button
                      color={'link'}
                      className={'p-0'}
                      onClick={() => downloadDocument(d.uploadId)}
                      disabled={!d.uploadId}
                      style={{ wordBreak: 'break-all', textAlign: 'left' }}
                      title={'Download "' + d.fileName + '"'}
                    >
                      {d.fileName}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Col>
      </Row>
    );
  } else {
    return <></>;
  }
};

export const ClientCheckboxCell: React.FC<INoteCellProps> = ({ cell, selectedCaseNotes, setSelectedCaseNotes }) => {
  const { noteId } = cell.row.original;
  return (
    <article>
      <CustomInput
        key={'print-select-' + noteId}
        type='checkbox'
        name={'isSelected'}
        id={'print-select-' + noteId}
        label={''}
        aria-label={'Select Note'}
        checked={selectedCaseNotes?.includes(noteId)}
        onClick={() => {
          if (selectedCaseNotes && setSelectedCaseNotes) {
            setSelectedCaseNotes((cn: any) =>
              selectedCaseNotes.includes(noteId)
                ? cn.filter((n: any) => n !== noteId)
                : cn
                ? cn.concat([noteId])
                : [noteId]
            );
          }
        }}
      />
    </article>
  );
};
