import * as React from 'react';
import { useEffect } from 'react';
import { Link, Route, Switch, useHistory, useParams } from 'react-router-dom';
import * as H from 'history';
import { Badge, Container, Nav, NavItem, NavLink } from 'reactstrap';

import { WorkshopDetails } from './WorkshopDetails';
import { Documents } from '../Files/Documents/Documents';
import { WorkshopDocumentDetails } from '../Files/Documents/WorkshopDocumentDetails';
import { useWindowDimensions } from '../../hooks/WindowHooks';
import { Participants } from './Participants';

export const Workshop = () => {
  const params = useParams<{ workshopId: string; tab?: string }>();
  const history = useHistory();
  const { xs, sm } = useWindowDimensions();
  const verticalMenu = xs || sm;

  useEffect(() => {
    history.replace(history.location.pathname, { overrideReload: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tab: string = params.tab || 'details';

  const NavItemLink: React.FC<{ to: H.LocationDescriptor; active: boolean; name: any; count?: number }> = (props) => {
    return (
      <NavItem>
        <NavLink tag={Link} to={props.to} active={props.active}>
          {props.name}
          {!!props.count && props.count > 0 && (
            <Badge color={'primary'} className={'ml-2'}>
              {props.count}
            </Badge>
          )}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <article className='page'>
      <div className={'page-header-image d-print-none'} />
      <header className='page-header d-print-none'>
        <Container fluid={true}>
          <div className={verticalMenu ? 'd-block d-md-none pb-4' : 'd-none d-md-block'}>
            <Nav tabs={!verticalMenu} pills={verticalMenu} vertical={verticalMenu}>
              <NavItemLink
                to={'/workshops/' + params.workshopId + '/details'}
                active={tab === 'details'}
                name={'Workshop Information'}
              />
              <NavItemLink
                to={'/workshops/' + params.workshopId + '/participants'}
                active={tab === 'participants'}
                name={'Participants'}
              />
              <NavItemLink
                to={'/workshops/' + params.workshopId + '/documents'}
                active={tab === 'documents'}
                name={'Documents'}
              />
            </Nav>
          </div>
        </Container>
      </header>
      <div style={{ backgroundColor: 'white' }}>
        <Route exact={true} path='/workshops/:workshopId/(details)?' component={WorkshopDetails} />
        <Route exact={true} path='/workshops/:workshopId/participants' component={Participants} />
        <Switch>
          <Route exact={true} path='/workshops/:workshopId/documents'>
            <Documents
              newDocumentPath={`/api/documents/add-workshop-document/${params.workshopId}`}
              getPaths={[`/api/workshops/${params.workshopId}/documents`]}
              forWorkshop={true}
            />
          </Route>
          <Route exact={true} path='/workshops/:workshopId/documents/:documentId'>
            <WorkshopDocumentDetails />
          </Route>
        </Switch>
      </div>
    </article>
  );
};
