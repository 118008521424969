import * as React from 'react';
import { FormEvent, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import * as AuthenticationService from '../../services/AuthenticationService';
import * as ToastService from '../../services/ToastService';
import * as PasswordService from '../../services/PasswordService';
import { Link } from 'react-router-dom';
import { usePasswordFormInputWithSet } from '../../hooks/FormHooks';

interface IMyUser {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
}

export const MyAccount: React.FC = () => {
  const [myUser, setMyUser] = useState<IMyUser>({
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
  });

  const { input: oldPassword, setValue: setOldPasswordValue } = usePasswordFormInputWithSet('');
  const [oldPasswordInvalid, setOldPasswordInvalid] = useState<boolean>(false);
  const { input: newPassword, setValue: setNewPasswordValue } = usePasswordFormInputWithSet('');
  const { input: confirmPassword, setValue: setConfirmPasswordValue } = usePasswordFormInputWithSet('');
  const authUserId = AuthenticationService.getUserId();

  useEffect(() => {
    authAxios.get('/api/users/' + authUserId).then((response) => {
      setMyUser(response.data as IMyUser);
    });
  }, [authUserId]);

  const saveUserDetails = () => {
    authAxios.post('/api/users/admin/update-my-account', myUser).then((response) => {
      if (response.data.status === 'OK') {
        ToastService.notifySuccess('User Updated');
      } else {
        ToastService.notifyError(response.data.errorMessage);
      }
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    authAxios
      .post('/api/users/update-password', {
        oldPassword: oldPassword.value,
        plainPassword: newPassword.value,
        userId: AuthenticationService.getUserId(),
      })
      .then((response) => {
        if (response.data.status === 'OK') {
          ToastService.notifySuccess('Password Updated');
          setConfirmPasswordValue('');
          setNewPasswordValue('');
          setOldPasswordValue('');
          setOldPasswordInvalid(false);
        } else {
          (response.data.errorFields as string[]).forEach((field: string) => {
            if (field === 'oldPassword') {
              setOldPasswordInvalid(true);
            }
          });
        }
      })
      .catch((error) => {
        const errorMessage = error.response.data.userMessage;
        if (errorMessage) {
          console.log('An error happened: ' + errorMessage);
        }
      });
  };

  const validateForm = () => {
    return PasswordService.isValidPassword(newPassword.value) && newPassword.value === confirmPassword.value;
  };

  return (
    <div className='user-details-container'>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>My Account</NavbarBrand>
      </Navbar>
      <Container fluid={true} className='my-3'>
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>Personal Details</CardHeader>
              <CardBody>
                <Form
                  onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                  }}
                >
                  <Row>
                    <Col md={6}>
                      <FormGroup row={true}>
                        <Label sm={12} for={'firstName'}>
                          First Name
                        </Label>
                        <Col sm={12}>
                          <Input
                            value={myUser.firstName}
                            type={'text'}
                            onChange={(e: any) => setMyUser({ ...myUser, firstName: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup row={true}>
                        <Label sm={12} for={'lastName'}>
                          Last Name
                        </Label>
                        <Col sm={12}>
                          <Input
                            value={myUser.lastName}
                            type={'text'}
                            onChange={(e: any) => setMyUser({ ...myUser, lastName: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup row={true}>
                    <Label sm={12} for={'email'}>
                      Email
                    </Label>
                    <Col sm={12}>
                      <Input
                        value={myUser.email}
                        type='text'
                        onChange={(e: any) => setMyUser({ ...myUser, email: e.target.value })}
                      />
                    </Col>
                  </FormGroup>
                </Form>
                <FormGroup row={true}>
                  <Col sm={12}>
                    <Button color='primary mr-2' onClick={() => saveUserDetails()}>
                      Save
                    </Button>
                    <Link to='/'>
                      <Button color='link'>Cancel</Button>
                    </Link>
                  </Col>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardHeader>Change Password</CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <FormGroup row={true}>
                    <Label sm={12} for={'oldPassword'}>
                      Current Password
                    </Label>
                    <Col sm={12}>
                      <Input {...oldPassword} invalid={oldPasswordInvalid} id='oldPassword' name='oldPassword' />
                      <FormFeedback>Current Password is not correct.</FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row={true}>
                    <Label sm={12} for={'newPassword'}>
                      New Password
                    </Label>
                    <Col sm={12}>
                      <Input
                        {...newPassword}
                        invalid={newPassword.value.length > 0 && !PasswordService.isValidPassword(newPassword.value)}
                        id='newPassword'
                        name='newPassword'
                      />
                      {newPassword.value.length > 0 ? (
                        <FormFeedback>
                          Password needs to be at least 8 characters long and have at least 1 letter and 1 number
                        </FormFeedback>
                      ) : (
                        <div className='password-requirements'>
                          Password needs to be at least 8 characters long and have at least 1 letter and 1 number
                        </div>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup row={true}>
                    <Label sm={12} for={'confirmPassword'}>
                      Confirm Password
                    </Label>
                    <Col sm={12}>
                      <Input
                        {...confirmPassword}
                        invalid={confirmPassword.value.length > 0 && newPassword.value !== confirmPassword.value}
                        id='confirmPassword'
                        name='confirmPassword'
                      />
                      <FormFeedback>Passwords don&apos;t match</FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row={true}>
                    <Col sm={12}>
                      <Button
                        color={'primary'}
                        disabled={!validateForm()}
                        onClick={() => handleSubmit}
                        style={{ marginBottom: '1em' }}
                        type='submit'
                      >
                        Update Password
                      </Button>
                    </Col>
                  </FormGroup>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
