import * as React from 'react';
import { useState } from 'react';
import { Button, CardBody, Col, FormGroup, Input, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { FaTimes } from 'react-icons/fa';
import { RequiredLabel } from '../RequiredLabel';
import { IContactDetails, ISupportPerson } from './ClientContext';

interface IProps {
  saveAction: (rp: ISupportPerson) => void;
  deleteAction?: (rp: ISupportPerson) => void;
  cancel?: () => void;
  supportPerson?: ISupportPerson;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SupportPerson: React.FC<IProps> = (props) => {
  const [addName, setAddName] = useState<string | undefined>(props.supportPerson?.name);
  const [addRelationshipType, setAddRelationshipType] = useState<string | undefined>(
    props.supportPerson?.relationshipType
  );
  const [addContactDetails, setAddContactDetails] = useState<IContactDetails[]>(
    props.supportPerson?.contactDetails || [{ contactType: '', details: '' }]
  );

  const relationshipOptions = [
    { value: 'Teacher', label: 'Teacher' },
    { value: 'Doctor', label: 'Doctor' },
    { value: 'Social Worker', label: 'Social Worker' },
    { value: 'Case Worker', label: 'Case Worker' },
    { value: 'Occupational Therapist', label: 'Occupational Therapist' },
    { value: 'Speech Language Pathologist', label: 'Speech Language Pathologist' },
    { value: 'Mother', label: 'Mother' },
    { value: 'Father', label: 'Father' },
    { value: 'Grandparent', label: 'Grandparent' },
  ];

  const contactTypeOptions = [
    { value: 'Phone Number', label: 'Phone Number' },
    { value: 'Email', label: 'Email' },
    { value: 'Address', label: 'Address' },
  ];

  const saveSupportPerson = () => {
    if (addName && addRelationshipType && addContactDetails) {
      props.saveAction({
        name: addName,
        relationshipType: addRelationshipType,
        contactDetails: addContactDetails,
      } as ISupportPerson);
    }
  };

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
        borderRadius: '1.25rem',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  return (
    <>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom d-flex'}>
        <NavbarBrand>{props.supportPerson ? `Edit ${props.supportPerson.name}` : 'Add Support Person'}</NavbarBrand>
        <div className={'d-flex ml-auto navbar-actions'}>
          {props.cancel !== undefined && (
            <Button color={'link'} size={'sm'} onClick={() => props.cancel && props.cancel()}>
              Cancel
            </Button>
          )}
          {props.supportPerson && (
            <Button
              color='danger'
              className='ml-3'
              onClick={() => {
                props.deleteAction && props.supportPerson && props.deleteAction(props.supportPerson);
              }}
            >
              Delete
            </Button>
          )}
          <Button
            color={'primary'}
            size={'sm'}
            className={'ml-2'}
            onClick={saveSupportPerson}
            aria-label={'Save Support Person'}
          >
            Save
          </Button>
        </div>
      </Navbar>
      <CardBody className={'pb-3'}>
        <Row>
          <Col lg={3}>
            <FormGroup className={'pb-1 mb-1'}>
              <RequiredLabel required={addName === ''}>Full Name</RequiredLabel>
              <Input
                type='text'
                placeholder='Type Name...'
                name='name'
                value={addName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const name = e.target.value;
                  setAddName(name);
                  props.setIsDirty(true);
                }}
                required={true}
                autoFocus={true}
              />
            </FormGroup>
          </Col>
          <Col lg={3}>
            <FormGroup>
              <RequiredLabel required={addRelationshipType === undefined}>Relationship</RequiredLabel>
              <CreatableSelect
                formatCreateLabel={(inputValue: string) => inputValue}
                styles={disabledSelectStyles}
                placeholder={<span className='placeholder'>Select...</span>}
                options={[
                  ...relationshipOptions,
                  ...(!addRelationshipType || relationshipOptions.some((e) => e.value === addRelationshipType)
                    ? []
                    : [{ value: addRelationshipType, label: addRelationshipType }]),
                ]}
                value={addRelationshipType ? { value: addRelationshipType, label: addRelationshipType } : undefined}
                onChange={(val) => {
                  props.setIsDirty(true);
                  setAddRelationshipType(val ? val.value : undefined);
                }}
                isMulti={false}
                // isDisabled={!props.isEditingAllowed}
                // styles={disabledSelectStyles}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <div className={'d-flex'}>
              <Label className={'mt-auto mb-0'}>Contact Details</Label>
            </div>
            {addContactDetails &&
              addContactDetails.length > 0 &&
              addContactDetails.map((cd, index) => (
                <Row key={index} className={'mt-1'}>
                  <Col sm={6}>
                    <CreatableSelect
                      formatCreateLabel={(inputValue: string) => inputValue}
                      styles={disabledSelectStyles}
                      placeholder={<span className='placeholder'>Select or Type...</span>}
                      options={[
                        ...contactTypeOptions,
                        ...(!cd.contactType || contactTypeOptions.some((e) => e.value === cd.contactType)
                          ? []
                          : [{ value: cd.contactType, label: cd.contactType }]),
                      ]}
                      value={cd.contactType ? { value: cd.contactType, label: cd.contactType } : undefined}
                      onChange={(val) => {
                        props.setIsDirty(true);
                        setAddContactDetails((acd) => [
                          ...acd.slice(0, index),
                          { ...acd[index], contactType: val ? val.value : '' },
                          ...acd.slice(index + 1),
                        ]);
                      }}
                      isMulti={false}
                      // isDisabled={!props.isEditingAllowed}
                      // styles={disabledSelectStyles}
                    />
                  </Col>
                  <Col sm={6} className={'d-flex align-items-center '}>
                    <Input
                      type='text'
                      placeholder='Info...'
                      name='name'
                      value={cd.details}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const detail = e.target.value;
                        setAddContactDetails((acd) => [
                          ...acd.slice(0, index),
                          { ...acd[index], details: detail },
                          ...acd.slice(index + 1),
                        ]);
                        props.setIsDirty(true);
                      }}
                    />
                    <Button
                      color={'link'}
                      className={'text-danger'}
                      aria-label={`Remove ${cd.contactType || 'contact detail'}`}
                      title={`Remove ${cd.contactType || 'contact detail'}`}
                      onClick={() => {
                        props.setIsDirty(true);
                        setAddContactDetails((acd) => [...acd.slice(0, index), ...acd.slice(index + 1)]);
                      }}
                    >
                      <FaTimes />
                    </Button>
                  </Col>
                </Row>
              ))}
            <Row className={'mt-2 ml-0'}>
              <Button
                size={'sm'}
                color={'link'}
                onClick={() =>
                  setAddContactDetails([...addContactDetails, { contactType: undefined, details: undefined }])
                }
              >
                Add Another Contact Detail
              </Button>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </>
  );
};
