import * as React from 'react';
import { useCallback } from 'react';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { authAxios } from '../services/AxiosService';

export interface IAxiosState<TData extends any> {
  type: AuthState;
  data?: TData;
  loading: boolean;
  called: boolean;
  error?: AxiosError;
  response?: AxiosResponse;
}

export type AxiosUpdateTuple = [(options?: any) => Promise<any>, any];

export const STATE_REQUEST_INITIAL = 'STATE_REQUEST_INITIAL';
export const STATE_REQUEST_START = 'STATE_REQUEST_START';
export const STATE_REQUEST_END = 'STATE_REQUEST_END';
export type AuthState = 'STATE_REQUEST_START' | 'STATE_REQUEST_END' | 'STATE_REQUEST_INITIAL';

export const ACTION_REQUEST_START = 'ACTION_REQUEST_START';
export const ACTION_REQUEST_END = 'ACTION_REQUEST_END';
export type IAxiosAction = {
  type: 'ACTION_REQUEST_START' | 'ACTION_REQUEST_END';
  data: {};
};
// eslint-disable-next-line no-unused-vars
const request = <TData extends any>(url: string, dispatch: React.Dispatch<IAxiosAction>, postData?: {}) => {
  dispatch({ type: ACTION_REQUEST_START } as IAxiosAction);

  const postCall = authAxios.put(url, postData);
  postCall
    .then((response) => {
      if (response.data) {
        console.log(response.data);
        dispatch({ type: ACTION_REQUEST_END, data: { data: response.data, response } });
      } else {
        console.log('NOT OK');
        dispatch({ type: ACTION_REQUEST_END, data: { response } });
      }
    })
    .catch((error) => {
      dispatch({ type: ACTION_REQUEST_END, data: { error } });
    });
  return postCall;
};

const authReducer: React.Reducer<IAxiosState<any>, IAxiosAction> = <TData extends any>(
  state: IAxiosState<TData>,
  action: IAxiosAction
) => {
  switch (action.type) {
    case ACTION_REQUEST_START:
      return {
        type: STATE_REQUEST_START,
        data: undefined,
        loading: true,
        called: true,
        error: undefined,
        response: undefined,
      } as IAxiosState<TData>;
    case ACTION_REQUEST_END:
      return { type: STATE_REQUEST_END, loading: false, called: true, ...action.data } as IAxiosState<TData>;
    default:
      return state;
  }
};
// eslint-disable-next-line no-unused-vars
export const usePutAxiosHook: <TData extends any>(url: string, config?: AxiosRequestConfig) => AxiosUpdateTuple = <
  TData extends any
>(
  url: string,
  config?: AxiosRequestConfig
): AxiosUpdateTuple => {
  const [state, dispatch] = React.useReducer(authReducer, {
    type: STATE_REQUEST_INITIAL,
    data: undefined,
    loading: false,
    called: false,
  } as IAxiosState<TData>);

  const callback: (postData?: {}) => Promise<AxiosResponse> = useCallback(
    (postData?: {}) => {
      console.log('calling callback');
      return request<TData>(url, dispatch, postData);
    },
    [url, dispatch]
  );

  return [callback, state] as AxiosUpdateTuple;
};
