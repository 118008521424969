import * as React from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import { useParams } from 'react-router';
import { AssociatedWorkshopsTable } from '../Workshops/AssociatedWorkshopsTable';

export const ClientWorkshops: React.FC = () => {
  const params = useParams<{ clientId: string }>();

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom d-flex'}>
        <NavbarBrand>Associated Workshops</NavbarBrand>
      </Navbar>
      <Container fluid={true} className={'p-0'}>
        <AssociatedWorkshopsTable clientId={params.clientId} />
      </Container>
    </div>
  );
};
