import * as React from 'react';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { FormEvent, useState } from 'react';
import { Button, Container, Navbar, NavbarBrand } from 'reactstrap';
import { useParams } from 'react-router';
import { useContext } from 'react';
import { FileContext } from './FileContext';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { useEffect } from 'react';
import { IReminder } from './FileReminders';
import { REMINDER_TYPE_MANUAL } from '../../contexts/DomainContext';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { FileReminderForm } from './FileReminderForm';
import { getUserId } from '../../services/AuthenticationService';

interface IFileReminderDetailsProps {
  onSave?: () => void;
  onCancel?: () => void;
}

export const FileReminderDetails: React.FC<IFileReminderDetailsProps> = (props) => {
  const params = useParams<{ fileId: string; reminderId?: string }>();
  const fileContext = useContext(FileContext);
  const history = useHistory();
  const [showDeleteReminder, setShowDeleteReminder] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [reminderTypeId, setReminderTypeId] = useState<string | undefined>();
  const [formIsFilled, setFormIsFilled] = useState(false);
  const [reminder, setReminder] = useState<IReminder | undefined>({
    reminderId: '',
    text: '',
    date: undefined,
    staffToNotify: [getUserId()],
    notificationTypeIds: [] as string[],
    resolved: false,
  } as IReminder);

  useEffect(() => {
    if (reminder?.text !== '' && reminder?.text !== undefined && reminder?.date !== undefined) {
      setFormIsFilled(true);
    } else {
      setFormIsFilled(false);
    }
  }, [reminder]);

  useEffect(() => {
    if (params.reminderId) {
      authAxios.get('/api/files/admin/' + params.fileId + '/reminders/' + params.reminderId).then((response) => {
        const reminderTemp: IReminder = response.data;
        setReminder(reminderTemp);
        setReminderTypeId(reminderTemp.reminderTypeId);
      });
    }
  }, [params.reminderId, params.fileId]);

  const save = () => {
    if (
      !reminder?.text ||
      !reminder?.date ||
      (reminder ? reminder.staffToNotify.length <= 0 : true) ||
      (reminder ? reminder.notificationTypeIds.length <= 0 : true)
    ) {
      return;
    }

    if (params.reminderId) {
      //update
      authAxios
        .post('/api/files/update-reminder', {
          fileId: params.fileId,
          reminderId: params.reminderId,
          text: reminder?.text,
          date: reminder?.date,
          staffToNotify: reminder?.staffToNotify,
          notificationTypeIds: reminder?.notificationTypeIds,
          reminderTypeId: reminderTypeId ? reminderTypeId : REMINDER_TYPE_MANUAL,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            fileContext.setReminders((r) =>
              r.map((rem) => {
                if (rem.reminderId === params.reminderId) {
                  return {
                    reminderId: rem.reminderId,
                    text: reminder?.text ? reminder?.text : '',
                    date: reminder?.date,
                    staffToNotify: reminder?.staffToNotify ? reminder?.staffToNotify : [],
                    notificationTypeIds: reminder?.notificationTypeIds,
                    reminderTypeId: rem.reminderTypeId,
                    resolved: rem.resolved,
                  } as IReminder;
                } else {
                  return rem;
                }
              })
            );
            notifySuccess('Reminder updated.');
          } else {
            notifyError('Error updating reminder.');
          }
        });
    } else {
      //create
      authAxios
        .post('/api/files/create-reminder', {
          fileId: params.fileId,
          text: reminder?.text,
          date: reminder?.date,
          staffToNotify: reminder?.staffToNotify,
          notificationTypeIds: reminder?.notificationTypeIds,
          reminderTypeId: reminderTypeId ? reminderTypeId : REMINDER_TYPE_MANUAL,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Reminder created.');
            fileContext.setReminders((r) =>
              r.concat([
                {
                  reminderId: response.data.reminderId,
                  text: reminder?.text ? reminder?.text : '',
                  date: reminder?.date,
                  staffToNotify: reminder?.staffToNotify ? reminder?.staffToNotify : [],
                  notificationTypeIds: reminder?.notificationTypeIds,
                  reminderTypeId: reminderTypeId ? reminderTypeId : REMINDER_TYPE_MANUAL,
                  resolved: false,
                  courtDateId: reminder.courtDateId ? reminder.courtDateId : undefined,
                },
              ])
            );
          } else {
            notifyError('Error creating note.');
          }
        });
      if (props.onSave) {
        props.onSave();
      }
    }
  };

  const handleCancel = () => {
    setIsDirty(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const deleteReminder = () => {
    if (params.reminderId) {
      authAxios
        .post('/api/files/delete-reminder', { fileId: params.fileId, reminderId: params.reminderId })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Reminder deleted.');
            fileContext.setReminders((r) => r.filter((rem) => rem.reminderId !== params.reminderId));
            history.push('/files/' + params.fileId + '/reminders');
          } else {
            notifyError('Error deleting reminder.');
          }
        });
    }
  };

  return (
    <>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
        className={'no-print'}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <NavbarBrand>{params.reminderId ? 'Edit Reminder' : 'Add Reminder'}</NavbarBrand>
          <div className={'ml-auto d-flex flex-row-reverse'}>
            <Button color={'primary'} className={'ml-2'} onClick={save} disabled={!isDirty || !formIsFilled}>
              Save Reminder
            </Button>
            {params.reminderId ? (
              <div>
                <Button color='danger' className={'ml-2'} onClick={() => setShowDeleteReminder(true)}>
                  Delete Reminder
                </Button>
              </div>
            ) : (
              <Button color='link' className={'ml-auto'} onClick={handleCancel}>
                Cancel
              </Button>
            )}
          </div>
        </Navbar>
        <Container fluid={true}>
          <FileReminderForm reminder={reminder} setReminder={setReminder} setIsDirty={setIsDirty} />
        </Container>
      </form>
      <ConfirmModal
        isOpen={showDeleteReminder}
        title={'Delete Reminder'}
        onConfirm={() => {
          setShowDeleteReminder(false);
          deleteReminder();
        }}
        onCancel={() => setShowDeleteReminder(false)}
        confirmButton={'Delete Reminder'}
      >
        <div>Are you sure you want to delete this reminder?</div>
      </ConfirmModal>
    </>
  );
};
