export const getAge = (dateOfBirth: Date) => {
  if (dateOfBirth) {
    const today = new Date();
    var years = today.getFullYear() - dateOfBirth.getFullYear();
    const months = today.getMonth() - dateOfBirth.getMonth();
    if (months < 0 || (months === 0 && today.getDate() < dateOfBirth.getDate())) {
      years--;
    }
    return years;
  } else {
    return undefined;
  }
};
