import { IClient } from '../../../components/Clients/ClientContext';
import { useGetAxiosHook } from '../../AxiosHook';

export const useClient = (clientId?: string) => {
  const URL = `/api/clients/${clientId}`;

  const { data, loading, error } = useGetAxiosHook<IClient>(URL, {}, !clientId || clientId === 'add');

  return { data, loading, error };
};
