import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { authAxios } from '../../services/AxiosService';
import { useHistory } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { Column, TableInstance, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import {
  DEPARTMENT_BIRTH_WORKER,
  DEPARTMENT_CULTURAL_CONNECTIONS,
  DEPARTMENT_FAMILY_PRESERVATION,
  DEPARTMENT_JORDANS_PRINCIPLE,
  DEPARTMENT_JUSTICE,
  DomainContext,
  FILE_SUB_TYPE_BIRTH_WORKER,
  FILE_SUB_TYPE_CULTURAL_CONNECTIONS,
  FILE_SUB_TYPE_FAMILY_PRESERVATION,
  FILE_SUB_TYPE_INDIVIDUAL,
  FILE_TYPE_BIRTH_WORKER,
  FILE_TYPE_CULTURAL_CONNECTIONS,
  FILE_TYPE_FAMILY_PRESERVATION,
  FILE_TYPE_JORDANS_PRINCIPLE,
  FILE_TYPE_JUSTICE,
  IFileTypeDisplay,
  IJordansPrincipleRequestType,
  IStage,
  STAGE_INTAKE_IN_PROGRESS,
  STAGE_REJECTED,
} from '../../contexts/DomainContext';
import {
  FileContext,
  IFileForTable,
  IIntakeFields,
  IJordansPrincipleData,
  IJordansPrincipleFields,
} from './FileContext';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';
import { TablePagination } from '../TablePagination/TablePagination';
import { ClientsContext } from '../../contexts/ClientsContext';
import { useWindowDimensions } from '../../hooks/WindowHooks';
import { useParams } from 'react-router';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { formatDateFromISONumber } from '../../formatters/DateTimeFormatter';
import { DateRangeDropdown } from '../../components/DateRangeDropdown/DateRangeDropdown';
import { RequiredLabel } from '../../components/RequiredLabel';
import Select from 'react-select';
import { IUser } from '../../components/Users/UsersTable';
import { UsersContext } from '../../contexts/UsersContext';
import { MyUserContext } from '../../contexts/MyUserContext';

export const FileRequestFiles: React.FC = () => {
  const params = useParams<{ fileId: string }>();
  const [files, setFiles] = useState<IFileForTable[]>([]);
  const domainContext = useContext(DomainContext);
  const fileContext = useContext(FileContext);
  const clientsContext = useContext(ClientsContext);
  const usersContext = useContext(UsersContext);
  const myUser = useContext(MyUserContext);
  const requestTypes = domainContext.jordansPrincipleRequestTypes;
  const clients = clientsContext.state.clients;
  const stages = domainContext.stages;
  const staff = usersContext.users.sort((a, b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName));
  const fileTypes = domainContext.fileTypes;
  const history = useHistory();
  const { xs } = useWindowDimensions();
  const jordansPrincipleRequestTypes = domainContext.jordansPrincipleRequestTypes;

  const [openFileRequestModal, setOpenFileRequestModal] = useState(false);
  const [trySave, setTrySave] = useState(false);

  const [fileRequestDetails, setFileRequestDetails] = useState('');
  const [fileRequestStageId, setFileRequestStageId] = useState('');
  const [fileRequestFileTypeId, setFileRequestTypeId] = useState('');
  const [fileRequestFileSubTypeId, setFileRequestSubTypeId] = useState('');
  const [fileRequestSelectedStaff, setFileRequestSelectedStaff] = useState<IUser[]>([]);
  const [fileRequestStaffIds, setFileRequestStaffIds] = useState<string[]>([]);
  const [fileRequestDepartment, setFileRequestDepartment] = useState('');

  //Jordans Principle fields
  const [fileRequestJPRequestTypeId, setFileRequestJPRequestTypeId] = useState('');

  useEffect(() => {
    authAxios.get('/api/files/' + params.fileId + '/request-files').then((response) => {
      setFiles(response.data);
    });
  }, []);

  useEffect(() => {
    setFileRequestSelectedStaff(staff.filter((s) => fileRequestStaffIds.includes(s.userId)));
  }, [staff, fileRequestStaffIds]);

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
        borderRadius: '1.25rem',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
    menu: (provided: any) => {
      return {
        ...provided,
        zIndex: 9999,
      };
    },
  };

  const clearFields = () => {
    setFileRequestDetails('');
    setFileRequestStageId('');
    setFileRequestTypeId('');
    setFileRequestSubTypeId('');
    setFileRequestJPRequestTypeId('');
    setFileRequestSelectedStaff([]);
  };

  const fileTypeIdToDepartmentId = (fileTypeId: string) => {
    if (fileTypeId === FILE_TYPE_BIRTH_WORKER) {
      return DEPARTMENT_BIRTH_WORKER;
    } else if (fileTypeId === FILE_TYPE_FAMILY_PRESERVATION) {
      return DEPARTMENT_FAMILY_PRESERVATION;
    } else if (fileTypeId === FILE_TYPE_CULTURAL_CONNECTIONS) {
      return DEPARTMENT_CULTURAL_CONNECTIONS;
    } else if (fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE) {
      return DEPARTMENT_JORDANS_PRINCIPLE;
    } else if (fileTypeId === FILE_TYPE_JUSTICE) {
      return DEPARTMENT_JUSTICE;
    }
  };

  const createFileRequest = () => {
    if (fileRequestFileTypeId === FILE_TYPE_JORDANS_PRINCIPLE && !fileRequestJPRequestTypeId) {
      setTrySave(true);
    } else {
      const data = {
        clientIds: fileContext.clientIds,
        otherPeople: fileContext.otherPeople,
        stageId: fileRequestStageId,
        fileTypeId: fileRequestFileTypeId,
        fileSubTypeId: fileRequestFileSubTypeId,
        createdDate: new Date().getTime(),
        fileRequestDetails: fileRequestDetails,
        staffIds: fileRequestStaffIds,
        intakeFileId: fileContext.file.fileId,
        intakeStaffIds: fileContext.staffIds,
      };

      authAxios.post('/api/files/create-intake-file-request', data).then((response) => {
        if (response.data.status === 'OK') {
          const file = { ...response.data.file, clientIds: data.clientIds, otherPeople: data.otherPeople };
          //update state for JP files after update-jordans-principle xhr
          if (fileRequestFileTypeId !== FILE_TYPE_JORDANS_PRINCIPLE) {
            // Because the client is set separately from the file creation give a slight moment for eventual consistency
            // in case the user wants to load the file very quickly
            setTimeout(() => {
              setFiles((f) => [...f, file]);
            }, 1500);
          }
          fileContext.setIntakeFields(
            (s) =>
              ({
                ...s,
                requestFileIds: s?.requestFileIds ? s?.requestFileIds.concat(file.fileId) : [file.fileId],
              } as IIntakeFields)
          );
          notifySuccess('File request created.');
          if (fileRequestFileTypeId === FILE_TYPE_JORDANS_PRINCIPLE) {
            const JPData = {
              fileId: file.fileId,
              requests: [],
              payments: [],
              requestTypeId: fileRequestJPRequestTypeId,
              fileSubTypeId: FILE_SUB_TYPE_INDIVIDUAL,
              fileName: '',
            } as IJordansPrincipleData;
            authAxios.post('/api/files/update-jordans-principle', JPData).then((response) => {
              if (response.data.status === 'OK') {
                const updatedJPFile = {
                  ...file,
                  jordansPrincipleFields: {
                    ...file.jordansPrincipleFields,
                    requestTypeId: fileRequestJPRequestTypeId,
                  } as IJordansPrincipleFields,
                };
                // Because the client is set separately from the file creation give a slight moment for eventual consistency
                // in case the user wants to load the file very quickly
                setTimeout(() => {
                  setFiles((f) => [...f, updatedJPFile]);
                }, 1500);
                console.log('JP updated :)');
              } else {
                console.log('JP not updated :(');
              }
            });
          }
        } else {
          notifyError(response.data.errorMessage);
        }
      });
      setOpenFileRequestModal(false);
      clearFields();
    }
  };

  const columns: Column<IFileForTable>[] = useMemo(
    () => [
      {
        Header: 'File #',
        accessor: 'fileNumber',
        width: 100,
        xs: true,
      },
      {
        Header: 'File Type',
        accessor: 'fileTypeDisplay',
        width: 150,
        xs: false,
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='departmentId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={domainContext.fileRequestTypeDisplay.map((s: IFileTypeDisplay) => ({
                value: s.name,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['fileTypeDisplay'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
        Cell: (props) => (
          <>
            <p className='item title'>
              {props.row.original['fileTypeId'] === FILE_TYPE_JORDANS_PRINCIPLE
                ? props.row.original['fileTypeDisplay'] +
                  ' (' +
                  jordansPrincipleRequestTypes.find(
                    (t: IJordansPrincipleRequestType) =>
                      t.jordansPrincipleRequestTypeId === props.row.original.jordansPrincipleFields?.['requestTypeId']
                  )?.abbreviation +
                  ')'
                : props.row.original['fileTypeDisplay']}
            </p>
          </>
        ),
      },
      {
        Header: 'Submitted Date',
        accessor: 'createdDate',
        id: 'createdDate',
        width: 200,
        xs: true,
        Cell: (c: any) => (c.row.original.createdDate ? formatDateFromISONumber(c.row.original.createdDate) : ''),
        filter: (rows: any, id: any, filterValue: { startDate?: Date; endDate?: Date }) => {
          return rows.filter((row: any) => {
            if (!filterValue.startDate || !filterValue.endDate) {
              return true;
            } else {
              const rowValue = row.original['createdDate'];
              return rowValue > filterValue.startDate.getTime() && rowValue < filterValue.endDate.getTime();
            }
          });
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue, preFilteredRows, setFilter, id } }) => (
          <DateRangeDropdown
            startDate={filterValue?.startDate}
            endDate={filterValue?.endDate}
            setRange={(dates: { startDate?: Date; endDate?: Date }) => {
              setFilter(dates);
            }}
            keyPrefix={'createdDate'}
          />
        ),
      },
      {
        Header: 'Stage',
        id: 'stageId',
        width: 150,
        xs: true,
        accessor: (file: IFileForTable) => {
          const rejectReason = file.reason ? ' - ' + file.reason : '';
          if (file.stageId !== STAGE_INTAKE_IN_PROGRESS && file.stageId !== STAGE_REJECTED) {
            return 'Accepted';
          } else {
            return stages.find((s: IStage) => s.stageId === file.stageId)?.name + rejectReason;
          }
        },
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const rowAStage = stages.find((s: IStage) => s.stageId === rowA.original[id]);
          const rowBStage = stages.find((s: IStage) => s.stageId === rowB.original[id]);
          if (rowAStage?.order && rowBStage?.order) {
            if (rowAStage.order > rowBStage.order) return 1;
            if (rowBStage.order > rowAStage.order) return -1;
            if (rowAStage.order === rowBStage?.order) {
              if (rowAStage.name > rowBStage?.name) {
                return 1;
              } else if (rowAStage.name < rowBStage.name) {
                return -1;
              } else {
                return 0;
              }
            }
          }
          return 0;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({
          column: {
            filterValue = [STAGE_REJECTED, STAGE_INTAKE_IN_PROGRESS, 'ACCEPTED'],
            preFilteredRows,
            setFilter,
            id,
          },
        }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='stageId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={[
                { value: STAGE_INTAKE_IN_PROGRESS, display: 'Intake In-Progress' },
                { value: 'ACCEPTED', display: 'Accepted' },
                { value: STAGE_REJECTED, display: 'Rejected' },
              ]}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['stageId'];
              if (filterValue.find((val: any) => rowValue === val)) {
                return true;
              }
              if (filterValue.some((e) => e === 'ACCEPTED')) {
                return rowValue !== STAGE_REJECTED && rowValue !== STAGE_INTAKE_IN_PROGRESS;
              }
              return false;
            }
          });
        },
      },
    ],
    [clients, stages]
  );

  const data: IFileForTable[] = useMemo(() => files, [files]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IFileForTable> = useTable(
    {
      columns: columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        hiddenColumns: ['reminders'],
        sortBy: [
          {
            id: 'reminders',
            desc: false,
          },
          {
            id: 'createdDate',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <div className={'table-responsive'} style={{ minHeight: '500px' }}>
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <Row className={'flex-fill'}>
            <Col md={2} xl={2}>
              <NavbarBrand>File Requests</NavbarBrand>
            </Col>
            <div
              className={'d-flex flex-grow-1 justify-content-end flex-wrap navbar-actions'}
              style={{ rowGap: '0.5rem', columnGap: '0.5rem' }}
            >
              <Button
                color={'primary'}
                onClick={() => {
                  setFileRequestTypeId(FILE_TYPE_BIRTH_WORKER);
                  setFileRequestStageId(STAGE_INTAKE_IN_PROGRESS);
                  setFileRequestSubTypeId(FILE_SUB_TYPE_BIRTH_WORKER);
                  setFileRequestDepartment(DEPARTMENT_BIRTH_WORKER);
                  setOpenFileRequestModal(true);
                }}
              >
                <FaPlus className='mr-2' />
                Birth Worker
              </Button>
              <Button
                color={'primary'}
                onClick={() => {
                  setFileRequestTypeId(FILE_TYPE_CULTURAL_CONNECTIONS);
                  setFileRequestStageId(STAGE_INTAKE_IN_PROGRESS);
                  setFileRequestSubTypeId(FILE_SUB_TYPE_CULTURAL_CONNECTIONS);
                  setFileRequestDepartment(DEPARTMENT_CULTURAL_CONNECTIONS);
                  setOpenFileRequestModal(true);
                }}
              >
                <FaPlus className='mr-2' />
                Cultural Connections
              </Button>
              <Button
                color={'primary'}
                className={'w-mobile-100'}
                onClick={() => {
                  setFileRequestTypeId(FILE_TYPE_FAMILY_PRESERVATION);
                  setFileRequestStageId(STAGE_INTAKE_IN_PROGRESS);
                  setFileRequestSubTypeId(FILE_SUB_TYPE_FAMILY_PRESERVATION);
                  setFileRequestDepartment(DEPARTMENT_FAMILY_PRESERVATION);
                  setOpenFileRequestModal(true);
                }}
              >
                <FaPlus className='mr-2' />
                Client Services
              </Button>
              <Button
                color={'primary'}
                className={'w-mobile-100 mr-2'}
                onClick={() => {
                  setFileRequestTypeId(FILE_TYPE_JORDANS_PRINCIPLE);
                  setFileRequestStageId(STAGE_INTAKE_IN_PROGRESS);
                  setFileRequestSubTypeId(FILE_SUB_TYPE_INDIVIDUAL);
                  setFileRequestDepartment(DEPARTMENT_JORDANS_PRINCIPLE);
                  setOpenFileRequestModal(true);
                }}
              >
                <FaPlus className='mr-2' />
                Jordan&apos;s Principle
              </Button>
            </div>
          </Row>
        </Navbar>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead style={{ overflow: 'visible' }}>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  return (
                    (!xs || (xs && column.xs)) && (
                      <th
                        key={`files-table-thead-tr-${index}-${column.id}`}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          width: column.width,
                          ...column.getHeaderProps(column.getSortByToggleProps()).style,
                        }}
                      >
                        {column.render('Header')}
                        <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                        <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </th>
                    )
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  key={`files-table-tr-${row.id}`}
                  {...row.getRowProps()}
                  style={{ cursor: row.original.canEdit ? 'pointer' : 'default', ...row.getRowProps().style }}
                  className={''}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      (!xs || (xs && cell.column.xs)) && (
                        <td
                          key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                          {...cell.getCellProps()}
                          onClick={() => {
                            const departmentId = fileTypeIdToDepartmentId(cell.row.original.fileTypeId);
                            if (departmentId && myUser.departmentAccessIds.includes(departmentId)) {
                              history.push('/files/' + cell.row.original.fileId);
                            }
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
      />
      <Modal
        isOpen={openFileRequestModal && fileTypes.map((f) => f.fileTypeId).includes(fileRequestFileTypeId)}
        className='logout-modal'
      >
        <ModalHeader>New File Request</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            {fileRequestFileTypeId === FILE_TYPE_JORDANS_PRINCIPLE && (
              <FormGroup className={'w-100 mb-2'}>
                <RequiredLabel for={'requestTypeId'} required={!fileContext.jordansPrincipleFields?.requestTypeId}>
                  Request Type
                </RequiredLabel>
                <Select
                  styles={disabledSelectStyles}
                  name={'requestTypeId'}
                  placeholder={<span className='placeholder'>Select...</span>}
                  options={requestTypes}
                  value={
                    requestTypes.find(
                      (s: IJordansPrincipleRequestType) =>
                        s.jordansPrincipleRequestTypeId === fileContext.jordansPrincipleFields?.requestTypeId
                    ) ?? undefined
                  }
                  type={'text'}
                  onChange={(value: any) => {
                    setFileRequestJPRequestTypeId(value?.jordansPrincipleRequestTypeId);
                  }}
                  getOptionValue={(option: any) => option.jordansPrincipleRequestTypeId}
                  getOptionLabel={(option: any) => option.name}
                  isClearable={false}
                  //disabled for CYFN-434, will put back in after CYFN is done with their changes
                  //isDisabled={params.fileId !== undefined}
                />
                {trySave && !fileRequestJPRequestTypeId && (
                  <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                )}
              </FormGroup>
            )}
            <FormGroup className={'w-100 mb-2'}>
              <Label for={'staffIds'}>Staff</Label>
              <Select
                styles={disabledSelectStyles}
                name={'staffIds'}
                placeholder={<span className='placeholder'>Select...</span>}
                options={staff.filter((u) => u.active && u.departmentAccessIds.includes(fileRequestDepartment))}
                onChange={(value: any) => {
                  setFileRequestStaffIds(value.map((v: IUser) => v.userId));
                }}
                value={fileRequestSelectedStaff}
                getOptionValue={(option: IUser) => option.userId}
                getOptionLabel={(option: IUser) =>
                  option.lastName + ', ' + option.firstName + (option.active ? '' : ' (Inactive)')
                }
                isClearable={true}
                isMulti={true}
              />
            </FormGroup>
            <FormGroup className={'w-100 mb-2'}>
              <Label>File Request Details</Label>
              <Input
                value={fileRequestDetails}
                type={'textarea'}
                style={{ minHeight: '226px' }}
                onChange={(e: any) => {
                  setFileRequestDetails(e.target.value);
                }}
              />
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              createFileRequest();
            }}
          >
            Create File Request
          </Button>
          <Button
            color={'light'}
            onClick={() => {
              setOpenFileRequestModal(false);
              clearFields();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
