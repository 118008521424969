import * as React from 'react';
import { useContext, useState } from 'react';
import { Card, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import { SupportPerson } from './SupportPerson';
import { ISupportPerson, ClientContext } from './ClientContext';

interface IProps {
  title?: string;
  disableActions: boolean;
  setEditingSupportPerson: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ClientSupportTeam: React.FC<IProps> = (props) => {
  const personContext = useContext(ClientContext);
  const supportTeam = personContext.supportTeam;
  const [editingIndex, setEditingIndex] = useState<number | undefined>(undefined);
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      <Card className={'mb-3'}>
        <CardHeader className={'d-flex align-items-center'}>{props.title || 'Circle of Support'}</CardHeader>
        <ListGroup flush={true}>
          {supportTeam.map((rp: ISupportPerson, index) => {
            if (editingIndex === index) {
              return (
                <ListGroupItem key={`supportTeam-listGroupItem-${index}`} className={'p-0'}>
                  <SupportPerson
                    saveAction={(e: ISupportPerson) => {
                      personContext.setSupportTeam((l) => [...l.slice(0, index), e, ...l.slice(index + 1)]);
                      setEditingIndex(undefined);
                      props.setIsDirty(false);
                      props.setEditingSupportPerson(false);
                    }}
                    deleteAction={(e: ISupportPerson) => {
                      personContext.setSupportTeam((l) => [...l.slice(0, index), ...l.slice(index + 1)]);
                      setEditingIndex(undefined);
                      props.setIsDirty(false);
                      props.setEditingSupportPerson(false);
                    }}
                    cancel={() => {
                      setEditingIndex(undefined);
                      props.setIsDirty(false);
                      props.setEditingSupportPerson(false);
                    }}
                    supportPerson={rp}
                    setIsDirty={props.setIsDirty}
                  />
                </ListGroupItem>
              );
            } else {
              return (
                <ListGroupItem
                  key={`supportTeam-listGroupItem-${index}`}
                  onClick={() => {
                    props.setEditingSupportPerson(true);
                    setEditingIndex(index);
                  }}
                  disabled={props.disableActions || showForm}
                  tag={'button'}
                >
                  <span className={'mr-1'}>{rp.name}</span>
                  <small className={'text-muted'}>{rp.relationshipType}</small>
                  {rp.contactDetails.length > 0 && (
                    <small className={'text-muted'}>
                      {rp.contactDetails.map(
                        (d, i) =>
                          d.contactType !== '' && (
                            <div key={i}>
                              {d.contactType}: {d.details}
                            </div>
                          )
                      )}
                    </small>
                  )}
                </ListGroupItem>
              );
            }
          })}
          {!showForm && (
            <ListGroupItem
              key={`supportTeam-listGroupItem-new`}
              tag={'button'}
              disabled={props.disableActions}
              onClick={() => {
                props.setEditingSupportPerson(true);
                setEditingIndex(undefined);
                setShowForm(true);
              }}
            >
              <FaPlus /> Add {props.title || 'Support Person'}
            </ListGroupItem>
          )}
        </ListGroup>
        {showForm && (
          <SupportPerson
            saveAction={(e: ISupportPerson) => {
              setShowForm(false);
              props.setIsDirty(false);
              props.setEditingSupportPerson(false);
              personContext.setSupportTeam((l) => [...l, e]);
            }}
            cancel={() => {
              props.setEditingSupportPerson(false);
              setShowForm(false);
              props.setIsDirty(false);
            }}
            setIsDirty={props.setIsDirty}
          />
        )}
      </Card>
    </>
  );
};
