import * as React from 'react';
import { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Badge, Button, Card, Modal, ModalBody, Navbar, NavbarBrand, Table } from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import * as ToastService from '../../services/ToastService';
import { useHistory, useParams } from 'react-router';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { IWorkshop, IWorkshopParticipant } from './WorkshopsTable';
import {
  DomainContext,
  IRegistrationStatus,
  WORKSHOP_REGISTRATION_STATUS_REGISTERED,
  WORKSHOP_REGISTRATION_STATUS_WAITLIST,
  WORKSHOP_REGISTRATION_STATUS_WITHDRAWN,
} from '../../contexts/DomainContext';
import Select from 'react-select';
import { ClientProvider, IClient, sortClientsByLastName } from '../Clients/ClientContext';
import { ClientDetails } from '../Clients/ClientDetails';
import { formatDateFromISONumber } from '../../formatters/DateTimeFormatter';
import { ClientOption, ClientSingleValue } from '../SelectComponents/SelectClientComponents';
import { ClientsContext } from '../../contexts/ClientsContext';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { useLocation } from 'react-router-dom';

export interface IParticipantForTable extends IClient {
  registrationStatusId: string;
}

export const Participants: React.FC = () => {
  const params = useParams<{ workshopId: string }>();
  const location = useLocation<{ overrideReload?: boolean }>();
  const overrideReload = location?.state?.overrideReload;
  const clientsContext = useContext(ClientsContext);
  const clients = clientsContext.state.clients.sort((a, b) => sortClientsByLastName(a, b));
  const domainContext = useContext(DomainContext);
  const registrationStatuses = domainContext.registrationStatuses;
  const history = useHistory();

  const [participantsForTable, setParticipantsForTable] = useState<IParticipantForTable[]>([]);
  const [newParticipantsForTable, setNewParticipantsForTable] = useState<IParticipantForTable[]>([]);

  const [editingClientId, setEditingClientId] = useState<string | undefined>(undefined);
  const [addingExistingClientId, setAddingExistingClientId] = useState<string | undefined>(undefined);
  const [showNewPersonDetails, setShowNewPersonDetails] = useState<boolean>(false);
  const [addingNewClient, setAddingNewClient] = useState(0);

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [openEventsOrParticipantsModal, setOpenEventsOrParticipantsModal] = useState(false);
  const [openDeleteParticipantModal, setOpenDeleteParticipantModal] = useState(false);
  const [participantIdForDelete, setParticipantIdForDelete] = useState('');

  const [capacity, setCapacity] = useState('');

  const [clientInputValue, setClientInputValue] = useState('');

  const peopleData = useCallback(
    (ids: IWorkshopParticipant[]) => {
      if (ids) {
        const workshopClients = ids.map(
          (pId) =>
            ({
              ...clients.find((i) => i.clientId === pId.participantId),
              registrationStatusId: pId.registrationStatusId,
            } as IParticipantForTable)
        );

        return workshopClients.sort((a, b) => sortClientsByLastName(a, b));
      } else {
        return [];
      }
    },
    [clients]
  );

  const createPersonAction = (participant: IClient) => {
    const updatedParticipants = [
      ...newParticipantsForTable,
      {
        ...participant,
        participantId: participant.clientId,
        registrationStatusId: WORKSHOP_REGISTRATION_STATUS_REGISTERED,
      } as IParticipantForTable,
    ];
    setNewParticipantsForTable(updatedParticipants);
    setShowNewPersonDetails(false);
    setAddingExistingClientId(undefined);
    setIsDirty(true);
    saveWorkshopParticipants([...updatedParticipants, ...participantsForTable]);
  };

  const editCompleteAction = (participant: IClient) => {
    if (newParticipantsForTable.map((p) => p.clientId).includes(participant.clientId)) {
      setNewParticipantsForTable((pft) =>
        pft.map((p) => {
          if (p.clientId === participant.clientId) {
            return { ...participant, registrationStatusId: WORKSHOP_REGISTRATION_STATUS_REGISTERED };
          } else {
            return p;
          }
        })
      );
    } else if (participantsForTable.map((p) => p.clientId).includes(participant.clientId)) {
      setParticipantsForTable((pft) =>
        pft.map((p) => {
          if (p.clientId === participant.clientId) {
            return { ...participant, registrationStatusId: WORKSHOP_REGISTRATION_STATUS_REGISTERED };
          } else {
            return p;
          }
        })
      );
    }
    setEditingClientId(undefined);
    setShowNewPersonDetails(false);
    setAddingExistingClientId(undefined);
  };

  useEffect(() => {
    if (params.workshopId) {
      authAxios
        .get('/api/workshops/' + params.workshopId)
        .then((response) => {
          if (response.data && response.data.status && response.data.status.includes('KO')) {
            history.push('/404');
          } else if (response.data) {
            setParticipantsForTable(peopleData(response.data.participants));
          } else {
            history.push('/404');
          }
        })
        .catch(() => {
          history.push('/404');
        });
    }
  }, [history, params.workshopId]);

  useEffect(() => {
    if (addingNewClient > 0) {
      var elmnt = document.getElementById('new-client-add');
      if (elmnt) {
        elmnt.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [addingNewClient]);

  //This is to get the capacity of a workshop from the participants tab, if further development for workshops is done, this should
  //be put in a workshop context
  useEffect(() => {
    if (!overrideReload) {
      authAxios
        .get('/api/workshops/admin/' + params.workshopId)
        .then((response) => {
          if (response.data && response.data.status && response.data.status.includes('KO')) {
            history.push('/404');
          } else {
            const workshops = response.data;
            setCapacity(workshops.find((w: IWorkshop) => w.workshopId === params.workshopId).capacity);
          }
        })
        .catch((response) => {
          history.push('/404');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.workshopId]);

  const saveWorkshopParticipants = (updatedParticipants: IParticipantForTable[]) => {
    const updatedParticipantsData = updatedParticipants.map(
      (pt) => ({ participantId: pt.clientId, registrationStatusId: pt.registrationStatusId } as IWorkshopParticipant)
    );
    authAxios
      .post('/api/workshops/update-participants', {
        workshopId: params.workshopId,
        participants: updatedParticipantsData,
      } as IWorkshop)
      .then((response) => {
        if (response.data.status === 'OK') {
          ToastService.notifySuccess('Workshop Participants Updated');
          setIsDirty(false);
        } else {
          ToastService.notifyError(response.data.errorMessage);
        }
      })
      .catch((error) => {
        ToastService.notifyError('Error:' + error);
      });
  };

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
        borderRadius: '1.25rem',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  return (
    <div className='white-background' style={{ minHeight: 200 }}>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className='border-bottom  pr-0'>
          <NavbarBrand className='d-flex align-items-center'>
            Participants
            <Badge className='ml-2'>
              {participantsForTable.filter((p) => p.registrationStatusId === WORKSHOP_REGISTRATION_STATUS_REGISTERED)
                .length +
                newParticipantsForTable.filter(
                  (p) => p.registrationStatusId === WORKSHOP_REGISTRATION_STATUS_REGISTERED
                ).length}{' '}
              {capacity ? 'of ' + capacity : ''} Registered
            </Badge>
            <Badge className='ml-2' color='warning'>
              {participantsForTable.filter((p) => p.registrationStatusId === WORKSHOP_REGISTRATION_STATUS_WAITLIST)
                .length +
                newParticipantsForTable.filter((p) => p.registrationStatusId === WORKSHOP_REGISTRATION_STATUS_WAITLIST)
                  .length}{' '}
              Waitlisted
            </Badge>
            <Badge className='ml-2' color='warning'>
              {participantsForTable.filter((p) => p.registrationStatusId === WORKSHOP_REGISTRATION_STATUS_WITHDRAWN)
                .length +
                newParticipantsForTable.filter((p) => p.registrationStatusId === WORKSHOP_REGISTRATION_STATUS_WITHDRAWN)
                  .length}{' '}
              Withdrawn
            </Badge>
          </NavbarBrand>
        </Navbar>

        {showNewPersonDetails ? (
          <Card className='m-2'>
            <ClientProvider clientId={addingExistingClientId}>
              <ClientDetails
                allowCreateFile={false}
                createCompleteAction={createPersonAction}
                editCompleteAction={editCompleteAction}
                clientId={addingExistingClientId}
                multiplePeopleFormat={true}
                autoFocus={true}
                cancelAction={() => {
                  setShowNewPersonDetails(false);
                  setAddingExistingClientId(undefined);
                }}
                newClient={true}
                forWorkshop={true}
                disableLeaveConfirm={true}
              />
            </ClientProvider>
          </Card>
        ) : (
          <div className={'p-2'}>
            <div className='d-flex align-items-center' style={{ gap: 8 }}>
              <Select
                className={'client-select flex-fill'}
                name={'existingPerson'}
                inputValue={clientInputValue}
                onInputChange={(e) => setClientInputValue(e)}
                options={
                  !!clientInputValue && clientInputValue.length >= 3
                    ? clients.filter(
                        (c) =>
                          c.searchString?.includes(clientInputValue.toUpperCase()) &&
                          !participantsForTable.map((p) => p.clientId).includes(c.clientId) &&
                          !newParticipantsForTable.map((p) => p.clientId).includes(c.clientId) &&
                          c.active
                      )
                    : []
                }
                value={clients.find((i: IClient) => i.clientId === addingExistingClientId) || null}
                onChange={(value: any) => {
                  const newParticipant = clients.find((c) => c.clientId === value?.clientId);
                  if (newParticipant) {
                    if (
                      !newParticipantsForTable.map((p) => p.clientId).includes(newParticipant.clientId) &&
                      !participantsForTable.map((p) => p.clientId).includes(newParticipant.clientId)
                    ) {
                      const newParticipantForTable = {
                        ...newParticipant,
                        registrationStatusId: WORKSHOP_REGISTRATION_STATUS_REGISTERED,
                      } as IParticipantForTable;
                      setNewParticipantsForTable([...newParticipantsForTable, newParticipantForTable]);
                      saveWorkshopParticipants([
                        ...newParticipantsForTable,
                        newParticipantForTable,
                        ...participantsForTable,
                      ]);
                    }
                    setShowNewPersonDetails(false);
                    setIsDirty(true);
                    setAddingNewClient((c) => c + 1);
                  }
                }}
                getOptionValue={(option: IClient) => option.clientId}
                getOptionLabel={(o: IClient) =>
                  `${o.lastName.toUpperCase()}, ${o.firstName} ${o.middleName} ${
                    o.birthDate ? formatDateFromISONumber(o.birthDate) : ''
                  }`
                }
                placeholder={<span className='placeholder'>Add existing client to workshop...</span>}
                isClearable={true}
                components={{ SingleValue: ClientSingleValue, Option: ClientOption }}
                noOptionsMessage={() =>
                  !!clientInputValue && clientInputValue.length >= 3
                    ? 'No client match your search. Add a new client or try again.'
                    : 'Enter at least 3 characters to search.'
                }
                styles={{
                  container: (base) => ({
                    ...base,
                    maxWidth: '400px',
                  }),
                  singleValue: (base) => ({
                    ...base,
                    position: 'relative',
                    top: 0,
                    transform: 'translateY(0)',
                    height: '100%',

                    padding: '0.25em 0',
                  }),
                  menu: (provided: any) => {
                    return {
                      ...provided,
                      zIndex: 9999,
                    };
                  },
                }}
              />
              <p className={'text-muted m-0'}>
                or{' '}
                <Button
                  color='link'
                  onClick={() => {
                    setShowNewPersonDetails(true);
                    setAddingNewClient((c) => c + 1);
                    setIsDirty(true);
                  }}
                  disabled={showNewPersonDetails}
                  className={'p-0 border-0 align-baseline'}
                >
                  Add New Client to Workshop
                </Button>
              </p>
            </div>
          </div>
        )}
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact</th>
              <th>Registration Status</th>
              <th>
                <span className='d-none'>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {newParticipantsForTable.map((p, index) => {
              if (p !== undefined) {
                if (editingClientId === p.clientId) {
                  return (
                    <tr key={p.clientId}>
                      <td colSpan={4} className='p-0'>
                        <ClientProvider clientId={p.clientId}>
                          <ClientDetails
                            allowCreateFile={false}
                            editCompleteAction={editCompleteAction}
                            deleteAction={() => {
                              setParticipantIdForDelete(p.clientId);
                              setOpenDeleteParticipantModal(true);
                            }}
                            clientId={editingClientId}
                            multiplePeopleFormat={true}
                            cancelAction={() => {
                              setEditingClientId(undefined);
                            }}
                            disableActions={false}
                            forWorkshop={true}
                            disableLeaveConfirm={true}
                          />
                        </ClientProvider>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={'fragment_' + p.clientId} className='bg-primary-light'>
                      <td>
                        <Button
                          color={'link'}
                          className={'p-0'}
                          onClick={() => {
                            history.push('/clients/' + p.clientId);
                          }}
                        >
                          <span style={{ color: 'black' }}>
                            {p.lastName?.toUpperCase() + ', ' + p.firstName + ' ' + p.middleName}
                          </span>
                        </Button>
                      </td>
                      <td>
                        {p.email && (
                          <div>
                            Email: <a href={`mailto:${p.email}`}>{p.email}</a>
                          </div>
                        )}
                        {p.phoneNumbers.map((phoneNumber) => (
                          <div key={phoneNumber.number}>
                            {phoneNumber.description}: <a href={`tel:${phoneNumber.number}`}>{phoneNumber.number}</a>
                          </div>
                        ))}
                      </td>
                      <td width={'20%'}>
                        <Select
                          styles={disabledSelectStyles}
                          name={'registrationStatusId_' + p.clientId.toString()}
                          options={registrationStatuses}
                          placeholder={<span className='placeholder'>Select...</span>}
                          value={registrationStatuses.find(
                            (r: IRegistrationStatus) => r.registrationStatusId === p.registrationStatusId
                          )}
                          onChange={(value: any) => {
                            setIsDirty(true);
                            const updatedNewParticipants = [
                              ...newParticipantsForTable.slice(0, index),
                              { ...newParticipantsForTable[index], registrationStatusId: value.registrationStatusId },
                              ...newParticipantsForTable.slice(index + 1),
                            ];
                            setNewParticipantsForTable((oldP) => [
                              ...oldP.slice(0, index),
                              { ...oldP[index], registrationStatusId: value.registrationStatusId },
                              ...oldP.slice(index + 1),
                            ]);
                            saveWorkshopParticipants([...updatedNewParticipants, ...participantsForTable]);
                          }}
                          getOptionValue={(option: IRegistrationStatus) => option.registrationStatusId}
                          getOptionLabel={(option: IRegistrationStatus) => option.name}
                        />
                      </td>
                      <td className={'text-right'}>
                        <Button
                          color={'primary'}
                          className={'w-mobile-100'}
                          size='sm'
                          disabled={editingClientId !== undefined}
                          onClick={() => {
                            setEditingClientId(p.clientId);
                            setAddingNewClient((c) => c + 1);
                          }}
                        >
                          Edit Details
                        </Button>
                      </td>
                    </tr>
                  );
                }
              }
              return null;
            })}

            {participantsForTable.map((p, index) => {
              if (p !== undefined) {
                if (editingClientId === p.clientId) {
                  return (
                    <tr key={p.clientId}>
                      <td colSpan={4} className='p-0'>
                        <ClientProvider clientId={p.clientId}>
                          <ClientDetails
                            allowCreateFile={false}
                            editCompleteAction={editCompleteAction}
                            deleteAction={() => {
                              setParticipantIdForDelete(p.clientId);
                              setOpenDeleteParticipantModal(true);
                            }}
                            clientId={editingClientId}
                            multiplePeopleFormat={true}
                            cancelAction={() => {
                              setEditingClientId(undefined);
                            }}
                            disableActions={false}
                            forWorkshop={true}
                            disableLeaveConfirm={true}
                          />
                        </ClientProvider>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <React.Fragment key={'fragment_' + p.clientId}>
                      <tr>
                        <td>
                          <Button
                            color={'link'}
                            className={'p-0'}
                            onClick={() => {
                              history.push('/clients/' + p.clientId);
                            }}
                          >
                            <span style={{ color: 'black' }}>
                              {p.lastName?.toUpperCase() + ', ' + p.firstName + ' ' + p.middleName}
                            </span>
                          </Button>
                        </td>
                        <td>
                          {p.email && (
                            <div>
                              Email: <a href={`mailto:${p.email}`}>{p.email}</a>
                            </div>
                          )}
                          {p.phoneNumbers.map((phoneNumber) => (
                            <div key={phoneNumber.number}>
                              {phoneNumber.description}: <a href={`tel:${phoneNumber.number}`}>{phoneNumber.number}</a>
                            </div>
                          ))}
                        </td>
                        <td width={'20%'}>
                          <Select
                            styles={disabledSelectStyles}
                            name={'registrationStatusId_' + p.clientId.toString()}
                            options={registrationStatuses}
                            placeholder={<span className='placeholder'>Select...</span>}
                            value={registrationStatuses.find(
                              (r: IRegistrationStatus) => r.registrationStatusId === p.registrationStatusId
                            )}
                            onChange={(value: any) => {
                              setIsDirty(true);
                              const updatedParticipants = [
                                ...participantsForTable.slice(0, index),
                                { ...participantsForTable[index], registrationStatusId: value.registrationStatusId },
                                ...participantsForTable.slice(index + 1),
                              ];
                              setParticipantsForTable((oldP) => [
                                ...oldP.slice(0, index),
                                { ...oldP[index], registrationStatusId: value.registrationStatusId },
                                ...oldP.slice(index + 1),
                              ]);
                              saveWorkshopParticipants([...updatedParticipants, ...newParticipantsForTable]);
                            }}
                            getOptionValue={(option: IRegistrationStatus) => option.registrationStatusId}
                            getOptionLabel={(option: IRegistrationStatus) => option.name}
                          />
                        </td>
                        <td className={'text-right'}>
                          <Button
                            color={'primary'}
                            className={'w-mobile-100'}
                            size='sm'
                            disabled={editingClientId !== undefined}
                            onClick={() => {
                              setEditingClientId(p.clientId);
                              setAddingNewClient((c) => c + 1);
                            }}
                          >
                            Edit Details
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                }
              }
              return null;
            })}
          </tbody>
        </Table>
      </form>
      <Modal
        isOpen={openEventsOrParticipantsModal}
        toggle={() => setOpenEventsOrParticipantsModal(!openEventsOrParticipantsModal)}
        className='logout-modal'
      >
        <ModalHeader toggle={() => setOpenEventsOrParticipantsModal(!openEventsOrParticipantsModal)}>
          Unable to Delete Workshop
        </ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            There are events and/or participants associated with this workshop and it cannot be deleted.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'primary'}
            onClick={() => {
              setOpenEventsOrParticipantsModal(false);
            }}
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={openEventsOrParticipantsModal}
        toggle={() => setOpenEventsOrParticipantsModal(!openEventsOrParticipantsModal)}
        className='logout-modal'
      >
        <ModalHeader toggle={() => setOpenEventsOrParticipantsModal(!openEventsOrParticipantsModal)}>
          Unable to Delete Workshop
        </ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            There are events and/or participants associated with this workshop and it cannot be deleted.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'primary'}
            onClick={() => {
              setOpenEventsOrParticipantsModal(false);
            }}
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={openDeleteParticipantModal}
        participantIdForDelete={participantIdForDelete}
        toggle={() => setOpenDeleteParticipantModal(!openDeleteParticipantModal)}
        className='logout-modal'
      >
        <ModalHeader toggle={() => setOpenDeleteParticipantModal(!openDeleteParticipantModal)}>
          Remove Participant
        </ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            Are you sure you want to remove this participant? Any attendance records for this participant will be
            deleted.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'danger'}
            onClick={() => {
              if (newParticipantsForTable.map((p) => p.clientId).includes(participantIdForDelete)) {
                setNewParticipantsForTable((pft) => pft.filter((p) => p.clientId !== participantIdForDelete));
              } else {
                setParticipantsForTable((pft) => pft.filter((p) => p.clientId !== participantIdForDelete));
              }
              saveWorkshopParticipants(
                [...newParticipantsForTable, ...participantsForTable].filter(
                  (p) => p.clientId !== participantIdForDelete
                )
              );
              setEditingClientId(undefined);
              setOpenDeleteParticipantModal(false);
            }}
          >
            Yes
          </Button>
          <Button color={'light'} onClick={() => setOpenDeleteParticipantModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
