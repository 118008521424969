import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { FileContext } from '../FileContext';
import {
  DomainContext,
  FILE_TYPE_BIRTH_WORKER,
  FILE_TYPE_FAMILY_PRESERVATION,
  FILE_TYPE_JORDANS_PRINCIPLE,
  IFrequency,
  IOutcome,
  IRequestCategory,
  JORDANS_PRINCIPLE_REQUEST_TYPE_ISC_SUBMISSION,
  JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE,
  JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER,
  REQUEST_CATEGORY_OTHER,
} from '../../../contexts/DomainContext';
import { useWindowDimensions } from '../../../hooks/WindowHooks';
import * as ToastService from '../../../services/ToastService';
import DatePicker from 'react-datepicker';
import { RequiredLabel } from '../../RequiredLabel';

interface IProps {
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  isEditingAllowed: boolean;
  trySave: boolean;
}

export const Requests = (props: IProps) => {
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const requests = fileContext.requests;
  const setRequests = fileContext.setRequests;
  const payments = fileContext.payments;
  const frequencyOptions = domainContext.frequencies;
  const familyPreservationCategoryOptions = domainContext.familyPreservationCategories;
  const birthWorkerCategoryOptions = domainContext.birthWorkerCategories;
  const allCategoryOptions = domainContext.allCategories;
  const jordansPrincipleISCSubmissionCategoryOptions = domainContext.jordansPrincipleISCSubmissionCategories;
  const jordansPrincipleNOLCategoryOptions = domainContext.jordansPrincipleNOLCategories;
  const jordansPrincipleOtherCategoryOptions = domainContext.jordansPrincipleOtherCategories;
  const allOutcomeOptions = domainContext.allOutcomes;
  const nonISCOutcomeOption = domainContext.nonISCOutcomes;
  const [changedRequestLength, setChangedRequestLength] = useState(0);
  const { xs, sm } = useWindowDimensions();
  const mobile = xs || sm;
  const formatCurrencyCommas = (amount: string | undefined) => {
    if (amount) {
      return amount
        .toString()
        .replace(/[, ]+/g, '')
        .replace(/-[^0-9.]/g, '')
        .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  const formatCurrencyCommasTwoDigits = (amount: string | undefined) => {
    if (amount) {
      const floatToUse = parseFloat(amount.toString().replace(/[, ]+/g, ''));
      return Number.isNaN(floatToUse)
        ? '0.00'
        : floatToUse
            .toFixed(2)
            .toString()
            .replace(/-[^0-9.]/g, '')
            .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (changedRequestLength > 0) {
      var elmt2 = document.getElementById('select-field');
      if (elmt2) {
        elmt2.focus();
        elmt2.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [changedRequestLength]);

  const formattedAmountToNumber = (amount: string | undefined) => {
    if (amount) {
      if (amount.length > 0 && amount.charAt(0) === '-' && amount !== '-') {
        return -Math.abs(Number(amount.toString().replace(/[, ]+|[- ]+/g, '')));
      } else {
        return Number(amount.toString().replace(/[,]+|[- ]+/g, ''));
      }
    } else {
      return 0;
    }
  };

  const calculateTotal = (cost: string | undefined, duration: string | undefined) => {
    if (cost && duration) {
      const total = formattedAmountToNumber(cost) * Number(duration);
      return formatCurrencyCommasTwoDigits(total.toString());
    } else {
      return '';
    }
  };

  const disabledSelectStyles = {
    control: (styles: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
        borderRadius: '1.25rem',
      };
    },
    singleValue: (styles: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  const allTotals = () => {
    let total = 0;
    requests.forEach((r) => {
      total = total + formattedAmountToNumber(calculateTotal(r.cost, r.duration));
    });
    return formatCurrencyCommasTwoDigits(total.toString());
  };

  const selectCategoryOptions = (fileType: string | undefined) => {
    if (
      fileType === FILE_TYPE_JORDANS_PRINCIPLE &&
      fileContext.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_ISC_SUBMISSION
    ) {
      return jordansPrincipleISCSubmissionCategoryOptions;
    } else if (
      fileType === FILE_TYPE_JORDANS_PRINCIPLE &&
      fileContext.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE
    ) {
      return jordansPrincipleNOLCategoryOptions;
    } else if (
      fileType === FILE_TYPE_JORDANS_PRINCIPLE &&
      fileContext.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER
    ) {
      return jordansPrincipleOtherCategoryOptions;
    } else if (fileType === FILE_TYPE_BIRTH_WORKER) {
      return birthWorkerCategoryOptions;
    } else if (fileType === FILE_TYPE_FAMILY_PRESERVATION) {
      return familyPreservationCategoryOptions;
    } else {
      return [];
    }
  };

  return (
    <>
      <Card>
        <CardHeader className={'d-flex align-items-center'}>
          {fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE && 'Requests'}
          {(fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION ||
            fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER) &&
            'Financial Assistance'}
        </CardHeader>
        <CardBody className={'p-0'}>
          <ListGroup flush={true}>
            {requests && requests.length > 0 && (
              <ListGroupItem className={'py-2 d-none d-md-block'}>
                <Row style={{ paddingRight: 42 }} className='row--condensed'>
                  <Col style={{ flexGrow: 1.5 }}>
                    <RequiredLabel required={requests.filter((r) => !r.categoryId).length > 0}>Category</RequiredLabel>
                  </Col>
                  {fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE &&
                    (fileContext.jordansPrincipleFields?.requestTypeId ===
                      JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE ||
                      fileContext.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER) && (
                      <Col style={!mobile ? { flexGrow: 0.7, flexShrink: 0, flexBasis: 0, minWidth: 80 } : {}}>
                        <RequiredLabel required={requests.filter((r) => !r.numberOfChildren).length > 0}>
                          # of Children
                        </RequiredLabel>
                      </Col>
                    )}
                  <Col style={{ flexGrow: 2.1 }}>
                    <Label>Description</Label>
                  </Col>
                  <Col>
                    <Label>Cost ($)</Label>
                  </Col>
                  <Col style={!mobile ? { flexGrow: 1, flexShrink: 0, flexBasis: 0 } : {}}>
                    <Label>Frequency</Label>
                  </Col>
                  <Col style={!mobile ? { flexGrow: 0.5, flexShrink: 0, flexBasis: 0, minWidth: 80 } : {}}>
                    <Label>Duration</Label>
                  </Col>
                  <Col>
                    <Label>Total ($)</Label>
                  </Col>
                  <Col>
                    <Label>Service Provider</Label>
                  </Col>
                  {fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE && (
                    <Col>
                      <Label>Outcome</Label>
                    </Col>
                  )}
                  {fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION && (
                    <Col>
                      <Label>Date Provided</Label>
                    </Col>
                  )}
                </Row>
              </ListGroupItem>
            )}
            {requests.map((r, index) => (
              <ListGroupItem key={index} className={'py-4'}>
                <div className={'d-flex'}>
                  <div className={'flex-grow-1'}>
                    <Row className='row--condensed'>
                      <Col sm={12} md={'auto'} style={!mobile ? { flexGrow: 1.5, flexShrink: 1, flexBasis: 0 } : {}}>
                        <Label className={'d-md-none'}>Category</Label>
                        <Select
                          key={`category-${r.requestId}-${r.categoryId}`}
                          styles={disabledSelectStyles}
                          name={'category'}
                          placeholder={<span className='placeholder'>Select...</span>}
                          options={selectCategoryOptions(fileContext.fileTypeId)}
                          //value chooses from all categories for CYFN-429 data preservation
                          value={allCategoryOptions.find((ft: any) => ft.categoryId === r.categoryId)}
                          onChange={(value: any) => {
                            setRequests((oldR) => [
                              ...oldR.slice(0, index),
                              { ...oldR[index], categoryId: value ? value.categoryId : undefined },
                              ...oldR.slice(index + 1),
                            ]);
                            props.setIsDirty(true);
                          }}
                          getOptionValue={(option: IRequestCategory) => option.categoryId}
                          getOptionLabel={(option: IRequestCategory) => option.name}
                          isDisabled={!props.isEditingAllowed}
                          isClearable={true}
                        />
                        {props.trySave && !r.categoryId && fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE && (
                          <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                        )}
                      </Col>
                      {fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE &&
                        (fileContext.jordansPrincipleFields?.requestTypeId ===
                          JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE ||
                          fileContext.jordansPrincipleFields?.requestTypeId ===
                            JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER) && (
                          <Col
                            sm={12}
                            md={'auto'}
                            style={!mobile ? { flexGrow: 0.7, flexShrink: 0, flexBasis: 0, minWidth: 80 } : {}}
                          >
                            <FormGroup className={'w-100 mb-2 '}>
                              <Input
                                key={`numberOfChildren-${r.requestId}-${r.numberOfChildren}`}
                                type='number'
                                value={r.numberOfChildren}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const numberOfChildren = e.target.value;
                                  setRequests((oldR) => [
                                    ...oldR.slice(0, index),
                                    { ...oldR[index], numberOfChildren: numberOfChildren },
                                    ...oldR.slice(index + 1),
                                  ]);
                                  props.setIsDirty(true);
                                }}
                              />
                            </FormGroup>
                            {props.trySave &&
                              !r.numberOfChildren &&
                              fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE && (
                                <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                              )}
                          </Col>
                        )}
                      <Col sm={12} md={'auto'} style={!mobile ? { flexGrow: 2, flexShrink: 2, flexBasis: 0 } : {}}>
                        {fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION ? (
                          <RequiredLabel
                            className={'d-md-none'}
                            for={'description'}
                            required={r.description === '' && r.categoryId === REQUEST_CATEGORY_OTHER}
                          >
                            Description
                          </RequiredLabel>
                        ) : (
                          <Label className={'d-md-none'}>Description</Label>
                        )}
                        <Input
                          id={index === requests.length - 1 ? 'select-field' : ''}
                          type={'textarea'}
                          placeholder='Type here...'
                          name='description'
                          style={{ minHeight: '38px', height: '38px' }}
                          value={r.description}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const description = e.target.value;
                            setRequests((oldR) => [
                              ...oldR.slice(0, index),
                              { ...oldR[index], description: description },
                              ...oldR.slice(index + 1),
                            ]);
                            props.setIsDirty(true);
                          }}
                        />
                        {props.trySave &&
                          r.description === '' &&
                          r.categoryId === REQUEST_CATEGORY_OTHER &&
                          fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION && (
                            <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                          )}
                      </Col>

                      <Col sm={12} md={'auto'} style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}>
                        <Label className={'d-md-none'}>Cost ($)</Label>
                        <Input
                          type='text'
                          style={{ textAlign: 'right' }}
                          value={r.cost}
                          onChange={(e) => {
                            const cost = e.target.value;
                            setRequests((oldR) => [
                              ...oldR.slice(0, index),
                              { ...oldR[index], cost: formatCurrencyCommas(cost) },
                              ...oldR.slice(index + 1),
                            ]);
                            props.setIsDirty(true);
                          }}
                          onBlur={(e) => {
                            const cost = e.target.value;
                            setRequests((oldR) => [
                              ...oldR.slice(0, index),
                              { ...oldR[index], cost: formatCurrencyCommasTwoDigits(cost) },
                              ...oldR.slice(index + 1),
                            ]);
                            props.setIsDirty(true);
                          }}
                        />
                      </Col>
                      <Col sm={12} md={'auto'} style={!mobile ? { flexGrow: 1, flexShrink: 0, flexBasis: 0 } : {}}>
                        <Label className={'d-md-none'}>Frequency</Label>
                        <Select
                          styles={disabledSelectStyles}
                          name={'fileTypeId'}
                          placeholder={<span className='placeholder'>Select...</span>}
                          options={frequencyOptions}
                          value={frequencyOptions.find((ft: any) => ft.frequencyId === r.frequencyId)}
                          onChange={(value: any) => {
                            value && value.frequencyId === '48b2736a-648e-4ff7-b1c2-8f024ed7f7d6'
                              ? setRequests((oldR) => [
                                  ...oldR.slice(0, index),
                                  {
                                    ...oldR[index],
                                    frequencyId: value ? value.frequencyId : undefined,
                                    duration: '1',
                                  },
                                  ...oldR.slice(index + 1),
                                ])
                              : setRequests((oldR) => [
                                  ...oldR.slice(0, index),
                                  { ...oldR[index], frequencyId: value ? value.frequencyId : undefined },
                                  ...oldR.slice(index + 1),
                                ]);
                            props.setIsDirty(true);
                          }}
                          getOptionValue={(option: IFrequency) => option.frequencyId}
                          getOptionLabel={(option: IFrequency) => option.name}
                          isDisabled={!props.isEditingAllowed}
                          isClearable={true}
                        />
                      </Col>
                      <Col
                        sm={12}
                        md={'auto'}
                        style={!mobile ? { flexGrow: 0.5, flexShrink: 0, flexBasis: 0, minWidth: 80 } : {}}
                      >
                        <Label className={'d-md-none'}>Duration</Label>
                        <Input
                          type='number'
                          value={r.duration}
                          onChange={(e) => {
                            setRequests((oldR) => [
                              ...oldR.slice(0, index),
                              { ...oldR[index], duration: e.target.value },
                              ...oldR.slice(index + 1),
                            ]);
                            props.setIsDirty(true);
                          }}
                        />
                      </Col>
                      <Col sm={12} md={'auto'} style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}>
                        <Label className={'d-md-none'}>Total ($)</Label>
                        <Input
                          style={{ textAlign: 'right' }}
                          type={'text'}
                          disabled={true}
                          name='total'
                          value={calculateTotal(r.cost, r.duration)}
                        />
                      </Col>
                      <Col sm={12} md={'auto'} style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}>
                        <Label className={'d-md-none'}>Service Provider</Label>
                        <Input
                          type={'text'}
                          placeholder='Type here...'
                          name='service provider'
                          value={r.serviceProvider}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const description = e.target.value;
                            setRequests((oldR) => [
                              ...oldR.slice(0, index),
                              { ...oldR[index], serviceProvider: description },
                              ...oldR.slice(index + 1),
                            ]);
                            props.setIsDirty(true);
                          }}
                        />
                      </Col>
                      {fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION && (
                        <Col sm={12} md={'auto'} style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}>
                          <Label className={'d-md-none'}>Date Provided</Label>
                          <DatePicker
                            selected={r.dateProvided ? new Date(r.dateProvided) : undefined}
                            name={'application-date'}
                            onChange={(d) => {
                              if (d) {
                                setRequests((oldR) => [
                                  ...oldR.slice(0, index),
                                  { ...oldR[index], dateProvided: d.getTime() },
                                  ...oldR.slice(index + 1),
                                ]);
                                props.setIsDirty(true);
                              } else {
                                setRequests((oldR) => [
                                  ...oldR.slice(0, index),
                                  { ...oldR[index], dateProvided: undefined },
                                  ...oldR.slice(index + 1),
                                ]);
                                props.setIsDirty(true);
                              }
                            }}
                            peekNextMonth
                            showMonthDropdown
                            disabled={!props.isEditingAllowed}
                            showYearDropdown
                            isClearable={true}
                            dateFormat='MMM dd, yyyy'
                            dropdownMode='select'
                            className={
                              props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                            }
                            placeholderText={'MMM dd, yyyy'}
                          />
                        </Col>
                      )}
                      {fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE && (
                        <Col sm={12} md={'auto'} style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}>
                          <Label className={'d-md-none'}>Outcome</Label>
                          <Select
                            styles={disabledSelectStyles}
                            name={'outcome'}
                            placeholder={<span className='placeholder'>Select...</span>}
                            options={
                              fileContext.jordansPrincipleFields?.requestTypeId ===
                              JORDANS_PRINCIPLE_REQUEST_TYPE_ISC_SUBMISSION
                                ? allOutcomeOptions
                                : nonISCOutcomeOption
                            }
                            value={allOutcomeOptions.find((ft: any) => ft.outcomeId === r.outcomeId)}
                            onChange={(value: any) => {
                              setRequests((oldR) => [
                                ...oldR.slice(0, index),
                                { ...oldR[index], outcomeId: value ? value.outcomeId : undefined },
                                ...oldR.slice(index + 1),
                              ]);
                              props.setIsDirty(true);
                            }}
                            getOptionValue={(option: IOutcome) => option.outcomeId}
                            getOptionLabel={(option: IOutcome) => option.name}
                            isDisabled={!props.isEditingAllowed}
                            isClearable={true}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row className={'pt-md-3'}>
                      <Col xs={12}>
                        <Label className={'d-md-none'}>Comments</Label>
                        <Input
                          type={'textarea'}
                          placeholder='Type comments here...'
                          name='comment'
                          // style={{ minHeight: '2.4rem', overflowY: 'visible', height: '-webkit-fill-available' }}
                          style={{ minHeight: '38px', height: '38px' }}
                          value={r.comment}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const description = e.target.value;
                            setRequests((oldR) => [
                              ...oldR.slice(0, index),
                              { ...oldR[index], comment: description },
                              ...oldR.slice(index + 1),
                            ]);
                            props.setIsDirty(true);
                          }}
                        />
                      </Col>
                      <Col className={'d-md-none'}>
                        <Button
                          color='danger'
                          className='w-mobile-100 mt-3'
                          onClick={() => {
                            setRequests((oldR) => [...oldR.slice(0, index), ...oldR.slice(index + 1)]);
                            props.setIsDirty(true);
                          }}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className={'d-none d-md-block'} style={{ width: 42 }}>
                    <Button
                      color={'link'}
                      className={'text-danger'}
                      onClick={() => {
                        if (payments.filter((p) => p.requestId === r.requestId).length === 0) {
                          setRequests((oldR) => [...oldR.slice(0, index), ...oldR.slice(index + 1)]);
                          props.setIsDirty(true);
                        } else {
                          ToastService.notifyError('There is a payment associated with this request');
                        }
                      }}
                    >
                      <FaTimes />
                    </Button>
                  </div>
                </div>
              </ListGroupItem>
            ))}
            {requests.length > 0 && (
              <ListGroupItem className={'py-2 d-none d-md-block'}>
                <Row style={{ paddingRight: 42 }}>
                  {fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION && <Col />}
                  <Col style={{ flexGrow: 2 }} />
                  <Col />
                  <Col />
                  <Col style={{ textAlign: 'right' }}>
                    <Label className={'mt-2'}>Total ($)</Label>
                  </Col>
                  <Col>
                    <Input
                      style={{ textAlign: 'right' }}
                      type={'text'}
                      disabled={true}
                      name='total'
                      value={allTotals()}
                    />
                  </Col>
                  <Col />
                  {fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE && <Col />}
                  {fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION && <Col />}
                </Row>
              </ListGroupItem>
            )}
            <ListGroupItem
              id={'add-new-request'}
              onClick={() => {
                setRequests((r) =>
                  r.concat([
                    { description: '', frequencyId: '', categoryId: '', duration: '', cost: '', outcomeId: '' },
                  ])
                );
                setChangedRequestLength((s) => s + 1);
                props.setIsDirty(true);
              }}
              tag={'button'}
            >
              <FaPlus /> {fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE && 'Add Funding Request'}
              {(fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION ||
                fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER) &&
                'Add Financial Assistance'}
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
    </>
  );
};
