import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import {
  Button,
  Col,
  Container,
  FormGroup,
  FormText,
  Label,
  ListGroup,
  ListGroupItem,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { useParams } from 'react-router';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { FormattedText } from '../FormattedText';
import { ContentState, EditorState } from 'draft-js';
import { FileUpload, OnFileDrop } from '../FileUpload/FileUpload';
import DatePicker from 'react-datepicker';
import LoadingService from '../Loading/LoadingService';
import * as FileUploadService from '../FileUpload/FileUploadService';
import { FaPrint } from 'react-icons/fa';
import { UsersContext } from '../../contexts/UsersContext';
import { ClientContext, IClientMeta } from './ClientContext';
import { IDocument } from '../Files/Documents/Documents';
import { DocumentListItem } from '../Files/Documents/DocumentListItem';
import { getUserId } from '../../services/AuthenticationService';
import { NOTE_DOCUMENT_TYPE } from '../../contexts/DomainContext';
import { useHistory } from 'react-router-dom';
import htmlToDraft from 'html-to-draftjs';
import { INote } from '../Files/FileNotes';
const DOMPurify = require('dompurify');

interface IClientNotesDetails {
  onSave: () => void;
  onCancel: () => void;
}

export const ClientNotesDetails: React.FC<IClientNotesDetails> = (props) => {
  const clientContext = useContext(ClientContext);
  const [isDirty, setIsDirty] = useState(false);
  const [textBoxEditor, setTextBoxEditor] = useState<EditorState>(EditorState.createEmpty());
  const [lastUpdated, setLastUpdated] = useState<number>();
  const [date, setDate] = useState<Date>(new Date());
  const [docsToUpload, setDocsToUpload] = useState<File[]>([]);
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [documentsToDelete, setDocumentsToDelete] = useState<IDocument[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const params = useParams<{ clientId: string; noteId?: string }>();
  const history = useHistory();
  const [printOption, setPrintOption] = useState('');
  const usersContext = useContext(UsersContext);
  const users = usersContext.users;
  const [note, setNote] = useState<INote | undefined>();

  useEffect(() => {
    console.log('');
    if (params.noteId) {
      authAxios.get('/api/clients/' + params.clientId + '/notes/' + params.noteId).then((response) => {
        const note: INote = response.data;
        setNote(note);
        setTextBoxEditor(
          EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(note.text).contentBlocks))
        );
        setLastUpdated(note.dateLastUpdated);
      });
      authAxios.get('/api/clients/' + params.clientId + '/notes/' + params.noteId + '/documents').then((response) => {
        setDocuments(response.data);
      });
    }
  }, [params.noteId, params.clientId]);

  useEffect(() => {
    if (printOption !== '') {
      window.print();
      setPrintOption('');
    }
  }, [printOption]);

  const doNewDocumentToUpload = (noteId?: string) => {
    const files = docsToUpload;
    const newDocumentPath = '/api/documents/add-client-note-document/' + params.clientId + '/' + noteId;

    if (!noteId) {
      console.log('Nowhere to upload these... bailing!', files);
      return;
    }
    console.log("Let's upload these ", files);

    const loadingKey: string = LoadingService.add();

    const doUploadOneFile = (file: File, index: number) => {
      return FileUploadService.uploadFile(file, newDocumentPath).then((response: any) => {
        if (response.data.status === 'OK') {
          setDocuments((docs: IDocument[]) => [...docs, response.data.document as IDocument]);
          setDocsToUpload((d) => (d.length <= 1 ? [] : [...d.slice(0, index), ...d.slice(index + 1)]));
        }
      });
    };

    const sequenceUploads = Array.from(files).reduce((p, file: File, i) => {
      return p.then(() => doUploadOneFile(file, i).catch((err) => console.log('Upload failed.', err)));
    }, Promise.resolve());

    sequenceUploads
      .then(() => {
        console.log('Uploaded all files.');
        clientContext.setDocumentUploaded(!clientContext.documentUploaded);
        LoadingService.remove(loadingKey);
      })
      .catch((error) => {
        console.log('One or more upload failed.', error);
        LoadingService.remove(loadingKey);
      });
  };

  const save = () => {
    if (params.noteId) {
      //update
      authAxios
        .put('/api/clients/update-note', {
          clientId: params.clientId,
          noteId: params.noteId,
          text: FormattedText.getContent(textBoxEditor),
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            if (documentsToDelete && documentsToDelete.length > 0) {
              documentsToDelete.map((d) => {
                authAxios.post('/api/documents/remove-client-note-document', {
                  clientId: params.clientId,
                  noteId: params.noteId,
                  documentId: d.documentId,
                });
              });
            }
            const updatedNotes = clientContext.notes.map((n) => {
              if (n.noteId === params.noteId) {
                return { ...n, text: FormattedText.getContent(textBoxEditor) };
              } else {
                return n;
              }
            });
            clientContext.setNotes(updatedNotes);
            notifySuccess('Note updated.');
            setLastUpdated(new Date().getTime());
            doNewDocumentToUpload(params.noteId);
          } else {
            notifyError('Error updating note.');
          }
        });
    } else {
      //create
      authAxios
        .post('/api/clients/create-note', {
          clientId: params.clientId,
          text: FormattedText.getContent(textBoxEditor),
          dateCreated: date.getTime(),
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Note created.');
            doNewDocumentToUpload(response.data.noteId);
            clientContext.setClientMeta((m: IClientMeta) => ({ ...m, noteCount: m?.noteCount ? m.noteCount + 1 : 1 }));
            clientContext.setNotes((n) =>
              n.concat([
                {
                  noteId: response.data.noteId,
                  text: FormattedText.getContent(textBoxEditor),
                  dateCreated: date.getTime(),
                  userCreated: getUserId(),
                  dateLastUpdated: new Date().getTime(),
                  userUpdated: getUserId(),
                  documents: docsToUpload.map((d) => {
                    return {
                      documentId: Math.random().toString(),
                      documentTypeId: NOTE_DOCUMENT_TYPE,
                      fileName: d.name,
                      documentState: '',
                      uploadId: '',
                    };
                  }),
                  clientId: params.clientId,
                  clientFirstName: clientContext.client.firstName,
                  clientLastName: clientContext.client.lastName,
                },
              ])
            );
          } else {
            notifyError('Error creating note.');
          }
        });
      props.onSave();
    }
  };

  const deleteNote = () => {
    if (params.noteId) {
      authAxios
        .post('/api/clients/delete-note', { clientId: params.clientId, noteId: params.noteId })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            clientContext.setNotes((s: INote[]) => s.filter((n) => n.noteId !== params.noteId));
            notifySuccess('Note deleted.');
            history.push({
              pathname: '/clients/' + params.clientId + '/notes',
              state: { deletedNoteId: params.noteId },
            });
            clientContext.setClientMeta((m: IClientMeta) => ({ ...m, noteCount: m.noteCount - 1 }));
          } else {
            notifyError('Error deleting note.');
          }
        });
    }
  };

  const handleCancel = () => {
    if (isDirty) {
      setCancelModalOpen(true);
    } else {
      props.onCancel();
    }
  };

  const handleFileDrop: OnFileDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setIsDirty(true);
      setDocsToUpload((files) => [...files, ...acceptedFiles]);
    }
  };

  const removeDocument = (document: IDocument) => {
    setIsDirty(true);
    setDocumentsToDelete((docs) => docs.concat(document));
    setDocuments((files) => {
      const filteredFiles = files.filter((file) => {
        if ('fileName' in file && 'fileName' in document) {
          return file.fileName !== document.fileName;
        }
        return false;
      });
      return filteredFiles;
    });
  };

  const removeDocumentToUpload = (document: File) => {
    setDocsToUpload((files) => {
      const filteredFiles = files.filter((file) => {
        if ('name' in file && 'name' in document) {
          return file.name !== document.name;
        }
        return false;
      });
      return filteredFiles;
    });
  };

  return (
    <>
      <div>
        <header className={'report-header hide-printable'}>
          <h2 className='text-center'>
            CYFN {clientContext.client.lastName.toUpperCase() + ', ' + clientContext.client.firstName} Case Notes
          </h2>
        </header>
        <ListGroup>
          <ListGroupItem key={note?.noteId} className={'hide-printable'}>
            <Row>
              <Col md={12} lg={2} className={'text-lg-right'}>
                <strong>
                  {users.find((u) => u.userId === note?.userCreated)
                    ? users.find((u) => u.userId === note?.userCreated)?.lastName.toUpperCase() +
                      ', ' +
                      users.find((u) => u.userId === note?.userCreated)?.firstName
                    : ''}
                </strong>
                <div className={'text-muted small mb-2'}>
                  {note?.dateCreated ? formatDateTime(new Date(note?.dateCreated)) : ''}
                </div>
              </Col>
              <Col md={8} lg={7} xl={6} className={'mb-3 mb-md-0'}>
                <main dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(note?.text ? note.text : '') }} />
              </Col>
              <Col>
                {documents && documents.length > 0 && (
                  <div>
                    <h6>Documents</h6>
                    <ul>
                      {documents.map((d) => (
                        <li key={d.documentId}>
                          <Button
                            color={'link'}
                            className={'p-0'}
                            disabled={!d.uploadId}
                            style={{ wordBreak: 'break-all', textAlign: 'left' }}
                          >
                            {d.fileName}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
        className={'no-print'}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <NavbarBrand>{params.noteId ? 'Edit Case Note' : 'Add Case Note'}</NavbarBrand>
          <div className={'ml-auto d-flex flex-row-reverse'}>
            <Button color={'primary'} className={'ml-2'} onClick={save} disabled={!isDirty}>
              Save Case Note
            </Button>
            {params.noteId ? (
              <div>
                <Button
                  color='warning'
                  className={'ml-auto'}
                  onClick={() => setPrintOption(params.noteId ? params.noteId : '')}
                >
                  <FaPrint /> Print
                </Button>
                <Button color='danger' className={'ml-2'} onClick={() => setDeleteModalOpen(true)}>
                  Delete Case Note
                </Button>
              </div>
            ) : (
              <Button color='link' className={'ml-auto'} onClick={handleCancel}>
                Cancel
              </Button>
            )}
          </div>
        </Navbar>
        <Container fluid={true}>
          <Row className={'mt-3'}>
            <Col md={12} lg={2} className={'text-lg-right'}>
              <Label htmlFor={'note'} className={'text-body h6 mb-0 font-weight-bold'}>
                Case Note
              </Label>
              <br />
              {params.noteId && (
                <div className={'text-muted small mb-2'}>
                  &nbsp;&nbsp;Last Updated:
                  <br /> {lastUpdated !== undefined ? formatDateTime(new Date(lastUpdated)) : ''}
                </div>
              )}
            </Col>
            <Col md={8} lg={7} xl={6}>
              <FormGroup>
                <FormattedText
                  name={'note'}
                  editorState={textBoxEditor}
                  onEditorStateChange={(editorState: EditorState) => {
                    setTextBoxEditor(editorState);
                    editorState.getUndoStack().size > 0 && setIsDirty(true);
                  }}
                  autoFocus={true}
                  placeholder={'Add your note here…'}
                />
              </FormGroup>
              {!params.noteId && (
                <FormGroup>
                  <Label htmlFor={'noteDate'}>Date</Label>
                  <DatePicker
                    selected={date}
                    id={'noteDate'}
                    onChange={(date) => {
                      if (date) {
                        setDate(date);
                      }
                    }}
                    className={'form-control'}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dateFormat='MMM dd, yyyy'
                  />
                </FormGroup>
              )}
            </Col>
            <Col>
              <FileUpload isUploading={false} onDrop={handleFileDrop} uploadSuccessful={false} />
              {(documents.length > 0 || docsToUpload.length > 0) && (
                <ListGroup className={'my-3'}>
                  {documents?.map((d, i) => (
                    <DocumentListItem
                      key={i}
                      name={d.fileName}
                      onDelete={() => removeDocument(d)}
                      onClick={() => alert('click')}
                    />
                  ))}
                  {docsToUpload.map((d, i) => (
                    <DocumentListItem
                      key={i}
                      name={d.name}
                      onDelete={() => removeDocumentToUpload(d)}
                      onClick={() => alert('click')}
                    />
                  ))}
                </ListGroup>
              )}
              <FormText color='muted' className={'my-3'}>
                New documents will be uploaded when this note is saved.
              </FormText>
            </Col>
          </Row>
        </Container>
      </form>
      <ConfirmModal
        isOpen={deleteModalOpen}
        title={'Delete Case Note'}
        onConfirm={() => {
          setDeleteModalOpen(false);
          deleteNote();
        }}
        onCancel={() => setDeleteModalOpen(false)}
        confirmButton={'Delete Case Note'}
        confirmButtonColor={'danger'}
      >
        <div>Are you sure you want to delete this case note?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={cancelModalOpen}
        title={`You have unsaved changes.`}
        onConfirm={() => {
          setCancelModalOpen(false);
          props.onCancel();
        }}
        onCancel={() => setDeleteModalOpen(false)}
        confirmButton={'Leave this Page'}
        cancelButton={'Stay on Page'}
      />
    </>
  );
};
