import * as React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useHistory } from 'react-router';

export const PageNotFound: React.FC = () => {
  const history = useHistory();

  return (
    <div>
      <Col>
        <Row style={{ position: 'relative', left: '50%' }}>
          <span>Page Not Found</span>
        </Row>
        <Row style={{ position: 'relative', left: '50%' }}>
          <Button className={'ml-2'} onClick={() => history.push('/')}>
            Go Home
          </Button>
        </Row>
      </Col>
    </div>
  );
};
