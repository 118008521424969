import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { IAddress, IClient } from '../Clients/ClientContext';
import { useGetSupportTeam } from '../../hooks/Clients/query/useGetSupportTeam';
import { Badge, Col, FormGroup, Label, Row } from 'reactstrap';
import { formatDateFromISONumber } from '../../formatters/DateTimeFormatter';
import { DomainContext, ICommunity } from '../../contexts/DomainContext';
import { useClientAddresses } from '../../hooks/Clients/query/useClientAddresses';
import { IPhoneNumber } from '../Clients/ClientDetails';
import { MyUserContext } from '../../contexts/MyUserContext';
import { getAge } from '../../utils/DateUtils';

export const ClientDisplay: React.FC<{ client: IClient; relationship?: string }> = (props) => {
  const { data: supportPeopleData } = useGetSupportTeam(props.client.clientId);
  const domainContext = useContext(DomainContext);
  const communities = domainContext.communities;
  const genders = domainContext.genders;
  const indigenousGroups = domainContext.indigenousGroups;
  const yukonFns = domainContext.yukonFns;
  const groupHomes = domainContext.groupHomes;
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const myUser = useContext(MyUserContext);

  const { data: addressData, loading: addressLoading, error: addressError } = useClientAddresses(props.client.clientId);

  useEffect(() => {
    const onCompleted = (data?: IAddress[]) => {
      if (data) {
        const activeAddresses = data.filter((ad) => ad.isCurrent);
        const nonActiveAddresses = data.filter((ad) => !ad.isCurrent);
        setAddresses(activeAddresses.concat(nonActiveAddresses));
      }
    };

    const onError = (e: any) => {
      console.log(`Error loading data ${e}`);
    };

    if (addressData && !addressLoading && !addressError) {
      onCompleted(addressData);
    } else if (!addressLoading && addressError) {
      onError(addressError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressLoading, addressData, addressError]);

  return (
    <>
      <div>
        <Row>
          <Col md={3} lg={2}>
            <FormGroup>
              <Label>
                Date of Birth
                {props.client.birthDate ? ' (Current Age: ' + getAge(new Date(props.client.birthDate)) + ')' : ''}
              </Label>
              <div>{formatDateFromISONumber(props.client.birthDate) || '-'}</div>
            </FormGroup>
          </Col>
          <Col md={3} lg={2}>
            <FormGroup>
              <Label>Gender</Label>
              <div>{genders.find((g) => g.genderId === props.client.genderId)?.name || '-'}</div>
            </FormGroup>
          </Col>
          <Col md={3} lg={2}>
            <FormGroup>
              <Label>Email</Label>
              <div>{props.client.email || '-'}</div>
            </FormGroup>
          </Col>
          <Col md={3} lg={2}>
            <FormGroup>
              <Label>Community</Label>
              <div>{communities.find((c: ICommunity) => c.communityId === props.client.communityId)?.name || '-'}</div>
            </FormGroup>
          </Col>
          <Col md={3} lg={2}>
            <FormGroup>
              <Label>School</Label>
              <div>{props.client.school || '-'}</div>
            </FormGroup>
          </Col>
          <Col md={3} lg={2}>
            <FormGroup>
              <Label>Status #</Label>
              <div>{props.client.statusCardNum || '-'}</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3} lg={2}>
            <FormGroup>
              <Label>In Group Care</Label>
              <div>{props.client.inGroupCare ? 'Yes' : 'No'}</div>
            </FormGroup>
          </Col>
          <Col md={3} lg={2}>
            <FormGroup>
              <Label>Group Home</Label>
              <div>{groupHomes.find((g) => g.groupHomeId === props.client.groupHomeId)?.name || '-'}</div>
            </FormGroup>
          </Col>
        </Row>
        {myUser.isSuperAdmin && props.client.flagged && (
          <Row>
            <Col>
              <FormGroup>
                <Label>Flagged</Label>
                <div>{props.client.flaggedDescription || 'Yes'}</div>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <FormGroup>
              <Label>Indigenous Ancestry</Label>
              {props.client.indigenousInfo?.length === 0 && <div>-</div>}
              {props.client.indigenousInfo?.length > 0 &&
                props.client.indigenousInfo.map((e, i) => {
                  return (
                    <div key={'ia_' + i} style={{ display: 'block' }}>
                      {e.indigenousGroupId && (
                        <div style={{ display: 'inline' }}>
                          {indigenousGroups.find((d: any) => d.indigenousGroupId === e.indigenousGroupId)?.name || '-'}
                        </div>
                      )}
                      {e.bandNation && (
                        <div className={'dot-list'} style={{ display: 'inline' }}>
                          {yukonFns.find((d) => d.yukonFirstNationId === e.bandNation)?.name || e.bandNation || '-'}
                        </div>
                      )}
                      {e.indigenousType && (
                        <div className={'dot-list'} style={{ display: 'inline' }}>
                          {e.indigenousType || '-'}
                        </div>
                      )}
                      {e.heritage && (
                        <div className={'dot-list'} style={{ display: 'inline' }}>
                          {e.heritage || '-'}
                        </div>
                      )}
                    </div>
                  );
                })}
            </FormGroup>
          </Col>
        </Row>
      </div>

      <div>
        <Row>
          <Col>
            <Row>
              {addresses.length === 0 && (
                <Col md={4} lg={3}>
                  {' '}
                  <FormGroup>
                    <Label>Addresses</Label>
                    <div>-</div>
                  </FormGroup>
                </Col>
              )}
              {addresses.length > 0 &&
                addresses.map((address: IAddress, ai) => {
                  return (
                    <Col className={'mb-4'} md={4} lg={3} key={`addresses-listGroupItem-${address.addressId}`}>
                      <FormGroup>
                        <Label>{ai === 0 && 'Addresses'}</Label>
                        <div>
                          <span>
                            <b>{address.label}</b>
                          </span>
                          {address.isCurrent ? (
                            <Badge color='secondary' className='ml-2'>
                              Current Address
                            </Badge>
                          ) : (
                            <Badge color='light' className='ml-2'>
                              Past Address
                            </Badge>
                          )}
                          <div className={'street-address'}>{address.line1}</div>
                          {address.line2 && <div className={'extended-address'}>{address.line2}</div>}
                          {(address.city !== '' || address.territory !== '' || address.postalCode !== '') && (
                            <div>{address.city + ', ' + address.territory + ', ' + address.postalCode}</div>
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  );
                })}
              <Col md={4} lg={3}>
                <FormGroup>
                  <Label>Phone Numbers</Label>
                  <div style={{ display: 'block' }}>
                    {props.client.phoneNumbers?.length === 0 && <div>-</div>}
                    {props.client.phoneNumbers?.length > 0 &&
                      props.client.phoneNumbers.map((p: IPhoneNumber, index: number) => (
                        <div style={{ display: 'block' }} key={`phoneNumber_${index}`}>
                          {p.description + ' ' + p.number}
                        </div>
                      ))}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <FormGroup>
        <Label>Circle of Support</Label>

        <div style={{ display: 'block' }}>
          <Row>
            {supportPeopleData?.length === 0 && <Col>-</Col>}
            {supportPeopleData &&
              supportPeopleData?.length > 0 &&
              supportPeopleData?.map((e) => (
                <Col md={4} lg={3} className={'mb-4'} key={Math.random()}>
                  {`${e.name} `}
                  <span className={'text-muted'}>{`${e.relationshipType}`}</span>
                  <small>
                    {e.contactDetails.map(
                      (d, i) =>
                        d.contactType !== '' && (
                          <div key={i}>
                            {d.contactType}: {d.details}
                          </div>
                        )
                    )}
                  </small>
                </Col>
              ))}
          </Row>
        </div>
      </FormGroup>
    </>
  );
};
