export const LoginTranslationEnglish = {
  login: {
    loginButton: 'Login',
  },
};

export const LoginTranslationSouthernTutchone = {
  login: {
    loginButton: 'Kwäyinda',
  },
};
