import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, CustomInput, ListGroup, ListGroupItem, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaArrowDown, FaArrowUp, FaPlus, FaPrint } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { Column, TableInstance, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { authAxios } from '../../services/AxiosService';
import { useLocation, useParams } from 'react-router';
import { UsersContext } from '../../contexts/UsersContext';
import { FileNotesDetails } from './FileNotesDetails';
import { FileContext } from './FileContext';
import { FileCheckboxCell, FileNoteMetadataCell, FileNoteTextCell, IFileNoteCell } from './FileNote';
import { TableGlobalFilter } from '../TableGlobalFilter/TableGlobalFilter';
import { IUser } from '../Users/UsersTable';
import { IDocument } from './Documents/Documents';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { sanitizeHTMLConfig } from '../../contexts/DomainContext';

const DOMPurify = require('dompurify');

export interface INote {
  noteId: string;
  text: string;
  dateCreated: number;
  userCreated: string;
  dateLastUpdated: number;
  userUpdated: string;
  documents?: IDocument[];
  isSelected?: boolean;
  fileId?: string;
  fileNumber?: string;
  clientId?: string;
  clientFirstName?: string;
  clientLastName?: string;
}

export const FileNotes: React.FC = () => {
  const params = useParams<{ fileId: string }>();
  const location = useLocation<{ deletedNoteId?: string }>();
  const usersContext = useContext(UsersContext);
  const fileContext = useContext(FileContext);
  const users = usersContext.users;
  const [showNewNote, setShowNewNote] = useState(false);
  const [printOption, setPrintOption] = useState('');
  const [isSelectingCaseNotes, setIsSelectingCaseNotes] = useState(false);
  const [selectedCaseNotes, setSelectedCaseNotes] = useState<string[]>([]);
  const [showClientNotes, setShowClientNotes] = useState(true);
  const [notes, setNotes] = useState<INote[]>([]);

  useEffect(() => {
    if (printOption !== '') {
      window.print();
      setPrintOption('');
      setIsSelectingCaseNotes(false);
      setSelectedCaseNotes([]);
    }
  }, [printOption]);

  useEffect(() => {
    const deletedNoteId = location?.state?.deletedNoteId;
    authAxios.get('/api/files/admin/' + params.fileId + '/notes').then((response) => {
      if (response.data.status === 'OK') {
        fileContext.setNotes(response.data.notes.filter((e: INote) => e.noteId !== deletedNoteId));
        setNotes(() => {
          if (showClientNotes) {
            return response.data.notes.filter((e: INote) => e.noteId !== deletedNoteId);
          } else {
            return response.data.notes.filter((e: INote) => e.noteId !== deletedNoteId && !e.clientId && e.fileId);
          }
        });
      }
    });
  }, [params.fileId]);

  useEffect(() => {
    if (!showClientNotes) {
      setNotes(fileContext.notes.filter((e: INote) => !e.clientId && e.fileId));
    } else {
      setNotes(fileContext.notes);
    }
  }, [showClientNotes, fileContext.notes]);

  const columns: Column<INote>[] = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'noteId',
      },
      {
        Header: 'User',
        accessor: (f: INote) => {
          if (f.userCreated) {
            const user = users.find((l: IUser) => l.userId === f.userCreated);
            return user?.firstName + ' ' + user?.lastName;
          }
        },
        id: 'userCreated',
        width: 100,
      },
      {
        Header: '',
        accessor: 'isSelected',
        width: 50,
        // eslint-disable-next-line react/display-name
        Cell: (cell: IFileNoteCell) => {
          return (
            <FileCheckboxCell
              cell={cell}
              selectedCaseNotes={selectedCaseNotes}
              setSelectedCaseNotes={setSelectedCaseNotes}
            />
          );
        },
      },
      {
        Header: 'Date Added',
        accessor: 'dateCreated',
        width: 250,
        // accessor: (f: INote) => formatDateTime(new Date(f.dateCreated)),
        // eslint-disable-next-line react/display-name
        Cell: (cell: IFileNoteCell) => {
          return <FileNoteMetadataCell cell={cell} />;
        },
      },
      {
        Header: 'Text',
        accessor: 'text',
        // eslint-disable-next-line react/display-name
        Cell: (cell: IFileNoteCell) => {
          return <FileNoteTextCell cell={cell} printNote={() => setPrintOption(cell.row.original.noteId)} />;
        },
      },
    ],
    [users, fileContext.notes, notes, selectedCaseNotes]
  );

  const data: INote[] = useMemo(() => notes, [notes, fileContext.notes]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    visibleColumns,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    setHiddenColumns,
    state,
    state: { pageIndex, pageSize },
  }: TableInstance<INote> = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: 'dateCreated',
            desc: true,
          },
        ],
        hiddenColumns: isSelectingCaseNotes ? ['userCreated', 'noteId'] : ['userCreated', 'noteId', 'isSelected'],
      },
    },
    // useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div>
        <header className={'report-header hide-printable'}>
          <h2 className='text-center'>CYFN File {fileContext.file.fileNumber} Case Notes</h2>
        </header>
        <ListGroup>
          {data.map((n) => (
            <ListGroupItem
              key={n.noteId}
              className={
                printOption === n.noteId ||
                printOption === 'all' ||
                (printOption === 'selected' && selectedCaseNotes.includes(n.noteId))
                  ? 'hide-printable'
                  : 'hide-always'
              }
            >
              <Row>
                <Col md={12} lg={2} className={'text-lg-right'}>
                  <strong>
                    {users.find((u) => u.userId === n.userCreated)
                      ? users.find((u) => u.userId === n.userCreated)?.lastName.toUpperCase() +
                        ', ' +
                        users.find((u) => u.userId === n.userCreated)?.firstName
                      : ''}
                  </strong>
                  <div className={'text-muted small mb-2'}>{formatDateTime(new Date(n.dateCreated))}</div>
                </Col>
                <Col md={8} lg={7} xl={6} className={'mb-3 mb-md-0'}>
                  <main dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(n.text, sanitizeHTMLConfig) }} />
                </Col>
                <Col>
                  {n.documents && n.documents.length > 0 && (
                    <div>
                      <h6>Documents</h6>
                      <ul>
                        {n.documents.map((d) => (
                          <li key={d.documentId}>
                            <Button
                              color={'link'}
                              className={'p-0'}
                              disabled={!d.uploadId}
                              style={{ wordBreak: 'break-all', textAlign: 'left' }}
                            >
                              {d.fileName}
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Col>
              </Row>
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
      <div className={'mb-5 no-print'}>
        {!showNewNote && (
          <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom d-flex flex-wrap sticky-top'}>
            <NavbarBrand className={'w-mobile-100'}>Case Notes</NavbarBrand>
            <div
              className={'d-flex flex-grow-1 justify-content-end flex-wrap navbar-actions'}
              style={{ rowGap: '0.5rem', columnGap: '0.5rem' }}
            >
              {isSelectingCaseNotes && (
                <>
                  <Button
                    className={'w-mobile-100'}
                    color={'link'}
                    onClick={() => {
                      setIsSelectingCaseNotes(false);
                      setSelectedCaseNotes([]);
                      setHiddenColumns(['userCreated', 'noteId', 'isSelected']);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={'w-mobile-100'}
                    color={'warning'}
                    onClick={() => {
                      setPrintOption('selected');
                    }}
                  >
                    <FaPrint /> Print Selected Case Notes
                  </Button>
                </>
              )}
              {!isSelectingCaseNotes && (
                <>
                  <CustomInput
                    className={'mt-2'}
                    type='checkbox'
                    id={'showClientNotes'}
                    name={'showClientNotes'}
                    label={'Show Client Case Notes'}
                    checked={showClientNotes}
                    onChange={() => {
                      setShowClientNotes(!showClientNotes);
                    }}
                  />
                  <Button
                    outline={true}
                    className={'w-mobile-100'}
                    onClick={() => {
                      setIsSelectingCaseNotes(true);
                      setHiddenColumns(['userCreated', 'noteId']);
                    }}
                  >
                    Select Case Notes
                  </Button>
                  <Button className={'w-mobile-100'} color={'warning'} onClick={() => setPrintOption('all')}>
                    <FaPrint /> Print All Case Notes
                  </Button>
                  <Button color={'primary'} className={'w-mobile-100'} onClick={() => setShowNewNote(true)}>
                    <FaPlus /> New Case Note
                  </Button>
                </>
              )}
            </div>
          </Navbar>
        )}
        {showNewNote && (
          <FileNotesDetails onSave={() => setShowNewNote(false)} onCancel={() => setShowNewNote(false)} />
        )}
        <table className={'table table-bordered'} {...getTableProps()}>
          <thead style={{ overflow: 'visible' }} className={'no-print'}>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.slice(0, -1).map((column: any) => (
                  <th
                    key={`files-table-thead-tr-${index}-${column.id}`}
                    width={column.width}
                    style={{ maxWidth: column.width }}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    {column.isSorted &&
                      (column.isSortedDesc ? <FaArrowDown className={'ml-2'} /> : <FaArrowUp className={'ml-2'} />)}
                  </th>
                ))}
                <TableGlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  colSpan={visibleColumns.length}
                  label={{ singular: 'Case Note', plural: 'Case Notes' }}
                />
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ overflow: 'visible' }} id={'table-body'}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={`files-table-tr-${row.id}`}
                  className={row.original.clientId ? 'bg-primary-light' : ''}
                >
                  {row.cells.map((cell: any, index: number) => {
                    return (
                      <td
                        key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                        colSpan={index === row.cells.length - 1 ? 2 : 1}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <TablePagination
          pageCount={pageCount}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizes={[20, 50, 100, 500]}
        />
      </div>
    </>
  );
};
