import * as React from 'react';
import { useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap';
import logo from '../Login/cyfn-logo.png';
import * as AuthenticationService from '../../services/AuthenticationService';
import { FaSignOutAlt, FaSync, FaUser } from 'react-icons/fa';
import { ConfirmModal } from '../../components/ConfirmModal/ConfirmModal';
import { AUTH_ACTION_SIGN_OUT, AuthContext } from '../../contexts/AuthContext';
import { EnvironmentInfoBar } from './EnvironmentBar';
import { useTranslation } from 'react-i18next';
import { MyUserContext } from '../../contexts/MyUserContext';
import {
  DEPARTMENT_BIRTH_WORKER,
  DEPARTMENT_CULTURAL_CONNECTIONS,
  DEPARTMENT_FAMILY_PRESERVATION,
  DEPARTMENT_JUSTICE,
} from '../../contexts/DomainContext';

export const NavigationBar: React.FC = () => {
  const [modal, setModal] = useState(false);
  const authContext = useContext(AuthContext);
  const myUser = useContext(MyUserContext);
  const { t } = useTranslation();
  const params = useParams<{ section?: string; sectionId?: string }>();

  const UserMenu: React.FC = () => {
    const userMenu = (
      <UncontrolledDropdown nav={true} className={'ml-sm-auto'}>
        <DropdownToggle nav={true} caret={true} className='initialsContainerDropdown'>
          <div className='initialsContainer'>
            <span className='userInitials'>{myUser.initials}</span>
          </div>
        </DropdownToggle>
        <DropdownMenu right={true} style={{ overflowY: 'auto' }}>
          <Link to='/my-account'>
            <DropdownItem>
              <FaUser /> My Account
            </DropdownItem>
          </Link>
          {authContext.state.expiringSoon && (
            <DropdownItem onClick={() => AuthenticationService.renew()}>
              <FaSync /> Renew Session
            </DropdownItem>
          )}
          <DropdownItem onClick={() => logoutToggle()}>
            <FaSignOutAlt /> Log Out
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    if (AuthenticationService.getUserSuperAdmin()) {
      return (
        <Nav navbar={true} className={'flex-grow-1 flex-wrap'}>
          <NavItem active={window.location.pathname === '/' || window.location.pathname.includes('/files')}>
            <Link to={{ pathname: '/files' }} className={'nav-link'}>
              Files
            </Link>
          </NavItem>
          <NavItem active={window.location.pathname.includes('/clients')}>
            <Link to={{ pathname: '/clients' }} className={'nav-link'}>
              {t('navigationBar.clientsLink')}
            </Link>
          </NavItem>
          <NavItem active={window.location.pathname.includes('/users')}>
            <Link to={{ pathname: '/users' }} className={'nav-link'}>
              Users
            </Link>
          </NavItem>
          <NavItem active={window.location.pathname.includes('/departments')}>
            <Link to={{ pathname: '/departments' }} className={'nav-link'}>
              Departments
            </Link>
          </NavItem>
          {myUser.departmentAccessIds.includes(DEPARTMENT_JUSTICE) ? (
            <>
              <NavItem active={window.location.pathname.includes('/glaude-members')}>
                <Link to={{ pathname: '/gladue-members' }} className={'nav-link'}>
                  Gladue Members
                </Link>
              </NavItem>
              <NavItem active={window.location.pathname.includes('/charges')}>
                <Link to={{ pathname: '/charges' }} className={'nav-link'}>
                  Charges
                </Link>
              </NavItem>
            </>
          ) : (
            <></>
          )}
          {myUser.departmentAccessIds.some((d) =>
            [DEPARTMENT_BIRTH_WORKER, DEPARTMENT_FAMILY_PRESERVATION, DEPARTMENT_CULTURAL_CONNECTIONS].includes(d)
          ) && (
            <NavItem active={window.location.pathname.includes('/workshops')}>
              <Link to={{ pathname: '/workshops' }} className={'nav-link'}>
                Workshops
              </Link>
            </NavItem>
          )}
          <UncontrolledDropdown nav={true} className={'nav-link__with-divider'}>
            <DropdownToggle nav={true} caret={true} className='reportsContainerDropdown'>
              Reports
            </DropdownToggle>
            <DropdownMenu style={{ zIndex: 1021, overflowY: 'auto' }}>
              <NavItem active={window.location.pathname.includes('/reports/exports')}>
                <Link to={'/reports/exports'}>
                  <DropdownItem>Data Exports</DropdownItem>
                </Link>
              </NavItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          {/*<NavItem>*/}
          {/*  <LanguageMenu />*/}
          {/*</NavItem>*/}
          <div className={'flex ml-sm-auto'}>{userMenu}</div>
        </Nav>
      );
    }
    if (AuthenticationService.loggedIn()) {
      return (
        <Nav navbar={true} className={'flex-grow-1 flex-wrap'}>
          <NavItem active={window.location.pathname === '/' || window.location.pathname.includes('/files')}>
            <Link to={{ pathname: '/' }} className={'nav-link'}>
              Files
            </Link>
          </NavItem>
          <NavItem active={window.location.pathname.includes('/clients')}>
            <Link to={{ pathname: '/clients' }} className={'nav-link'}>
              {t('navigationBar.clientsLink')}
            </Link>
          </NavItem>
          {myUser.departmentAccessIds.some((d) =>
            [DEPARTMENT_BIRTH_WORKER, DEPARTMENT_FAMILY_PRESERVATION, DEPARTMENT_CULTURAL_CONNECTIONS].includes(d)
          ) && (
            <NavItem active={window.location.pathname.includes('/workshops')}>
              <Link to={{ pathname: '/workshops' }} className={'nav-link'}>
                Workshops
              </Link>
            </NavItem>
          )}
          <div className={'flex ml-sm-auto'}>{userMenu}</div>
        </Nav>
      );
    }

    return (
      <Link to={'/login?redirect=' + window.location.pathname}>
        <Button outline={true} color='warning'>
          Log In
        </Button>
      </Link>
    );
  };

  const logout = () => {
    AuthenticationService.removeJwtFromStorage();
    authContext.dispatch(AUTH_ACTION_SIGN_OUT);
  };

  const logoutToggle = () => {
    setModal((s) => !s);
  };

  return (
    <header>
      <Navbar color='purple' light={true} dark={false} expand='xs' className='navigation-bar flex-wrap'>
        <EnvironmentInfoBar />
        <a href={'#main'} className={'skip-navigation nav-link'}>
          Skip Navigation
        </a>
        <NavbarBrand tag={Link} to={'/'}>
          <img src={logo} style={{ height: 50 }} className='cyfn-logo' alt='CYFN Logo' />
        </NavbarBrand>
        <hr className='d-block d-md-none w-100' />
        <UserMenu />
      </Navbar>
      <div
        style={{
          backgroundColor:
            params.section &&
            params.section !== 'gladue-members' &&
            params.sectionId &&
            params.sectionId !== 'add' &&
            params.sectionId !== 'merge'
              ? '#00000000'
              : '#f8f9fa',
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 80'>
          <path
            fill='#a9c4bf'
            fillOpacity='1'
            d='M0,50L80,55C160,60,320,70,480,65C640,60,800,30,960,22.5C1120,10,1280,7.5,1360,2.5L1440,0L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z'
          ></path>
        </svg>
      </div>
      <ConfirmModal
        isOpen={modal}
        title={'Confirm Logout'}
        onConfirm={() => logout()}
        onCancel={() => logoutToggle()}
        confirmButton={'Logout'}
      >
        You are about to logout.
      </ConfirmModal>
    </header>
  );
};
