import * as React from 'react';
import { useContext } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Login } from './components/Login/Login';
import { AUTH_STATE_LOADING, AUTH_STATE_SIGNED_IN, AUTH_STATE_SIGNED_OUT, AuthContext } from './contexts/AuthContext';
import { ForgotPassword } from './components/ForgotPassword/ForgotPassword';
import { ChangePassword } from './components/ChangePassword/ChangePassword';
import { DomainProvider } from './contexts/DomainContext';
import { UsersProvider } from './contexts/UsersContext';
import { SignedInLayout } from './SignedInLayout';
import { MyUserProvider } from './contexts/MyUserContext';
import { ClientsProvider } from './contexts/ClientsContext';

export const AppLayout = () => {
  const authContext = useContext(AuthContext);

  switch (authContext.state.type) {
    case AUTH_STATE_SIGNED_IN:
      return (
        <DomainProvider>
          <UsersProvider>
            <ClientsProvider>
              <MyUserProvider>
                <SignedInLayout />
              </MyUserProvider>
            </ClientsProvider>
          </UsersProvider>
        </DomainProvider>
      );
    case AUTH_STATE_LOADING:
      return (
        <Router>
          <Route path='/' render={() => <div>Checking Authentication</div>} />
        </Router>
      );
    case AUTH_STATE_SIGNED_OUT:
      return (
        <Router>
          <Switch>
            <Route path='/forgot-password'>
              <ForgotPassword />
            </Route>
            <Route path='/change-password/:token'>
              <ChangePassword changeType={'reset'} />
            </Route>
            <Route path='/create-password/:token'>
              <ChangePassword changeType={'create'} />
            </Route>
            <Route path='/login'>
              <Login />
            </Route>
            <Route path='/'>
              <Redirect to='/login' />
            </Route>
          </Switch>
        </Router>
      );
    default:
      return (
        <Router>
          <Route path='/'>
            <div>Authentication Error</div>
          </Route>
        </Router>
      );
  }
};
