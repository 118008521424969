import * as React from 'react';
import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Column, TableInstance, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { useMemo } from 'react';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { Button, Navbar, NavbarBrand } from 'reactstrap';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import { DomainContext } from '../../contexts/DomainContext';

interface IProps {
  selectRow?: (userId: string) => void;
}

export interface ICharge {
  chargeId?: string;
  criminalSectionCode: string;
  description?: string;
  isActive: boolean;
}

export const ChargesTable: React.FC<IProps> = (props) => {
  const domainContext = useContext(DomainContext);
  const history = useHistory();
  const charges = domainContext.charges.sort((a: ICharge, b: ICharge) =>
    a.criminalSectionCode.toUpperCase().localeCompare(b.criminalSectionCode.toUpperCase())
  ) as ICharge[];

  const columns: Column<ICharge>[] = useMemo(
    () => [
      {
        Header: 'Criminal Section Code',
        accessor: 'criminalSectionCode',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Active',
        id: 'active',
        accessor: (c) => (c.isActive ? 'Yes' : 'No'),
      },
    ],
    []
  );
  const data: ICharge[] = useMemo(() => charges, [charges]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    prepareRow,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<ICharge> = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: 20 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const selectRow = (chargeId: string) => {
    if (props.selectRow) {
      props.selectRow(chargeId);
    } else {
      history.push('/charges/' + chargeId);
    }
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>Charges</NavbarBrand>
        <Button color={'primary'} tag={Link} to={{ pathname: '/charges/add' }}>
          <FaPlus className='mr-2' />
          Add Charge
        </Button>
      </Navbar>
      <div className={'table-responsive'}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`charges-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`charges-table-thead-tr-${index}-${column.id}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);

              return (
                <tr key={`charges-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`charges-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() => selectRow(row.original.chargeId)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
      />
    </div>
  );
};
