import * as React from 'react';
import { useState } from 'react';
import { Link, Route, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { authAxios } from '../../services/AxiosService';
import * as H from 'history';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';
import { IUser } from './UsersTable';
import { UserDetails } from './UserDetails';
import { UserEmailHistory } from './UserEmailHistory';
import { UserEmailView } from './UserEmailView';
import { UserLoginHistory } from './UserLoginHistory';
import { useWindowDimensions } from '../../hooks/WindowHooks';
import { useHistory } from 'react-router';

export const User = () => {
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const params = useParams<{ userId: string; tab?: string }>();
  const history = useHistory();
  const userId = params.userId;
  const { xs, sm } = useWindowDimensions();
  const verticalMenu = xs || sm;

  useEffect(() => {
    authAxios
      .get('/api/users/admin/' + userId)
      .then((response) => {
        console.log('response: ', response);
        if (response.data && response.data.status && response.data.status.includes('KO')) {
          history.push('/404');
        } else {
          const user = response.data;
          setUser(user);
        }
      })
      .catch(() => {
        history.push('/404');
      });
  }, [userId]);

  const tab: string = params.tab || 'details';

  const NavItemLink = (to: H.LocationDescriptor, active: boolean, name: any) => {
    return (
      <NavItem>
        <NavLink tag={Link} to={to} active={active}>
          {name}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <article className='page'>
      <div className={'page-header-image'} />
      <header className='page-header'>
        <Container fluid={true}>
          {user !== undefined && (
            <div className='d-flex flex-column'>
              <h3 className='page-header__title order-2'>{user?.lastName.toUpperCase() + ', ' + user?.firstName}</h3>
              <h5 className='page-header__id order-1 text-primary'>{user?.email}</h5>
            </div>
          )}
          <div className={verticalMenu ? 'd-block d-md-none pb-4' : 'd-none d-md-block'}>
            <Nav tabs={!verticalMenu} pills={verticalMenu} vertical={verticalMenu}>
              {NavItemLink('/users/' + params.userId + '/details', tab === 'details', 'Details')}
              {NavItemLink('/users/' + params.userId + '/emails', tab === 'emails', 'Emails')}
              {NavItemLink('/users/' + params.userId + '/login-history', tab === 'login-history', 'Login History')}
            </Nav>
          </div>
        </Container>
      </header>
      <div style={{ backgroundColor: 'white' }}>
        <Route exact={true} path='/users/:userId/(details)?' component={UserDetails} />
        <Route exact={true} path='/users/:userId/emails' component={UserEmailHistory} />
        <Route exact={true} path='/users/:userId/emails/:emailMessageId' component={UserEmailView} />
        <Route exact={true} path='/users/:userId/login-history' component={UserLoginHistory} />
      </div>
    </article>
  );
};
