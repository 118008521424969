import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Column, TableInstance, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { useMemo } from 'react';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { Button, Navbar, NavbarBrand } from 'reactstrap';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { TablePagination } from '../TablePagination/TablePagination';
import { authAxios } from '../../services/AxiosService';
import { DomainContext, IDepartment, IStatus } from '../../contexts/DomainContext';
import { formatDateFromISONumber } from '../../formatters/DateTimeFormatter';
import { DropdownMultiSelectCheckboxes } from '../DropdownMultiSelectCheckboxes/DropdownMultiSelectCheckboxes';

interface IProps {
  selectRow?: (userId: string) => void;
}

export interface IWorkshop {
  workshopId?: string;
  name: string;
  description?: string;
  departmentId?: string;
  theme?: string;
  workshopEvents: IEventWorkshop[];
  // participantIds: string[];
  participants: IWorkshopParticipant[];
  status?: string;
  capacity?: string;
  locationId?: string;
  location?: string;
}

export interface IEventWorkshop {
  eventWorkshopId?: string;
  startDate: number;
  endDate?: number;
  attendanceTaken: boolean;
  participants: IEventParticipant[];
}

export interface IWorkshopParticipant {
  participantId: string;
  registrationStatusId?: string;
}

export interface IEventParticipant {
  participantId: string;
  attendanceId?: string;
}

export const WorkshopsTable: React.FC<IProps> = (props) => {
  const domainContext = useContext(DomainContext);
  const location = useLocation<{ deletedWorkshopId?: string }>();
  const history = useHistory();
  const [workshops, setWorkshops] = useState<IWorkshop[]>([]);
  const departments = domainContext.departments;
  const statuses = domainContext.statuses;

  useEffect(() => {
    authAxios.get('/api/workshops').then((response) => {
      if (response.data) {
        setWorkshops(
          response.data.sort((a: IWorkshop, b: IWorkshop) =>
            a.name.toUpperCase().localeCompare(b.name.toUpperCase())
          ) as IWorkshop[]
        );
      }
    });

    const deletedWorkshopId = location?.state?.deletedWorkshopId;

    authAxios.get('/api/workshops').then((response) => {
      setWorkshops(response.data.filter((c: any) => c.workshopId !== deletedWorkshopId));
    });
  }, []);

  const columns: Column<IWorkshop>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Department',
        id: 'departmentId',
        accessor: (w: IWorkshop) => {
          return departments.find((d: IDepartment) => w.departmentId === d.departmentId)?.name;
        },
      },
      {
        Header: 'Status',
        id: 'statusId',
        accessor: (w: IWorkshop) => {
          return statuses.find((s: IStatus) => w.status === s.statusId)?.name;
        },
        // eslint-disable-next-line react/display-name
        Filter: ({ column: { filterValue = statuses.map((s) => s.statusId), preFilteredRows, setFilter, id } }) => {
          return (
            <DropdownMultiSelectCheckboxes
              values={filterValue}
              keyPrefix='statusId'
              setValues={(val: string[]) => {
                setFilter(val);
              }}
              options={statuses.map((s: IStatus) => ({
                value: s.statusId,
                display: s.name,
              }))}
            />
          );
        },
        filter: (rows: any, id: any, filterValue: string[]) => {
          return rows.filter((row: any) => {
            if (filterValue.length === 0) {
              return true;
            } else {
              const rowValue = row.original['status'];
              return filterValue.find((val: any) => rowValue === val);
            }
          });
        },
      },
      {
        Header: 'Start Date',
        id: 'startDate',
        accessor: (w: IWorkshop) => {
          const earliestEvent = w.workshopEvents.reduce(
            (a, b) => (a.startDate < b.startDate ? a : b),
            w.workshopEvents[0] || undefined
          );
          if (earliestEvent) {
            return earliestEvent.startDate;
          } else {
            return '';
          }
        },
        Cell: (cell: any) => {
          if (cell && cell.row.values && cell.row.values.startDate) {
            return <span>{formatDateFromISONumber(cell.row.values.startDate)}</span>;
          } else {
            return <span />;
          }
        },
      },
      {
        Header: 'End Date',
        id: 'endDate',
        accessor: (w: IWorkshop) => {
          let latestEvent: any = undefined;
          w.workshopEvents.forEach((e) => {
            if (e.endDate && latestEvent === undefined) {
              latestEvent = e;
            } else if (e.endDate && latestEvent?.endDate) {
              if (latestEvent.endDate < e.endDate) {
                latestEvent = e;
              }
            }
          });
          if (latestEvent) {
            return latestEvent?.endDate;
          } else {
            return '';
          }
        },
        Cell: (cell: any) => {
          if (cell && cell.row.values && cell.row.values.endDate) {
            return <span>{formatDateFromISONumber(cell.row.values.endDate)}</span>;
          } else {
            return <span />;
          }
        },
      },
    ],
    [departments, statuses]
  );
  const data: IWorkshop[] = useMemo(() => workshops, [workshops]);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    prepareRow,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: TableInstance<IWorkshop> = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            id: 'startDate',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const selectRow = (workshopId: string) => {
    if (props.selectRow) {
      props.selectRow(workshopId);
    } else {
      history.push('/workshops/' + workshopId);
    }
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-auto'>Workshops</NavbarBrand>
        <Button color={'primary'} tag={Link} to={{ pathname: '/workshops/add' }}>
          <FaPlus className='mr-2' />
          Add Workshop
        </Button>
      </Navbar>
      <div className={'table-responsive'}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`workshops-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`workshops-table-thead-tr-${index}-${column.id}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);

              return (
                <tr key={`workshops-table-tr-${row.id}`} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={`workshops-table-td-${cell.row.id}-${cell.column.id}`}
                        {...cell.getCellProps()}
                        onClick={() => selectRow(row.original.workshopId)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
      />
    </div>
  );
};
