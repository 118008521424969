import * as React from 'react';
import { useContext } from 'react';
import { FaEnvelope, FaPrint } from 'react-icons/fa';
import { Button, Col, Container, Nav, Navbar, NavbarBrand, Row } from 'reactstrap';
import { formatDate } from '../../formatters/DateTimeFormatter';
import { FileContext } from './FileContext';
import { DomainContext, INDIGENOUS_GROUP_FIRST_NATION } from '../../contexts/DomainContext';
import { ClientsContext } from '../../contexts/ClientsContext';

export const FilePrintRequest: React.FC = () => {
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const file = fileContext.file;
  const clientsContext = useContext(ClientsContext);
  const clients = clientsContext.state.clients;

  const mode = window.location.origin.replace(/(^\w+:|^)\/\//, '');
  const jpEmail =
    mode === 'cyfn-cm.ca'
      ? 'sac.principedejordanrn-nrjordansprinciple.isc@canada.ca'
      : 'makecyfn.dev+sac.principedejordanrn-nrjordansprinciple.isc@gmail.com';

  const formatCurrencyCommasTwoDigits = (amount: string | undefined) => {
    if (amount) {
      const floatToUse = parseFloat(amount.toString().replace(/[, ]+/g, ''));
      return Number.isNaN(floatToUse)
        ? '0.00'
        : floatToUse
            .toFixed(2)
            .toString()
            .replace(/-[^0-9.]/g, '')
            .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  const formattedAmountToNumber = (amount: string | undefined) => {
    if (amount) {
      if (amount.length > 0 && amount.charAt(0) === '-' && amount !== '-') {
        return -Math.abs(Number(amount.toString().replace(/[, ]+|[- ]+/g, '')));
      } else {
        return Number(amount.toString().replace(/[,]+|[- ]+/g, ''));
      }
    } else {
      return 0;
    }
  };

  const calculateTotal = (cost: string | undefined, duration: string | undefined) => {
    if (cost && duration) {
      const total = formattedAmountToNumber(cost) * Number(duration);
      return formatCurrencyCommasTwoDigits(total.toString());
    } else {
      return '';
    }
  };

  const emailText = () => {
    const lineBreak = '%0D%0A';
    const li = '%E2%80%A2%20';
    const header =
      `Date: ${formatDate(file.createdDate ? new Date(file.createdDate) : undefined)}` +
      lineBreak +
      `File Type: ${domainContext.fileTypes.find((e) => e.fileTypeId === file.fileTypeId)?.name}` +
      lineBreak;

    const applications =
      file.clientIds &&
      file.clientIds.length > 0 &&
      clients
        .filter((i) => file.clientIds.includes(i.clientId))
        .map(
          (i, index) =>
            `Applicant ${index + 1}: ${i.lastName.toUpperCase() + ', ' + i.firstName}` +
            lineBreak +
            li +
            `Date of Birth: ${formatDate(i.birthDate ? new Date(i.birthDate) : undefined)}` +
            lineBreak +
            li +
            `Status Number: ${i.statusCardNum}` +
            lineBreak +
            li +
            `Indigenous Ancestry: ${i.indigenousInfo
              .map(
                (ii) =>
                  domainContext.indigenousGroups.find((ig) => ig.indigenousGroupId === ii.indigenousGroupId)?.name +
                  (ii.bandNation
                    ? '(' +
                      (ii.indigenousGroupId === INDIGENOUS_GROUP_FIRST_NATION
                        ? domainContext.yukonFns.find((fn) => fn.yukonFirstNationId === ii.bandNation)
                          ? domainContext.yukonFns.find((fn) => fn.yukonFirstNationId === ii.bandNation)?.name
                          : ''
                        : ii.bandNation) +
                      ')'
                    : '')
              )
              .join(', ')}` +
            lineBreak
        )
        .join();

    const background = `Request Rationale: ${file.details}` + lineBreak;
    const requests =
      file.requests &&
      file.requests
        .map(
          (r, index) =>
            `Request ${index + 1}: ${r.description}` +
            lineBreak +
            li +
            `Cost ($): ${r.cost}` +
            lineBreak +
            li +
            `Frequency: ${domainContext.frequencies.find((f) => f.frequencyId === r.frequencyId)?.name}` +
            lineBreak +
            li +
            `Duration: ${r.duration}` +
            lineBreak +
            li +
            `Total ($): ${calculateTotal(r.cost, r.duration)}` +
            lineBreak
        )
        .join();
    return `${header}${lineBreak}${applications}${lineBreak}${background}${lineBreak}${requests}`;
  };

  const allTotals = () => {
    let total = 0;
    file.requests.forEach((r) => {
      total = total + formattedAmountToNumber(calculateTotal(r.cost, r.duration));
    });
    return formatCurrencyCommasTwoDigits(total.toString());
  };

  return (
    <div>
      <div className='d-print-none'>
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom'}>
          <NavbarBrand>{'Print Request'}</NavbarBrand>
          <Nav className={'ml-auto'}>
            <Button
              tag={'a'}
              href={`mailto:${jpEmail}?subject=Request&body=${emailText()}`}
              color='secondary'
              target={'_blank'}
              className={'mr-2'}
            >
              <FaEnvelope /> Email
            </Button>

            <Button color='warning' type='submit' onClick={() => window.print()}>
              <FaPrint /> Print
            </Button>
          </Nav>
        </Navbar>
      </div>
      <Container fluid={true} className={'my-3 container--no-margin-print'}>
        <div className='report' style={{ padding: '15px' }}>
          <header className={'report-header'}>
            <hr className={'d-none d-print-block'} />
            <h2 className='text-center'>COUNCIL OF YUKON FIRST NATIONS</h2>
            <h3 className='text-center h5 text-muted'>APPLICATION</h3>
          </header>
          <div id={'application-to-print'}>
            <p>
              <Row>
                <Col>Date: {formatDate(file.createdDate ? new Date(file.createdDate) : undefined)}</Col>
                <Col>File Type: {domainContext.fileTypes.find((e) => e.fileTypeId === file.fileTypeId)?.name}</Col>
              </Row>
            </p>
            {fileContext.clientIds &&
              fileContext.clientIds.length > 0 &&
              clients
                .filter((i) => fileContext.clientIds.includes(i.clientId))
                .map((i, index) => (
                  <p key={i.clientId}>
                    <Row>
                      <Col>
                        <b>Applicant {index + 1}:</b> {i.lastName.toUpperCase() + ', ' + i.firstName}
                        <li>Date of Birth: {formatDate(i.birthDate ? new Date(i.birthDate) : undefined)}</li>
                        <li>Status Number: {i.statusCardNum}</li>
                        <li>
                          Indigenous Ancestry:{' '}
                          {i.indigenousInfo
                            .map(
                              (ii) =>
                                domainContext.indigenousGroups.find(
                                  (ig) => ig.indigenousGroupId === ii.indigenousGroupId
                                )?.name +
                                (ii.bandNation
                                  ? '(' +
                                    (ii.indigenousGroupId === INDIGENOUS_GROUP_FIRST_NATION
                                      ? domainContext.yukonFns.find((fn) => fn.yukonFirstNationId === ii.bandNation)
                                        ? domainContext.yukonFns.find((fn) => fn.yukonFirstNationId === ii.bandNation)
                                            ?.name
                                        : ''
                                      : ii.bandNation) +
                                    ')'
                                  : '')
                            )
                            .join(', ')}
                        </li>
                      </Col>
                    </Row>
                  </p>
                ))}
            <p>
              <Row>
                <Col>Request Rationale</Col>
              </Row>
              <Row>
                <Col style={{ whiteSpace: 'pre-wrap' }}>{file.details}</Col>
              </Row>
            </p>
            {file.requests && file.requests.length > 0 && file.jordansPrincipleFields?.requestName && (
              <p>
                <b>Request Name: </b>
                {file.jordansPrincipleFields?.requestName}
              </p>
            )}
            {file.requests &&
              file.requests.map((r, index) => (
                <p key={r.requestId}>
                  <Row>
                    <Col>
                      <b>Request {index + 1}:</b> {r.description}
                      <li>Cost ($): {r.cost}</li>
                      <li>Frequency: {domainContext.frequencies.find((f) => f.frequencyId === r.frequencyId)?.name}</li>
                      <li>Duration: {r.duration}</li>
                      <li>Total ($): {calculateTotal(r.cost, r.duration)}</li>
                    </Col>
                  </Row>
                </p>
              ))}
            {file.requests && file.requests.length > 0 && (
              <p>
                <b>Total ($):</b> {allTotals()}
              </p>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
